import React from 'react';

var bgimg1 = require('./../../images/Collage.webp');

class Team1 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 mobile-page-padding bg-pink">
                    <div className="container">
                        {/* TITLE START 
                       <div className="section-head mb-5">
                            <div className="sx-separator-outer separator-center">
                                <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg2.default + ')' }}>
                                    <h3 className="sep-line-one">Super Team</h3>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                    <div className="about-home-3 m-b30 bg-white">
                                        <h2 className="m-t0 m-b20 sx-tilte text-center">Super Team</h2>
                                        <p className="text-center">We are a professional architectural and interior designing firm, started by young entrepreneurs keeping a goal in the mind that every house, the building should be designed in the most creative and amazing manner, it should reflect the beauty of the architecture. Obsessed with the idea, the founder created a powerhouse for all types of architecture and interior designing, one platform for all that is “NaksheWala.com”.</p>
                                       
                                        
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                    <div className="video-section-full-v2">
                                        <div className="video-section-full bg-no-repeat bg-contain bg-center overlay-wraper m-b30" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                          
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* IMAGE CAROUSEL START 
                        <div className="section-content">
                            <div className="row team-item-four">
                                {teamMembers.map((item, index) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6 m-b30" key={index}>
                                        <div className="our-team-2 ">
                                            <div className="profile-image">
                                                <img src={item.image.default} alt="" />
                                                
                                            </div>
                                            <div className="figcaption text-black">
                                                <h4 className="m-t0"><NavLink to={"/team-single"}>{item.membername}</NavLink></h4>
                                                <span className="m-b0">{item.position}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>*/}
                    </div>
                </div>
            </>
        );
    }
};

export default Team1;