/*eslint no-redeclare: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
import {React} from 'react';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from "react-router-dom";
import { registerUser } from '../../features/user/userActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';

var logo = '/assets/images/NAKSHEWALA_LOGO.png';

const Register = () => {
  const [agree, setAgree] = useState(false); 
  const { mobile, success, onBoarding, error } = useSelector(
    (state) => state.user
  )
  const dispatch = useDispatch()
  const { register, handleSubmit } = useForm()
  const history = useHistory()

  const enabled =
  agree
  ;
  
  useEffect(() => {
    if(error) {
      toast.error(error, {autoClose:3000})
    }
    if(!mobile) {
      history.push('/login')
    }

    if(success === 'error') {
      alert("Server Error")
    }
    if(success === true && onBoarding === true) {
      toast.success("Registration completed Successfully", {autoClose:3000})
      setTimeout(()=> {
       history.push("/profile");
      },3000)
    }
   
   
   
  }, [success, onBoarding, mobile, error, history])

  const submitForm = (data) => {
    dispatch(registerUser({ firstName: data.firstName, lastName: data.lastName, email: data.email }))
  
  }

  /*const submitForm = async (data) => {
    console.log(data)
    try {
      const response = await fetch('', {
        method: 'POST',
        body: JSON.stringify({
        "uid": Number(localStorage.getItem('uid')),
        "first_name": data.firstName,
        "last_name":  data.lastName,
        "email": data.email,
        "mobile": Number(localStorage.getItem('otpNumber')) 
        }),
        "headers": {
          "Accept": "application/json",
          "x-api-key": "ee80487e-a00e-4898-bc5a-ac5db6078d27",
          "x-api-secret": "IEwJcMy8BcS4j7IqUxXQS1Tgyd43m1cHRGerBdTRqkJ9jNOmTjfUfWdOs8YVdDKgQo8VSLdtWkpIcbRIQLQhv0o8MwQCOTnS66ydzxQBbmO4dbTvH2HqfqjfxuCXeQ5G",
          "Content-Type": "application/json",
          'Authorization': `Bearer ${localStorage.getItem('token')}` 
        },
      });
      if (!response.ok) {
        const result = await response.json();
        const msg = JSON.stringify(result);
        //console.log(msg)
      }
      const result = await response.json();
      const msg = JSON.stringify(result);
      if(result.success) {
        toast.success("Registration completed Successfully", {autoClose:3000})
        setTimeout(()=> {
          history.push("/profile");
        },3000)
      }
      console.log(msg)
    } catch (err) {
      console.log(err)
    } 
  }; **/
  const handleError = (errors) => {
    toast.error("All fields must be filled", {autoClose:3000})
  
  };  
  const registerOptions = {
    firstName: { required: "First Name is required", },
    lastName: { required: "Last Name is required", },
    email: { required: "Email is required", }
  };

return (
<>
<div id="main-wrapper" className="oxyy-login-register">
  <div className="container-fluid px-0">
    <div className="row g-0 min-vh-100"> 

      <div className="col-md-5">
        <div className="hero-wrap h-100 bg-primary-2 ">
          <div className="hero-mask opacity-3 bg-primary"></div>
          <div className="hero-content w-100">
            <div className="container d-flex flex-column min-vh-100 for-mobile-lo">
              <div className="row g-0">
                <div className="col-11 col-md-10 col-lg-9 mx-auto">
                  <div className="logo mt-5 mb-5 mb-md-0"> <a className="d-flex for-logo-mobile" href="/" title="Oxyy"><img src={logo} alt="Nakshewala" /></a> </div>
                </div>
              </div>
              <div className="row g-0 my-auto">
                <div className="col-11 col-md-10 col-lg-9 mx-auto">
                  <p className="text-4 lh-base">We're glad to see you again!</p>
                  <h1 className="text-9 fw-600 mb-5">Right Design and Right ideas matter a lot of on Interior design.</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="col-md-7 d-flex flex-column">
        <div className="container pt-5">
          <div className="row g-0">
            <div className="col-11 mx-auto">
              <p className="text-right text-2 mb-0 ">Already a member? <NavLink className='red' to={"/login"}>Sign In</NavLink></p>
            </div>
          </div>
        </div>
        <div className="container my-auto py-5">
          <div className="row g-0">
            <div className="col-11 col-md-10 col-lg-9 col-xl-8 mx-auto">
              <h3 className="fw-600 mb-3 login-h3">Sign up to Nakshewala</h3>
             
              
              <form id="registerForm" onSubmit={handleSubmit(submitForm, handleError)}>
                <div className="mb-3">
                  <label for="fullName" className="form-label fw-500">First Name</label>
                  <input {...register('firstName', registerOptions.firstName) } type="text" className="form-control bg-ec border-light" id="firstName" required="" placeholder="Enter First Name" />
                </div>
                <div className="mb-3">
                  <label for="fullName" className="form-label fw-500">Last Name</label>
                  <input {...register('lastName', registerOptions.lastName) } type="text" className="form-control bg-ec border-light" id="lastName" required="" placeholder="Enter Last Name" />
                </div>
                <div className="mb-3">
                  <label for="emailAddress" className="form-label fw-500">Email Address</label>
                  <input {...register('email', registerOptions.email) } type="email" className="form-control bg-ec border-light" id="emailAddress" required="" placeholder="Enter Email Address" />
                </div>
               
                <div className="form-check pl-0">
                  <input onClick={(e) => setAgree(e.target.checked)} id="agree" name="agree" className="form-check-input" type="checkbox" />
                  <label className="form-check-label my-4" for="agree">I agree to the <a className='red' href="#">Terms</a> and <a className='red' href="#">Privacy Policy</a>.</label>
                </div>
                <button disabled={!enabled} className="btn btn-primary shadow-none" type="submit">Create Account</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  </div>
</div>
<ToastContainer />
</>
)
}

export default Register;