import React from "react";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import Recommended from "./../../Elements/Recommended";
import About5 from "./../../Elements/About5";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import HomeCta from "./../../Elements/HomeCta";
import InteriorSection1 from "./../../Elements/InteriorSection1";
import configData from "./../../../config.js";

const VastuBedroom = () => {
  const [seo, setSeo] = useState([]);
  const [page_content, setPage_content] = useState("");

  useEffect(() => {
    async function fetchProducts() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=vastu-for-bedrooms.php`
        );
        setSeo(pageSeo.data);
        setPage_content(pageSeo.data[0].page_content);
      } catch (error) {
        console.log(error);
      }
    }

    fetchProducts();
  }, []);

  return (
    <>
      {seo.map((item, index) => (
        <Helmet>
          <title>{item.pagetitle}</title>
          <meta name="description" content={item.metadescription} />
          <meta name="keywords" content={item.metakeywords} />
        </Helmet>
      ))}

      <Header2 />

      <div className="page-content">
        {seo.map((item, index) => (
          <InteriorSection1
            page_name={item.page_name}
            short_des_heading={item.short_des_heading}
            sub_title={item.sub_title}
            short_description={item.short_description}
          />
        ))}

        <div className="p-t30 p-b30 ">
          <div className="container">
            <div className="section-content">
              <h2>Vastu For Bedrooms</h2>

              <p>
                A bedroom is the heart of the house and one of those spaces in a
                home where one expects to relax and find a sound sleep after a
                long day at work. And Vastu shastra — supported by the flow of
                cosmic energy within an area to boost living conditions and
                promote harmony — can help in ensuring that this sanctuary is
                stuffed with good vibes. If applied correctly, these Vastu for
                bedroom tips can facilitate your enhance the positive
                environment reception. Furthermore, colors play a significant
                role in every bedroom. Keep this in mind, and before we dive
                deeper into what’s necessary for a Vastu-compliant bedroom.
              </p>

              <p>
                As per the Vastu for the bedroom, the perfect direction to make
                the bedroom is the southwest corner of the house. Bedroom
                direction as per Vastu says that you simply should never build
                the bedroom within the northeast direction because it's reserved
                for the pooja room. Similarly, the southeast direction isn't the
                best bedroom direction as per Vastu.
              </p>

              <p>
                Coming to the doorway to the bedroom, the door should either air
                the north, west, or side of the walls, as per the Vastu for the
                main bedroom. The door should never air the south-side wall.
                Single doors are ideal for a bedroom.
              </p>

              <div className="single-service-list">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <h2>
                      There are some Vastu tips, especially for Kid's Bedroom
                    </h2>

                    <ul className="list-angle-right anchor-line first bed-ctt">
                      <li>
                        <b>Kid’s Bedroom-Direction and Furniture</b>

                        <p>
                          The Kid’s room should be in the west direction of the
                          area while the door should be east-facing.
                          Furthermore, the sleeping direction as per Vastu
                          should be towards the west or south-west corner of the
                          area to herald healthiness and fortune.
                        </p>
                      </li>

                      <li>
                        <b>Vastu-compliant Colours of Kid's Room</b>
                        <p>
                          Green, light blue, purple, and yellow are the right
                          colors for a kid’s bedroom. Bright colors add
                          positivity and encourage children to move. These
                          colors enhance cognitive abilities like concentration
                          and focus.
                        </p>
                      </li>

                      <li>
                        <b>Materials use in Kid’s Bedroom</b>
                        <p>
                          A kid’s room should be fabricated from natural
                          materials like wood, consistent with the Vastu for the
                          bedroom. All furniture must be light colored. try and
                          avoid metal-frame beds and furniture since these could
                          attract negative vibes and disrupt Vastu.
                        </p>
                      </li>
                    </ul>

                    <h2>Mistakes we do while designing a kid's bedroom</h2>

                    <ul className="bedroom-mistakes">
                      <li>Avoid dark color furniture, wallpaper, or paint</li>
                      <li>
                        Get obviate creaking and damaged furniture immediately
                      </li>
                      <li>
                        Do not place mirrors ahead of the bed as they will be
                        the rationale for nightmares
                      </li>
                      <li>
                        Limited use of electronics items they emit harmful
                        radiations which is not good for health.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HomeCta />
        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>
      <About5 page_content={page_content} />
      <Footer />
    </>
  );
};
export default VastuBedroom;
