import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import configData from "./../../config.js";

class HomeTrendingHouseDesign extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      DataisLoaded: false,
    };
  }

  //API CALL
  componentDidMount() {
    fetch(`${configData.ADMIN_URL}/api/home-radymade-house-design`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json.data,
          DataisLoaded: true,
        });
      });
  }

  render() {
    const options = {
      loop: true,
      dotsEach: 3,
      autoplay: false,
      center: false,
      //stagePadding: 100,
      //items: 1,
      margin: 40,

      dots: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1.2,
          margin: 20,
          //dots: true,
        },
        640: {
          items: 2,
          margin: 20,
          //dots: true,
        },
        768: {
          items: 2,
          margin: 20,
          //dots: true,
        },
        991: {
          items: 4,
          margin: 15,
          nav: true,
          //dots: true,
        },
        1200: {
          items: 4,
          margin: 15,
          nav: true,
          //dots: true,
        },
      },
    };

    const { DataisLoaded, items } = this.state;
    if (!DataisLoaded)
      return (
        <div>
          <p> Loading.... </p>{" "}
        </div>
      );

    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-bg  mobile-page-padding p-t20 p-b50 `}
        >
          <div className="">
            <div className="">
              {/* TITLE START */}
              <div className="section-head container">
                <div className="sx-separator-outer separator-left">
                  <NavLink to={"/readymade-house-design.php"}>
                    <h3 className="sep-line-one">
                      {" "}
                      <span className="red">Trending</span> House Design{" "}
                      <img
                        alt="trending-img"
                        className="mobile-hidden"
                        src="assets/images/Trending.svg"
                      />
                      <button className="btn view_all float-right">
                        View All
                      </button>
                    </h3>
                  </NavLink>
                </div>
              </div>

              {/* TITLE END */}
              <div className="container work-carousel-outer container-mobile">
                <OwlCarousel
                  className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center "
                  {...options}
                >
                  {items.map((item, index) => (
                    <div key={index} className="item">
                      <div className="project-mas hover-shadow m-a30 bg-white">
                        <div className="">
                          <div className="image-efct-one">
                            <span className="design_code">
                              Design Code : {item.plan_no}{" "}
                            </span>

                            <NavLink to={item.slug}>
                              <LazyLoadImage
                                className="w-100 h-213"
                                effect="blur"
                                src={
                                  `${configData.ADMIN_URL}/assets/images/banner/` +
                                  item.header_image
                                }
                                alt={item.title}
                              />
                            </NavLink>
                          </div>
                          <div className="project-info ">
                            <h4 className="sx-tilte mt-3 ml-2 fs-17 min-height-40">
                              <NavLink to={item.slug}>{item.title}</NavLink>
                            </h4>
                            <p className="bg-gray p-10 fs-14 shadow-cs text-center">
                              <i class="fa fa-expand"></i> {item.width}
                              sqft&nbsp; &nbsp;
                              <i class="fa fa-retweet"></i> {item.plot_size}
                              &nbsp;&nbsp;
                              <i class="fa fa-compass"></i> {item.direction}
                            </p>
                            <NavLink to={item.slug}>
                              <i className="link-plus bg-primary" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomeTrendingHouseDesign;
