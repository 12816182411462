import {React, useEffect} from 'react';
import { useHistory } from "react-router-dom";

const InstitutionalPlan = () => {
    const history = useHistory();
    useEffect(() => {
       history.push('/commercial-designs/10')
      })

    return(
        <></>
    )
}
export default InstitutionalPlan;