import React from "react";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
//config url
import configData from "./../../config.js";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useContext } from "react";
import { IPInfoContext } from "ip-info-react";

var bgimg2 = require("./../../images/background/bg-5.png");

const HomeWhyNakshewala = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [requirement, setRequirement] = useState("");
  const [countryInfo, setCountryInfo] = useState(null);
  const [ip, setIp] = useState(null);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [number, setNumber] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [requirementError, setRequirementError] = useState("");
  const userInfo = useContext(IPInfoContext);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  // const enabled =
  //   requirement.length > 0 &&
  //   name.length > 0 &&
  //   email.length > 0 &&
  //   mobile.length > 0;
  useEffect(() => {
    const fetchCountryData = async () => {
      setIp(userInfo.ip);
      if (ip) {
        try {
          const response = await fetch(`https://api.country.is/${ip}`);
          const data = await response.json();
          setCountryInfo(data.country);
        } catch (error) {
          console.error("Error fetching country data:", error);
        }
      }
    };
    fetchCountryData();
  }, [userInfo.ip, ip]);
  const handleChangeNumber = (number) => {
    setNumber(number);
    if (number) {
      try {
        const phoneNumber = parsePhoneNumberFromString(number);

        const countryCode = `${
          phoneNumber ? phoneNumber.countryCallingCode : ""
        }`;
        const nationalNumber = phoneNumber ? phoneNumber.nationalNumber : "";
        setCode(countryCode);
        // setValue(nationalNumber);
        setMobile(code + nationalNumber);

        if (isValidPhoneNumber(number)) {
          console.log(number);
          setError(false);
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      }
    } else {
      setCode("");
      // setValue("");
      setError(true);
    }
  };
  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    setNameError(value.trim() === "" ? "Name is required" : "");
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(value) ? "Please enter a valid email" : "");
  };
  const handleRequirementChange = (e) => {
    const value = e.target.value;
    setRequirement(value);
    setRequirementError(value.trim() === "" ? "Requirement is required" : "");
  };

  //contact form POST API
  async function saveQuery(event) {
    event.preventDefault();
    if (name && email && mobile && requirement) {
      let data = { name, email, mobile, requirement };
      if (isValidPhoneNumber("+" + mobile)) {
        let result = await fetch(`${configData.ADMIN_URL}/api/contact`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            Accept: "applicatiion/json",
          },

          body: JSON.stringify(data),
        });

        const item = await result.json();
        if (item.status === "success") {
          window.location.href = `/thanks.php`;
          setIsOpen(false);
        } else {
          toast.error("Please Try Again!", { autoClose: 3000 });
        }
      } else {
        toast.error("Invalid Phone Number!", { autoClose: 3000 });
      }
    } else {
      if (!mobile) {
        setError(true);
      }
      if (!name) {
        setNameError("Name is required");
      }
      if (!email) {
        setEmailError("Email is required");
      }
      if (!requirement) {
        setRequirementError("Requirement is required");
      }
    }
  }

  return (
    <>
      <div className="p-t30 p-b0">
        <div className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className=" m-b30 bg-white">
                  <h3 className="m-t0 fsm-20 m-b20 sx-tilte">
                    Why <span className="red">Nakshe</span>Wala.com
                    <span className="red">?</span>
                  </h3>
                  <ul className="list-angle-right anchor-line">
                    <li>
                      <i class="fa-circle-check"></i>{" "}
                      <NavLink to="/">
                        Customer satisfaction oriented service.
                      </NavLink>
                    </li>
                    <li>
                      <i class="fa-solid fa-circle-check"></i>{" "}
                      <NavLink to="/">
                        Cost effective Floor Plans which save cost of
                        construction & time of Residential house design.
                      </NavLink>
                    </li>

                    <li>
                      <i class="fa-circle-check"></i>{" "}
                      <NavLink to="/">Competitive service charges.</NavLink>
                    </li>

                    <li>
                      <i class="fa-circle-check"></i>{" "}
                      <NavLink to="/why-nakshewala.php">
                        We are 100% committed to your satisfaction for quality
                        and service, as we design every house like we ourselves
                        going to live in that home. We are the industry’s...
                        <span className="red">
                          <u>Read more</u>
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                  <div className="text-center mobile-center">
                    <button
                      onClick={showModal}
                      className="desktop-btn btn-half"
                    >
                      <span>Consult Now</span>
                    </button>
                  </div>

                  <Modal show={isOpen} onHide={hideModal}>
                    <Modal.Header>
                      <div className="modal-header-img"></div>
                      <p className="formText text-center">
                        Discuss with Expert Now
                      </p>
                      <button
                        onClick={hideModal}
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </Modal.Header>

                    <Modal.Body>
                      <form method="post" className="text-center">
                        <div class="result"></div>
                        <div class="form-group">
                          <input
                            onChange={handleNameChange}
                            type="text"
                            className="form-input"
                            placeholder="Enter Name"
                          />
                          {nameError && (
                            <p className="error-msg">{nameError}</p>
                          )}
                        </div>

                        <div class="form-group">
                          <input
                            onChange={handleEmailChange}
                            required=""
                            type="email"
                            className="form-input"
                            placeholder="Enter Email"
                          />
                          {emailError && (
                            <p className="error-msg">{emailError}</p>
                          )}
                        </div>

                        <div class="form-group">
                          <div className="custom-phone-input">
                            <PhoneInput
                              international
                              className="session-phone-control"
                              defaultCountry={countryInfo}
                              countryCallingCodeEditable={false}
                              value={number || ""} // Ensuring value is a string
                              placeholder="Enter phone number"
                              onChange={handleChangeNumber}
                            />
                          </div>
                          {error && (
                            <p className="errorMsg">
                              Please enter a valid number and country code
                            </p>
                          )}
                        </div>

                        <div class=" form-group">
                          <textarea
                            onChange={handleRequirementChange}
                            required=""
                            className="form-textarea"
                            placeholder="Enter Plot Size & Requirements"
                          ></textarea>
                          {requirementError && (
                            <p className="error-msg">{requirementError}</p>
                          )}
                        </div>

                        <button
                          onClick={saveQuery}
                          //disabled={!enabled}
                          className="submitBtn"
                          type="submit"
                        >
                          Get Free Quote
                        </button>
                      </form>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                  </Modal>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 mobile-hidden">
                <div className="counter-blocks">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                      <div
                        className="sx-count  text-black sx-icon-box-wraper bg-repeat  bg-pink p-a15"
                        style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                      >
                        <h2 className="st-count-number sx-text-primary text-left">
                          <span className="counter">
                            <LazyLoadImage
                              effect="blur"
                              className="w-40"
                              src="assets/images/oriented11.webp"
                              alt="Customer Oriented"
                            />
                          </span>
                        </h2>
                        <h4 className="m-tb0 fs-18">Customer Oriented</h4>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                      <div
                        className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-pink p-a15"
                        style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                      >
                        <h2 className="st-count-number sx-text-primary text-left">
                          <span className="counter">
                            <LazyLoadImage
                              effect="blur"
                              src="assets/images/cost.svg"
                              alt="Cost effective"
                            />
                          </span>
                        </h2>
                        <h4 className="m-tb0 fs-18">Cost Effective</h4>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                      <div
                        className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-pink p-a15"
                        style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                      >
                        <h2 className="st-count-number sx-text-primary text-left">
                          <span className="counter">
                            <LazyLoadImage
                              effect="blur"
                              src="assets/images/professional.svg"
                              alt="Cost effective"
                            />
                          </span>
                        </h2>
                        <h4 className="m-tb0 fs-18">Professional Architects</h4>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 m-b30">
                      <div
                        className="sx-count text-black sx-icon-box-wraper bg-repeat  bg-pink p-a15"
                        style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                      >
                        <h2 className="st-count-number sx-text-primary text-left">
                          <span className="counter">
                            <LazyLoadImage
                              effect="blur"
                              src="assets/images/phone-contact.png"
                              alt="Telephonic Support"
                            />
                          </span>
                        </h2>
                        <h4 className="m-tb0 fs-18">Telephonic Support</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default HomeWhyNakshewala;
