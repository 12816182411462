import React, { Suspense } from "react";
import Header2 from "../Common/Header2";
import PtnrBanner from "./../Elements/PartnerBanner";
const Footer = React.lazy(() => import("../Common/Footer"));
const Associate2 = React.lazy(() => import("./../Elements/Partnerassoc"));
const PartnerTeam = React.lazy(() => import("./../Elements/Partnerteam"));
var bnrimg = require("./../../images/banner/ptnrbanner1.png");

const Partner = () => {
  return (
    <>
      <Header2 />

      <PtnrBanner
        title="Generate"
        pagename="Abo"
        description="More Work & Profit"
        title1="While Working With Us."
        bgimage={bnrimg}
      />
      <Suspense fallback={<div>Loading...</div>}>
        <Associate2 />
        <PartnerTeam />
        <div className="p-t50 p-b50" style={{ background: "#FFF1F0" }}>
          <div className="container">

            <p className="">
              The nakshewala.com Associates Program helps Architects /Supplier/ Contractor / Civil Engineer to 
			  grow their businesses while working with us.
            </p>
			
			<p className="">
			<b>NaksheWala.com</b> invites  Architects /Supplier/ Contractor / Civil Engineer for providing Architectural 
			& Engineering Services for their various units and other development projects for various categories. 
			</p>
			
			<b>How to get Associates with us</b>
			
			<div class="single-service-list">
			<div class="row">
			<div class="col-lg-12 col-md-12">
			<ul class="a-rrt-de-cco">
			<li>
			 Apply HERE
			</li>
			<li>
			  We get back to you within 2 working day
			</li>
			<li>
			   Start earning with us.
			</li>
			</ul>
			</div>
			</div>
			</div>
			
			<b>Why to get Associates with Nakshewala.com</b>
			
			<div class="single-service-list">
			<div class="row">
			<div class="col-lg-12 col-md-12">
			<ul class="a-rrt-de-cco">
			<li>
			  Great amount of earning.
			</li>
			<li>
			  Exclusive promotions locally and nearby area.
			</li>
			<li>
			 Dedicated support 6 days a week.
			</li>
			<li>
			 Grow your business.
			</li>
			<li>
			 Get verified leads.
			</li>
			<li>
			 Positive cash-flow.
			</li>
			</ul>
			</div>
			</div>
			</div>
			
			
			<p className="">
			<i>We hope this helped you to understand better why it is beneficial to associate with nakshewala.com and 
			how you can do it quickly and easily.  So don't wait any longer - join us now at Nakshewala.com and let's 
			get started on making amazing things happen!</i>
			</p>
			
			<p className="">
			Contact us for more information: <a className="partner-supportpage" href="mailto:support@nakshewala.com">support@nakshewala.com</a> or via phone under +91 80108 22233
			</p>
			 
                          
          </div>
        </div>
		<Footer />
      </Suspense>

    
    </>
  );
};
export default Partner;
