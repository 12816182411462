import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const services = [
  {
    image: require("./../../images/Commercial-Building.webp"),
    title: "Commercial Building Elevation Design",
    address: "/commercial-building-elevation.php",
    description:
      "Many of our projects cannot be featured in this section due to the Security levels of the space.",
  },
  {
    image: require("./../../images/Shopping-Complex.webp"),
    title: "Shopping Complex Building Elevation",
    address: "/shopping-complex-building-designs.php",
    description:
      "Many of our projects cannot be featured in this section due to the Security levels of the space.",
  },
  {
    image: require("./../../images/SCHOOL-&-COLLEGE.webp"),
    title: "School And College Building Elevation",
    address: "/school-and-collage-building-designs.php",
    description:
      "Many of our projects cannot be featured in this section due to the Security levels of the space.",
  },

  {
    image: require("./../../images/HOSPITAL-CLINIC.webp"),
    title: "Hospital And Clinic Building Elevation",
    address: "/hospital-and-clinic-building-designs.php",
    description:
      "Many of our projects cannot be featured in this section due to the Security levels of the space.",
  },
  {
    image: require("./../../images/HOTEL_Banquet.webp"),
    title: "Hotel And Banquet Building Elevation",
    address: "/hotel-and-banquet-building-designs.php",
    description:
      "Many of our projects cannot be featured in this section due to the Security levels of the space.",
  },
  {
    image: require("./../../images/Industrial.webp"),
    title: "Industrial And Factory Building Designs",
    address: "/industrial-and-factory-building-designs.php",
    description:
      "Many of our projects cannot be featured in this section due to the Security levels of the space.",
  },
  {
    image: require("./../../images/Corporate-Builiding.webp"),
    title: "Corporate And Office Building Elevation",
    address: "/corporate-and-office-building-designs.php",
    description:
      "Many of our projects cannot be featured in this section due to the Security levels of the space.",
  },
];

//var bgimg1 = require('./../../images/background/cross-line2.png');

class HomeCommercialElevation extends React.Component {
  render() {
    const options1 = {
      loop: true,
      autoplay: false,
      center: false,
      //stagePadding: 100,
      items: 1,
      margin: 40,

      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
          margin: 20,
        },
        640: {
          items: 2,
          margin: 20,
        },
        768: {
          items: 2,
          margin: 20,
        },
        991: {
          items: 4.5,
          margin: 15,
          nav: true,
          dots: false,
        },
        1200: {
          items: 4.5,
          margin: 15,
          nav: true,
          dots: false,
        },
      },
    };

    const options = {
      loop: true,
      autoplay: false,
      center: false,
      dotsEach: 3,
      items: 3,
      margin: 40,
      nav: true,
      dots: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 1,
        },
        991: {
          items: 1,
        },
      },
    };
    return (
      <>
        <div className="desktop-hidden section-full p-tb40 bg-gray inner-page-padding ">
          <div className="container">
            <div className="section-content">
              {/* TITLE START */}
              <div className="section-head">
                <div className="sx-separator-outer separator-left">
                  <NavLink to={"/commercial-building-elevation.php"}>
                    <h3 className="sep-line-one">
                      <span className="red">Commercial Building</span> Elevation{" "}
                      <img className="w-3"
                        alt="commercial"
                        src="assets/images/Commercial-icon.svg"
                      />{" "}
                    </h3>
                  </NavLink>
                </div>
              </div>
              {/* TITLE END */}
              <div className="work-carousel-outer ">
                <OwlCarousel
                  showThumbs={false}
                  className="owl-carousel mfp-gallery project-carousel project-carousel1 owl-btn-vertical-center commercials"
                  {...options}
                >
                  {services.map((item, index) => (
                    <NavLink key={index} to={item.address}>
                      <div key={index} className="item">
                        <div
                          className="sx-box   image-single-carousel bg-cover"
                          style={{
                            backgroundImage: "url(" + item.image + ")",
                          }}
                        >
                          <div className="sx-info  p-t20 text-white">
                            <h4 className="sx-tilte m-t0 fsm-15">
                              <NavLink to={item.address}>
                                {item.title}{" "}
                                <span className="red v-align-m">
                                  <i className="fa fa-arrow-right"></i>
                                </span>
                              </NavLink>
                            </h4>
                            <p></p>
                            <NavLink
                              to={item.address}
                              className="site-button btn-half button-sm d-none"
                            >
                              <span>View All</span>
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </NavLink>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-hidden section-full p-tb40 bg-gray inner-page-padding">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <NavLink to={"/commercial-building-elevation.php"}>
                  <h3 className="sep-line-one">
                    Commercial Building <span className="red">Elevation</span>{" "}
                    <img
                      src="assets/images/Commercial-icon.svg"
                      className="w-3"
                      alt="Commercial elevations"
                    />
                  </h3>
                </NavLink>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content for-comm-evl-bld">
              <div className="client-grid m-b40">
                <div className="row justify-content-center">
                  <OwlCarousel
                    className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center commercials"
                    {...options1}
                  >
                    {services.map((item, index) => (
                      <div className="m-b10 " key={index}>
                        <div className="shadow-cs">
                          <NavLink
                            to={item.address}
                            className="client-logo-pic"
                          >
                            <LazyLoadImage
                              alt={item.title}
                              effect="blur"
                              className="w-100 h-240 "
                              src={item.image}
                            />

                            <div>
                              <span>
                                <i className="fa fa-search"></i>
                              </span>
                            </div>
                            <NavLink to={item.address}>
                              <p className="fs-18 house_area_text mb-0">
                                {item.title}
                              </p>
                            </NavLink>
                          </NavLink>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <div className="section-content-commercial_new"></div>
          </div>
        </div>
      </>
    );
  }
}

export default HomeCommercialElevation;
