import React from "react";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import Recommended from "./../../Elements/Recommended";
import About5 from "./../../Elements/About5";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import HomeCta from "./../../Elements/HomeCta";
import InteriorSection1 from "./../../Elements/InteriorSection1";
import configData from "./../../../config.js";

const VastuNewHouse = () => {
  const [seo, setSeo] = useState([]);
  const [page_content, setPage_content] = useState("");

  useEffect(() => {
    async function fetchProducts() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=vastu-for-new-house-construction.php`
        );
        setSeo(pageSeo.data);
        setPage_content(pageSeo.data[0].page_content);
      } catch (error) {
        console.log(error);
      }
    }

    fetchProducts();
  }, []);

  return (
    <>
      {seo.map((item, index) => (
        <Helmet>
          <title>{item.pagetitle}</title>
          <meta name="description" content={item.metadescription} />
          <meta name="keywords" content={item.metakeywords} />
        </Helmet>
      ))}

      <Header2 />

      <div className="page-content">
        {seo.map((item, index) => (
          <InteriorSection1
            page_name={item.page_name}
            short_des_heading={item.short_des_heading}
            sub_title={item.sub_title}
            short_description={item.short_description}
          />
        ))}

        <HomeCta />
        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>
      <About5 page_content={page_content} />
      <Footer />
    </>
  );
};
export default VastuNewHouse;
