import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const logos = [
  {
    image: require("./../../images/bedroom_area.webp"),
    title: "Bedroom",
    url: "/bedroom-interior-designs.php",
  },
  {
    image: require("./../../images/kitchen.webp"),
    title: "Kitchen",
    url: "/kitchen-interior-designs.php",
  },
  {
    image: require("./../../images/Living-room.webp"),
    title: " living Area",
    url: "/living-room-interior-designs.php",
  },
  {
    image: require("./../../images/dining.webp"),
    title: " Dinning Area",
    url: "/dining-interior-designs.php",
  },
  {
    image: require("./../../images/kids.webp"),
    title: "Kids room",
    url: "/kids-room-interior-designs.php",
  },
  {
    image: require("./../../images/toilet.webp"),
    title: "Toilet",
    url: "/toilet-interior-designs.php",
  },
  {
    image: require("./../../images/office.webp"),
    title: "Office ",
    url: "/office-interior-designs.php",
  },
  {
    image: require("./../../images/showroom.webp"),
    title: "Showrooms",
    url: "/show-room-interior-designs.php",
  },
  {
    image: require("./../../images/restaurant.webp"),
    title: "Restaurants",
    url: "/restaurant-interior-designs.php",
  },

  {
    image: require("./../../images/drawing.webp"),
    title: "Drawing Room",
    url: "/drawing-room-interior-designs.php",
  },
];

const logo = [
  {
    image: require("./../../images/banquet_hall.webp"),
    title: "Banquet Hall",
    url: "/hotel-and-banquet-hall-interior-designs.php",
  },
  {
    image: require("./../../images/gym.webp"),
    title: "GYM Interior",
    url: "/gym-and-yoga-center.php",
  },
  {
    image: require("./../../images/jewellery.webp"),
    title: "Jewellery",
    url: "/jewellery-showroom-interior-designs.php",
  },
  {
    image: require("./../../images/entarnce.webp"),
    title: "Entrance",
    url: "/entrance-interior-design.php",
  },
  {
    image: require("./../../images/pooja.webp"),
    title: "Pooja",
    url: "/puja-room-interior-designs.php",
  },
];
var bgimg1 = require("./../../images/background/bg-12.webp");

function HomeInteriorDesktop() {
  const [isActive, setActive] = useState("false");

  const ToggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
      <div
        className="section-full mobile-hidden mobile-page-padding bg-gray  p-t20 p-b10 bg-repeat"
        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
      >
        <div className="container">
          {/* TITLE START */}
          <div className="section-head">
            <div className="sx-separator-outer separator-left">
              <NavLink to={"/interior-designing.php"}>
                {" "}
                <h3 className="sep-line-one">
                  <span className="red">Latest </span> In Interiors{" "}
                  <img
                    className="w-3"
                    src="assets/images/interior.svg"
                    alt=""
                  />
                </h3>
              </NavLink>
            </div>
          </div>
          {/* TITLE END */}
          <div className="section-content">
            <div className="client-grid m-b20">
              <div className="row">
                {logos.map((item, index) => (
                  <div
                    className="col-xs-2 col-half-offset  m-b10 p-1"
                    key={index}
                  >
                    <div className="shadow-cs">
                      <a href={item.url} className="client-logo-pic">
                        <LazyLoadImage
                          className="w-100"
                          effect="blur"
                          src={item.image}
                          alt={item.title}
                        />

                        <div>
                          <span>
                            <i className="fa fa-search"></i>
                          </span>
                        </div>
                      </a>
                      <a href={item.url}>
                        <p className="interior_area_text">{item.title}</p>
                      </a>
                    </div>
                  </div>
                ))}
              </div>

              <div className={isActive ? "d-none" : "d-block "}>
                <div className="row">
                  {logo.map((item, index) => (
                    <div
                      className="col-xs-2 col-half-offset  m-b10 p-1"
                      key={index}
                    >
                      <div className="shadow-cs">
                        <a href={item.url} className="client-logo-pic">
                          <LazyLoadImage
                            className="w-100"
                            effect="blur"
                            src={item.image}
                            alt={item.title}
                          />
                          <div>
                            <span>
                              <i className="fa fa-search"></i>
                            </span>
                          </div>
                        </a>
                        <a href={item.url}>
                          <p className="interior_area_text">{item.title}</p>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="text-center">
            <button onClick={ToggleClass} className="interior_view_more mb-4">
              {isActive ? "View More " : "View Less "}{" "}
              {isActive ? (
                <i className="fa fa-angle-down"></i>
              ) : (
                <i className="fa fa-angle-up"></i>
              )}{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeInteriorDesktop;
