import React from 'react';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import axios from 'axios'
import { useEffect, useState } from "react";
 //config url
import configData from "./../../config.js";

const Faq = () => {
    
    let history = useHistory();
    const [todos, setTodos] = useState([]);
    const [totalTodos, setTotalTodos] = useState("");
    //const [totalRecords, setTotalRecords] = useState("");
    const [currentPage, setcurrentPage] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(20);
    const [pageNumberLimit, setpageNumberLimit] = useState(5);
    const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
    const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
    //Getting Url Params
   
    const search = useLocation().search; 
    const currenPageParms = new URLSearchParams(search).get('currentpage');

    useEffect(() => { 
        async function fetchData() {
            if(currenPageParms) {
                if(currenPageParms !== 'null') {
                setcurrentPage(currenPageParms);
              }
           }
         try {
         const config = {
            "headers": {
                "Accept": "application/json",
                "x-api-key": configData.X_API_KEY,
                "x-api-secret": configData.X_API_SECRET,
                "Content-Type": "application/json"
                
            },
            }
          let item = await axios.get(`${configData.ADMIN_URL}/api/faqs?page=${currenPageParms ? currenPageParms : 1}`,config)
            setTodos(item.data.data.data)
            setTotalTodos(item.data.data.total);
            setitemsPerPage(20);
            setpageNumberLimit(5);
            setcurrentPage(item.data.data.current_page)
           
         } catch (error) {
            console.log(error)
         }
        }
        fetchData();
    }, [currenPageParms]);

    const pages = [];
  for (let i = 1; i <= Math.ceil(totalTodos / itemsPerPage); i++) {
    pages.push(i);
  }
  //console.log(pages.length);
  // Change page
  async function paginate(pageNumber) { 
    try {
      const config = {
         "headers": {
             "Accept": "application/json",
             "x-api-key": configData.X_API_KEY,
             "x-api-secret": configData.X_API_SECRET,
             "Content-Type": "application/json"
             
         },
         }
       let item = await axios.get(`${configData.ADMIN_URL}/api/faqs?page=${pageNumber}`,config)
         setTodos(item.data.data.data)
         setTotalTodos(item.data.data.total);
         setcurrentPage(item.data.data.current_page)
        
      } catch (error) {
         console.log(error)
      }
    
    setcurrentPage(pageNumber) };
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={() => paginate(number)}
          className={currentPage === number ? "active" : null}
        >
         <NavLink onClick={() => paginate(number)} to={`?currentpage=` + number} className='page-link'>
              {number}
            </ NavLink>
       
        </li>
      );
    } else {
      return null;
    }
  });


    async function handleNextbtn() {
      
      history.push(`?currentpage=${Number(currentPage) + 1}`);
      const config = {
        "headers": {
            "Accept": "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            "Content-Type": "application/json"
            
        },
        }
      const item = await axios.get(`${configData.ADMIN_URL}/api/faqs?page=${Number(currentPage) + 1}`,config)
      setTodos(item.data.data.data);
    
      setcurrentPage(item.data.data.current_page)
      setTotalTodos(item.data.data.total);

    setcurrentPage(Number(currentPage) + 1);
   

    if (Number(currentPage) + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  async function handlePrevbtn()  {

      history.push(`?currentpage=${Number(currentPage) - 1}`);
      const config = {
        "headers": {
            "Accept": "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            "Content-Type": "application/json"
            
        },
        }
      const item = await axios.get(`${configData.ADMIN_URL}/api/faqs?page=${Number(currentPage) - 1}`,config)
      setTodos(item.data.data.data);
      //setSeo(item.data.seo);
      setTotalTodos(item.data.data.total);
      setcurrentPage(item.data.data.current_page)

    setcurrentPage(currentPage - 1);

    if ((Number(currentPage) - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }
   
        return (
            <>
                <Header2 />
                <div className="page-content">
                   {/* <Banner title="Frequently Asked Questions" pagename="Faq" description="The essence of interior design will always be about people and how they live. It is about the realities of what makes for an attractive, civilized." bgimage={bnrimg.default}/>
                     SECTION CONTENTG START */}
                    <div className="section-full p-t80 p-b80 inner-page-padding for-mobile-spacing-faq">
                        <div className="container">
                        <div className="text-center"><img className="w-50 mtm-20" src="assets/images/faqs.webp" alt="FAQ" /></div>
                        
                            <div className="faq-1">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="sx-separator-outer separator-center">
                                        <div className=" mb-2" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>
                                            
                                            <h2 class="mb-2 heading-h2">Frequently <span className="red">Asked Questions</span></h2>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                <div className="row">
                                   
                                    <div className="col-md-6">
                                        {/* Accordian */}
                                        <div className="sx-accordion acc-bg-gray" id="accordion5">
                                        {todos.slice(0,5).map((item, index) => (
                                            <div key={index} className="panel sx-panel">
                                                <div className="acod-head acc-actives">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" className='collapsed' href={`#collapseOne${item.id}`} data-parent={`#accordion5`}>
                                                        {item.question}
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id={`collapseOne${item.id}`} className="acod-body collapse">
                                                    <div className="acod-content p-a15">
                                                        <p>{item.answer}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            ))}

                                          
                                        </div>

                                        
                                    </div>

                                     <div className="col-md-6 faq-list">
                                     {todos.slice(6,12).map((item, index) => (
                                            <div key={index} className="panel sx-panel">
                                                <div className="acod-head acc-actives">
                                                    <h4 className="acod-title">
                                                        <a data-toggle="collapse" className='collapsed' href={`#collapseOne${item.id}`} data-parent={`#accordion5`}>
                                                        {item.question}
                                                            <span className="indicator"><i className="fa fa-plus" /></span>
                                                        </a>
                                                    </h4>
                                                </div>
                                                <div id={`collapseOne${item.id}`} className="acod-body collapse ">
                                                    <div className="acod-content p-a15">
                                                        <p>{item.answer}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            ))}
                                     
                                </div>

                                </div>
                            </div>
                            <div className="paginate">
                                    <ul className="pageNumbers">
                                        <li>
                                          <button
                                            onClick={handlePrevbtn}
                                            disabled={currentPage === pages[0] ? true : false}
                                          >
                                            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Previous
                                          </button>
                                        </li>
                                        {pageDecrementBtn}
                                        {renderPageNumbers}
                                        {pageIncrementBtn}

                                        <li>
                                          <button
                                            onClick={handleNextbtn}
                                            disabled={currentPage === pages[pages.length - 1] ? true : false}
                                          >
                                            Next <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                                          </button>
                                        </li>
                                       

                                      </ul>
                                </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </>
        );
   
};

export default Faq;