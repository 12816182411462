import React from "react";
import Header2 from "../Common/Header2";
import Footer from "../Common/Footer";
import Recommended from "./../Elements/Recommended";
import InteriorSideNav from "./../Elements/InteriorSideNav";
import CommomInteriorCollapse from "./../Elements/CommomInteriorCollapse";

const LuxuriousInterior = () => {
  return (
    <>
      <Header2 />
      <div className="page-content">
        <div className="section-full p-t80 p-b50 mobile-page-padding retro-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 sticky_column for-desktop">
                <div className="about4-section m-b20 bg-gray p-a20 for-interior-style-part">
                  <div className="widget widget_services">
                    <InteriorSideNav />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="section-content">
                  <div className="service-single-block m-b30">
                    <div className="sx-media">
                      <img src="assets/images/modeint.webp" alt="" />
                    </div>
                    <h3 className="m-t30 sx-tilte">
                      <b>Luxurious Interior Design</b>
                    </h3>

                    <p>
                      In today's time, everyone loves lavish designs, especially
                      when it involves interiors. ’Luxury” relates very closely
                      to the client’s unique taste and way of life. Your
                      interior should be a reflection of your own style,
                      lifestyle, and attitude towards life. If you look around
                      your home and it doesn't represent the personality that
                      you want from your home it's not worth it. Luxury interior
                      design is a creative process done by experienced interior
                      designers, and with the assistance of professional
                      designers, we will enhance the design and feel of the
                      house, and make it luxuries for living. Luxury interior
                      design can appear in any style, from transitional to
                      traditional, however, all of them share something common:
                      refined, thoughtful decisions every step of the way.
                    </p>

                    <p>
                      NaksheWala.com offers interior design as per your
                      requirements to reflect your ideas and need for a perfect
                      design. We also consider the taste and choice of our
                      clients to design a Luxury Interior. The modernistic
                      luxury design explores new ideas, pays close attention to
                      each detail, and precisely considers materials and
                      homestretches. At every stage of the planning process,
                      every element is key; from a functional layout to the
                      ultimate scenery touches.{" "}
                    </p>

                    <p>
                      {" "}
                      To make the concept of “luxury interior design” strong, we
                      executed the general design in a very systematic way
                      without losing authenticity — and will reflect the user’s
                      personal needs and tastes, and improve their quality of
                      life and therefore the experience of their interior
                      environment.
                    </p>

                    <p>
                      Spend less fortune with us and get the best Luxury design
                      at affordable prices. You can get benefitted from our
                      professional Interior design team and get a Luxury
                      interior plan. We work on your requirements exclusively
                      and design an interior. We can provide your home a
                      luxurious interior in terms of design, easy living &
                      brilliance. We believe in creativity and quality. We enjoy
                      the whole process of designing as we feel that we are
                      going to live in that house.
                    </p>
                  </div>

                  <div className="col-lg-4 col-md-12 sticky_column for-mobile-interior-view">
                    <div className="about4-section m-b20 bg-gray p-a20">
                      <div className="widget widget_services">
                        <InteriorSideNav />
                      </div>
                    </div>
                  </div>
                  <CommomInteriorCollapse />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>
      <Footer />
    </>
  );
};
export default LuxuriousInterior;
