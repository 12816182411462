import React from "react";
import About5 from "./About5";
import HomeFaq from "./HomeFaq";
import Recommended from "./Recommended";
import Banner2 from "./Banner2";


class ServiceBottom extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }
 
  render() {
    
    return <><Banner2 />

    <HomeFaq />
    <Recommended title="Recommended Services" bgcolor="bg-white" />
    <About5 page_content={this.props.page_content} /></>;
  }
}

export default ServiceBottom;
