import React from 'react';
import Footer from './../Common/Footer';
import {useState, useEffect } from "react";
import axios from 'axios';
import {NavLink} from 'react-router-dom';
import Recommended from './../Elements/Recommended';
import About5 from './../Elements/About5';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import EnquireNow from './../Elements/EnquireNow';
import {Helmet} from "react-helmet";
//import { bindMagnific } from '../../assets/js/popup';
import ReactHtmlParser from 'html-react-parser';
import HeaderText from './../Elements/HeaderText';
import configData from "./../../config.js";
import Header2 from './../Common/Header2';

const bannerUrl = `${configData.ADMIN_URL}/assets/images/recent_project/`;

const SingleRecentProject = () => {
  
    const [items, setItems] = useState([])
    const [images, setImages] = useState([])
   // const [otherprojects, setOtherprojects] = useState([])
   // 
    
    
  /* const handleClick = (id, e) => {
    async function fetchData() {
        try {
          let item = await axios.get(
            `${configData.ADMIN_URL}/api/recent_project_detail/${id}`
          );
          setItems(item.data.data1);
          setImages(item.data.images);
          //setOtherprojects(item.data.otherprojects)
        } catch (error) {
          console.log(error);
        }
      }
      fetchData();
    } */
    
    useEffect(() => {
        
    var url = window.location.pathname;
    var result= url.split('/');
    var page = result[result.length-2];
    console.log(page)
        async function fetchData() {
          try {
            let item = await axios.get(
              `${configData.ADMIN_URL}/api/recent_project_detail/${page}`
            );
           console.log(item)
           setItems(item.data.data1);
          setImages(item.data.images);
         
          } catch (error) {
            console.log(error);
          }
        }
        fetchData();
      }, []);
     

      const options = {
        loop: true,
        autoplay: false,
        center: false,
        margin: 40,
        dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 1.3,
                margin: 20,
                dots: false,
            },
            640: {
                items: 1.3,
                margin: 20,
                dots: false,
            },
            768: {
                items: 1.3,
                margin: 20,
                dots: false,
            },
            991: {
                items: 4.5,
                margin: 15,
                dots: false,

            },
            1200: {
                items: 4.5,
                margin: 15

            }

        }
    };
    
        return (
            <>
               
               {items.map((item, index) => (
                <Helmet key={index}>  
                <title>{item.pagetitle}</title>
                <meta name="description" content={item.metadescription} />
                <meta name="keywords" content={item.metakeywords} />
                <meta property="og:type" content="Page" />
                <meta property="og:title" content={item.pagetitle} />
                <meta property="og:description" content={item.metadescription} />
                <meta property="og:url" content={window.location.href} />
                <link rel="canonical" href={configData.CANONICAL_URL+window.location.pathname.slice(1)} />
                <meta property="og:site_name" content={configData.SITE_NAME} />
                <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/recent_project/${item.image}`} />
                </Helmet> 
        ))}
         <Header2/>
         <div className="page-content">
                    {items.map((item, index) => (
                      <div className="sx-bnr-inr overlay-wraper bg-center bg-cover height-fit" style={{ backgroundImage: `url('${bannerUrl + item.image}')` }} >

                       
                            <div class="overlay-main bg-black opacity-05"></div>
                            <div className="container">
                                <div className="sx-bnr-inr-entry">
                                    <div className="banner-title-outer text-left mtb-20">
                                        <div className="banner-title-name ">
                                            <h2 className="m-tb0 mb-3">{item.title}</h2>
                                            <h5 className="text-white mb-3">{item.owner} {item.location}</h5>

                                            <p className="fs-16 lh-1">
                                               <HeaderText short_description={ReactHtmlParser(item.short_description)} />
                                            </p>
                                        </div>
                                        <EnquireNow/>
                                    </div>
                                </div>
                            </div>
                         </div>
                    ))}

                    {items.map((item, index) => (
                        <div key={index}>
                            <ul className="sx-breadcrumb breadcrumb-style-2 bg-black pl-2 pb-1">
                                <div className="container">
                                    <li className="red"><NavLink className="red" to={"./"}>Home </NavLink></li>
                                    <NavLink to={"/interior-designing.php"}>
                                        <li className="red">Interior Design </li>
                                    </NavLink>
                                    <li>{item.title}</li>
                                </div>
                            </ul>
                        </div>
                    ))}

                    {Object.entries(images).map(([key, item], i) => (
                        <div>
                            <div className="section-head container">
                                <div className="sx-separator-outer separator-left mt-2">
                                    <h3 className="sep-line-one sep-line-one-interior fw-500">
                                        <div dangerouslySetInnerHTML={{__html: key}}></div>
                                    </h3>
                                </div>
                            </div>
                            <div className="container work-carousel-outer mt-2">
                                <OwlCarousel className="owl-carousel  project-carousel3 owl-btn-vertical-center " {...options}>
                                    {item.map((data, index) => (
                                        <div key={index} className="item">
                                            <div className="project-mas hover-shadow m-a30 ">
                                                <div className=''>
                                                    <div className="popup-gallery">
                                                        <a className="mfp_image" href={`${configData.ADMIN_URL}/assets/project_image/` + data.image}>
                                                            
                                                            <img className="max-h-200"
                                                                 src={`${configData.ADMIN_URL}/assets/project_image/` + data.image}
                                                                 alt={key} />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                        </div>
                    ))}

                    {/* SECTION CONTENT END  */}
                    <div className="section-full d-none p-b20  inner-page-padding mt-4">
                        <div className="container container-mobile">
                            <div className="section-content">
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="sx-separator-outer separator-left plm-15">

                                        <h3 className="sep-line-one pb-0"><span className='red'>Similar </span>Interiors <NavLink
                                            to={"/recent-interior-designing-project.php"} className="bg-3e btn view_all float-right">View
                                            All</NavLink></h3>

                                    </div>
                                </div>
                                {/* TITLE END 
                                <div className="work-carousel-outer">
                                    <OwlCarousel className="owl-carousel  project-carousel3 owl-btn-vertical-center" {...options}>
                                        {otherprojects.map((item, index) => (
                                            <div key={index} className=" m-b10  mm-b30">
                                                <div className="sx-box sx-product-box overflow-hide bg-pink bordered">
                                                    <div className="client-name">{item.owner}</div>
                                                    <div>
                                                        <NavLink onClick={handleClick(item.id)}
                                                                 to={"/" + item.slug + "/" + item.id + "/recent"}>
                                                            <img className="max-h-214"
                                                                 src={"https://www.nakshewala.com/admin/public/assets/images/recent_project/" + item.image}
                                                                 alt=""/>
                                                        </NavLink>

                                                    </div>
                                                    <div className="p-t10 pl-2 pr-2 text-left">
                                                        <h5 className="sx-title fsm-15 fs-16 w-240 most-loved-title">
                                                            <NavLink onClick={handleClick(item.id)} className="fw-600"
                                                                     to={"/" + item.slug + "/" + item.id + "/recent"}>{item.title}</NavLink>
                                                        </h5>
                                                        <p className="most-loved-desc"
                                                           dangerouslySetInnerHTML={{__html: item.short_description.slice(0, 200)}}></p>


                                                        <p className="category_code_text"><NavLink
                                                            onClick={handleClick(item.id)}
                                                            to={"/" + item.slug + "/" + item.id + "/recent"}><span
                                                            className="float-right view_details mobile-hidden mb-3">View details</span><span
                                                            className="float-right desktop-hidden mt--15"><i
                                                            className="fa fa-eye"></i></span></NavLink></p>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                    <Recommended title="Recommended Services" bgcolor="bg-white"/>
                    {items.map((item, index) => (
                        <About5 key={index} page_content={item.page_content}/>
                    ))}
                </div>
                <Footer/>
            </>
        );
    
};


export default SingleRecentProject;
