/*eslint react/jsx-no-target-blank: "off"*/
/*eslint jsx-a11y/anchor-has-content: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
import React, { useState, useEffect } from "react";
import { NavLink, useHistory, useLocation  } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from "axios";
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import ContactForm from './../Elements/ContactForm';
import { Helmet } from "react-helmet";  
import { ToastContainer, toast } from 'react-toastify';
import StickyBox from "react-sticky-box";
import BlogSidebar from './../Elements/BlogSidebar';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
//config url
import configData from "./../../config.js";

import Loader from "./../Elements/Loader";

var bgimg1 = require('./../../images/background/cross-line2.png');
var bannerUrl = `${configData.ADMIN_URL}/assets/blog/`;

    const BlogSingle = ({ match }) => {

      const [isLoading, setIsLoading] = useState(true);
      const [data, setData] = useState();
      const [comments, setComments] = useState([]);
      const [blogId, setBlogId] = useState([]);
      const [name, setName] = useState(""); 
      const [email, setEmail] = useState(""); 
      const [comment, setComment] = useState(""); 
      const [todos, setTodos] = useState([]);
      const location = useLocation();
      const history = useHistory();
      const blog_url = window.location.pathname;
      var  newPage = blog_url.replace('/','');
       var url = newPage.replace('','');
       console.log(url);
       
       //Fetch similar blogs
       useEffect(() => {
        async function fetchProducts() {
         try {
          let item = await axios.get(`${configData.ADMIN_URL}/api/blogs?page=1`)
          setTodos(item.data.data);
          
         } catch (error) {
          console.log(error);
         }
        }
         fetchProducts();
    }, [location.key]);
    
    //Fetch blog data title based
      useEffect(() => {
        //alert(window.location.href)
        async function fetchProducts() {
            setIsLoading(true);
         try {
          let item = await axios.get(`${configData.ADMIN_URL}/api/blogdetails?blog_url=${url}`)
           setData(item.data.data);
           setBlogId(item.data.data[0].id);
          let comment = await axios.get(`${configData.ADMIN_URL}/api/blogs/${item.data.data[0].id}/comments`);
           setComments(comment.data);
           setIsLoading(false);
         } catch (error) {
           console.log(error);
           history.push("/error-404") 
         }
        }
        
        fetchProducts();
    }, [location.key, history,url ]);

       //saving Comment 
    async function saveComment(e){
        e.preventDefault();
        let data={name,comment,email}
        console.log(data);
        let result =await fetch(`${configData.ADMIN_URL}/api/blogs/${blogId}/comments`,{
            method: 'POST',
            headers: {
               "Content-Type": "application/json",
               "Accept": "applicatiion/json"
            },

             body:JSON.stringify(data)
          });

        result =await result.json();
       toast.success('Comment Saved Successfully!', {autoClose:3000})
        console.error(result.message); }


       const options = {
            loop: true,
            dotsEach: 3,
            autoplay: false,
            center: false,
              //stagePadding: 100,
            //items: 1,
            margin: 40,
            nav: false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1.2,
                    margin: 20,
                    //dots: true,
                },
                640: {
                    items: 2,
                    margin: 20,
                    //dots: true,
                },
                768: {
                    items: 2,
                    margin: 20,
                    //dots: true,
                },
                991: {
                    items: 3,
                    margin: 20,
                    //dots: true,
                },
                1200: {
                    items: 3,
                     margin: 20,
                     //dots: true,
                }

            }
        };

        return (
            <>
         <Header2 />
            {isLoading ? <Loader /> :
            <>
           
            {data.map((item, index) => (

            <Helmet key={index}>  
            <title>{item.blogtitle}</title>
            <meta name="description" content={item.blogdescription} />
            <meta name="keywords" content={item.blogkeyword} />
            <meta property="og:url" content={item.blog_url} />
            <link rel="canonical" href={configData.CANONICAL_URL+window.location.pathname.slice(1)} />
            <meta property="og:type" content="Blog" />
            <meta property="og:title"  content={item.blog_title} />
            <meta property="og:description" content={item.blogdescription} />
            <meta property="og:image" content={`${configData.ADMIN_URL}/assets/blog/` + item.blog_image} />
            </Helmet> 

             ))}
               
               {data.map((item, index) => (
                <div className="page-content ">
                    <div className="sx-bnr-inr overlay-wraper bg-top-center bg-cover d-none" style={{ backgroundImage: 'url('+ bannerUrl + item.blog_image + ')' }}>
                    <div class="overlay-main bg-black opacity-05"></div>
                    <div className="container">
                        <div className="sx-bnr-inr-entry">
                            <div className="banner-title-outer text-left">
                                <div className="banner-title-name ">
                                    <h2 className="m-tb0 mb-3">{item.blog_title}</h2>   
                                                              
                                    <p className="fs-15 lh-1">
                                        
                                    </p>
                                </div>
                                
                            </div>
                           
                        </div>
                    </div>
                </div>
                 <div className="d-none">
                    <ul className="sx-breadcrumb breadcrumb-style-2 bg-black pl-2 pb-1">
                    <div className="container">
                    <li className="red"><NavLink className="red" to={"./"}>Home </NavLink></li>
                         <li className="red"><NavLink className="red" to={"/blog"}>Blogs </NavLink></li>
                        <li>{item.blog_title}</li>
                        </div>
                    </ul>
                </div>
                    {/* SECTION CONTENT START */}
                    <div className={`section-full-${index} section-full p-t80 p-b30 inner-page-padding`}>
                        <div className="">
                            <div className="blog-single-space container ml-auto mr-auto">
                            <div class="col-xl-12 mtm-25">

                            <div class="single-post-info-content text-center mt-5">
                                <div class="meta-box">
                                    <ul class="meta-info">
                                        <li>By <a href="#">Admin</a>
                                     </li>
                                        <li>On <a href="#">{item.created_at.slice(0,10)}</a>
                                        </li>
                                        
                                         <li className="post-category">In  {item.category.map((cat, index) => (
                                         <NavLink to={"blogs?category=" + cat.id}> 
                                          {cat.category_name}</NavLink> 
                                         ))}
                                         </li>
                                        
                                    </ul>
                                </div>
                                <h1 class="blog-title">{item.blog_title}</h1>
                            </div>
                            <div className="clearfix single-post-share text-center mbm-20   mt-4">
                                                    
                                                    <div className="widget_social_inks">
                                                        <ul className="social-icons social-md social-square social-dark m-b0">
                                                            <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`} rel="noreferrer" target="_blank" className="fa fa-facebook" /></li>
                                                            <li><a href={`https://twitter.com/intent/tweet?text=Nakshewala&url=${window.location.href}`} target="_blank"  rel="noreferrer" className="fa fa-twitter" /></li>
                                                            <li><a href={`whatsapp://send?text=${window.location.href}`} target="_blank" rel="noreferrer" className="fa fa-whatsapp" /></li>
                                                            
                                                            <li><a href={`https://instagram.com/api/v1/media/upload/${window.location.href}`} target="_blank" rel="noreferrer" className="fa fa-instagram" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                            </div>
                             <div className="row">
                            {/* BLOG START */}
                                <div className="blog-post blog-detail col-lg-8 col-md-12 col-sm-12  text-black mt-4">
                                    <div className="sx-post-media">
                                        <div className="portfolio-item">
                                            <img className="img-responsive w-100 " src={`${configData.ADMIN_URL}/assets/blog/` + item.blog_image} alt=""/>
                                        </div>
                                    </div>
                                    <div className="sx-post-meta mt-3 d-none">
                                        <ul>
                                            <li className="post-date"><strong><i className="fa fa-clock-o"></i> </strong> <span>{item.created_at.slice(0,10)}</span> </li>
                                            <li className="post-author"><NavLink to={"#"}><strong><i className="fa fa-user-o"></i> </strong> By <span>Admin</span></NavLink> </li>
                                            {item.category.map((cat, index) => (
                                                    <li className="post-category"> <NavLink to={"blogs?category=" + cat.id}><strong><i className="fa fa-list-alt"></i> </strong>   {cat.category_name}</NavLink> </li>
                                            ))}

                                            
                                        </ul>
                                    </div>
                                    <div className="sx-post-title d-none">
                                        <h3 className="post-title">{item.blog_title}</h3>
                                    </div>

                                    <div className="sx-post-text text-justify mt-4" dangerouslySetInnerHTML={ { __html: item.blog_content } }></div>

                                    
                                    <div className="autor-post-tag-share p-a30 bg-gray">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="widget_tag_cloud m-b15">
                                                    <h5 className="tagcloud">Tags</h5>
                                                    <div className="tagcloud">
                                                        {item.tag}

                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 d-none">
                                                <div className="clearfix single-post-share">
                                                    <h5>Share this Post:</h5>
                                                    <div className="widget_social_inks">
                                                        <ul className="social-icons social-md social-square social-dark m-b0">
                                                            <li><a href="https://www.facebook.com" target="_blank" rel="noreferrer" className="fa fa-facebook" /></li>
                                                            <li><a href="https://www.twitter.com" target="_blank" rel="noreferrer" className="fa fa-twitter" /></li>
                                                            <li><a href="https://whatsapp.com" target="_blank" rel="noreferrer" className="fa fa-whatsapp" /></li>
                                                            
                                                            <li><a href="https://www.instagram.com" target="_blank" rel="noreferrer" className="fa fa-instagram" /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="clear" id="comment-list">
                                    <div className="comments-area mt-2" id="comments">
                                        
                                        <div className="comments-outer">
                                            {/* COMMENT LIST START */}
                                            {comments ? <h4 className="comment-reply-title mb-4 mt-4" id="reply-title">Comments</h4> : ""}
                                            <ol className="comment-list">
                                            {comments.map((item, index) => (
                                                <li className="comment">
                                                    {/* COMMENT BLOCK */}
                                                    <div className="comment-body">
                                                        <div className="comment-meta">
                                                            <NavLink to={"#"}>{item.created_at.slice(0,10)}</NavLink>
                                                        </div>
                                                        <div className="comment-author vcard">
                                                            <img className="avatar photo" src={require('./../../images/testimonials/default_user-png.png').default} alt=""/>
                                                            <cite className="fn"> {item.name} </cite>
                                                            <span className="says">says:</span>
                                                        </div>
                                                        <p>{item.comment} </p>
                                                        
                                                    </div>
                                                    {/* SUB COMMENT BLOCK */}
                                                    
                                                </li>
                                                ))}
                                            </ol>
                                            {/* COMMENT LIST END */}
                                            {/* LEAVE A REPLY START */}
                                            <div className="comment-respond m-t30" id="respond">
                                                <h2 className="comment-reply-title" id="reply-title">Leave a Comments
                                                    <small>
                                                    <NavLink to={"#"} style={{ display: 'none' }} id="cancel-comment-reply-link" rel="nofollow">Cancel reply</NavLink>
                                                    </small>
                                                </h2>
                                                <form className="comment-form  p-tb15" id="commentform" method="post">
                                                    <p className="comment-form-author">
                                                        <label htmlFor="author">Name <span className="required">*</span></label>
                                                        <input onChange={(e)=>{setName(e.target.value)}} defaultValue="" className="form-control" type="text" name="user-comment" placeholder="Name" id="author" />
                                                    </p>
                                                    <p className="comment-form-email">
                                                        <label htmlFor="email">Email <span className="required">*</span></label>
                                                        <input onChange={(e)=>{setEmail(e.target.value)}} defaultValue="" className="form-control" type="text" name="email" placeholder="Email" id="email" />
                                                    </p>
                                                    
                                                    <p className="comment-form-comment">
                                                        <label htmlFor="comment">Comment</label>
                                                        <textarea onChange={(e)=>{setComment(e.target.value)}} className="form-control" rows={8} name="comment" placeholder="Comment" id="comment" defaultValue={""} />
                                                    </p>
                                                    <p className="form-submit">
                                                        <button onClick={saveComment} className="site-button btn-half" type="button"><span> Submit</span></button>
                                                    </p>
                                                </form>
                                            </div>
                                            {/* LEAVE A REPLY END */}
                                        </div>
                                    </div>
                                </div>
                                    
                                </div>
                                 {/* SIDE BAR START */}

                                <div className="col-lg-4 col-md-12 col-sm-12 sticky_column mt-4">
                                <StickyBox offsetTop={80} offsetBottom={20}>
                                <ContactForm />
                                <BlogSidebar />
                                </StickyBox>
                                </div>
                                
                                
                                {/* SIDE BAR END */}
                                {/* OUR BLOG START */}
                             </div>
                                {/* TITLE START */}
                                <div className="section-head">
                                    <div className="sx-separator-outer separator-left mt-4">
                                        <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg1.default + ')' }}>
                                            <h3 className="sep-line-one p-3">People Also Read</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                {/* IMAGE CAROUSEL START */}
                                <div className="section-content">
                                    <div className="container">
                                {/* TITLE END */}
                                {/* IMAGE CAROUSEL START */}
                                <div className="section-content">
                                    <div className="">
                                    <OwlCarousel className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center " {...options}>
                                    {todos.map((item, index) => (
                                        <div key={index} className="">
                                            <div className="blog-post blog-grid date-style-2">
                                                <div className="sx-post-media sx-img-effect img-reflection">
                                                    <NavLink to={`${item.blog_url}`}><img className="h-218" src={`${configData.ADMIN_URL}/assets/blog/` + item.blog_image} alt={item.blog_title} /></NavLink>
                                                </div>
                                                <div className="sx-post-info p-t10">
                                                    <div className="sx-post-meta ">
                                                       <ul>
                                                            <li className="post-date"><strong>{item.created['day']}</strong> <span>{item.created['month']}</span> </li>
                                                            <li className="post-author"><NavLink to={"#"}>By <span className="red">Admin</span></NavLink> </li>
                                                            {item.category.map((cat, index) => (
                                                            <li key={index} className="post-comment"> <NavLink to={"blogs?category=" + cat.id}>{cat.category_name}</NavLink> </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className="sx-post-title ">
                                                        <h4 className="post-title fs-20"><NavLink to={`${item.blog_url}`}>{item.blog_title}</NavLink></h4>
                                                    </div>
                                                     <p className='mb-4 mt-2 line-h-2'>{item.blogdescription.slice(0, 100)}...<NavLink to={`${item.blog_url}`} title="READ MORE" rel="bookmark" className="site-button-link red"><u>Read more</u></NavLink></p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        ))}
                                    </OwlCarousel>
                                    </div>
                                </div>
                                    </div>
                                </div>
                                {/* OUR BLOG END */}
                                
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>
                ))}
               </>
              }
                <Footer />
                 <ToastContainer />
            </>
        );
  
};

export default BlogSingle;