import React from "react";

const logos = [
  {
    count: "Main Entrance",
    title: "Main Entrance",
    flaticon: "flaticon-door-1",
    description:
      "The Main entrance should not face either intersections of roads, or an approaching dead end road. Nor should it be obstructed by tall structures like trees or lamp posts. If it has steps leading to the house, then build an even number of steps.",
  },
  {
    count: "Dining Room",
    title: "Dining Room",
    flaticon: "flaticon-table-3",
    description:
      "West is the direction of Saturn, representative of the masses and therefore also of their feeding mouths and hunger. Vastu shastra directs that the eating room should be located in the Western side of the house.",
  },
  {
    count: "Kitchen",
    title: "Kitchen",
    flaticon: "flaticon-helmet",
    description:
      "Agni, the vedic god of fire, resides in South-East. Vastu therefore recommends that the kitchen should be loacated in the South-Eastern corner.",
  },
  {
    count: "Rooms",
    title: "Rooms",
    flaticon: "flaticon-living-room",
    description:
      "A Vastu house has odd number of rooms. Count all rooms which have a door.",
  },
  {
    count: "Altar",
    title: "Altar",
    flaticon: "flaticon-table-3",
    description:
      "The ideal direction to place the altar is North-Eastern direction.",
  },
  {
    count: "Balcony",
    title: "Balcony",
    flaticon: "flaticon-helmet",
    description:
      "Plan a balcony in North or East. A balcony in South is not recommended.",
  },
  {
    count: "Bath Room",
    title: "Bath Room",
    flaticon: "flaticon-home-1",
    description:
      "Ideally the bathroom should be located in East. If that is not possible, then the next best direction is North.",
  },
  {
    count: "Beams",
    title: "Beams",
    flaticon: "flaticon-brick-wall",
    description:
      "Design to have an even number of beams in a room. But do not sleep under the beams as it is likely to cause health complications.",
  },
  {
    count: "Bedroom",
    title: "Bedroom",
    flaticon: "flaticon-bed",
    description:
      "Plan the Master bedroom in South or South-Western corner of the house. A room in North-Western corner should be given to the newly-weds in the family. In either case avoid sleeping with feet facing South because it drains away the body energy.",
  },
  {
    count: "Cash and Valuables",
    title: "Cash and Valuables",
    flaticon: "flaticon-ruler-1",
    description:
      "Place them in a cupboard on the Northern side, preferably in a room which is in Northern side.",
  },
  {
    count: "Windows",
    title: "Windows",
    flaticon: "flaticon-window-2",
    description:
      "Vastu warns that a house should not have a total number of 3, 6 or 11 windows.",
  },
  {
    count: "Doors",
    title: "Doors",
    flaticon: "flaticon-door-1",
    description: "Plan the house to have even number of doors",
  },
  {
    count: "Pillars",
    title: "Pillars",
    flaticon: "flaticon-helmet",
    description: "Even numbers of pillars promote harmony in the house.",
  },
  {
    count: "Staircase",
    title: "Staircase",
    flaticon: "flaticon-stairs-2",
    description:
      "A staircase is considered to be heavy. Its preferred direction is South or West. Do not have a staircase in North-Eastern part of the house.",
  },
  {
    count: "Toilets",
    title: "Toilets",
    flaticon: "flaticon-helmet",
    description:
      "Avoid the construction of a toilet in the North-eastern part of the house. It would spell disaster. Plan to have it between South and South-Western side of the house.",
  },
  {
    count: "Trees",
    title: "Trees",
    flaticon: "flaticon-cactus",
    description:
      "West, South Tall trees are fine in S and W, but should be avoided in N and E.",
  },
];

class VastuLiving extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80  p-b50 bg-gray">
          <div className="section-content">
            <div className="container">
              {/* TITLE START */}
              <div className="section-head">
                <div className="sx-separator-outer separator-center for-only-vas-tuu">
                  <div className="sx-separator bg-white bg-moving bg-repeat-x living-ani-mation">
                    <h3 className="sep-line-one vastuu-txt-lvng">
                      Vastu for{" "}
                      <span className="better-living-text">better living</span>
                    </h3>
                  </div>
                </div>
              </div>

              {/* TITLE END */}
              <div className="row">
                {logos.map((item, index) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 m-b30"
                    key={index}
                  >
                    <div className="sx-icon-box-wraper  icon-count-2-outer vatu-wh-le">
                      <div className="icon-count-2 bg-white livind-crd for-vasti">
                        <span className="icon-count-number for-vas">
                          {item.count}
                        </span>
                        <div className="icon-xl inline-icon m-b5 scale-in-center">
                          <span className="icon-cell">
                            <i className={item.flaticon} />
                          </span>
                        </div>
                        <div className="icon-content">
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase">
            <strong>Vastu Shastra</strong>
          </div>
        </div>
      </>
    );
  }
}
export default VastuLiving;
