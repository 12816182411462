import React from "react";

const Loader = () => {
  return (
    <div className="loading-area">
      <div className="loading-box" />
      <div className="loading-pic">
        <div className="cssload-spinner">
          <div className="cssload-cube cssload-cube0" />
          <div className="cssload-cube cssload-cube1" />
          <div className="cssload-cube cssload-cube2" />
          <div className="cssload-cube cssload-cube3" />
          <div className="cssload-cube cssload-cube4" />
          <div className="cssload-cube cssload-cube5" />
          <div className="cssload-cube cssload-cube6" />
          <div className="cssload-cube cssload-cube7" />
          <div className="cssload-cube cssload-cube8" />
          <div className="cssload-cube cssload-cube9" />
          <div className="cssload-cube cssload-cube10" />
          <div className="cssload-cube cssload-cube11" />
          <div className="cssload-cube cssload-cube12" />
          <div className="cssload-cube cssload-cube13" />
          <div className="cssload-cube cssload-cube14" />
          <div className="cssload-cube cssload-cube15" />
        </div>
      </div>
    </div>
  );
};

export default Loader;
