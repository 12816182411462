
import React from 'react';
 
const logos = [
     { image: require('./../../images/banquet_hall.webp'),
      title: 'Banquet Hall',
      url:   '/hotel-and-banquet-hall-interior-designs.php'
       },
    { image: require('./../../images/gym.webp'),
      title: 'GYM Interior',
      url:   '/gym-and-yoga-center.php' },
    { image: require('./../../images/jewellery.webp'),
      title: 'Jewellery',
      url:   '/jewellery-showroom-interior-designs.php' },
      { image: require('./../../images/office.webp'),
      title: 'Office ',
      url:   '/office-interior-designs.php' },
    { image: require('./../../images/showroom.webp'),
      title: 'Showrooms',
      url:   '/show-room-interior-designs.php' },
    { image: require('./../../images/restaurant.webp'),
      title: 'Restaurants',
      url:   '/restaurant-interior-designs.php' },   
]

var bgimg1 = require('./../../images/background/bg-12.jpg');

function InteriorSection4() {
   
        return (
            <>
                <div className="section-full desktop-hidden mobile-page-padding bg-gray  p-t20 p-b10 bg-repeat" style={{ backgroundImage: 'url(' + bgimg1.default + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-left">
                               
                                   <h2 className="sep-line-one">Best <span className='red'>Commercial Interior</span> <br></br> Designs <span className="red v-align-m"><i class="fa fa-long-arrow-right"></i></span></h2>
                            
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="client-grid m-b20">
                                <div className="row for-mobile">
                                    {logos.map((item, index) => (
                                        
                                        <div className="col-lg-3 col-md-4 col-sm-3 col-4 m-b10 p-1" key={index}>
                                        <div className="shadow-cs">
                                            <a href={item.url} className="client-logo-pic">
                                                <img className='w-100' src={item.image} alt={item.title} />
                                                <div>
                                                    <span><i className="fa fa-search"></i></span>
                                                </div>
                                            </a>
                                            <p className='interior_area_text bg-white'>{item.title}</p>
                                        </div>
                                        </div>
                                    ))}
                                </div>


                                

                            </div>
                        </div>
                          
                        
                       

                    </div>
                   
                  
                </div>
            </>
        );   
};

export default InteriorSection4;

