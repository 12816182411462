import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import { useEffect, useState } from "react";
import axios from "axios";
import {  useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom';
 import { ToastContainer, toast } from 'react-toastify';
 import ProfileSideBar from './ProfileSideBar';
 //config url
import configData from "../../config.js";
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
    
const FavDesign = () => {
	const [data, setData] = useState([]);
	const history = useHistory()
	const { onBoarding } = useSelector(
		(state) => state.user
	  )
	const [message, setMessage] = useState(false);
	async function removeItem(event){
		//alert( event.currentTarget.id)
		try {
			const response = await fetch(`${configData.ADMIN_URL}/api/services/wishlist/remove`, {
			  method: 'POST',
			  body: JSON.stringify({
			  "id": Number(event.currentTarget.id)
			  }),
			  "headers": {
				"Accept": "application/json",
				"x-api-key": configData.X_API_KEY,
				"x-api-secret": configData.X_API_SECRET,
				"Content-Type": "application/json",
				'Authorization': `Bearer ${localStorage.getItem('token')}` 
			  },
			});
			if (!response.ok) {
				toast.error("Please try again", {autoClose:3000})
			 
			}
			const result = await response.json();
			
			//alert("item deleted")
			if(result.status === 'success') {
			  toast.success("Item Removed Successfully", {autoClose:1000})
			  try {
				const config = {
				   "headers": {
					   "Accept": "application/json",
					   "x-api-key": configData.X_API_KEY,
					   "x-api-secret": configData.X_API_SECRET,
					   "Content-Type": "application/json",
					   'Authorization': `Bearer ${localStorage.getItem('token')}` 
				   },
				   }
				 let item = await axios.get(`${configData.ADMIN_URL}/api/users/wishlist`,config)
				
				 setData(item.data.data)
				if(item.data.data.length <= 0) {
				   setMessage(true)
				}
				else {
				   setMessage(false)
				}
				} catch (error) {
				 console.log(error);
				}
			  
			}
		
		  } catch (err) {
			console.log(err)
			toast.error("Please try again", {autoClose:3000})
		  } 
	  };
	useEffect(() => {
		/*if(onBoarding != true) {
			history.push('/register')
		  }*/
		async function fetchData() {
		 try {
		 const config = {
			"headers": {
				"Accept": "application/json",
				"x-api-key": configData.X_API_KEY,
				"x-api-secret": configData.X_API_SECRET,
				"Content-Type": "application/json",
				'Authorization': `Bearer ${localStorage.getItem('token')}` 
			},
			}
		  let item = await axios.get(`${configData.ADMIN_URL}/api/users/wishlist`,config)
		  console.log(item.data.data)
		  setData(item.data.data)
		 if(item.data.data.length <= 0) {
			setMessage(true)
		 }
		 else {
			setMessage(false)
		 }
		 } catch (error) {
		  console.log(error);
		  if (error.response) {
			history.push("/login");
			//console.log(error.response.data);
			/*toast.error(error.response.data.message, {autoClose:1000})
			setTimeout(()=> {
				history.push("/login");
			},1000)*/
			console.log(error.response.status);
			//console.log(error.response.headers);
		  }
		 }
		}
		fetchData();
	}, [onBoarding, history]);
	 
  return (

	<>
	<Header2 />
	<div>
	  <div className="page-content bg-white">
		<div className="content-block">
		  <section className="content-inner bg-white">
			<div className="container">
			  <div className="row">
				<div className="col-xl-3 col-lg-4 m-b30">
					<ProfileSideBar />
				</div>
				<div className="col-xl-9 col-lg-8 m-b30">
				  <div className="shop-bx shop-profile">
					<div className="shop-bx-title clearfix">
					  <h5 className="text-uppercase">Favorite Designs</h5>
					</div>
  
					{/*{data.map((item, i) => (
		  <p id={item.id} onClick={removeItem}>{item.id}</p>
		))}*/}
					{message ? (
					  <div style={{ textAlign: "center" }}>
						<img alt="profile" src="./assets/images/wishlist11.svg" />
  
						<h3 style={{ marginTop: 30 }}>
						  There isn't anything added yet
						</h3>
						<NavLink to='/readymade-house-design.php' className='site-button2 mt-4'>Explore House Design</NavLink>
					  </div>
					) : (
					  <div className="row">
						{data.map((item, index) => (
                                  <div key={index} className="col-lg-4 col-md-4 col-sm-6 col-6 m-b10 p-2 mm-b30 mobile-grip">
                                  <div className="sx-box sx-product-box overflow-hide shadow-cs">
                                      
                                      <div className="sx-thum-bx">
									  <NavLink to={item.slug ? item.slug : ''}>

										{item.primary_images ?
										<div  className="bg-contain bg-center h-200 bg-no-repeat" style={{ backgroundImage: `url('${item.primary_images[0]}')` }} />
										: <div  className="bg-contain bg-center h-200 bg-no-repeat"  />}


										</NavLink>
										  <div className="item-cart-view">
											<div id={item.id} onClick={removeItem} className="item-cart-btn">
											<i className="fa fa-times" />
											</div>
										</div>
                                          
                                      </div>
                                      <div className="p-t10 pl-2 pr-2 text-left">
                                          <h5 className="sx-title fsm-15 fs-16">
										  <NavLink to={item.slug ? item.slug : ''}>{item.title ? item.title : ''}</NavLink>
                                          </h5>
                                          <ul className=" m-b10 category_info dm-flex">
                                              <li>
                                                  <i class="fa fa-expand"></i> {item.plot_size ? item.plot_size : ''}  sqft
                                              </li>
                                              <li className="pl-20">
                                                  <i className="fa fa-compass" /> {item.direction ?item.direction : ''}   Facing
                                              </li>
                                          </ul>
                                          <p className="category_code_text">Code: {item.plan_no ? item.plan_no : ''} <NavLink to={item.slug ? item.slug : ''}><span className="float-right view_details mobile-hidden">View details</span><span className="float-right desktop-hidden"><i className="fa fa-eye"></i></span></NavLink> </p>
                                          
                                      </div>
                                  </div>
                                  </div>
                                  ))}

						
					  </div>
					)}
				  </div>
				</div>
			  </div>
			</div>
		  </section>
		</div>
	  </div>
	</div>
  
	<Footer />
	<ToastContainer />
  </>
  

          );
   
};
export default FavDesign;