import React from "react";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const projects = [
  {
    image: require("./../../images/completedProjects/1/3d_front_elevation.webp"),
    title: "3D Elevation",
    description:
      "We provide a range of architectural 3D modeling services to our customers to aid the design",
  },
  {
    image: require("./../../images/completedProjects/1/completed_site_project.webp"),
    title: "Completed Project",
    description:
      "Analysis and planning services that help both the client and architects to work out the forthcoming project...",
  },
  {
    image: require("./../../images/completedProjects/2/R-3798-R04.webp"),
    title: "3D Elevation",
    description:
      "We provide a range of architectural 3D modeling services to our customers to aid the design",
  },
  {
    image: require("./../../images/completedProjects/2/completed-R-3798-R04.webp"),
    title: "Completed Project",
    description:
      "Analysis and planning services that help both the client and architects to work out the forthcoming project...",
  },

  {
    image: require("./../../images/completedProjects/3/3d_front_elevation.webp"),
    title: "3D Elevation",
    description:
      "We provide a range of architectural 3D modeling services to our customers to aid the design",
  },
  {
    image: require("./../../images/completedProjects/3/completed_site_view.webp"),
    title: "Completed Project",
    description:
      "Analysis and planning services that help both the client and architects to work out the forthcoming project...",
  },

  {
    image: require("./../../images/completedProjects/4/gulshan-dash-3d-elevation-before.jpg"),
    title: "3D Elevation",
    description:
      "We provide a range of architectural 3D modeling services to our customers to aid the design",
  },
  {
    image: require("./../../images/completedProjects/4/gulshan-dash-3d-elevation-completed-project-after.jpg"),
    title: "Completed Project",
    description:
      "Analysis and planning services that help both the client and architects to work out the forthcoming project...",
  },
  {
    image: require("./../../images/completedProjects/5/3d-elevation-before.jpg"),
    title: "3D Elevation",
    description:
      "We provide a range of architectural 3D modeling services to our customers to aid the design",
  },
  {
    image: require("./../../images/completedProjects/5/3d-elevation-completed.jpg"),
    title: "Completed Project",
    description:
      "Analysis and planning services that help both the client and architects to work out the forthcoming project...",
  },
];

class HomeDesignReality extends React.Component {
  componentDidMount() {
    // loadScript('./assets/js/rev-script-5.js');
    // loadScript('./assets/plugins/revolution-addons/beforeafter/js/revolution.addon.beforeafter.min.js');
  }
  render() {
    const options = {
      loop: true,
      autoplay: false,
      center: false,
      mouseDrag: false,
      touchDrag: false,
      //stagePadding: 100,
      items: 2,
      dots: false,
      nav: true,
      slideBy: 2,
      margin: 0,

      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 2,
          margin: 0,
        },
        640: {
          items: 2,
          margin: 20,
          slideBy: 2,
        },
        768: {
          items: 2,
          margin: 20,
          slideBy: 2,
        },
        991: {
          items: 2,
          margin: 0,
          slideBy: 2,
        },
        1200: {
          items: 2,
          margin: 0,
          slideBy: 2,
        },
      },
    };
    return (
      <>
        <div className="  section-full mobile-page-padding p-t30 p-b30 bg-gray">
          <div className="">
            <div className="container mt-2 mb-4 section-head">
              <div className="sx-separator-outer">
                <h3 className="sep-line-one fsm-18" style={{ fontWeight: 600 }}>
                  <span className="red" style={{ fontWeight: 600 }}>
                    Designs
                  </span>{" "}
                  that Become Reality{" "}
                  <NavLink
                    to={"/completedprojects.php"}
                    className="btn view_all float-right design-btn"
                  >
                    View All
                  </NavLink>
                </h3>
              </div>
            </div>
            <div className="section-content container">
              <div className=" work-carousel-outer">
                <OwlCarousel
                  showThumbs={false}
                  className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center "
                  {...options}
                >
                  {projects.map((item, index) => (
                    <div key={index} className="item">
                      <div className="project-mas hover-shadow m-a30 ">
                        <NavLink to={"/completedprojects.php"}>
                          <div className="">
                            <div className="image-efct-one">
                              <span className="stories-v3">{item.title}</span>
                              <LazyLoadImage
                                alt={item.title}
                                effect="blur"
                                className="w-100 mobile-max-h"
                                src={item.image}
                              />
                            </div>
                          </div>
                        </NavLink>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomeDesignReality;
