import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
//config url
import configData from "./../../config.js";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useContext } from "react";
import { IPInfoContext } from "ip-info-react";

const FreeQuote = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };
  //contact form

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [requirement, setRequirement] = useState("");
  const [countryInfo, setCountryInfo] = useState(null);
  const [ip, setIp] = useState(null);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [number, setNumber] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [requirementError, setRequirementError] = useState("");

  const userInfo = useContext(IPInfoContext);

  // const enabled =
  //   requirement.length > 0 &&  name.length > 0 &&  email.length > 0 && mobile.length > 0;
  useEffect(() => {
    const fetchCountryData = async () => {
      setIp(userInfo.ip);
      if (ip) {
        try {
          const response = await fetch(`https://api.country.is/${ip}`);
          const data = await response.json();
          setCountryInfo(data.country);
        } catch (error) {
          console.error("Error fetching country data:", error);
        }
      }
    };
    fetchCountryData();
  }, [userInfo.ip, ip]);
  const handleChangeNumber = (number) => {
    setNumber(number);
    if (number) {
      try {
        const phoneNumber = parsePhoneNumberFromString(number);

        const countryCode = `${
          phoneNumber ? phoneNumber.countryCallingCode : ""
        }`;
        const nationalNumber = phoneNumber ? phoneNumber.nationalNumber : "";
        setCode(countryCode);
        // setValue(nationalNumber);
        setMobile(code + nationalNumber);

        if (isValidPhoneNumber(number)) {
          console.log(number);
          setError(false);
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      }
    } else {
      setCode("");
      // setValue("");
      setError(true);
    }
  };
  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    setNameError(value.trim() === "" ? "Name is required" : "");
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(value) ? "Please enter a valid email" : "");
  };

  const handleRequirementChange = (e) => {
    const value = e.target.value;
    setRequirement(value);
    setRequirementError(value.trim() === "" ? "Requirement is required" : "");
  };
  async function saveQuery(event) {
    event.preventDefault();
    if (name && email && mobile && requirement) {
      let data = { name, email, mobile, requirement };
      if (isValidPhoneNumber("+" + mobile)) {
        let result = await fetch(`${configData.ADMIN_URL}/api/contact`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            Accept: "applicatiion/json",
          },

          body: JSON.stringify(data),
        });

        const item = await result.json();
        if (item.status === "success") {
          window.location.href = `/thanks.php`;
          setIsOpen(false);
        } else {
          toast.error("Please Try Again!", { autoClose: 3000 });
        }
      } else {
        toast.error("Invalid Phone Number!", { autoClose: 3000 });
      }
    } else {
      if (!mobile) {
        setError(true);
      }
      if (!name) {
        setNameError("Name is required");
      }
      if (!email) {
        setEmailError("Email is required");
      }
      if (!requirement) {
        setRequirementError("Requirement is required");
      }
    }
  }

  return (
    <>
      <li onClick={showModal} class="d-md-none">
        <NavLink to={"/#"}>
          <i class="fa fa-quote-left"></i> Get Free Quote
        </NavLink>
      </li>

      <Modal show={isOpen} onHide={hideModal}>
        <Modal.Header>
          <div className="modal-header-img"></div>
          <p className="formText text-center">Discuss with Expert Now</p>
          <button
            onClick={hideModal}
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </Modal.Header>

        <Modal.Body>
          <form method="post" className="text-center">
            <div class="result"></div>
            <div class="form-group">
              <input
                onChange={handleNameChange}
                type="text"
                className="form-input"
                placeholder="Enter Name"
              />
              {nameError && <p className="error-msg">{nameError}</p>}
            </div>

            <div class="form-group">
              <input
                onChange={handleEmailChange}
                required=""
                type="email"
                className="form-input"
                placeholder="Enter Email"
              />
              {emailError && <p className="error-msg">{emailError}</p>}
            </div>

            <div class="form-group">
              <div className="custom-phone-input">
                <PhoneInput
                  international
                  className="session-phone-control"
                  defaultCountry={countryInfo}
                  countryCallingCodeEditable={false}
                  value={number || ""} // Ensuring value is a string
                  placeholder="Enter phone number"
                  onChange={handleChangeNumber}
                />
              </div>
              {error && (
                <p className="errorMsg">
                  Please enter a valid number and country code
                </p>
              )}
            </div>

            <div class=" form-group">
              <textarea
                onChange={handleRequirementChange}
                required=""
                className="form-textarea"
                placeholder="Enter Plot Size & Requirements"
              ></textarea>
              {requirementError && (
                <p className="error-msg">{requirementError}</p>
              )}
            </div>

            <button
              onClick={saveQuery}
              //disabled={!enabled}
              className="submitBtn"
              type="submit"
            >
              Get Free Quote
            </button>
          </form>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

export default FreeQuote;
