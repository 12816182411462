import React from 'react';
import Header2 from './../../Common/Header2';
import Footer from './../../Common/Footer';
import { NavLink } from 'react-router-dom';
import About5 from './../../Elements/About5';
import InteriorPageDesktop from './../../Elements/InteriorPageDesktop';
import InteriorSection1 from './../../Elements/InteriorSection1';
import InteriorSection2 from './../../Elements/InteriorSection2';
import InteriorSection3 from './../../Elements/InteriorSection3';
import InteriorSection4 from './../../Elements/InteriorSection4';
import InteriorSection6 from './../../Elements/InteriorSection6';
import MostLoved from './../../Elements/MostLoved';
import  HomeCta from './../../Elements/HomeCta';
import { Helmet } from "react-helmet"; 
import Loader from "./../../Elements/Loader";
import configData from "./../../../config.js";
   
 
class InteriorDesigning extends React.Component {

     // Constructor 
    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            DataisLoaded: false
        };
    }

    //API CALL
    componentDidMount() {
        fetch(
        `${configData.ADMIN_URL}/api/metapageurl?page_url=interior-designing.php`)
        .then((res) => res.json())
        .then((json) => {
            this.setState({
                items: json,
                DataisLoaded: true
            });

            //console.log(json.seo);
        })
    

        /*function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');
        loadScript('./assets/js/magnific-popup.min.js');*/

    };

    render() {

        const { DataisLoaded, items } = this.state;
        if (!DataisLoaded) return <Loader />

        return (
            <>
            {items.map((item, index) => (
            <Helmet key={index}>  
            <title>{item.pagetitle}</title>
            <meta name="description" content={item.metadescription} />
            <meta name="keywords" content={item.metakeywords} />
            <meta property="og:type" content="Page" />
            <meta property="og:title" content={item.pagetitle} />
            <meta property="og:description" content={item.metadescription} />
            <meta property="og:url" content={window.location.href} />
            <link rel="canonical" href={`${configData.CANONICAL_URL}interior-designing.php`} />
            <meta property="og:site_name" content={configData.SITE_NAME} />
            <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_banner}`} />
            </Helmet> 
             ))}
                <Header2 /> 
                <div className="page-content">
                       {items.map((item, index) => (
                         <InteriorSection1 key={index} page_name={item.page_name} short_des_heading={item.short_des_heading} sub_title={item.sub_title} short_description={item.short_description}  />
                       ))}
                        <InteriorSection2 />
                        <InteriorPageDesktop />  
                         <InteriorSection4 />
                        <InteriorSection3 />
                        <MostLoved />
                        <InteriorSection6 />
                        <HomeCta hTag="h5" />
                        <div className="section-full  mobile-page-padding bg-gray2  p-t50 p-b40 bg-repeat">
                            <div className="container">
                                {/* TITLE START */}

                                {/* TITLE END */}
                                <div className="section-content">
                                <div className="client-grid m-b20">
                                    <div className="row">
                                    <div className="col-md-4 faq-list">
                                        {/* Accordian */}
                                        <div>
                                        <h5 className="desktop-hidden">
                                            Frequently <span className="red">Asked</span> Questions
                                            <span className="red">.</span>
                                        </h5>
                                        <h5 className="fs-2rem mobile-hidden fw-700">
                                            Frequently <br />
                                            <span className="red">Asked</span>
                                            <br /> Questions<span className="red">.</span>
                                        </h5>
                                        <p className="faqText mobile-hidden">
                                            still have any doubt in mind? <br /> Don't worry just{" "}
                                            <a href="mailto:support@nakshewala.com">
                                            <span className="red">
                                                <u>write us</u>
                                            </span>
                                            </a>{" "}
                                            your concern we will get back to you within 24 hrs.
                                        </p>
                                        </div>
                                        <NavLink
                                        to="/faq"
                                        className="desktop-btn btn-half mobile-hidden"
                                        >
                                        <span>
                                            Read All FAQ <i className="fa fa-arrow-right"></i>
                                        </span>
                                        </NavLink>
                                    </div>
                                    <div className="col-md-8">
                                        {/* Accordian */}
                                        <div className="sx-accordion acc-bg-gray" id="accordion5">
                                        <div className="panel sx-panel faqbox">
                                            <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a
                                                data-toggle="collapse"
                                                href="#collapseOne5"
                                                data-parent="#accordion5"
                                                >
                                                What is your Process?
                                                <span className="indicator">
                                                    <i className="fa fa-plus" />
                                                </span>
                                                </a>
                                                <hr className="desktop-hidden mt-0 mb-0" />
                                            </h4>
                                            </div>
                                            <div
                                            id="collapseOne5"
                                            className="acod-body collapse  show"
                                            >
                                            <div className="acod-content p-a15">
                                                <p className="faqText">
                                                {" "}
                                                Get aboard and discuss your detailed requirement
                                                with dedicated Project Architect, get the
                                                customized first cut layout in three working days,
                                                have the changes modification in the design as per
                                                your satisfaction.
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="panel sx-panel faqbox">
                                            <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a
                                                data-toggle="collapse"
                                                href="#collapseTwo5"
                                                className="collapsed"
                                                data-parent="#accordion5"
                                                >
                                                What is your cost?
                                                <span className="indicator">
                                                    <i className="fa fa-plus" />
                                                </span>
                                                </a>
                                            </h4>
                                            </div>
                                            <div id="collapseTwo5" className="acod-body collapse">
                                            <div className="acod-content p-a15">
                                                <p className="faqText">
                                                Our cost depends upon the plot size, number of
                                                floors, type of building etc. For best offer
                                                kindly share your plan or call us.
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="panel sx-panel faqbox">
                                            <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a
                                                data-toggle="collapse"
                                                href="#collapseThree5"
                                                className="collapsed"
                                                data-parent="#accordion5"
                                                >
                                                How many corrections do you provide?
                                                <span className="indicator">
                                                    <i className="fa fa-plus" />
                                                </span>
                                                </a>
                                            </h4>
                                            </div>
                                            <div id="collapseThree5" className="acod-body collapse">
                                            <div className="acod-content p-a15">
                                                <p className="faqText">
                                                We do provide one proposed concept of
                                                plan/elevation and provide up to seven corrections
                                                as per the client’s satisfaction.
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="panel sx-panel faqbox">
                                            <div className="acod-head">
                                            <h4 className="acod-title">
                                                <a
                                                data-toggle="collapse"
                                                href="#collapseFour5"
                                                className="collapsed"
                                                data-parent="#accordion5"
                                                >
                                                Why should we go for Complete Package?
                                                <span className="indicator">
                                                    <i className="fa fa-plus" />
                                                </span>
                                                </a>
                                            </h4>
                                            </div>
                                            <div id="collapseFour5" className="acod-body collapse">
                                            <div className="acod-content p-a15">
                                                <p className="faqText">
                                                Complete package will give you complete details
                                                required for construction of a building. Starting
                                                from footing to completion we will give you all
                                                the drawings as your building’s safety and money
                                                matters.
                                                </p>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <span className=" desktop-hidden">
                                        <p className="faqText desktop-hidden mt-4">
                                            still have any doubt in mind? Don't worry just{" "}
                                            <a href="mailto:support@nakshewala.com">
                                            <span className="red">
                                                <u>write us</u>
                                            </span>
                                            </a>{" "}
                                            your concern we will get
                                            <br /> back to you within 24 hrs.
                                        </p>
                                        <NavLink to="/faq" className="desktop-btn btn-half">
                                            <span>
                                            Read All FAQ <i className="fa fa-arrow-right"></i>
                                            </span>
                                        </NavLink>
                                        </span>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        {items.map((item, index) => (
                         <About5 page_content={item.page_content} />
                        ))}
                    
                </div>
                <Footer />
            </>
        );
    };
};

export default InteriorDesigning;