/*eslint no-redeclare: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
import {React, useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form'
import Timer from 'otp-timer'
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import { otpVerify } from '../../features/user/userActions'
//config url
import configData from "./../../config.js";
import OTPInput from "otp-input-react";
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
var logo = '/assets/images/NAKSHEWALA_LOGO.png';


const OtpVerification = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [OTP, setOTP] = useState("");
 
  const { register, handleSubmit, formState: { errors } } = useForm();

  const { onBoarding, success, error } = useSelector(
    (state) => state.user
  )
  useEffect(() => {

    if(success === 200 && onBoarding === true) {
      history.push("/profile");
    }

    if(onBoarding === true) {
      history.push("/profile");
    }

    if(onBoarding === false) {
      history.push('/register')
    }

    if(error){
      toast.error(error.replace(/[^a-zA-Z 0-9]/g, ''), {autoClose:2000})
    }
    
  }, [success, onBoarding, error, history])

  const HandleOtpVerification = async (data) => {
   // alert(OTP)
    if(OTP.length !== 4){
      toast.error("All inputs must be filled!", {autoClose:2000})
    }
    if(OTP.length === 4){
      const otp = OTP;
      dispatch(otpVerify({ otp: otp }))
    }
    //const otp = `${data.otpDigitOne}${data.otpDigitTwo}${data.otpDigitThree}${data.otpDigitFour}`
    
    //alert(otp)
    /*try {
      const response = await fetch(`${configData.ADMIN_URL}/api/auth/verify-otp`, {
        method: 'POST',
        body: JSON.stringify({
          "mobile": localStorage.getItem('mobile'),
          "otp": otp
        }),
        "headers": {
          "Accept": "application/json",
          "x-api-key": configData.X_API_KEY,
          "x-api-secret": configData.X_API_SECRET,
          "Content-Type": "application/json"
        },
      });
      if (!response.ok) {
        const result = await response.json();
        const msg = JSON.stringify(result.message);
        toast.error(msg.replace(/[^a-zA-Z 0-9]/g, ''), {autoClose:3000})
      }
        const result = await response.json();
        console.log(result)
        localStorage.setItem('uid', result.data.uid);
        localStorage.setItem('token', result.data.token);
        const onboarding = JSON.parse(result.data.onboarding)
        localStorage.setItem('onboarding', onboarding);
        if(!result.data.onboarding) {
          toast.success("Otp Verification Successfull", {autoClose:3000})
          setTimeout(()=> {
            history.push("/register");
          },3000)
        }
        else {
          toast.success("Login Successfull", {autoClose:3000})
          setTimeout(() => {
            history.push("/profile");
          },3000)
        }
    } catch (err) {
      setErr(err.message);
      console.log(err)
    } */
    
  };
  const ResendOtp = async () => {
    try {
      const response = await fetch(`${configData.ADMIN_URL}/api/auth/resend-otp`, {
        method: 'POST',
        body: JSON.stringify({
          "mobile": localStorage.getItem('mobile'),
        }),
        "headers": {
          "Accept": "application/json",
          "x-api-key": configData.X_API_KEY,
          "x-api-secret": configData.X_API_SECRET,
          "Content-Type": "application/json"
        },
      });
      if (!response.ok) {
        const result = await response.json();
        const msg = JSON.stringify(result.message);
        toast.error(msg.replace(/[^a-zA-Z 0-9]/g, ''), {autoClose:3000})
      }
      const result = await response.json();
      console.log('result: ', JSON.stringify(result));
      history.push("/otp-verify");
      
    } catch (err) {
      //setErr(err.message);
      toast.error(err.message.replace(/[^a-zA-Z 0-9]/g, ''), {autoClose:3000})
      console.log(err)
    } 
  };
  const handleError = (errors) => {
    toast.error("All fields must be filled", {autoClose:3000})
  
  };        
 
return (
<>
<div id="main-wrapper" className="oxyy-login-register">
  <div className="container-fluid px-0">
    <div className="row g-0 min-vh-100 for-mobile-view"> 

      <div className="col-md-5">
        <div className="hero-wrap h-100 bg-primary-2 ">
          <div className="hero-mask opacity-3 bg-primary"></div>
          <div className="hero-content w-100">
            <div className="container d-flex flex-column min-vh-100 for-mobile-lo">
              <div className="row g-0">
                <div className="col-11 col-md-10 col-lg-9 mx-auto">
                  <div className="logo mt-5 mb-5 mb-md-0"> <a className="d-flex for-logo-mobile" href="/" title="Oxyy"><img src={logo} alt="Nakshewala" /></a> </div>
                </div>
              </div>
              <div className="row g-0 my-auto forlogin-txt-mbl">
                <div className="col-11 col-md-10 col-lg-9 mx-auto">
                  <p className="text-4 lh-base">We're glad to see you again!</p>
                  <h1 className="text-9 fw-600 mb-5 for-txt-one-log">Right Design and Right ideas matter a lot of on Interior design.</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <div className="col-md-7 d-flex flex-column">
        <div className="container pt-5 for-ver-fy-otpps">
          <div className="row g-0">
            <div className="col-11 mx-auto">
           
            </div>
          </div>
        </div>
        <div className="container my-auto py-5 for-verify-otp-verification">
          <div className="row g-0">
          <div className="col-11 col-md-10 col-lg-9 col-xl-8 mx-auto">
              <h3 className="fw-600 mb-4 verification-text">Two-Step Verification</h3>
              <p className="text-cente"><img className="img-fluid" src="assets/images/otp-icon.png" alt="verification" /></p>
              <p className="text-muted mb-1">Please enter the OTP (one time password) to verify your account. A Code has been sent to <span className="text-dark text-4">{localStorage.getItem('mobile')}</span></p>
              <form id="otp-screen" onSubmit={handleSubmit(HandleOtpVerification, handleError)}>
                <label className="form-label fw-500">Enter 4 digit code </label>
             
                <div className="">
                <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number"  disabled={false}  />
                  {/*<div className="col">
                    <input {...register('otpDigitOne', verificationOptions.otpDigitOne) } type="text" className="form-control bg-ec border-light text-center text-6 py-2" maxlength="1"  />
                  </div>
                  <div className="col">
                    <input type="text" {...register('otpDigitTwo', verificationOptions.otpDigitTwo) } className="form-control bg-ec border-light text-center text-6 py-2" maxlength="1"   />
                  </div>
                  <div className="col">
                    <input type="text" {...register('otpDigitThree', verificationOptions.otpDigitThree) } className="form-control bg-ec border-light text-center text-6 py-2" maxlength="1"   />
                  </div>
                  <div className="col">
                    <input  type="text" {...register('otpDigitFour', verificationOptions.otpDigitFour) } className="form-control bg-ec border-light text-center text-6 py-2" maxlength="1"   />
                 </div>*/}
                </div>
                <div className="row mt-3 align-items-center">
                  <div className="col">
                    <button type="submit" className="btn btn-primary shadow-none mt-2 text-white">Verify</button>
                  
                    
                  </div>
                  <div className="">
                    <p className="text-end text-2 mb-0 d-flex resendt-text">Didn't get the code? <a  className='red' href={'#'}> <Timer seconds= {60} minutes={0} ButtonText="Resend OTP" text="Resend in" resend={ResendOtp} /></a></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    
    </div>
  </div>
</div>
<ToastContainer />
</>
)
}

export default OtpVerification;