import React from "react";
import { NavLink } from "react-router-dom";

const services = [
  {
    count: "01",
    title: "Simplex House",
    title1: "Plans",
    url: "/simplex-floor-plans.php",
    flaticon: "floorplan.svg",
    bg: "bg-white",
    description:
      "We provide a range of architectural 3D modeling services to our customers to aid the design, planning and...",
  },
  {
    count: "02",
    title: "Duplex House",
    title1: "Plans",
    url: "/duplex-floor-plans.php",
    flaticon: "duplex.svg",
    bg: "bg-white",
    description:
      "Analysis and planning services that help both the client and architects to work out the forthcoming project...",
  },
  {
    count: "03",
    title: "Bungalow House",
    title1: "Plans",
    url: "/bungalow-house-plans.php",
    flaticon: "bunglow.svg",
    bg: "bg-white",
    description:
      "We offer comprehensive Architectural Engineering Services including Interior design, Master planning, 3D modeling...",
  },
  {
    count: "04",
    title: "Vastu House",
    title1: "Plans",
    url: "/vastu-house-plans.php",
    flaticon: "vastu.svg",
    bg: "bg-white",
    description:
      "Project management is the process by which our team plans and executes your project. We will develop it...",
  },
  {
    count: "05",
    title: "Independent Floor",
    title1: "Plans",
    bg: "bg-white",
    url: "/independent-floor-house-plans.php",
    flaticon: "simplex.svg",

    description:
      "Our team also provides consultations on all architectural issues, even if you need specific info about working...",
  },
  {
    count: "06",
    title: "Small House",
    title1: "Plans",
    url: "/small-house-plans.php",
    flaticon: "small.svg",
    bg: "bg-white",
    description:
      "We combine Interior and Exterior Design services and often provide them as a single solution. It helps us...",
  },
  {
    count: "06",
    title: "Popular House",
    title1: "Plans",
    url: "/popular-house-plans.php",
    flaticon: "popular.svg",
    bg: "bg-white",
    description:
      "We combine Interior and Exterior Design services and often provide them as a single solution. It helps us...",
  },
  {
    count: "06",
    title: "Affordable House",
    title1: "Plans",
    url: "/affordable-house-plans.php",
    flaticon: "affordable.svg",
    bg: "bg-white",
    description:
      "We combine Interior and Exterior Design services and often provide them as a single solution. It helps us...",
  },

  {
    count: "06",
    title: "Farm House",
    title1: "Plans",
    url: "/farm-house-plans.php",
    flaticon: "farm.svg",
    bg: "bg-white",
    description:
      "We combine Interior and Exterior Design services and often provide them as a single solution. It helps us...",
  },
  {
    count: "06",
    title: "Apartment House",
    title1: "Plans",
    url: "/multifamily-apartment-house-plans.php",
    flaticon: "appartment.svg",
    bg: "bg-white",
    description:
      "We combine Interior and Exterior Design services and often provide them as a single solution. It helps us...",
  },
  {
    count: "06",
    title: "Commercial Floor",
    title1: "Plans",
    url: "/commercial-floor-plans.php",
    flaticon: "commercial.svg",
    bg: "bg-white",
    description:
      "We combine Interior and Exterior Design services and often provide them as a single solution. It helps us...",
  },
  {
    count: "06",
    title: "Consult",
    title1: "Now",
    url: "/contact-us.php",
    flaticon: "consult.svg",
    bg: "bg-red",
  },
];

const HomeFloor = (props) => {
  return (
    <>
      <div
        className={`${props.bgcolor} section-full desktop-hidden mobile-page-padding p-t80 p-b50`}
      >
        <div className="container">
          <div className="section-content mt-1">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="sx-separator-outer separator-left m-b30 ">
                  <NavLink to={"/readymade-floor-plans.php"}>
                    <h3 className="sep-line-one pt-0  mt-0">
                      Most <span className="red">Loved</span> Floor Plans{" "}
                      <img src="assets/images/floor.svg" alt="floor plan" />
                    </h3>
                  </NavLink>
                  <ul className="list-angle-right anchor-line floor">
                    <NavLink to={"/simplex-floor-plans.php"}>
                      <li>
                        <img
                          alt="simplex floor plans"
                          src="assets/images/floorplan.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/simplex-floor-plans.php"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          Simplex House Plans
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/duplex-floor-plans.php"}>
                      <li>
                        <img
                          alt="Duplex House Plans"
                          src="assets/images/duplex.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/duplex-floor-plans.php"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          {" "}
                          Duplex House Plans
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/bungalow-house-plans.php"}>
                      <li>
                        <img
                          alt="Bungalow House Plans"
                          src="assets/images/bunglow.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/bungalow-house-plans.php"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          Bungalow House Plans
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/vastu-house-plans.php"}>
                      <li>
                        <img
                          alt=" Vastu House Plans"
                          src="assets/images/vastu.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/vastu-house-plans.php"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          Vastu House Plans
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/independent-floor-house-plans.php"}>
                      <li>
                        <img
                          alt="Independent Floor Plans"
                          src="assets/images/simplex.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/independent-floor-house-plans.php"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          Independent Floor Plans
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/small-house-plans.php"}>
                      {" "}
                      <li>
                        <img
                          alt="Small House Plans"
                          src="assets/images/small.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/small-house-plans.php"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          Small House Plans
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/popular-house-plans.php"}>
                      <li>
                        <img
                          alt="Popular House Plans"
                          src="assets/images/popular.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/popular-house-plans.php"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          Popular House Plans
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/affordable-house-plans.php"}>
                      <li>
                        <img
                          alt=" Affordable House Plans"
                          src="assets/images/affordable.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/affordable-house-plans.php"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          Affordable House Plans
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/farm-house-plans.php"}>
                      <li>
                        <img
                          alt="Farm House Plans"
                          src="assets/images/commercial.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/farm-house-plans.php"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          Farm House Plans
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/multi-storey-residential-buildings.php"}>
                      <li>
                        <img
                          alt="Apartment House Plans"
                          src="assets/images/appartment.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to="/multi-storey-residential-buildings.php"
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          Apartment House Plans
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/commercial-floor-plans.php"}>
                      <li>
                        <img
                          alt="Commercial Floor Plans"
                          src="assets/images/commercial.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/commercial-floor-plans.php"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          Commercial Floor Plans
                        </NavLink>
                      </li>
                    </NavLink>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full mobile-page-padding d-sm-none d-md-block p-t20 p-b50 bg-pink">
        <div className="section-content">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head container">
              <div className="sx-separator-outer separator-left">
                <NavLink to={"/readymade-floor-plans.php"}>
                  <h3 className="sep-line-one">
                    {" "}
                    <span className="red">Most Loved</span> Floor Plans{" "}
                    <img src="assets/images/floor.svg" alt="" loading="lazy" />
                  </h3>
                </NavLink>
              </div>
            </div>
            {/* TITLE END */}
            <div className="row">
              {services.map((item, index) => (
                <div className=" col-lg-2 col-md-2 col-sm-12 m-b30" key={index}>
                  <div className="sx-icon-box-wraper  icon-count-2-outer">
                    <div className={`${item.bg} icon-count-2`}>
                      <NavLink to={item.url}>
                        <div className="icon-xl inline-icon m-b5 scale-in-center">
                          <span className="icon-cell">
                            {" "}
                            <img
                              className="w-80"
                              src={`assets/images/${item.flaticon}`}
                              alt={item.title}
                              loading="lazy"
                            />
                          </span>
                        </div>
                        <div className="icon-content">
                          <h4
                            className="sx-tilte fs-17 pl-0"
                            style={{ marginBottom: 0 }}
                          >
                            {item.title}
                          </h4>
                          <h4
                            className="sx-tilte one fs-17"
                            style={{ marginTop: 0 }}
                          >
                            {item.title1}
                          </h4>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeFloor;
