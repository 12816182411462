import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import configData from "./../../config.js";

const items = [
{
"id":7,
"title":"Apartments",
"primary_image":"cmrcl_bnr_638616cbab90c.webp",
"position":4,
},
{
"id":8,
"title":"Shopping Complex",
"position":5,
"primary_image":"cmrcl_bnr_638616dc4580c.webp",
},
{
"id":9,
"title":"Hospitals",
"position":6,
"primary_image":"cmrcl_bnr_6385cd62a25c8.webp",
},
{
"id":10,
"title":"Institutional",
"position":7,
"primary_image":"cmrcl_bnr_6385cd7cebf26.webp",
},
{
"id":11,
"title":"Corporate Buildings",
"position":8,
"primary_image":"cmrcl_bnr_6385cd8ed74e1.webp",
},
{
"id":12,
"title":"Hospitality",
"position":9,
"primary_image":"cmrcl_bnr_6385cda9ae4d3.webp",
}
]

const options = {
  loop: true,
  autoplay: false,
  center: false,
  //stagePadding: 100,
  items: 1,
  margin: 40,

  dots: false,
  navText: [
    '<i class="fa fa-angle-left"></i>',
    '<i class="fa fa-angle-right"></i>',
  ],
  responsive: {
    0: {
      items: 1,
      margin: 20,
    },
    640: {
      items: 2,
      margin: 20,
    },
    768: {
      items: 2,
      margin: 20,
    },
    991: {
      items: 4.5,
      margin: 15,
      nav: true,
      dots: false,
    },
    1200: {
      items: 4.5,
      margin: 15,
      nav: true,
      dots: false,
    },
  },
};

var bgimg1 = require("./../../images/background/bg-12.webp");

class HomeUniqueCommercial extends React.Component {
  
  render() {
   
    return (
      <>
        <div
          className="mobile-hidden section-full  mobile-page-padding bg-gray  p-t20 p-b10 bg-repeat"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <NavLink to={"/commercial-designs"}>
                  <h3 className="sep-line-one">
                    Unique <span className="red">Commercial</span> Designs{" "}
                    <img
                      src="assets/images/Commercial-icon.svg"
                      className="w-3"
                      alt="commercial"
                    />
                  </h3>
                </NavLink>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content section-content-commercials">
              <div className="client-grid m-b40">
                <div className="row justify-content-center">
                  <OwlCarousel
                    className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center "
                    {...options}
                  >
                    {items.map((item, index) => (
                      <div className="m-b10 " key={index}>
                        <div className="shadow-cs">
                          <NavLink
                            to={"commercial-designs/" + item.id}
                            className="client-logo-pic"
                          >
                            <LazyLoadImage
                              className="w-100 h-240"
                              effect="blur"
                              src={
                                `${configData.ADMIN_URL}/assets/images/banner/` +
                                item.primary_image
                              }
                              alt={item.title}
                            />

                            <NavLink to={"commercial-designs/" + item.id}>
                              <p className="house_area_text">{item.title}</p>
                            </NavLink>
                          </NavLink>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                </div>
              </div>
            </div>
            <div className="section-content-commercial_new"></div>
          </div>
        </div>

        <div
          className="desktop-hidden section-full  mobile-page-padding bg-gray  p-t20 p-b10 bg-repeat"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-left">
                <NavLink to={"/commercial-designs"}>
                  <h3 className="sep-line-one">
                    Unique <span className="red">Commercial</span> Designs{" "}
                    <img
                      src="assets/images/Commercial-icon.svg"
                      className="w-3"
                      alt=""
                    />
                  </h3>
                </NavLink>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content section-content-commercial">
              <div className="m-b10">
                <div className="row ">
                  {items.map((item, index) => (
                    <div className="col-sm-6 col-md-3 col-6">
                      <div className="m-b10 " key={index}>
                        <div className="shadow-cs">
                          <NavLink
                            to={"commercial-designs/" + item.id}
                            className="client-logo-pic mb-3"
                          >
                            <img
                              className="w-100 h-240"
                              src={
                                `${configData.ADMIN_URL}/assets/images/banner/` +
                                item.primary_image
                              }
                              alt={item.title}
                              style={{ height: "100%", maxWidth: "100%" }}
                            />

                            <p className="house_area_text_commercial">
                              {item.title}
                            </p>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="section-content-commercial_bottom"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomeUniqueCommercial;
