import React from 'react';

var bgimg1 = require('./../../images/buy-NaksheWala.webp');

class Whybuy extends React.Component {
 

  
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b50">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                    <div className="about-home-3 m-b30 bg-white">
                                        <h3 className="m-t0 m-b20 sx-tilte" style={{fontSize:33,fontWeight:600}}>Why Buy from Nakshewala.com?</h3>
                                        <p>
										Architectural services provider, NaksheWala.com reputedly is in the market since 2012, 
										growing bit by bit each day, gaining more of more knowledge, projects appreciations from client over the 
										country and across the world.
										</p>
										
										<p>
										Nakshewala.com is highly committed for client’s satisfaction and fulfilling all the requirements in order to 
										get their dream projects done.
										</p>
										
										<p>
										We are 100% committed to your satisfaction for quality and service, as we design every house like we ourselves going 
										to live in that home. We are the industry’s one of the most trusted and leading source for Customized House Plans, 
										Small House Plans, Duplex House Plans, 3D Front Elevations, all type of Architecture Designing and Interior Designing. 
										Here are some other points you should go through with so that you feel confident while designing with us:
										</p>
										
										
										{/* <ul className="list-angle-right anchor-line">
                                            <li><NavLink to="/about-1">We provide a architectural 3D modeling services.</NavLink></li>
                                            <li><NavLink to="/about-1">Our specialists are ready to consult you on any topic.</NavLink></li>
                                            <li><NavLink to="/about-1">We develop and implement better interior design.</NavLink></li>
                                            <li><NavLink to="/about-1">We provide high-quality interior services for clients.</NavLink></li>
                                        </ul>
                                        <div className="text-left">
                                            <NavLink to="/about-1" className="site-button btn-half"><span>Read More</span></NavLink>
	                                   </div>*/}
									   
									   
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                    <div className="video-section-full-v2">
                                        <div className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30 bak-grd" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                            <div className="overlay-main bg-black opacity-04" style={{opacity:0}} />
                                            <div className="video-section-inner par-tt">
                                                
												{/*<div className="video-section-content">
                                                    <NavLink to={"#"} className="play-now">
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </NavLink>

                                                    <div className="video-section-bottom">
                                                        <h3 className="sx-title text-white">25 Years<br />Experience</h3>
                                                    </div>
	                                              </div>*/}
												
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </>
        );
    }
};

export default Whybuy;