import React, { Suspense } from "react";
import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation  } from 'react-router-dom';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import Recommended from './../Elements/Recommended';
import About5 from './../Elements/About5';
import axios from "axios";
import { Helmet } from "react-helmet"; 
import  HomeCta from './../Elements/HomeCta';
import EnquireNow from './../Elements/EnquireNow'; 
import configData from "./../../config.js";

var bannerUrl = `${configData.ADMIN_URL}/admin/public/assets/images/banner/`;

const RecentProjects = ({ match }) => {

          let history = useHistory();
          const location = useLocation();
          const search = useLocation().search;
          const [seo, setSeo] = useState([]);
          const [todos, setTodos] = useState([]);
        //  const [loading, setLoading] = useState(false);
          const [totalTodos, setTotalTodos] = useState("");
          const [currentPage, setcurrentPage] = useState(1);
          const [itemsPerPage, setitemsPerPage] = useState(8);
          const [pageNumberLimit, setpageNumberLimit] = useState(5);
          const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
          const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
          const [open, setOpen] = useState(false);
          const [type, setType] = useState("all");
          const currenPageParms = new URLSearchParams(search).get('currentpage');
          const typeParams = new URLSearchParams(search).get('type');
           const [page_content, setPage_content] = useState("");

           const container = React.createRef();
                //setOpen(false);

            const  handleButtonClick = () => {
                 setOpen(true);
              };
            const  close = () => {
            setOpen(false);
            
            };
             window.onunload = function () {
               localStorage.removeItem('type');
               
             }
         //const cat = window.location.pathname; 
          useEffect(() => {
            if(currenPageParms) {
            //console.log(currenPageParms);
          if(currenPageParms !== 'null') {
            setcurrentPage(currenPageParms);
            //console.log(currentPage);
          }

        }
            async function fetchProducts() {
              //  setLoading(true);
             try {
              const item = await axios.get(`${configData.ADMIN_URL}/api/recent_project?currentpage=${currenPageParms ? currenPageParms : 1}&type=${typeParams ? typeParams : type}`)
              setTodos(item.data.data);
              setTotalTodos(item.data.total);
              setitemsPerPage(8);
              setpageNumberLimit(5);
              setPage_content(item.data.seo[0].page_content);
              setcurrentPage(item.data.currentpage);
              setSeo(item.data.seo);
             //  setLoading(false);
             } catch (error) {
              console.log(error);
             }
            }
            
            fetchProducts();
        }, [location.key, currenPageParms, type, typeParams]);

          async function typeValue(event){
          var type = event.target.value;
          setType(type);
          console.log(type);
          localStorage.setItem("type", type);
          history.push(`?type=${localStorage.getItem('type')}`);
          let item = await axios.get(`${configData.ADMIN_URL}/api/recent_project?type=${localStorage.getItem('type')}`)
          setTodos(item.data.data);
          console.log(item.data.data);
          console.log(item.data.total);
          setcurrentPage(item.data.currentpage);
          setTotalTodos(item.data.total);
    };
        // Get current Todo
         const pages = [];
          for (let i = 1; i <= Math.ceil(totalTodos / itemsPerPage); i++) {
            pages.push(i);
          }
          const paginate = pageNumber => setcurrentPage(pageNumber);
          const renderPageNumbers = pages.map((number) => {
            if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
              return (
                <li
                  key={number}
                  id={number}
                  onClick={() => paginate(number)}
                  className={currentPage === number ? "active" : null}
                >
                 <NavLink onClick={() => paginate(number)} to={"?a=1&currentpage=" + number} className='page-link'>
                      {number}
                    </ NavLink>
               
                </li>
              );
            } else {
              return null;
            }
          });
 

    async function handleNextbtn() {
      
      history.push(`?currentpage=${Number(currentPage) + 1}`);
      const item = await axios.get(`${configData.ADMIN_URL}/api/recent_project?page=${Number(currentPage) + 1}`)
     
     setTodos(item.data.data);
     setTotalTodos(item.data.total);

    setcurrentPage(Number(currentPage) + 1);
    //console.log(Number(currentPage) + 1);

    if (Number(currentPage) + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  async function handlePrevbtn()  {

     history.push(`?currentpage=${Number(currentPage) + 1}`);
      const item = await axios.get(`${configData.ADMIN_URL}/api/recent_project?page=${Number(currentPage) - 1}`)
     
     setTodos(item.data.data);
     setTotalTodos(item.data.total);


    setcurrentPage(currentPage - 1);

    if ((Number(currentPage) - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  }; 
          let pageIncrementBtn = null;
          if (pages.length > maxPageNumberLimit) {
            pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
          }

          let pageDecrementBtn = null;
          if (minPageNumberLimit >= 1) {
            pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
          }

         
        return (
            <>

                 {seo.map((item, index) => (
                 <Helmet key={index}>  
                 <title>{item.pagetitle}</title>
                 <meta name="description" content={item.metadescription} />
                 <meta name="keywords" content={item.metakeywords} />
                 <meta property="og:type" content="Page" />
                 <meta property="og:title" content={item.pagetitle} />
                 <meta property="og:description" content={item.metadescription} />
                 <meta property="og:url" content={window.location.href} />
                 <link rel="canonical" href={`${configData.CANONICAL_URL}recent-interior-designing-project.php`} />
                 <meta property="og:site_name" content={configData.SITE_NAME} />
                 <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_banner}`} />
                 </Helmet> 
                 ))}
                <Header2 />
                <Suspense fallback={<div>Loading...</div>}>
                <div className="page-content">
                 {seo.map((item, index) => (
                <div key={index} className="sx-bnr-inr overlay-wraper bg-top-center bg-cover" style={{ backgroundImage: 'url('+ bannerUrl + item.header_banner + ')' }}>
                    <div class="overlay-main bg-black opacity-05"></div>
                    <div className="container">
                        <div className="sx-bnr-inr-entry">
                            <div className="banner-title-outer text-left">
                           
                                <div className="banner-title-name ">
                                    <h2 className="m-tb0 mb-3">{item.page_name}</h2>   
                                    <h5 className="text-white">{item.short_des_heading}</h5>                             
                                    <p className="fs-15 lh-1">
                                    <div dangerouslySetInnerHTML={ { __html: item.short_description } }></div>
                                    </p>
                                </div>
                               
                                  <EnquireNow />
                            </div>
                            
                        </div>
                    </div>
                </div>
               ))} 
                   
                    <div>
                    <ul className="sx-breadcrumb breadcrumb-style-2 bg-black pl-2 pb-1">
                     <div className="container">
                    <li className="red"><NavLink className="red" to={"./"}>Home </NavLink></li>
                        <NavLink to={"/interior-designing.php"}><li className="red">Interior Designing </li></NavLink>
                        <li>Recent Projects</li>
                        </div>
                    </ul>
                </div>
                    {/* SECTION CONTENT START */}
                    {/* OUR Team START */}
                    <div className="section-full p-t30 p-b50 mobile-page-padding">
                    <div  className="row">
                    <div className="container md-flex"  ref={container}>
                       <div className="wm-50 col-xl-6 col-lg-6 col-md-6 col-sm-6 m-t20 m-b20"><h4 className="fw-600 red text-transform-c">{typeParams ? typeParams : type} Projects</h4></div> 
                        <div className="wm-50 col-xl-12 col-lg-12 col-md-6 col-sm-6 m-b20 m-t20 text-right mtm--50">
                        <button  onClick={handleButtonClick} className="filters">
                        <span className="red"><i className="fa fa-filter"></i></span> Filters</button>
                        {open && (
                        <div class="dropdown shadow-css dropdown-top">
                        <div onClick={close} className="clear-all pointer"><i className="fa fa-times"></i> Filters</div>
                          <ul className="filters-list p-a10">
                            <li>
                            <input onChange={typeValue} type="radio"  id="all" value="all" name="filter"  />
                            <label className="fw-600" for="all">All</label> 
                            </li>

                            <li>
                            <input onChange={typeValue} type="radio"  id="residential" value="residential" name="filter" />
                            <label className="fw-600" for="residential">Residentail</label>
                             </li>

                            <li>
                            <input onChange={typeValue} type="radio"  id="commercial" value="commercial" name="filter" />
                            <label className="fw-600" for="commercial">Commercial</label>
                             </li>
                          
                          </ul>
                        </div>
                      )}</div>
                        </div>
                    </div>
                        <div className="container">
                            {/* IMAGE CAROUSEL START */}
                            <div className="section-content">
                                <div className="row our-team-2-outer">
                                    {todos.map((item, index) => (

                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                         
                                            <div className="our-team-2">
                                                <div className="profile-image">
                                                    <NavLink to={item.slug}><img src={`${configData.ADMIN_URL}/assets/images/recent_project/` + item.image} alt="" /></NavLink>
                                                  
                                                </div>
                                                <div className="figcaption text-black text-center">
                                                    <h4 className="m-t0 mb-0"><NavLink className="red fw-600 fs-19" to={item.slug}>{item.title}</NavLink></h4>
                                                    <span>{item.owner}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="paginate">
                                    <ul className="pageNumbers">
                                        <li>
                                          <button
                                            onClick={handlePrevbtn}
                                            disabled={currentPage === pages[0] ? true : false}
                                          >
                                            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Previous
                                          </button>
                                        </li>
                                        {pageDecrementBtn}
                                        {renderPageNumbers}
                                        {pageIncrementBtn}

                                        <li>
                                          <button
                                            onClick={handleNextbtn}
                                            disabled={currentPage === pages[pages.length - 1] ? true : false}
                                          >
                                            Next <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                                          </button>
                                        </li>
                                       

                                      </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* OUR Team END */}
                    {/* SECTION CONTENT END  */}
                     <HomeCta />
                    
                    <Recommended  title="Recommended Services" bgcolor="bg-white" />
                      <About5 page_content={page_content} />
                
                </div>
                </Suspense>
                <Footer />
            </>
        );
   
};

export default RecentProjects;