import React from 'react';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import WhatWeDo from './../Elements/WhatWeDo';
import HomeStatistics from './../Elements/HomeStatistics';
import Team1 from './../Elements/Team1';
import { NavLink } from 'react-router-dom';
import { useState } from "react";
import ModalVideo from 'react-modal-video';
import { Helmet } from "react-helmet"; 
//config url
import configData from "./../../config.js";

import '../../../node_modules/react-modal-video/css/modal-video.min.css';

var bgimg1 = require('./../../images/video-bg.jpg');

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 650) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? <span className="red cursor-pointer">...read more</span> : <span className="red cursor-pointer">show less</span>}
      </span>
    </p>
  );
};

class AboutUs extends React.Component {

    constructor() {
        super()
        this.state = {
            isOpen: false,
            items: []
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    //API CALL
    componentDidMount() {
        fetch(
        `${configData.ADMIN_URL}/api/metapageurl?page_url=aboutus.php`)
        .then((res) => res.json())
        .then((json) => {
            this.setState({
                items: json,
                DataisLoaded: true
            });
        })

        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');
    };
    render() {

        const { items } = this.state;

        return (
            <>

            {items.map((item, index) => (
            <Helmet>  
            <title>{item.pagetitle}</title>
            <meta name="description" content={item.metadescription} />
            <meta name="keywords" content={item.metakeywords} />
            </Helmet> 
             ))}

                <Header2 />
                <div className="page-content">
                   
                    <div className="section-full mobile-page-padding p-t80 p-b50">
                    <div className="container">
                     <div className="text-center mt--20"><img  alt="about us" className="wm-100" src="assets/images/office-concept-illustration.webp" /></div>
                    
                        <div className="section-content">

                        {/* TITLE START */}
                                <div className="section-head">
                                    <div className="sx-separator-outer separator-center">
                                        <div className="" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>
                                            
                                            <h2 className="mt--20 heading-h2 fsm-25">About <span className="red">Nakshewala.com</span></h2>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}

                            <div className="row">
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                    <div className="about-home-3 m-b30 bg-white">
                                        <h3 className="m-t0 m-b20 sx-tilte fsm-21">Nakshewala.com an online architectural organisation.</h3>
                                        <p className="text-justify overflow-text"><ReadMore>NaksheWala.com started with all types of architecture designing services that is Customized house plans/home plans/house maps, customized 3d elevations, and all the technical drawings such as structural drawings, working drawings, plumbing & electrical drawings and it went beyond our expectations and we were very glad that we managed to serve as many people as we could in architecture services and the number of clients is increasing with each passing day till now.

In the number of years we launched our other services too which are “3D Floor Plan & Video Walkthroughs” for builders specifically and individual client’s if they want, and boom we did it as well, we made tones of efforts to make it happen, Nakshewala.com was then dealing in Architecture and "3D Visualization" services "3d floor plan & walkthroughs". We were and are always open for feedback and suggestions, we learned, we gained knowledge about how can we establish it to an extent and we were ready to give our 200% efforts to make designing easy for all our customers.

 We were and are highly dedicated to our client’s satisfaction, this is the quality of our team since then differentiating us from any other designing firm and we are proud of it. Getting a remarkable success and establishment of architecture & visualization services, Nakshewala.com was then eager to provide “Online Interior Designing Services as well, since the motive, in the beginning, was to give one single platform for all the architecture and interior designing services. Responsibilities increased and so our dedication we were fully charged to do the hard work so that it all works out. We did all that we could and eventually, we achieved that set goal too. Now our responsibility is to provide customized architecture designing, visualization, walkthrough & online interior designing services all over India and across the world.

We are very proud of our dedicated team and its efforts because of which we managed to come this far, many more years to go, many more things to achieve, and more of all many more clients' relationships are waiting for us. Nakshewala.com reputedly completed 8 years of excellent services doing more than eleven thousand projects in more than 500 hundred cities of India and across the world and we are ready to serve more of them.  Our Mission is to provide our customers with an unmatched level of customer service and satisfaction. We know that building a home is like transforming a dream into reality. We design each house plan as we are going to live in it and it is our goal to provide those house designs that make a home a more comfortable place to live with a unique design.

Our architectural designing works are resultant of creative minds and the best technology available. Each member of our staff is trained and versed in architectural practices and terminology. And are ready to assist you with any questions you may have. We believe to make the process of designing your dream home to be an informative and creative manner. We are always available to answer any questions you may have about a particular house plan, modifications to one of our house plans, or any query regarding the architecture & interior designing.

Now NaksheWala.com is introducing readymade house design services in a very cost-effective way in this we are offering readymade floor plans, elevation, and other technical drawings and we are open to give one-time correction as per your need in designs too as no readymade design can fully satisfy all your needs. Establishing a single platform for all services we are now aiming to give a platform of connection for all the vendors such as contractors, suppliers, associates, etc. with the clients as required. NaksheWala.com believes in sustainable growth, hence aimed towards professional and social growth.   We offer architecture & interior services at competitive prices. We concentrate on our commitment to provide the best customer service that exceeds our customers' expectations. We strongly believe this dedication to serving our customers is the primary reason behind the growth and success of NaksheWala.com.

Our goal is to make the process of finding your dream house simple, quick, and enjoyable..... So stop dreaming, Start building.

Feel free to call or email us with questions or comments that you may have to help us improve your experience at NaksheWala.com!!!</ReadMore> </p>
                                       
                                        
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12">
                                    <div className="video-section-full-v2">
                                        <div className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30" style={{ backgroundImage: 'url(' + bgimg1 + ')' }}>
                                            <div className="overlay-main bg-black opacity-04" />
                                            <div className="video-section-inner">
                                                <div className="video-section-content">
                                                    <NavLink to={"#"} className="play-now" onClick={this.openModal}>
                                                        <i className="icon fa fa-play" />
                                                        <span className="ripple" />
                                                    </NavLink>

                                                    <div className="video-section-bottom">
                                                        <h3 className="sx-title text-white">25 Years<br />Experience</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalVideo channel='vimeo' isOpen={this.state.isOpen} videoId='34741214' onClose={() => this.setState({ isOpen: false })} />
                    <WhatWeDo />
                    <HomeStatistics />
                    <Team1 />
                
                </div>

                <Footer />
            </>
        );
    };
};

export default AboutUs;