import React from "react";
import { NavLink } from "react-router-dom";



const LandingOffer = (props) => {
  return (
    <>
      
      <div
        className={`${props.bgcolor} section-full desktop-hidden mobile-page-padding p-t80 p-b50`}
      >
        <div className="container">
          <div className="section-content mt-1">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="sx-separator-outer separator-left m-b30 ">
                  <NavLink to={"/readymade-floor-plans.php"}>
                    <h3 className="sep-line-one pt-0  mt-0">
                      <span className="red">We </span>Offer{" "}
                      <img src="assets/images/floor.svg" alt="floor plan" />
                    </h3>
                  </NavLink>
                  <ul className="list-angle-right anchor-line floor">
                    <NavLink to={"/"}>
                      <li>
                        <img
                          alt="Customized House Design"
                          src="assets/images/floorplan.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/simplex-floor-plans.php"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          Customized House Design
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/"}>
                      <li>
                        <img
                          alt="Exclusive 3D Elevations"
                          src="assets/images/duplex.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/duplex-floor-plans.php"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          {" "}
                          Exclusive 3D Elevations
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/"}>
                      <li>
                        <img
                          alt="Structural Drawings"
                          src="assets/images/bunglow.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                         Structural Drawings
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/vastu-house-plans.php"}>
                      <li>
                        <img
                          alt="Working Drawings"
                          src="assets/images/vastu.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                          Working Drawings
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/"}>
                      <li>
                        <img
                          alt="Electrical Drawings"
                          src="assets/images/simplex.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                         Electrical Drawings
                        </NavLink>
                      </li>
                    </NavLink>
                    <NavLink to={"/small-house-plans.php"}>
                      {" "}
                      <li>
                        <img
                          alt="Plumbing Drawings "
                          src="assets/images/small.svg"
                          style={{ width: 30, height: "100%" }}
                        />{" "}
                        <NavLink
                          to={"/"}
                          style={{ verticalAlign: "sub", marginLeft: 10 }}
                        >
                         Plumbing Drawings 
                        </NavLink>
                      </li>
                    </NavLink>
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingOffer;
