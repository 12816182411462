
import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const logos = [
   { image: require('./../../images/bedroom_area.webp'),
      title: 'Bedroom',
      url:   '/bedroom-interior-designs.php'
       },
    { image: require('./../../images/kitchen.webp'),
      title: 'Kitchen',
      url:   '/kitchen-interior-designs.php' },
       { image: require('./../../images/Living-room.webp'),
      title: ' living Area',
      url:   '/living-room-interior-designs.php' },
    { image: require('./../../images/dining.webp'),
      title: ' Dinning Area',
      url:   '/dining-interior-designs.php' },
    { image: require('./../../images/kids.webp'),
      title: 'Kids room',
      url:   '/kids-room-interior-designs.php' },
    { image: require('./../../images/toilet.webp'),
      title: 'Toilet',
      url:   '/toilet-interior-designs.php' },

      { image: require('./../../images/entarnce.webp'),
      title: 'Entrance',
      url:   '/entrance-interior-design.php'  },
       { image: require('./../../images/pooja.webp'),
      title: 'Pooja',
      url:   '/puja-room-interior-designs.php' },
      { image: require('./../../images/drawing.webp'),
      title: 'Drawing Room',
      url:   '/drawing-room-interior-designs.php' }
    
]

var bgimg1 = require('./../../images/background/bg-12.jpg');

function InteriorSection2() {
        return (
            <>
                <div className="section-full desktop-hidden mobile-page-padding bg-gray  p-t20 p-b10 bg-repeat" style={{ backgroundImage: 'url(' + bgimg1.default + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-left">
                               
                                   <h2 className="sep-line-one">Home <span className='red'>Interior Designs</span> <br></br> By Rooms <span className="red v-align-m"><i class="fa fa-long-arrow-right"></i></span></h2>
                            
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="client-grid m-b20">
                                <div className="row for-mobile">
                                    {logos.map((item, index) => (
                                        
                                        <div className="col-lg-3 col-md-4 col-sm-3 col-4 m-b10 p-1" key={index}>
                                        <div className="shadow-cs">
                                            <a href={item.url} className="client-logo-pic">
                                            <LazyLoadImage
                                            className="w-100"
                                            effect="blur"
                                            src={item.image}
                                            alt={item.title}
                                            />
                                                <div>
                                                    <span><i className="fa fa-search"></i></span>
                                                </div>
                                            </a>
                                            <a href={item.url}><p className='interior_area_text bg-white'>{item.title}</p> </a>
                                        </div>
                                        </div>
                                    ))}
                                </div>


                                

                            </div>
                        </div>
                          
                        
                       

                    </div>
                   
                  
                </div>
            </>
        );   
};

export default InteriorSection2;

