import React from "react";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import Recommended from "./../../Elements/Recommended";
import About5 from "./../../Elements/About5";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import HomeCta from "./../../Elements/HomeCta";
import InteriorSection1 from "./../../Elements/InteriorSection1";
import configData from "./../../../config.js";

const VastuPoojaRoom = () => {
  const [seo, setSeo] = useState([]);
  const [page_content, setPage_content] = useState("");

  useEffect(() => {
    async function fetchProducts() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=vastu-for-pooja-room.php`
        );
        setSeo(pageSeo.data);
        setPage_content(pageSeo.data[0].page_content);
      } catch (error) {
        console.log(error);
      }
    }

    fetchProducts();
  }, []);

  return (
    <>
      {seo.map((item, index) => (
        <Helmet>
          <title>{item.pagetitle}</title>
          <meta name="description" content={item.metadescription} />
          <meta name="keywords" content={item.metakeywords} />
        </Helmet>
      ))}

      <Header2 />

      <div className="page-content">
        {seo.map((item, index) => (
          <InteriorSection1
            page_name={item.page_name}
            short_des_heading={item.short_des_heading}
            sub_title={item.sub_title}
            short_description={item.short_description}
          />
        ))}

        <div className="p-t30 p-b30 ">
          <div className="container">
            <div className="section-content">
              <h2>Vastu For Pooja Rooms</h2>

              <p>
                The pooja room is the most holy place in the house. Consequently
                having the right Vastu for the pooja room in the house is vital.
                Indian homes are deficient without the presence of a pooja room
                or a mandir region. These spaces assume a crucial part in our
                homes since they are viewed as the core of positive energy and
                consecrated inside and out. The Mandir is the space in the house
                that sends its energy to the remainder of the house according to
                Vastu. In any case, imagine a scenario where the pooja room
                isn't working according to Vastu standards. All things
                considered, in such cases, the pooja room may not satisfy its
                maximum capacity and may require fixing.
              </p>

              <h2>Direction Of Pooja Room As Per Vastu shastra</h2>
              <p>
                The area of the pooja rooms is vital in Vastu shastra.
                Specialists say that the most ideal headings for pooja room are
                the northeast, trailed by east and north. These bearings are
                promising and will carry favorable luck to all individuals
                living in the house.
              </p>

              <div className="single-service-list">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <h5>
                      While building the pooja room in your home, ensure you are
                      not developing it:
                    </h5>

                    <ul className="direction-pooja" >
                      <li>Under a flight of stairs</li>
                      <li>Directly before the fundamental entryway</li>
                      <li>Close to the toilet or</li>
                      <li>Imparting a wall to a toilet or washing room.</li>
                    </ul>

                    <h2>
                      Shades of Wall and Floor in Pooja Room according to Vastu.
                    </h2>

                    <p>
                      As per Vastu science, Colors plays a huge part in the vibe
                      and energy of the room. as per the Vastu rules, Colors
                      hold meaning and are delegates of the components of nature
                      and, can end up being exceptionally gainful for the
                      family.
                    </p>

                    <p>
                      According to Vastu for the pooja room, white, light
                      yellow, and blue are probably the most promising varieties
                      for a mandir. While white is the image of virtue, blue
                      carries serenity to put, going with both incredible
                      decisions. Dull varieties like dark, naval force blue, or
                      violet ought to be kept away from here. It is likewise
                      prescribed to have a light-shaded deck, and you can pick
                      white marble or cream variety tiles for this space
                    </p>

                    <p>
                      In the above data, we shared everything to be familiar
                      with Vastu for the pooja room. Right from the heading to
                      variety, lighting, and positions, and that's just the
                      beginning - everything is covered.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <HomeCta />
        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>

      <About5 page_content={page_content} />
      <Footer />
    </>
  );
};
export default VastuPoojaRoom;
