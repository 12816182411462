import { createSlice } from "@reduxjs/toolkit";
import {
  registerUser,
  userWishList,
  checkNumber,
  otpVerify,
} from "./userActions";

const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

const mobile = localStorage.getItem("mobile")
  ? localStorage.getItem("mobile")
  : null;

const userId = localStorage.getItem("uid")
  ? JSON.parse(localStorage.getItem("uid"))
  : null;

const onBoarding = localStorage.getItem("onboarding")
  ? JSON.parse(localStorage.getItem("onboarding"))
  : null;

const initialState = {
  loading: false,
  userId,
  token,
  onBoarding,
  mobile,
  userInfo: null,
  error: null,
  wishListStatus: null,
  success: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("onboarding");
      localStorage.removeItem("uid");
      localStorage.removeItem("mobile"); // delete token from storage
      state.loading = false;
      state.userInfo = null;
      state.token = null;
      state.mobile = null;
      state.error = null;
      state.userId = null;
      state.onBoarding = null;
    },
    clearWishList: (state) => {
      state.wishListStatus = null;
    },
  },
  extraReducers: {
    [registerUser.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [registerUser.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.onBoarding = true;
      state.error = null;
      state.success = true; // registration successful
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    [userWishList.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.wishListStatus = null;
    },
    [userWishList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.wishListStatus = true;
      state.error = null;
    },
    [userWishList.rejected]: (state, { payload }) => {
      state.loading = true;
      state.wishListStatus = false;
      state.error = payload;
    },

    [checkNumber.pending]: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [checkNumber.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = 200;
      state.error = null;
      state.mobile = payload;
    },
    [checkNumber.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = payload;
    },

    [otpVerify.pending]: (state, { payload }) => {
      state.loading = true;
      state.error = payload;
      state.success = null;
    },
    [otpVerify.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = payload.status_code;
      state.userId = payload.data.uid;
      state.token = payload.data.token;
      state.onBoarding = payload.data.onboarding;
      state.error = null;
    },
    [otpVerify.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = null;
      state.error = payload;
    },
  },
});
export const { logout } = userSlice.actions;
export const { clearWishList } = userSlice.actions;
export default userSlice.reducer;
