import React from "react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
//config url
import configData from "./../../config.js";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useContext } from "react";
import { IPInfoContext } from "ip-info-react";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [requirement, setRequirement] = useState("");
  const [countryInfo, setCountryInfo] = useState(null);
  const [ip, setIp] = useState(null);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [number, setNumber] = useState("");
  //const [value, setValue] = useState("");
  const userInfo = useContext(IPInfoContext);
  const [requirementError, setRequirementError] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  // const enabled =
  //   requirement.length > 0 &&
  //   name.length > 0 &&
  //   email.length > 0 &&
  //   mobile.length > 0;

  useEffect(() => {
    const fetchCountryData = async () => {
      setIp(userInfo.ip);
      if (ip) {
        try {
          const response = await fetch(`https://api.country.is/${ip}`);
          const data = await response.json();
          setCountryInfo(data.country);
        } catch (error) {
          console.error("Error fetching country data:", error);
        }
      }
    };
    fetchCountryData();
  }, [userInfo.ip, ip]);

  const handleChangeNumber = (number) => {
    setNumber(number);
    if (number) {
      try {
        const phoneNumber = parsePhoneNumberFromString(number);

        const countryCode = `${
          phoneNumber ? phoneNumber.countryCallingCode : ""
        }`;
        const nationalNumber = phoneNumber ? phoneNumber.nationalNumber : "";
        setCode(countryCode);
        // setValue(nationalNumber);
        setMobile(code + nationalNumber);

        if (isValidPhoneNumber(number)) {
          setError(false);
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      }
    } else {
      setCode("");
      // setValue("");
      setError(true);
    }
  };
  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    setNameError(value.trim() === "" ? "Name is required" : "");
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(value) ? "Please enter a valid email" : "");
  };

  const handleRequirementChange = (e) => {
    const value = e.target.value;
    setRequirement(value);
    setRequirementError(value.trim() === "" ? "Requirement is required" : "");
  };
  async function saveQuery(event) {
    event.preventDefault();
    if (name && email && mobile && requirement) {
      let data = { name, email, mobile, requirement };
      if (isValidPhoneNumber("+" + mobile)) {
        let result = await fetch(`${configData.ADMIN_URL}/api/contact`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            Accept: "applicatiion/json",
          },

          body: JSON.stringify(data),
        });

        const item = await result.json();

        if (item.status === "success") {
          window.location.href = `/thanks.php`;
        } else {
          var msg = JSON.stringify(item.errors.email[0]);
          toast.error(msg, { autoClose: 3000 });
        }
      } else {
        toast.error("Invalid phone number", { autoClose: 3000 });
      }
    } else {
      if(!mobile){
        setError(true);
      }
      if (!name) {
        setNameError("Name is required");
      }
      if (!email) {
        setEmailError("Email is required");
      }
      if (!requirement) {
        setRequirementError("Requirement is required");
      }
    }
  }
  return (
    <>
      <div className="display-form" style={{ paddingTop: 10 }}>
        <div className="">
          <form className="" action="#" method="post">
            <div className="form-group">
              <input
                type="text"
                value={name}
                onChange={handleNameChange}
                className="bg-white form-control"
                placeholder="Enter Your Name"
                style={{ fontSize: 13 }}
              />
              {nameError && <p className="error-msg">{nameError}</p>}
            </div>

            <div className="form-group">
              {/* <input
                type="text"
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                className="bg-white form-control"
                placeholder="Enter Contact Number"
                style={{ fontSize: 13 }}
              /> */}
              <div className="form-control">
                <PhoneInput
                  international
                  className="bg-white footer-form-phone-control "
                  defaultCountry={countryInfo}
                  countryCallingCodeEditable={false}
                  value={number || ""}
                  placeholder="Enter phone number"
                  onChange={handleChangeNumber}
                />
              </div>
              {error && (
                <p className="error-msg">Please enter a valid number</p>
              )}
            </div>

            <div className="form-group">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                className="bg-white form-control"
                placeholder="Enter Email Address"
                style={{ fontSize: 13 }}
              />
              {emailError && <p className="error-msg">{emailError}</p>}
            </div>

            <div className="form-group">
              <textarea
                value={requirement}
                onChange={handleRequirementChange}
                className="bg-white form-control"
                placeholder="Enter Plot size & Requirements"
                style={{ fontSize: 13 }}
              ></textarea>
              {requirementError && (
                <p className="error-msg">{requirementError}</p>
              )}
            </div>

            <button
              onClick={saveQuery}
              // disabled={!enabled}
              type="button"
              value="submit"
              className="counter-btn w-100 site-cart-btn site-button-secondry mt-3 footer-contact-form-left"
              style={{ fontSize: 13 }}
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default ContactForm;
