/*eslint no-unused-vars: "off"*/
import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: '01',
        url: '/readymade-house-design.php',
        title: ' House Design',
        image: require('./../../images/services/service-projects/b90d586f-8264-463a-808a-871d17f4c4a5.webp')
    },
    {
        count: '02',
        url: '/commercial-designs',
        title: 'Commercial Designs',
        image: require('./../../images/services/service-projects/570aea57-a620-43eb-9591-78f0d72e6f88.webp')
    },
    {
        count: '03',
        url: '/3d-2d-floor-plans.php',
        title: '3D Floor Plan',
        image: require('./../../images/services/service-projects/6539db2f-8b6b-4b91-8190-c9899fa7f64b.webp')
    },
    {
        count: '04',
        url: '/interior-designing.php',
        title: ' Interior Design',
        image: require('./../../images/services/service-projects/cb15da60-ecad-4a66-9534-6271dadac2e6.webp')
    }
]

var bgimg2 = require('./../../images/background/cross-line2.png');
var bgimg3 = require('./../../images/background/bg-12.jpg');

class WhatWeDo extends React.Component {
    render() {
        return (
            <>
                <div className="section-full  mobile-page-padding bg-white  p-t50 p-b50 bg-repeat overflow-hide" style={{ backgroundImage: 'url(' + bgimg3.default + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head mb-5">
                            <div className="sx-separator-outer separator-center">
                                <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg2.default + ')' }}>
                                    <h3 className="sep-line-one">What We Do</h3>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="row number-block-three-outer justify-content-center">
                                {services.map((item, index) => (
                                      
                                    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                    <a href={item.url} >
                                        <div className="number-block-three slide-ani-top">
                                            <div className="sx-media">
                                                <img className="min-h-210" src={item.image} alt="" />
                                            </div>
                                            <div className="figcaption bg-gray  p-a20">
                                                <h5 className="m-tb0 text-center"><NavLink to={item.url}>{item.title}</NavLink></h5>
                                                <div className="figcaption-number animate-top-content">
                                                    <span>{item.count}</span>
                                                </div>
                                            </div>
                                        </div>
                                         </a>
                                    </div>
                                   
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default WhatWeDo;