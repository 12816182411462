import React, { Suspense } from "react";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import Recommended from "./../../Elements/Recommended";
import VastuDirection from "./VastuDirection";
import VastuAllDirectionBanner from "./VastuAllDirectionBanner";
import About5 from "./../../Elements/About5";
import axios from "axios";
import { useEffect, useState } from "react";
import configData from "./../../../config.js";
var bnrimg = require("./../../../images/vastu-consultancy.webp");

const VastuSouthFacing = () => {
	
  const [page_content, setPage_content] = useState("");

  //SEO API CALL
  useEffect(() => {
    async function fetchData() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=vastu-for-south-facing-house.php`
        );
       
        setPage_content(pageSeo.data[0].short_description);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <Header2 />
      <div className="page-content">
        <VastuAllDirectionBanner
          title="Vastu for South Facing House"
          pagename=""
          description="Getting designs in an auspicious way"
          bgimage={bnrimg}
        />

        <div className="p-t50 p-b50 north-all-paragraph-content">
          <div className="container">
            <h3 className="north-facing-content-section">
              South facing house can also be auspicious
            </h3>
            <p>
              Over a period of time, South facing houses have earned a very bad
              reputation and many people believe that – each and every – South
              facing house is bad, actually it is not true it’s a huge
              “misconception”. In fact, if we consider Vastu shastra, a South
              facing house also can be the very auspicious house if an only if,
              it follows certain and very specific Vastu principles. Therefore
              if you get a chance to live in or buy a South facing house which
              is following all the Vastu, then there’s nothing best to it. Vastu
              shastra always indicated that all directions are equally
              auspicious and equally good. Vastu for South Facing Home Plan can
              be so much effective that it can give you all the possibilities of
              prosperity and comfort. We offer Vastu Floor Plan that strictly
              follows Vastu and makes it happen.
            </p>

            <p>
              NaksheWala is providing all the relevant information to follow
              Vastu for South Facing House Plans. Vastu believes that each and
              every direction has its own value and known facts, none of them is
              inauspicious. The main thing that matters the most in the Vastu is
              the position of the main entrance, one and only factor which
              defines the auspiciousness of the entire house. So while placing
              the entrance of the house make sure that you aren’t making any
              mistake. To simply the positioning of the main entrance let us
              assume and divide the south direction into south-east to
              south-west nine equal steps, each step called pada in
              Vastu-shastra. The best suitable position for main entrance is at
              4th pada, it is the best and most auspicious. You can also use 1,2
              and 3 pada for the entrance gate, the second-best choice for
              entrance. Avoid main entrance in any pada from 5th to 9th cause
              they are the most inauspicious.
            </p>

            <p>
              Another point to keep in mind is to not place any water-related
              things in the south-west corner. For car parking, any of the sides
              can be used apart from the south. Keep kitchen in the south-east
              or north-west direction. Make the master bedroom at the south-west
              corner of the house or if the house has multiple floors then place
              master bedroom at the topmost floor of the house. Pooja room can
              be located at North-East along with other valuables. North-west is
              good for a guest bedroom and kids bedroom. These are the various
              Vastu guidelines one can use to make out most of his/her newly
              built house. Vastu is something which can make it happen.
            </p>
          </div>
        </div>

        <Suspense fallback={<div>Loading...</div>}>
        <VastuDirection />
        <Recommended title="Recommended Services" bgcolor="bg-white" />
        <About5 page_content={page_content} />
        </Suspense>
		
      </div>
      <Footer />
    </>
  );
};
export default VastuSouthFacing;
