import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import HomeCta from './../Elements/HomeCta';

const Feedback = () => {
 
  return (

  <>
  
   <Header2 />
   
   
         <div className="page-content">
		 
		  <div className="section-full p-t80 p-b50 mobile-page-padding for-feed-bk">
                        
			<div className="container">
                         
        
			  <div style={{textAlign:"center"}}>
			  
			 <h2><b><span style={{color:"#c9100f"}}>Feed</span>back</b></h2>
			
			 
			 <p>
			 <b>Thank you for taking the time to give us feedback.</b> 
			 </p>
			 
			 <p>
			 We are continuously working to improve the site, and your feedback is essential. We are always seeking to improve our website, and service we provide to you and we welcome always your feedback as an important part of this process. You just need to fill the below given form for the same.
			 </p>
			 
			  </div>
			  
			  </div>
			  
			 </div>
			
	
			
			
			<HomeCta  />
			
		 
		 </div>
	 
	 
        <Footer />

</>

          );
   
};
export default Feedback;