import React from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
//config url
import configData from "./../../config.js";
import { NavLink } from "react-router-dom";

var bgimg1 = require("./../../images/referBgDesktop.svg");
var bgimg2 = require("./../../images/coin.svg");

const HomeRefer = (props) => {
  const [button, setButton] = useState(false);
  const { register, handleSubmit } = useForm();
  /* const { loading, userInfo, error, success, mobileSaved, token, onBoarding } = useSelector(
      (state) => state.user
    )
    useEffect(() => {
      if(token) {
       setButton(true)
      }
    }, [token])*/

  const submitForm = async (data) => {
    try {
      const response = await fetch(
        `${configData.ADMIN_URL}/api/referral/store`,
        {
          method: "POST",
          body: JSON.stringify({
            send_to: data.send_to,
            send_mobile: data.send_mobile,
            from_name: data.from_name,
            from_mobile: data.from_mobile,
          }),
          headers: {
            Accept: "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        const result = await response.json();
        toast.error(result.message, { autoClose: 3000 });
      }
      const result = await response.json();

      if (result.data.success) {
        setIsOpen(false);
        toast.success("Thank you for referring, message sent", {
          autoClose: 3000,
        });
        setButton(false);
      }
      console.log(result);
    } catch (err) {
      toast.error("Try again", { autoClose: 3000 });
    }
  };
  const handleError = (errors) => {
    toast.error("All fields must be filled", { autoClose: 3000 });
  };
  const registerOptions = {
    send_to: { required: "required" },
    from_name: { required: "required" },
    from_mobile: { required: "required" },
    send_mobile: { required: "required" },
  };
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        className={`${props.bgcolor} hidden mobile-hidden p-t30 p-b30 bg-no-repeat bg-cover`}
        style={{ backgroundImage: "url(" + bgimg1.default + ")" }}
      >
        <div className="container">
          <div className="section-content">
            <div className="row justify-content-center">
              <div className="col-xl-2 col-lg-2 col-md-2"></div>
              <div className="col-xl-4 col-lg-4 col-md-4">
                <div className=" m-b30">
                  <h2 className="p-t40 mb-1 sx-tilte text-white">
                    {" "}
                    Refer & Earn{" "}
                  </h2>
                  <p className="fs-22  text-white">
                    Refer a friend and earn rewards.
                  </p>
                  <div className="mb-2">
                    {button ? (
                      <NavLink
                        to={"/#"}
                        onClick={showModal}
                        className="site-button-white-bg"
                      >
                        Refer & Earn
                      </NavLink>
                    ) : (
                      <NavLink to={"/login"} className="site-button-white-bg">
                        Sign Up Now
                      </NavLink>
                    )}

                    <Modal show={isOpen} onHide={hideModal}>
                      <Modal.Header>
                        <div className="modal-header-img for-refer"></div>
                        <p className="formText text-center for-text-refer">
                          Refer a friend
                        </p>
                        <button
                          onClick={hideModal}
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </Modal.Header>

                      <Modal.Body>
                        <form
                          method="post"
                          className="text-center"
                          onSubmit={handleSubmit(submitForm, handleError)}
                        >
                          <div class="result"></div>
                          <div class="form-group">
                            <input
                              {...register("send_to", registerOptions.send_to)}
                              type="email"
                              className="form-input"
                              placeholder="Enter Friend's Email"
                            />
                          </div>

                          <div class="form-group">
                            <input
                              {...register(
                                "send_mobile",
                                registerOptions.send_mobile
                              )}
                              required=""
                              type="number"
                              className="form-input"
                              placeholder="Enter Friend's Mobile Number"
                            />
                          </div>

                          <div class="form-group">
                            <input
                              {...register(
                                "from_name",
                                registerOptions.from_name
                              )}
                              required=""
                              type="text"
                              className="form-input"
                              placeholder="Enter Your Name"
                            />
                          </div>

                          <div class="form-group">
                            <input
                              {...register(
                                "from_mobile",
                                registerOptions.from_mobile
                              )}
                              required=""
                              type="number"
                              className="form-input"
                              placeholder="Enter Your Mobile Number"
                            />
                          </div>

                          <button className="submitBtn" type="submit">
                          Get Free Quote
                          </button>
                        </form>
                      </Modal.Body>
                      <Modal.Footer></Modal.Footer>
                    </Modal>
                    <ToastContainer />
                  </div>
                  <p className="mb-0 text-white fs-22">
                    Sign in to unlock offers *
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="">
                  <div
                    className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper"
                    style={{ backgroundImage: "url(" + bgimg2.default + ")" }}
                  >
                    <div className="overlay-main" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeRefer;
