import React from 'react';
import { useEffect, useState } from "react";
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import Recommended from './../Elements/Recommended';
import About5 from './../Elements/About5';
import axios from "axios";
import { Helmet } from "react-helmet"; 
import InteriorSection1 from './../Elements/InteriorSection1'; 
import configData from "./../../config.js";

    const Floor = () => {
        
          let history = useHistory();
          const [seo, setSeo] = useState([]);
          const [todos, setTodos] = useState([]);
          const [totalTodos, setTotalTodos] = useState("");
          const [currentPage, setcurrentPage] = useState(1);
          const [itemsPerPage, setitemsPerPage] = useState(12);
          const [pageNumberLimit, setpageNumberLimit] = useState(4);
          const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(4);
          const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
          const [page_content, setPage_content] = useState("");
         //Getting Url Params
          const location = useLocation();
          const search = useLocation().search;
          const currenPageParms = new URLSearchParams(search).get('currentpage');

          useEffect(() => {
            //window.location.reload(1);
            async function fetchProducts() {
                 if(currenPageParms) {
                   if(currenPageParms !== 'null') {
                   setcurrentPage(currenPageParms);
                 }
              }
               
             try {
              const item = await axios.get(`${configData.ADMIN_URL}/api/3d-floor-plan?currentpage=${currenPageParms ? currenPageParms : 1}`)
              setTodos(item.data.data);
              let pageSeo = await axios.get(`${configData.ADMIN_URL}/api/metapageurl?page_url=3d-2d-floor-plans.php`)
              setSeo(pageSeo.data);
              setitemsPerPage(12);
              setpageNumberLimit(5);
              setPage_content(pageSeo.data[0].page_content);
              setTotalTodos(item.data.total);
               
             } catch (error) {
              console.log(error);
             }
            }
            
            fetchProducts();
        }, [location.key, currenPageParms]);

          

            // Get current Todo
 const pages = [];
  for (let i = 1; i <= Math.ceil(totalTodos / itemsPerPage); i++) {
    pages.push(i);
  }

  //console.log(pages.length);

  
 
  // Change page
  const paginate = pageNumber => setcurrentPage(pageNumber);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={() => paginate(number)}
          className={currentPage === number ? "active" : null}
        >
         <NavLink onClick={() => paginate(number)} to={"?currentpage=" + number} className='page-link'>
              {number}
            </ NavLink>
       
        </li>
      );
    } else {
      return null;
    }
  });


    async function handleNextbtn() {
      
      history.push(`?currentpage=${Number(currentPage) + 1}`);
      const item = await axios.get(`${configData.ADMIN_URL}/api/3d-floor-plan?currentpage=${Number(currentPage) + 1}`)
      setTodos(item.data.data);
      setSeo(item.data.seo);
      setTotalTodos(item.data.total);

    setcurrentPage(Number(currentPage) + 1);
    

    if (Number(currentPage) + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  async function handlePrevbtn()  {

      history.push(`?currentpage=${Number(currentPage) - 1}`);
      const item = await axios.get(`${configData.ADMIN_URL}/api/3d-floor-plan?currentpage=${Number(currentPage) - 1}`)
      setTodos(item.data.data);
      setSeo(item.data.seo);
      setTotalTodos(item.data.total);


    setcurrentPage(currentPage - 1);

    if ((Number(currentPage) - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

 

        return (
            <>

             {seo.map((item, index) => (

              <Helmet key={index}>  
              <title>{item.pagetitle}</title>
              <meta name="description" content={item.metadescription} />
              <meta name="keywords" content={item.metakeywords} />
              <meta property="og:type" content="Page" />
              <meta property="og:title" content={item.pagetitle} />
              <meta property="og:description" content={item.metadescription} />
              <meta property="og:url" content={window.location.href} />
              <link rel="canonical" href={`${configData.CANONICAL_URL}3d-2d-floor-plans.php`} />
              <meta property="og:site_name" content={configData.SITE_NAME} />
              <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_banner}`} />
              </Helmet> 

             ))} 

                <Header2 />
                <div className="page-content">

               {seo.map((item, index) => (

                    <InteriorSection1 key={index} page_name={item.page_name} short_des_heading={item.short_des_heading} sub_title={item.sub_title} short_description={item.short_description}  />
                
                ))}

                  
                    <div>
                    <ul className="sx-breadcrumb breadcrumb-style-2 bg-black pl-2 pb-1">
                     <div className="container">
                    <li className="red"><NavLink className="red" to={"./"}>Home </NavLink></li>
                        
                        <li>3D Floor Plans</li>
                        </div>
                    </ul>
                </div>
                    {/* SECTION CONTENT START */}
                    <div className="section-full pt-5 inner-page-padding">
                        <div className="container">
                        

                                <ul className="masonry-outer mfp-gallery work-grid row clearfix list-unstyled">
                                {todos.map((item, index) => (
                                      <div key={index} className= "cat-1 masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                       <a className="mfp-link" href={`${configData.ADMIN_URL}/assets/page_images/large/` + item.l_image }>
                                          <div className="sx-box image-hover-block">
                                          <div className="popup-gallery">
                                              <a className="mfp_image" href={`${configData.ADMIN_URL}/assets/page_images/large/` + item.l_image }>          
                                                  <img alt={item.i_name} className="max-h-200"
                                                      src={`${configData.ADMIN_URL}/assets/page_images/large/` + item.l_image }
                                                       />
                                                </a>
                                          </div> 
                                          </div>
                                           </a>
                                      </div>
                                  ))}
                              </ul>

                                <div className="paginate">
                                    <ul className="pageNumbers">
                                        <li>
                                          <button
                                            onClick={handlePrevbtn}
                                            disabled={currentPage === pages[0] ? true : false}
                                          >
                                            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Previous
                                          </button>
                                        </li>
                                        {pageDecrementBtn}
                                        {renderPageNumbers}
                                        {pageIncrementBtn}

                                        <li>
                                          <button
                                            onClick={handleNextbtn}
                                            disabled={currentPage === pages[pages.length - 1] ? true : false}
                                          >
                                            Next <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                                          </button>
                                        </li>
                                       

                                      </ul>
                                </div>
                                    

                        

                           
                           
                        </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                      
                    <Recommended  title="Recommended Services" bgcolor="bg-white" />
                
                    <About5 page_content={page_content} />
                
                </div>

                <Footer />
            </>
        );
  
};

export default Floor;