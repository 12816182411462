import React, { Suspense } from "react";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import Recommended from "./../../Elements/Recommended";
import VastuDirection from "./VastuDirection";
import VastuAllDirectionBanner from "./VastuAllDirectionBanner";
import About5 from "./../../Elements/About5";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import configData from "./../../../config.js";


var bnrimg = require("./../../../images/vastu-consultancy.webp");

const VastuWestFacing = () => {
	 const [seo, setSeo] = useState([]);
  const [page_content, setPage_content] = useState("");

  //SEO API CALL
  useEffect(() => {
    async function fetchData() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=vastu-for-west-facing-house.php`
        );
        setSeo(pageSeo.data);
        setPage_content(pageSeo.data[0].short_description);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
	  {seo.map((item, index) => (
        <Helmet>
          <title>{item.pagetitle}</title>
          <meta name="description" content={item.metadescription} />
          <meta name="keywords" content={item.metakeywords} />
        </Helmet>
      ))}
      <Header2 />
      <div className="page-content">
        <VastuAllDirectionBanner
          title="Vastu for West Facing House"
          pagename=""
          description="Getting designs in an auspicious way"
          bgimage={bnrimg}
        />
        <div className="p-t50 p-b50 north-all-paragraph-content">
          <div className="container">
            <h3 className="north-facing-content-section">Art of setting things</h3>
            <p>
              The facing of the plot actually matters a lot while building your
              ideal house. People who believe in Vastu shastra also we all
              should do, the direction of the main door of the house is the main
              consideration. Generally, people have some misconceptions about
              the west-facing houses, that they are bad in terms of Vastu.
              Actually having west facing house is a third choice followed by
              North facing and East facing house but at the same time, it can be
              the best choice too if the tips of Vastu followed strictly. Not so
              good house can be very auspicious at times. Here we are describing
              some Vastu guidelines where to locate all the parts of the house
              like where to place rooms, kitchen, pooja, entrance etc. Along
              with the Vastu guidelines we will be sharing scientific logic
              behind them. Many people find it difficult to recognize the
              orientation of the house or site so that they can make most out of
              it. There is a myth about the west-facing house which has been
              made by so-called “Vastu-specialist” that it is not good to have a
              west-facing house but its an utterly false. Actually, as per Vastu
              all directions are equally auspicious and equally good, the
              auspiciousness of a house doesn’t depend upon facing it is totally
              depends upon the location of the things, settings parts
              productively. In order to figure out where to place entrance of
              the house imagine and divide the west length of the house from the
              north-west corner to south-west corner into nine equal parts,
              these equal parts, each known as pada, now give them count from
              north-west to south-west 1-9. Now the best position you can have
              for entrance is 3,4,5 & 6 padas, the entrance can also be located
              in 1 & 2 pada as a second choice. Try to avoid 7,8 & 9 padas for
              entrance. Place the kitchen in the south-east if it is not
              possible then locate the kitchen in the north-west direction, do
              not forget to face towards the east and while cooking when you
              have a kitchen in the south-east and north-west respectively.
              Master bedroom will be best in the south-west and if the house has
              multiple floors then locate the master bedroom on the top floor.
              Kids room and guest bedroom can be placed in the north-west part
              of the house.
            </p>
          </div>
        </div>

        <Suspense fallback={<div>Loading...</div>}>
        <VastuDirection />
        <Recommended title="Recommended Services" bgcolor="bg-white" />
        <About5 page_content={page_content} />
        </Suspense>

      </div>
      <Footer />
    </>
  );
};
export default VastuWestFacing;
