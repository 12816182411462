import React from "react";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import Recommended from "./../../Elements/Recommended";
import About5 from "./../../Elements/About5";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import HomeCta from "./../../Elements/HomeCta";
import InteriorSection1 from "./../../Elements/InteriorSection1";
import configData from "./../../../config.js";

const VastuInterior = () => {
  const [seo, setSeo] = useState([]);
  const [page_content, setPage_content] = useState("");

  useEffect(() => {
    async function fetchProducts() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=vastu-for-interiors.php`
        );
        setSeo(pageSeo.data);
        setPage_content(pageSeo.data[0].page_content);
      } catch (error) {
        console.log(error);
      }
    }

    fetchProducts();
  }, []);

  return (
    <>
      {seo.map((item, index) => (
        <Helmet>
          <title>{item.pagetitle}</title>
          <meta name="description" content={item.metadescription} />
          <meta name="keywords" content={item.metakeywords} />
        </Helmet>
      ))}

      <Header2 />

      <div className="page-content">
        {seo.map((item, index) => (
          <InteriorSection1
            page_name={item.page_name}
            short_des_heading={item.short_des_heading}
            sub_title={item.sub_title}
            short_description={item.short_description}
          />
        ))}

        <div className="p-t30 p-b30 ">
          <div className="container">
            <div className="section-content">
              <h2>Vastu for Interiors</h2>

              <p>
                Vastu is for interiors too, many people believe that Vastu
                Shastra only pertains to the look and construction aspects of a
                property. However, the reality is that it's the maximum amount
                applicable to the inside ornament of homes although your house
                is created as per Vastu norms, if, you have got neglected Vastu
                for interiors, then, it may produce an imbalance within the
                overall Vastu of that property.
              </p>

              <p>
                It's believed that the arrangement of Interiors, will have an
                impact on the people living in the house. Based on the
                arrangements, positive and negative powers are evolved,
                affecting the occupants. In today’s confined urban scenarios, it
                isn't always possible to beautify the interiors according to
                Vastu. thus, small changes can be made with the accessories,
                colors, and furniture of the house, to make it Vastu compliant.
              </p>

              <p>
                Its depends to you, design your house as per vastu norms and
                follow
              </p>

              <div className="single-service-list">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <h2>Impact of interior arrangement on Vastu</h2>
                    <p>
                      Nakshewala.com indicate that interior décor elements, just
                      as the color of the wall, entrance of the house,
                      positioning of the furniture, and placement of the temple,
                      are a number of the prime considerations. the inside
                      arrangement of a house impacts the Vastu of a property in
                      the following ways:
                    </p>

                    <ul className="interior-arrangement">
                      <li>
                        The position of the kitchen is said to the health and
                        wealth of the family.
                      </li>
                      <li>
                        Interiors of a bedroom impact the health, career, and
                        private life, of those who board it.
                      </li>
                      <li>
                        The position of the pooja room impacts prosperity and
                        peace within the house.
                      </li>
                      <li>
                        The placement of mirrors at various places within the
                        house may impact health and happiness.
                      </li>

                      <li>
                        Correct placement of water is incredibly important, to
                        take care of physiological conditions and hygiene.
                      </li>

                      <li>
                        The choice of assorted colors for various sections
                        within the house also can have a particular impact.”
                      </li>
                    </ul>
                    <p>
                      When you design your home according to Vastu norms,
                      remember Vastu-related tips given by nakshewala.com. which
                      is beneficial for your home{" "}
                      <b>
                        Here are a couple of alternative vital interior-related
                        Vastu tips, for home occupiers:
                      </b>
                    </p>

                    <ul className="interior-rules">
                      <li>
                        Avoid exploitation of black color pieces of furniture
                        and tiles in the room
                      </li>
                      <li>
                        Let the reading/studying direction be east-facing.
                      </li>
                      <li>One will sleep in any direction, except north.</li>
                      <li>Avoid dim lights anyplace within the home.</li>

                      <li>Keep the northeast corner clean and tidy.</li>

                      <li>Avoid facing a mirror while sleeping or sitting.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <HomeCta />
        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>
      <About5 page_content={page_content} />
      <Footer />
    </>
  );
};
export default VastuInterior;
