import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
//config url
import configData from "./../../config.js";

export const checkNumber = createAsyncThunk(
  "user/number",
  async ({ number }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "x-api-key": configData.X_API_KEY,
          "x-api-secret": configData.X_API_SECRET,
          "Content-Type": "application/json",
        },
      };
      const item = await axios.post(
        `${configData.ADMIN_URL}/api/auth/check-mobile`,
        {
          mobile: number,
        },
        config
      );
      console.log(item.data);
      if (item.data.status === "success") {
        localStorage.setItem("mobile", number);
        return number;
      }

      //return item
    } catch (error) {
      console.log(
        "redux wishlist error:",
        error.response.data.errors.mobile[0]
      );
      console.log("redux wishlist error:", error.response);
      if (error.response.data.errors.mobile[0]) {
        return rejectWithValue(error.response.data.errors.mobile[0]);
      }
    }
  }
);

export const otpVerify = createAsyncThunk(
  "user/otpVerify",
  async ({ otp }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "x-api-key": configData.X_API_KEY,
          "x-api-secret": configData.X_API_SECRET,
          "Content-Type": "application/json",
        },
      };
      const item = await axios.post(
        `${configData.ADMIN_URL}/api/auth/verify-otp`,
        {
          mobile: localStorage.getItem("mobile"),
          otp: otp,
        },
        config
      );
      console.log(item.data);
      if (item.data.status === "success") {
        localStorage.setItem("uid", item.data.data.uid);
        localStorage.setItem("token", item.data.data.token);
        localStorage.setItem("onboarding", item.data.data.onboarding);
        return item.data;
      }

      //return item
    } catch (error) {
      //console.log('redux wishlist error:', error.response.data.errors)
      console.log("redux wishlist error:", error.response.data.message);
      return rejectWithValue(error.response.data.message);
      /*if (error.response.data.errors.mobile[0]) {
        return rejectWithValue(error.response.data.errors.mobile[0])
      } */
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/register",
  async ({ firstName, lastName, email }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "x-api-key": configData.X_API_KEY,
          "x-api-secret": configData.X_API_SECRET,
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const item = await axios.post(
        `${configData.ADMIN_URL}/api/users/update`,
        {
          uid: Number(localStorage.getItem("uid")),
          first_name: firstName,
          last_name: lastName,
          email: email,
          mobile: Number(localStorage.getItem("mobile")),
        },
        config
      );
      console.log(item.data);
      if (item.data.status === "error") {
        return item.data.status;
      } else {
        localStorage.setItem("onboarding", true);
        return item.data.status;
      }
    } catch (error) {
      console.log("redux error:", error.response.data.errors.email);
      if (error.response.data.errors.email[0]) {
        return rejectWithValue(error.response.data.errors.email[0]);
      } else {
        return rejectWithValue(error.response.data.errors.mobile[0]);
      }
    }
  }
);

export const userWishList = createAsyncThunk(
  "user/userWishList",
  async ({ type, id }, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "x-api-key": configData.X_API_KEY,
          "x-api-secret": configData.X_API_SECRET,
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const item = await axios.post(
        `${configData.ADMIN_URL}/api/services/wishlist`,
        {
          type: Number(type),
          id: Number(id),
        },
        config
      );
      console.log(item.data);
      //alert("Service add to wishlist")
      return item.data.status;
    } catch (error) {
      console.log("redux wishlist error:", error.response.data.errors.id[0]);
      // alert(error.response.data.errors.id[0])
      if (error.response.data.errors.id[0]) {
        return rejectWithValue(error.response.data.errors.id[0]);
      }
    }
  }
);
