/*eslint no-redeclare: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import { useForm } from 'react-hook-form'
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
import ProfileSideBar from './ProfileSideBar';
 //config url
import configData from "./../../config.js";
    
const Profile = () => {
	 const history = useHistory()
	 const [firstName, setFirstName] = useState("");
	 const [lastName, setLastName] = useState("");
	 const [email, setEmail] = useState("");
	 const [title, setTitle] = useState("");
	 const [age, setAge] = useState("");
	 const [mobile, setMobile] = useState("");
	 const [city, setCity] = useState("")
	 const [country, setCountry] = useState("")
	 const [post_code, setPost_code] = useState("")
	 const [address, setAddress] = useState("")
        
		  useEffect(() => { 
			async function fetchData() {
			 try {
			 const config = {
				"headers": {
					"Accept": "application/json",
					"x-api-key": configData.X_API_KEY,
                    "x-api-secret": configData.X_API_SECRET,
					"Content-Type": "application/json",
					'Authorization': `Bearer ${localStorage.getItem('token')}` 
				},
				}
			  let item = await axios.get(`${configData.ADMIN_URL}/api/users/me`,config)
			  
			  setFirstName(item.data[0].first_name)
			  setLastName(item.data[0].last_name)
			  setEmail(item.data[0].email)
			  if(!item.data[0].email){
				history.push("/register"); 
			  }
			  setMobile(item.data[0].mobile)
			  setCity(item.data[0].city)
			  setCountry(item.data[0].country)
			  setPost_code(item.data[0].zip_code)
			  setTitle(item.data[0].details.title)
			  setAge(item.data[0].details.age)
			  setAddress(item.data[0].address)
			 // let faq = await axios.get(`${configData.ADMIN_URL}/api/faqs`,config)
			 // console.log(faq)
			  console.log(item)
			 } catch (error) {
				console.log(error)
				if (error.response) {
					//console.log(error.response.data);
					toast.error(error.response.data.message, {autoClose:1000})
					setTimeout(()=> {
						history.push("/login");
					},1000)
					console.log(error.response.status);
					//console.log(error.response.headers);
				  }
			  
			  
			 }
			}
			fetchData();
		}, [history]);
        
		const submitForm = async (event) => {
			event.preventDefault();
			try {
			  const response = await fetch(`${configData.ADMIN_URL}/api/users/update`, {
				method: 'POST',
				body: JSON.stringify({
				"uid": Number(localStorage.getItem('uid')),
				"first_name": firstName,
				"last_name": lastName,
				"email": email,
				"mobile": Number(mobile),
				"title": title,
				"languages": ["en", "hi", "es"],
				"age": Number(age),
				"country": country,
				"post_code": post_code,
				"city": city,
				"address": address
				}),
				"headers": {
				  "Accept": "application/json",
				  "x-api-key": configData.X_API_KEY,
                  "x-api-secret": configData.X_API_SECRET,
				  "Content-Type": "application/json",
				  'Authorization': `Bearer ${localStorage.getItem('token')}` 
				},
			  });
			  if (!response.ok) {
				const result = await response.json();
				const numberError = JSON.stringify(result.errors.mobile);
				const emailError = JSON.stringify(result.errors.email);
                toast.error(numberError.replace(/[^a-zA-Z 0-9]/g, ''), {autoClose:3000})
				toast.error(emailError.replace(/[^a-zA-Z 0-9]/g, ''), {autoClose:3000})
				//console.log(msg)
			  }
			  const result = await response.json();
			  const msg = JSON.stringify(result);
			  
			  if(result.success) {
				toast.success("Data updated Successfully", {autoClose:3000})
				setTimeout(()=> {
				  history.push("/profile");
				},3000)
			  }
			  console.log(result.status)
			  if(result.status === "error") {
				toast.error(result.message, {autoClose:3000})
			  }
			} catch (err) {
			  console.log(err)
			  toast.error("Please try again", {autoClose:3000})
			} 
		  };
		  
  return (
	<>
	<Header2 />
	<div>
	  <div className="page-content bg-white">
		<div className="content-block">
		  <section className="content-inner bg-white">
			<div className="container">
			  <div className="row">
				<div className="col-xl-3 col-lg-4 m-b30">
				  <ProfileSideBar />
				</div>
				<div className="col-xl-9 col-lg-8 m-b30">
				  <div className="shop-bx shop-profile">
					<div className="shop-bx-title clearfix">
					  <h5 className="text-uppercase">Basic Information</h5>
					</div>
					<form onSubmit={submitForm}>
					  <div className="row m-b30">
						<div className="col-lg-6 col-md-6">
						  <div className="mb-3">
							<label for="formcontrolinput1" className="form-label">
							  First Name :
							</label>
							<input
							  type="text"
							  onChange={(e) => setFirstName(e.target.value)}
							  value={firstName ? firstName : ""}
							  className="form-control  pro-fil-e11"
							 
							/>
						  </div>
						</div>
						<div className="col-lg-6 col-md-6">
						  <div className="mb-3">
							<label for="formcontrolinput2" className="form-label">
							  Last Name :
							</label>
							<input
							  type="text"
							  onChange={(e) => setLastName(e.target.value)}
							  value={lastName ? lastName : ""}
							  className="form-control pro-fil-e11"
							  id="formcontrolinput2"
							 
							/>
						  </div>
						</div>
						<div className="col-lg-6 col-md-6">
						  <div className="mb-3">
							<label for="formcontrolinput2" className="form-label">
							Age:
							</label>
							<input
							  type="number"
							  onChange={(e) => setAge(e.target.value)}
							  value={age ? age : ""}
							  className="form-control pro-fil-e11"
							  id="formcontrolinput2"
							  placeholder=""
							/>
						  </div>
						</div>
						<div className="col-lg-6 col-md-6">
						  <div className="mb-3">
							<label for="formcontrolinput2" className="form-label">
						   Job Title:
							</label>
							<input
							  type="text"
							  onChange={(e) => setTitle(e.target.value)}
							  value={title ? title : ""}
							  className="form-control pro-fil-e11"
							  id="formcontrolinput2"
							  placeholder=""
							/>
						  </div>
						</div>
					  </div>
					  <div className="shop-bx-title clearfix">
						<h5 className="text-uppercase">Contact Information</h5>
					  </div>
					  <div className="row">
						<div className="col-lg-6 col-md-6">
						  <div className="mb-3">
							<label
							  for="formcontrolinput15"
							  className="form-label"
							>
							  Contact Number :
							</label>
							<input
							readOnly
							  type="text"
							  onChange={(e) => setMobile(e.target.value)}
							  value={mobile ? mobile : ""}
							  className="form-control pro-fil-e11"
							  id="formcontrolinput15"
							 
							/>
						  </div>
						</div>
						<div className="col-lg-6 col-md-6">
						  <div className="mb-3">
							<label
							  for="formcontrolinput16"
							  className="form-label"
							>
							  Email Address :
							</label>
							<input
							readOnly
							  type="text"
							  onChange={(e) => setEmail(e.target.value)}
							  value={email ? email : ""}
							  className="form-control pro-fil-e11"
							  id="formcontrolinput16"
							  
							/>
						  </div>
						</div>
						<div className="col-lg-6 col-md-6">
						  <div className="mb-3">
							<label
							  for="formcontrolinput19"
							  className="form-label"
							>
							  City :
							</label>
							<input
							  type="text"
							  onChange={(e) => setCity(e.target.value)}
							  value={city ? city : ""}
							  className="form-control  pro-fil-e11"
							  id="formcontrolinput19"
							  
							/>
						  </div>
						</div>
						<div className="col-lg-6 col-md-6">
						  <div className="mb-3">
							<label
							  for="formcontrolinput110"
							  className="form-label"
							>
							  Address :
							</label>
							<input
							  type="text"
							  onChange={(e) => setAddress(e.target.value)}
							  className="form-control pro-fil-e11"
							  value={address ? address : ""}
							 
							/>
						  </div>
						</div>
						<div className="col-lg-6 col-md-6">
						  <div className="mb-3">
							<label
							  for="formcontrolinput110"
							  className="form-label"
							>
							  Country :
							</label>
							<input
							  type="text"
							  onChange={(e) => setCountry(e.target.value)}
							  className="form-control pro-fil-e11"
							  value={country ? country : ""}
							
							/>
						  </div>
						</div>
						<div className="col-lg-6 col-md-6">
						  <div className="mb-3">
							<label
							  for="formcontrolinput18"
							  className="form-label"
							>
							  Postcode :
							</label>
							<input
							  type="text"
							  onChange={(e) => setPost_code(e.target.value)}
							  className="form-control pro-fil-e11"
							  
							  value={post_code ? post_code : ""}
							/>
						  </div>
						</div>
					  </div>
					  <button
						type="submit"
						className="btn btn-primary btnhover mt-2"
					  >
						Update
					  </button>
					</form>
				  </div>
				</div>
			  </div>
			</div>
		  </section>
		</div>
	  </div>
	</div>
	<ToastContainer />
	<Footer />
  </>
  

);
   
};
export default Profile;