import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
//import Banner from './../Elements/Banner';
//import { NavLink } from 'react-router-dom';


//var bnrimg = require('./../../images/banner/hdr_bnr5eaa5da4ec21aprivacy_policy.jpg');
//var bgimg1 = require('./../../images/video-bg.jpg');
//var bgimg3 = require('./../../images/background/bg-12.jpg');
//var bgimg4 = require('./../../images/background/cross-line2.png');



class Policy extends React.Component {

   

    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                    
                    <div className="section-full mobile-page-padding p-t80 p-b50 pri-va-cy">
                    <div className="container">
                    <div className="text-center"><img alt="" className="w-50" src="assets/images/privacy-policy.webp" /></div>
                        <div className="section-content">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className=" mb-3">
                                    
                                    <h2 class="mb-3 heading-h2">Privacy <span className="red">Policy</span></h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                         <div className="section-content">
                            <h4>What information do we collect from you?</h4>
                            <p>We collect some of your basic information when you register to our site, subscribe our newsletter or fill our query form. We collect these information to reach you out for the things you’re looking for. When registering to our website you will be asked to fill you Name, Phone Number, Email Id and your address, however you may visit our website anonymously.</p>
                            <h4>What is the purpose of collecting user’s information?</h4>
                            <p>Any of the information collected by us can be used for the following purpose:</p>

                            <p><b>To personalize user experience:</b> We are highly devoted to the customer satisfaction and it can only be attained when things are personalized customers, hence information given by users helps us to respond better to their individual needs.</p>


                            <p><b>To improve our website:</b> We believe in learning and improving ourselves on a regular basis, we try to improve our website offerings and services. Information and interests shared by your side helps us to improve our quality of work.</p>

                            <p><b>To improve customer services:</b>  Your information helps us to understand your need more efficiently hence it helps us to be more effective respondent for your query and need of services.</p>

                            <p><b>To send periodic promotional or informational emails:</b>  While campaigning any of promotions, surveys, contests or other site features your email may be used to send you specific information, respond to your queries or to respond to your any other questions regarding us or over services.</p>

                            <p><b>Note:</b>  Information gathered from your side whether public or private will not be sold, exchanged, transferred or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested. </p>

                            <h4>What is assurance of information security?</h4>
                            <p>We have acquired various measure of safety and implemented them to secure your information, when you enter, submit or access your personal information on our website. We assure you for the information safety.</p>
                            <h4>Do we use cookies?</h4>
                            <p>es, Cookies are small files that a site or its service provider transfers to your computer hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information.
cookies are designed to be reliable medium to remember the information such as anything added to the shopping cart, hence We use cookies to help us remember and process the items in your shopping cart and understand and save your preferences for future visits.</p>

                            <h4>Is there any third party links? </h4>
                            <p>Occasionally, at our discretion, we may include or offer third party products or services on our website. These third party sites have separate and independent privacy policies. We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>



                            <h4>Do we share any of the information to the other parties? </h4>
                            <p>We do not sell, trade, transfer or exchange any of the user identifiable information to any of the other parties. This doesn’t includes trusted third parties, whosoever assist/helps us in operating our website, running and conducting our business or servicing you as long as these parties agree to keep the information confidential, we may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety.  </p>


                            

 


                    </div>
                        
                       </div>
                     </div>
                
                   
                  
                    
                </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default Policy;