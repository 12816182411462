import React, { Suspense } from "react";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import Recommended from "./../../Elements/Recommended";
import VastuDirection from "./VastuDirection";
import VastuAllDirectionBanner from "./VastuAllDirectionBanner";
import About5 from "./../../Elements/About5";
import axios from "axios";
import { useEffect, useState } from "react";
import configData from "./../../../config.js";
var bnrimg = require("./../../../images/vastu-consultancy.webp");

const VastuEastFacing = () => {
	
  const [page_content, setPage_content] = useState("");

  //SEO API CALL
  useEffect(() => {
    async function fetchData() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=vastu-for-east-facing-house.php`
        );
        
        setPage_content(pageSeo.data[0].short_description);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <Header2 />
      <div className="page-content">
        <VastuAllDirectionBanner
          title="Vastu for East Facing House"
          pagename=""
          description="Getting designs in an auspicious way"
          bgimage={bnrimg}
        />

        <div className="p-t50 p-b50 north-all-paragraph-content">
          <div className="container">
            <h3 className="north-facing-content-section">Way to relish happiness</h3>
            <p>
              Vastu Shastra contributes to the architecture so that the inmates
              can enjoy health wealth and prosperity. Inmates of Vastu oriented
              house enjoy peace and happiness in the family. Talking about
              directions, then East is the most auspicious direction among all.
              Building a house or any building, the east should have given first
              preference for the entrance as you can get the maximum amount of
              sunlight.
            </p>

            <p>
              East is generally governed by the heavenly body sun so its results
              are generally favorable for the inmates. As east-facing provide
              maximum sunlight, that always adds to the growth and prosperity of
              the house. East direction is also very effective for studies and
              desk work, as it favors the growth of creative faculties in a
              person and also increases his/her efficiency in a drastic manner.
            </p>

            <p>
              East facing triggers your inborn talent and enhances your
              intellectual caliber. If you have a plot facing east then its
              layout will be something like this: Main entrance should be
              located in the middle of the east side of the plot, middle
              location is the most auspicious as it is held by the sun (god of
              fame), a bit side of northeast also can be used for entrance it
              can be a second-best location for entrance, avoid southeast
              direction to place entrance as it can be a source of illness for
              the entire family.
            </p>

            <p>
              The kitchen should be located to the southeast corner of the plot,
              if it is not possible then it can be located to the northwest
              corner. Also, remember to face east while having a south-east
              kitchen and face towards the west while having a north-west
              kitchen whenever you are cooking. Pooja Room should be located in
              North-East as it is the most auspicious location for Pooja.
              North-West location is auspicious for the living room and even you
              can locate a guest bedroom too in the north-west direction as it
              is held by the moon.
            </p>

            <p>
              South-West is the best location for Master Bedroom, south-west
              slope helps in the blood circulation. Garage, dresser, stairs, and
              walk-in closets can also be located in the southwest. Stairs and
              over-head should be located to the south as they have heavy
              structures. West can be utilized to place the dining room, study
              room, and kids' bedroom.
            </p>

            <p>
              North the location of mercury can be used for the drawing-room,
              living room, and office. Hall is the location where all other
              parts of the house should be connected and therefore should be
              placed in the middle of the plot. These are the various tips for
              everyone to get the best outcome of your house rather happy house.
              Hope the information provided here will be of use to you and you
              will be kept in mind while designing your house.
            </p>
          </div>
        </div>
        <Suspense fallback={<div>Loading...</div>}>
        <VastuDirection />
        <Recommended title="Recommended Services" bgcolor="bg-white" />
        <About5 page_content={page_content} />
        </Suspense>
      </div>
      <Footer />
    </>
  );
};
export default VastuEastFacing;
