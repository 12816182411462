import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const services = [
  {
    count: "It is best to leave only maximum 5 doors open.",
    title: "Plans and Projects",
    flaticon: "flaticon-sketch",
  },
  {
    count: "Avoid keeping a TV set in bed-room.",
    title: "Interior",
    flaticon: "flaticon-stairs",
  },

  {
    count: "Avoid keeping any water feature or plants in the bed room.",
    title: "Exterior",
    flaticon: "flaticon-window",
  },
  {
    count:
      "Arrange the furniture to from a square or a circle or an octagon in.",
    title: "Architecture",
    flaticon: "flaticon-skyline",
  },
  {
    count: "Brighten the corners.",
    title: "Furniture",
    flaticon: "flaticon-bed",
  },
  {
    count: "Place a picture of bright sunrise on southern wall in living room.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "The wind should come from south-west in the bed room.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "Dining-room should not expose to the front door of your house.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "Never put mirror in kitchen.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "Keep the broom and mops out of the sight in kitchen.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "Keep the bathroom and toilet door closed as much as possible.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "Windows should open outward normally.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "Do not keep prickly cactus, plants in the house.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "Place an aquarium in the south-east corner in living room.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "Place a happy family picture in living room.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "Don’t grown very high trees like Bangan, Pipal, Thornytrees.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count:
      "Cheque the gate of the lift is not in front of the main gate of the house.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "There should not be any room which has shape.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "There should not be obstructive houses surrounding your plot.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "The house should be fully airy and has enough water resource.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "The seat in the latrin should be NORTH-SOUTH.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "In the office, sit facing the door.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "Hang a picture of mountains behind you in the office.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count: "Place the computer on your right side on the table.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count:
      "One should never hoard stale food, withered flowers, torn clothes, waste paper, waste materials, empty tins, old jars and useless things. These things prevent Lakshmi from entering the house.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count:
      "If there is marble flooring in the house you should see that the old leather shoes are not lying here and there. Marble is considered to be holy stone. If possible avoid marble in bedroom, bathroom, and latrine.",
    title: "Decoration",
    flaticon: "flaticon-door",
  },
  {
    count:
      "In a place of worship in the house it is necessary to have open atmosphere. Use marble in the worship room. Take care that there is enough light and air. Latrine should not be near worship room.",
  },
];

class Vastutips extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: false,
      center: false,
      margin: 0,
      nav: true,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        640: {
          items: 2,
        },
        768: {
          items: 2,
        },
        991: {
          items: 3,
        },
        1200: {
          items: 5,
        },
      },
    };
    return (
      <>
        <div className="section-full mobile-page-padding p-t50 p-b50 bg-white bg-change-section">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="sx-separator bg-white bg-moving bg-repeat-x living-ani-mation">
                  <h3 className="sep-line-one tip-vatu-tip">
                    Vastu <span className="vastu-grey-tips">Tips</span>
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
          </div>
          <OwlCarousel
            className="owl-carousel service-slider-two owl-btn-vertical-center"
            {...options}
          >
            {services.map((item, index) => (
              <div className="item-tips-whole-box" key={index} >
                <div
                  className="bgcall-block d-flex flex-wrap justify-content-center align-content-end bg-cover overlay-wraper vatu-bd-div-sl fortext-vasty"
                  style={{ backgroundImage: "#666666" }}
                >
                  <div className="overlay-main bg-black opacity-05" />

                  <div className="bg-content-mid-outer mid-level-text">
                    <div className="bg-content-mid">
                      <div className="sx-icon-box-wraper center text-white icon-txt-vastutps for ifo icon">
                        <i
                          className="fa fa-info-circle for-tips-onltvastu"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <span className="bgcall-block-number text-new-slid">
                        {item.count}
                      </span>

                      <div className="bg-overlay" />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </>
    );
  }
}

export default Vastutips;
