import React from "react";
import ExploreButton from "./ExploreButton";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
//config url
import configData from "./../../../config.js";

var bgimg1 = require("./../../../images/background/bg-5.png");
var imageUrl = `${configData.ADMIN_URL}/assets/images/testimonial/`;

const items = [

  {
    "id":179,
    "author_images":"author5fb799d6a4b96R-3332 Rajesh.jpg",
    "test_txt":"Thank you for all the hard work you put in daily! Know that it is recognized and greatly appreciated. Especially I must appreciate the co-ordinator has Managed it well. Amazing support by Nakshewala",
    "test_author":"Rajesh Booramurthy",
    "location":"Chennai, Tamil Nadu",
    },

    {"id":162,
    "author_images":"author5f168dff455edauthor5f0edcd5363b52449-Upadesh-Shakya.webp",
    "test_txt":"Dear Nakshewala Team, I was very confused and disturbed about 'My Sweet Home' planning. It was a dream project for me and my family. I explored a lot but nothing was working. Then destiny took me to you and I was really impressed seeing your ideas. After all I got a perfect layout and dream home finally with help of your guidance. Now people are wondering for such a beautiful house and I really feel proud and happy when it is praised. Thank you dear Nakshewala.com for such a wonderful experience !! Regards",
    "test_author":"Upadesh Shakya","location":"Amreli, Gujarat",
   },

  {
    "id":145,
    "author_images":"author5bd16837582aeIMG_20180911_123823.jpg",
    "test_txt":"Dear Sir,\r\n\r\nThere is thousands of consultancy service for planning and designing of home when searching on Google.\r\n\r\nI have talk most of them and found expensive.\r\n\r\nFinally one of the my friend suggesting for Nakshewala.com .\r\n\r\nWhen I talked with their customer service they explained all the aspects of planning and designing home as per our requirement. Hence I have finally decided and took service from Nakshewala.com they planned my home as per my requirement and I am very satisfied with my planning and designing of home. \r\n\r\nI personally thanks Team for their guidance regarding cost effective plan and arrangement of building components as per Vastu.\r\n\r\nAlso appreciate for fast service.\r\n\r\n\r\n Kind Regards,\r\n\r\n Krishna Kishor",
    "test_author":"Krishna Kishor",
    "location":"Patna, Bihar",
  
    },
  {
  "id":162,
  "author_images":"author5f168dff455edauthor5f0edcd5363b52449-Upadesh-Shakya.webp",
  "test_txt":"Dear Nakshewala Team, I was very confused and disturbed about 'My Sweet Home' planning. It was a dream project for me and my family. I explored a lot but nothing was working. Then destiny took me to you and I was really impressed seeing your ideas. After all I got a perfect layout and dream home finally with help of your guidance. Now people are wondering for such a beautiful house and I really feel proud and happy when it is praised. Thank you dear Nakshewala.com for such a wonderful experience !! Regards","test_author":"Upadesh Shakya","location":"Amreli, Gujarat",
  },
  

{
  "id":126,
  "author_images":"author5baa16bf26e10R2287.jpg",
  "test_txt":"Thanks Nakshewala.com for the customised floor plan & front elevation for my site, 30\u00d7 53 sq.ft...I came to know about ur website throu Google.\r\nI am really satisfied & happy with the services provided by your team they was very patient & understanding while listening to my queries & requirements.He suggested plans exactly according to my requirements including vastu needs.and made changes wherever I had requested for it.\r\nFinally I am happy that the plan was customised exactly according to my needs.I feel that was the advantage of opting for online services ....\r\nWould like to suggest to my friends about it.\r\nGREAT JOB, KEEP IT UP\r\nThanks again Nakshewala.com \r\nWith regards, \r\nDr.Jagadish",
  "test_author":"Dr.Jagadish Gulbarga",
  "location":"Gulbarga, karnataka",
  },
 
  ]

class Testimonials extends React.Component {
  // // Constructor
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     items: [],
  //     DataisLoaded: false,
  //   };
  // }

  // //API CALL
  // componentDidMount() {
  //   fetch(`${configData.ADMIN_URL}/api/testimonials`)
  //     .then((res) => res.json())
  //     .then((json) => {
  //       this.setState({
  //         items: json.data,
  //         DataisLoaded: true,
  //       });

  //       console.log(json.data);
  //     });
  // }

  render() {
    const options = {
      loop: true,
      dotsEach: 3,
      autoplay: false,
      center: false,
      //stagePadding: 100,
      //items: 1,
      margin: 40,
      nav: false,
      dots: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1.2,
          margin: 20,
          //dots: true,
        },
        640: {
          items: 2,
          margin: 20,
          //dots: true,
        },
        768: {
          items: 2,
          margin: 20,
          //dots: true,
        },
        991: {
          items: 4,
          margin: 15,
          mouseDrag: false,
          dots: false,
          //dots: true,
        },
        1200: {
          items: 4,
          margin: 15,
          mouseDrag: false,
          dots: false,
          //dots: true,
        },
      },
    };

   
    return (
      <>
        <div
          className="section-full   p-t20 p-b40  "
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="">
            <div className="">
              {/* TITLE START */}
              <div className="section-head container">
                <div className="sx-separator-outer separator-left">
                  
                    <h3 className="sep-line-one">
                      {" "}
                      <span className="red">Testimonials </span> 
                      <img
                        alt="trending-img"
                        className="mobile-hidden"
                        src="assets/images/Trending.svg"
                      />
                     <ExploreButton />
                    </h3>
                  
                </div>
              </div>

              {/* TITLE END */}
              <div className="blog-post blog-lg date-style-3 container container-mobile">
                <div className="sx-post-media">
                  {/*Fade slider*/}
                  <OwlCarousel
                    className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center"
                    {...options}
                  >
                    {items.map((item, index) => (
                      <div key={index} className="item shadow-sm ml-1 mh-420">
                        <div className="aon-thum-bx">
                        
                          <div
                          className="bg-cover bg-center h-200 bg-no-repeat"
                          style={{
                            backgroundImage: `url('${
                              imageUrl + item.author_images
                            }')`,
                          }}
                        />
                        </div>
                        <div className="sx-post-info  bg-white">
                          <div className="text-center">
                            <p className="mb-2 pt-3 default-testimonial-text">
                              {item.test_txt.slice(0, 80)}...
                            </p>
                            <h6 className="red mb-1">{item.test_author}</h6>
                            <h6 className="client-location">{item.location}</h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                  {/*fade slider END*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Testimonials;
