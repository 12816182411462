import React, { Suspense } from "react";
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
//import HomeFaq from './../Elements/HomeFaq'
//import Work from './../Elements/Work';
//import About5 from './../Elements/About5';
//import Recommended from './../Elements/Recommended';
//import HomeCta from './../Elements/HomeCta';
import InteriorSection1 from './../Elements/InteriorSection1';
import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet"; 
//config url
import configData from "./../../config.js";
const About5 = React.lazy(() => import("./../Elements/About5"));
const HomeCta = React.lazy(() => import("./../Elements/HomeCta"));
const Work = React.lazy(() => import("./../Elements/Work"));
const Recommended = React.lazy(() => import("./../Elements/Recommended"));
const HomeFaq = React.lazy(() => import("./../Elements/HomeFaq"));



const CustomizedHouseDesign = () => {

  const [seo, setSeo] = useState([]);
  const [page_content, setPage_content] = useState("");

   //SEO API CALL
  useEffect(() => {
    async function fetchData() {
     try {
      let pageSeo = await axios.get(`${configData.ADMIN_URL}/api/metapageurl?page_url=customized-house-designs.php`)
      setSeo(pageSeo.data);
      setPage_content(pageSeo.data[0].page_content);
     } catch (error) {
      console.log(error);
     }
    }
    fetchData();
}, []);
 
  return (
  <>

  {seo.map((item, index) => (

    <Helmet key={index}>  
    <title>{item.pagetitle}</title>
    <meta name="description" content={item.metadescription} />
    <meta name="keywords" content={item.metakeywords} />
    <meta property="og:type" content="Page" />
    <meta property="og:title" content={item.pagetitle} />
    <meta property="og:description" content={item.metadescription} />
    <meta property="og:url" content={window.location.href} />
    <link rel="canonical" href={`${configData.CANONICAL_URL}customized-house-designs.php`} />
    <meta property="og:site_name" content={configData.SITE_NAME} />
    <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_banner}`} />
    </Helmet> 

     ))}

   <Header2 />                                                                                          
  <div className="page-content">
    {seo.map((item, index) => (
        <InteriorSection1 key={index} page_name={item.page_name} short_des_heading={item.short_des_heading} sub_title={item.sub_title} short_description={item.short_description}  />
    ))}
    {/* ABOUT TEAM MEMBER SECTION START */}
		<div style={{background:"black"}}>
		<div className="container">
		<div className="row">
		<p style={{marginBottom:0}}><a className="site-map-sblk" href="/">Home</a></p>
		<span style={{color:"#c91006",paddingRight:5,paddingLeft:5}}><i class="fa fa-angle-right"></i></span>
		<p style={{color:"white",marginBottom:0}}><a className="site-map-sblk-sec" href="/customized-house-designs.php">Customized House Designs</a></p>   
		</div>
		</div>
		</div>		
    <Suspense fallback={<div>Loading...</div>}>
    <Work />
    <HomeCta /> 
    <HomeFaq /> 
    <Recommended hTag="H3"  title="Recommended Services" bgcolor="bg-white" /> 
   <About5 page_content={page_content} />
	</Suspense>

   
 </div>
<Footer />
  
</>

 );
   
};
export default CustomizedHouseDesign;			  