import React, {Suspense} from 'react';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import PriceTable from './../Elements/PriceTable';
import Recommended from './../Elements/Recommended';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet"; 
import axios from "axios";
import About5 from './../Elements/About5';
import InteriorSection1 from './../Elements/InteriorSection1'; 
import configData from "./../../config.js";

const Package =()=> {

	const [seo, setSeo] = useState([]);
	const [page_content, setPage_content] = useState("");

	  useEffect(() => {
	    async function fetchProducts() {
	     try {
	      let pageSeo = await axios.get(`${configData.ADMIN_URL}/api/metapageurl?page_url=our-packages.php`)
	      setSeo(pageSeo.data);
	      setPage_content(pageSeo.data[0].page_content);
	     } catch (error) {
	      console.log(error);
	     }
	    }
	    
	    fetchProducts();
	}, []);


  
        return (   
            <>

             {seo.map((item, index) => (

			    <Helmet key={index}>  
			    <title>{item.pagetitle}</title>
			    <meta name="description" content={item.metadescription} />
			    <meta name="keywords" content={item.metakeywords} />
			    </Helmet> 

			     ))}
			
			   <Header2 />
			   
			   <div className="page-content">
			   
                    {seo.map((item, index) => (
                       <InteriorSection1 page_name={item.page_name} short_des_heading={item.short_des_heading} sub_title={item.sub_title} short_description={item.short_description}  />
                    ))}

                    <Suspense fallback={<div>Loading...</div>}>
					<PriceTable />
				    <Recommended  title="Recommended Services" bgcolor="bg-white" /> 
					</Suspense>
			   </div>
               <Suspense fallback={<div></div>}>
			   <About5 page_content={page_content} />     
			   </Suspense>
			  <Footer />
            </>
        );
   
};

export default Package;