import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import configData from "./../../config.js";

class MostLoved extends React.Component {

     // Constructor 
    constructor(props) {
        super(props);
   
        this.state = {
            items: [],
            DataisLoaded: false
        };
    }

    //API CALL
    componentDidMount() {
        fetch(
        `${configData.ADMIN_URL}/api/recent_project`)
        .then((res) => res.json())
        .then((json) => {
            this.setState({
                items: json.data,
                DataisLoaded: true
            });

           
        })
    }

    render() {

         const { DataisLoaded, items } = this.state;
        if (!DataisLoaded) return <div>
            <p> Loading.... </p> </div> ;

        const options = {
            loop: true,
            autoplay: false,
            center: false,
            items: 4,
            margin: 10,
            nav: false,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1.2,
                    margin: 15,
                },
                640: {
                    items: 1.2,
                    margin: 15
                },
                768: {
                    items: 1.2,
                    margin: 10
                },
                991: {
                    items: 4.5,
                    margin: 10
                },
                1200: {
                    items: 4
                }

            }
        };
        return (
            <>
                <div className="section-full  p-b20  inner-page-padding mt-4">
                    <div className="container container-mobile">
                        <div className="section-content">
                            {/* TITLE START */}
                             <div className="section-head">
                                 <div className="sx-separator-outer separator-left plm-15">
                               
                                    <h2 className="sep-line-one pb-0"><span className='red'>Most Loved </span>Interiors  <NavLink to={"/recent-interior-designing-project.php"} className="bg-3e btn view_all float-right">View All</NavLink></h2>
                            
                            </div>
                            </div>
                            {/* TITLE END */}
                             <div className="work-carousel-outer">
                                <OwlCarousel className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center" {...options}>
                                    {items.map((item, index) => (
                                         <div key={index} className=" m-b10  mm-b30">
                                         <NavLink to={item.slug}>
                                                <div className="sx-box sx-product-box overflow-hide bg-pink bordered">
                                                    <div className="client-name">{item.owner}</div> 
                                                    <div>
                                                        <NavLink to={item.slug}>
                                                        <LazyLoadImage
                                                        className="max-h-214"
                                                        effect="blur"
                                                        src={`${configData.ADMIN_URL}/assets/images/recent_project/` + item.image}
                                                        alt={item.title}
                                                        />
                                                        
                                                        </NavLink>
                                                        
                                                    </div>
                                                    <div className="p-t10 pl-2 pr-2 text-left">
                                                        <h4 className="sx-title fsm-15 fs-16 w-240 most-loved-title">
                                                        <NavLink className="fw-600" to={item.slug}>{item.title.slice(0, 25)}</NavLink>
                                                        </h4>
                                                        <p className="most-loved-desc" dangerouslySetInnerHTML={ { __html: item.short_description.slice(0, 200) }}></p>

                                                       
                                                        <p className="category_code_text"> <NavLink to={item.slug}><span className="float-right view_details mobile-hidden mb-3">View details</span><span className="float-right desktop-hidden mt-0"><i className="fa fa-eye"></i></span></NavLink></p>
                                                     
                                                    </div>
                                                </div>
                                                </NavLink>
                                            </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                            
                        </div> 
                    </div>
                    <div className="container">
                    <div className="interior_view_more mb-2 text-center w-100 ">
                            <a rel="noreferrer" target="_blank" href={`${configData.ADMIN_URL}/public/assets/files/SAMPLE_INTERIOR_NAKSHEWALA.pdf`} download className="text-white fw-400 "><i className="fa fa-download"></i> Download Samples</a>
                            </div>
                             </div>
                </div>
            </>
        );
    }
};

export default MostLoved;