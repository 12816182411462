import React from "react";
import { NavLink } from "react-router-dom";
import Header2 from "./../Common/Header2";
import Footer from "./../Common/Footer";
import Recommended from "./../Elements/Recommended";
import InteriorSideNav from "./../Elements/InteriorSideNav";
import CommomInteriorCollapse from "./../Elements/CommomInteriorCollapse";

const ModernInterior = () => {
  return (
    <>
      <Header2 />
      <div className="page-content">
        <div className="section-full p-t80 p-b50 mobile-page-padding retro-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 sticky_column for-desktop">
                <div className="about4-section m-b20 bg-gray p-a20 for-interior-style-part">
                  <div className="widget widget_services">
                    <InteriorSideNav />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="section-content">
                  <div className="service-single-block m-b30">
                    <div className="sx-media">
                      <img src="assets/images/modeint.webp" alt="" />
                    </div>
                    <h3 className="m-t30 sx-tilte">
                      <b>Modern Interior</b>
                    </h3>

                    <p>
                      Modern design is an interior design style distinguished by
                      a{" "}
                      <b>
                        clean lines, minimalism, natural materials, and natural
                        light
                      </b>{" "}
                      in simple words Modern design interior refers to the
                      aesthetic tone of a specific historical time.
                    </p>

                    <p>
                      The modern home design grew out from the modern art
                      revolution, New technologies, industrialization, and
                      access to building materials like steel and concrete also
                      informed modern art and architecture from the 1920s to the
                      early 1950s. Clean lines, floor-to-ceiling windows, open
                      living spaces, and flat roofs became the dominant style of
                      this era.
                    </p>

                    <h4 className="m-t30 sx-tilte">
                      <b>Components Of Modern Interior</b>
                    </h4>

                    <p>
                      Consider the following modern design ideas If you are
                      looking to incorporate a modern look in your home.
                    </p>

                    <div className="single-service-list">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <ul className="list-angle-right anchor-line first">
                            <li>
                              <NavLink to={"/services-1"}>
                                <b>Straight lines: </b>
                              </NavLink>
                              <p>
                                Modern homes are built with clean, straight
                                lines and minimal ornamentation. flat, smooth
                                surfaces are major areas of strength and, lines
                                make strong articulations that accentuate the
                                fundamental idea of everything.
                              </p>
                            </li>

                            <li>
                              <NavLink to={"/services-1"}>
                                <b>Neutral colors: </b>
                              </NavLink>
                              <p>
                                Modern style avoids brilliant tones for a
                                monochromatic, nonpartisan variety range. Think
                                white, beige, dark, dark, and pastel tones.
                              </p>
                            </li>

                            <li>
                              <NavLink to={"/services-1"}>
                                <b>Open floor plan: </b>
                              </NavLink>
                              <p>
                                Use furniture rather than walls to separate the
                                spaces of an open arrangement home. For
                                instance, a kitchen island can isolate a kitchen
                                from the lounge area, and a sectional couch can
                                carry definition to an open front room.
                              </p>
                            </li>

                            <li>
                              <NavLink to={"/services-1"}>
                                <b>Minimal home stylistic layout: </b>
                              </NavLink>
                              <p>
                                Modern spaces are liberated from mess and
                                pointless twists. The current stylistic theme
                                focuses on usefulness over design.
                              </p>
                            </li>

                            <li>
                              <NavLink to={"/services-1"}>
                                <b>Low and long furnishings: </b>
                              </NavLink>
                              <p>
                                Long furniture pieces arranged low to the ground
                                can give your home a cutting edge look in the
                                event that they're likewise produced using
                                normal materials like unpainted wood or metal.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 sticky_column for-mobile-interior-view">
                    <div className="about4-section m-b20 bg-gray p-a20">
                      <div className="widget widget_services">
                        <InteriorSideNav />
                      </div>
                    </div>
                  </div>

                  <CommomInteriorCollapse />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>
      <Footer />
    </>
  );
};
export default ModernInterior;
