import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

/*const projects = [
    {
        image: require('./../../images/3d-service.webp'),
        title: '3D Floor Plans',
        description: 'As Per Your requirements',
        icon: 'assets/images/floor-icon.svg',
        url: '/3d-2d-floor-plans.php'
    },
    {
        image: require('./../../images/customized-service.webp'),
        title: 'Customized Designs',
        description: 'As Per Your requirements',
        icon: 'assets/images/elevation-icon.svg',
        url: '/*'
    },
    
    {
        image: require('./../../images/front-service.webp'),
        title: 'Front Elevations',
        description: 'As Per Your requirements',
        icon: 'assets/images/building.svg',
        url: '/front-elevation.php'
    },
    {
        image: require('./../../images/commercial-service.webp'),
        title: 'Commercial Designs',
        description: 'As Per Your requirements',
        icon: 'assets/images/commercialIcon.svg',
        url: '/commercial-building-elevation.php'
    },
    {
        image: require('./../../images/interior-service.webp'),
        title: 'Interior Designs',
        description: 'As Per Your requirements',
        icon: 'assets/images/interior-icon.svg',
        url: '/interior-designing.php'
    }
    
        
]

var bgimg1 = require('./../../images/background/cross-line2.png'); */

class Projects3 extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: false,
      center: false,
      //stagePadding: 100,

      margin: 40,

      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1.2,
          margin: 20,
          dots: false,
        },
        640: {
          items: 1.2,
          margin: 20,
          dots: false,
        },
        768: {
          items: 1.2,
          margin: 20,
          dots: false,
        },
        991: {
          items: 4.3,
          margin: 15,
          dots: false,
        },
        1200: {
          items: 4.3,
          margin: 15,
        },
      },
    };
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full  mobile-page-padding p-t20 p-b30 `}
        >
          <div className="">
            <div className="">
              {/* TITLE START */}
              <div className="section-head container">
                <div className="sx-separator-outer separator-left"> 
                {this.props.hTag ?  <h3 className="sep-line-one mb-0">
                    {" "}
                    <span className="red">Recommended</span> Services{" "}
                    <img
                      className="w-3"
                      src="assets/images/home-icon.svg"
                      alt=""
                    />{" "}
                    <button className="mobile-hidden btn view_all float-right">
                      View All
                    </button>
                  </h3> : <h4 className="sep-line-one mb-0">
                    {" "}
                    <span className="red">Recommended</span> Services{" "}
                    <img
                      className="w-3"
                      src="assets/images/home-icon.svg"
                      alt=""
                    />{" "}
                    <button className="mobile-hidden btn view_all float-right">
                      View All
                    </button>
                  </h4>}
                  
                </div>
              </div>

              {/* TITLE END */}
              <div className="container container-mobile work-carousel-outer">
                <OwlCarousel
                  className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center "
                  {...options}
                >
                  {/*{projects.map((item, index) => (
                                        <div key={index} className="item">
                                            <div className="project-mas hover-shadow m-a30 ">
                                                <div className=''>
                                                <div className="">
                                                <span className="design_code d-md-none d-log-none">Design Code : BLY21</span>

                                                    <NavLink to={item.url}><img src={item.image.default} alt="" /></NavLink>
                                                   
                                                    
                                                </div>
                                                <div className="project-info shadow-cs">
                                                    <h4 className="sx-tilte pt-3 pr-2 pl-2 mb-0 fs-17"><NavLink to={item.url}>{item.title}</NavLink> <span className="float-right"><img src={item.icon} alt=""/></span></h4>
                                                    <p className="pr-1 pl-2 mb-0 mt-0 fs-15 pb-3">{item.description}</p>
                                                    <NavLink to={"/"}><i className="link-plus bg-primary" /></NavLink>
                                                </div>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    ))}*/}
                  <div className="item">
                    <div className="project-mas hover-shadow m-a30 ">
                      <a href={"/3d-2d-floor-plans.php"}>
                        <img src="/assets/images/3d-service.webp" alt="" />
                      </a>
                      <div className="project-info shadow-cs">
                        <h4 className="sx-tilte pt-3 pr-2 pl-2 mb-0 fs-17">
                          <a href={"/3d-2d-floor-plans.php"}>3D Floor Plans</a>
                          <span className="float-right">
                            <img src="/assets/images/floor-icon.svg" alt="" />
                          </span>
                        </h4>
                        <p className="pr-1 pl-2 mb-0 mt-0 fs-15 pb-3">
                          As Per Your requirements
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="project-mas hover-shadow m-a30 ">
                      <NavLink to={"/customized-house-designs.php"}>
                        <img
                          src="/assets/images/customized-service.webp"
                          alt=""
                        />
                      </NavLink>
                      <div className="project-info shadow-cs">
                        <h4 className="sx-tilte pt-3 pr-2 pl-2 mb-0 fs-17">
                          <NavLink to={"/customized-house-designs.php"}>
                            Customized Designs
                          </NavLink>
                          <span className="float-right">
                            <img
                              src="/assets/images/elevation-icon.svg"
                              alt=""
                            />
                          </span>
                        </h4>
                        <p className="pr-1 pl-2 mb-0 mt-0 fs-15 pb-3">
                          As Per Your requirements
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="project-mas hover-shadow m-a30 ">
                      <NavLink to={"/front-elevation.php"}>
                        <img src="/assets/images/front-service.webp" alt="" />
                      </NavLink>
                      <div className="project-info shadow-cs">
                        <h4 className="sx-tilte pt-3 pr-2 pl-2 mb-0 fs-17">
                          <NavLink to={"/front-elevation.php"}>
                            Front Elevations
                          </NavLink>
                          <span className="float-right">
                            <img src="/assets/images/building.svg" alt="" />
                          </span>
                        </h4>
                        <p className="pr-1 pl-2 mb-0 mt-0 fs-15 pb-3">
                          As Per Your requirements
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="project-mas hover-shadow m-a30 ">
                      <NavLink to={"/commercial-building-elevation.php"}>
                        <img
                          src="/assets/images/commercial-service.webp"
                          alt=""
                        />
                      </NavLink>
                      <div className="project-info shadow-cs">
                        <h4 className="sx-tilte pt-3 pr-2 pl-2 mb-0 fs-17">
                          <NavLink to={"/commercial-building-elevation.php"}>
                            Commercial Designs
                          </NavLink>
                          <span className="float-right">
                            <img
                              src="/assets/images/commercialIcon.svg"
                              alt=""
                            />
                          </span>
                        </h4>
                        <p className="pr-1 pl-2 mb-0 mt-0 fs-15 pb-3">
                          As Per Your requirements
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="project-mas hover-shadow m-a30 ">
                      <NavLink to={"/interior-designing.php"}>
                        <img
                          src="/assets/images/interior-service.webp"
                          alt=""
                        />
                      </NavLink>
                      <div className="project-info shadow-cs">
                        <h4 className="sx-tilte pt-3 pr-2 pl-2 mb-0 fs-17">
                          <NavLink to={"/interior-designing.php"}>
                            Interior Designs
                          </NavLink>
                          <span className="float-right">
                            <img
                              src="/assets/images/interior-icon.svg"
                              alt=""
                            />
                          </span>
                        </h4>
                        <p className="pr-1 pl-2 mb-0 mt-0 fs-15 pb-3">
                          As Per Your requirements
                        </p>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Projects3;
