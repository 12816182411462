import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import configData from "./../../config.js";

const InteriorSideNav = (props) => {
  const [setIsOpen] = React.useState(false);
 
  //contact form
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [requirement, setRequirement] = useState("");
 

  const enabled =
    requirement.length > 0  &&  name.length > 0 && email.length > 0 && mobile.length > 0;

  async function saveQuery(event) {
    event.preventDefault();
    let data = { name, email, mobile, requirement };

    let result = await fetch(`${configData.ADMIN_URL}/api/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": configData.X_API_KEY,
        "x-api-secret": configData.X_API_SECRET,
        Accept: "applicatiion/json",
      },

      body: JSON.stringify(data),
    });

    const item = await result.json();
    console.log(item);
    if (item.status === "success") {
      window.location.href = `/thanks.php`;
      setIsOpen(false);
    } else {
      toast.error("Please Try Again!", { autoClose: 3000 });
    }
  }

  return (
    <>
      <h4 className="widget-title left-nav">Find Your Style</h4>
      <ul className="bg-white">
        <li className="md-it">
          <NavLink to={"modern-interior.php"}>Modern</NavLink>
        </li>
        <li className="md-it">
          <NavLink to={"/minimalist-interior.php"}>Minimalist</NavLink>
        </li>
        <li className="md-it">
          <NavLink to={"/retro-interior.php"}>Retro</NavLink>
        </li>
        <li className="md-it">
          <NavLink to={"/contemporary-interior.php"}>Contemporary</NavLink>
        </li>
        <li className="md-it">
          <NavLink to={"/luxurious-interior.php"}>Luxurious</NavLink>
        </li>
        <li className="md-it">
          <NavLink to={"/artdeco.php"}>Art Deco</NavLink>
        </li>
      </ul>

      <ul className="bg-white form-input-list">
        <p className="md-it">
          <b>Consult For Free</b>
        </p>

        <form method="post" className="text-center">
          <div class="result"></div>
          <div class="form-group">
            <input
              onChange={(e) => {
                setName(e.target.value);
              }}
              type="text"
              className="form-input"
              placeholder="Enter Name"
            />
          </div>

          <div class="form-group">
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required=""
                  type="email"
                  className="form-input"
                  placeholder="Enter Email"
                />
              </div>
        
          <div class="form-group">
              <input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required=""
                type="email"
                className="form-input"
                placeholder="Enter Email"
              />
            </div>
          

          <div class="form-group">
            <input
              onChange={(e) => {
                setMobile(e.target.value);
              }}
              required=""
              type="number"
              className="form-input"
              placeholder="Enter Phone number"
            />
          </div>

          <div class="d-none form-group">
            <textarea
              onChange={(e) => {
                setRequirement(e.target.value);
              }}
              required=""
              className="form-textarea"
              placeholder="Message"
            ></textarea>
          </div>

          <button
            disabled={!enabled}
            onClick={saveQuery}
            className="submitBtn"
            type="submit"
          >
            Get Free Quote
          </button>
        </form>
      </ul>
      <ToastContainer />
    </>
  );
};

export default InteriorSideNav;
