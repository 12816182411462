import React from "react";
import { BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import Home1 from "./Pages/Home1";
import LandingNew from "./Pages/Landing/LandingNew";
import ScrollToTop from "./Pages/ScrollToTop";
import ReadymadeHouseDesign from "./Pages/Services/ReadymadeHouseDesign";
import ReadymadePages from "./Pages/Services/ReadymadePages";
import CommercialBuildingElevation from "./Pages/Services/CommercialBuildingElevation";
import CommercialPages from "./Pages/Services/CommercialPages";
import CommercialDesigns from "./Pages/Services/CommercialDesigns";
import FrontElevation from "./Pages/Services/FrontElevation";
import FrontElevationPages from "./Pages/Services/FrontElevationPages";
import FloorPlan from "./Pages/Services/FloorPlan";
import FloorPlanPages from "./Pages/Services/FloorPlanPages";
import ReadymadeDetail from "./Pages/Services/ReadymadeDetail";
import ElevationDetail from './Pages/Services/ElevationDetail';
import CommercialDesignsDetail from './Pages/Services/CommercialDesignsDetail';
import CustomizedHouseDesign from './Pages/CustomizedHouseDesign';
import Floor from './Pages/Floor';
import InteriorDesigning from './Pages/Services/InteriorDesigning';
import InteriorCategory from './Pages/Services/InteriorCategory';
import RecentProjects from './Pages/RecentProjects';
import SingleRecentProject from './Pages/SingleRecentProject';
import VideoWalkThrough from './Pages/VideoWalkThrough';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import Faq from './Pages/Faq';
import Terms from './Pages/Terms';
import Policy from './Pages/PrivacyPolicy';
import Feedback from './Pages/Feedback';
import Sitemap from './Pages/Sitemap';
import Testimonial from './Pages/Testimonial';
import Clients from './Pages/Clients';
import Blog from './Pages/Blog';
import BlogSingle from './Pages/BlogSingle';
import Partner from './Pages/Partner';
import Error from './Pages/Error';
import Login from './Pages/Login';
import OtpVerification from './Pages/OtpVerification';
import Register from './Pages/Register';
import Profile from './Pages/Profile';
import FavDesign from './Pages/FavouriteDesign'; 
import SearchList from './Pages/SearchList';
import CompletedProjects from './Pages/CompletedProjects';

import VastuConsultancy from './Pages/Vastu/VastuConsultancy';
import VastuEastFacing from './Pages/Vastu/VastuEastFacing';
import VastuWestFacing from './Pages/Vastu/VastuWestFacing';
import VastuNorthFacing from './Pages/Vastu/VastuNorthFacing';
import VastuSouthFacing from './Pages/Vastu/VastuSouthFacing';
import VastuBedroom from './Pages/Vastu/VastuBedroom';
import VastuInterior from './Pages/Vastu/VastuInterior';
import VastuKitchen from './Pages/Vastu/VastuKitchen';
import VastuNewHouse from './Pages/Vastu/VastuNewHouse';
import VastuPoojaRoom from './Pages/Vastu/VastuPoojaRoom'; 
import VastuToilet from './Pages/Vastu/VastuToilet';
import ReadymadeCustom from "./Pages/Services/ReadymadeCustom";
import FloorCustom from "./Pages/Services/FloorCustom";
import  AskExpert from './Pages/AskExpert';
import  ViewDetails from './Pages/ViewDetails';
import Payment from "./Pages/Payment";
import WhyNakshewala from './Pages/whynakshewala';
import ModernInterior from './Pages/ModernInterior';
import MinimalistInterior from './Pages/MinimalistInterior';
import RetroInterior from './Pages/RetroInterior';
import ContemporaryInterior from './Pages/ContemporaryInterior';
import LuxuriousInterior from './Pages/LuxuriousInterior';
import ArtInterior from './Pages/ArtInterior';
import  Package from './Pages/Package';
import InstitutionalPlan from './Pages/Services/InstitutionalPlan';
import HospitalPlans from './Pages/Services/HospitalPlans';
import Thanks from './Pages/Thanks';
//Nakshewala Routes
/*import Testimo from './Pages/testimonial';
import Completedprojt from './Pages/completedprojects';
import Sitemap from './Pages/sitemap';
import Partner from './Pages/partner';
import Customizedhouse from './Pages/customized-house-designs';
import Vastu from './Pages/vastu-consultancy';
import VastuNorth from './Pages/Individual-direction-pages';
import VastuSouth from './Pages/vastu-south-facing-house';
import VastuEast from './Pages/vastu-East-facing-house';
import VastuWest from './Pages/vastu-west-facing-house';
import Howwork from './Pages/howworks';
import WhyNakshewala from './Pages/whynakshewala';
import ModernInt from './Pages/moderninterior';
import MinimalistInt from './Pages/minimalistinterior';
import RetroInt from './Pages/retrointerior';
import Feedback from './Pages/feedback';
import Contemporary from './Pages/contemporaryinterior';
import Luxurious from './Pages/luxuriousinterior';
import VastuInt from './Pages/vastu-interior';
import Vastuktch from './Pages/vastu-for-kitchen';
import VastuNewHouse from './Pages/vastu-new-house-construction';
import VastuBedroom from './Pages/vastu-for-bedroom';
import VastuToilets from './Pages/vastu-for-toilets';
import VastuPooja from './Pages/vastu-for-pooja-room';
import  AskExpert from './Pages/askexpert';
import  Package from './Pages/our-packages';
import  View from './Pages/view-detail';
import  ArtInt from './Pages/artdeco';
import ReadymadeHouseDesign from './Pages/readymade-house-design';
import InteriorDesigning from './Pages/InteriorDesigning';
import ReadymadeHouseDesignDemo from './Pages/readymadeHouseDesignDemo';
import ReadymadeFloorPlans from './Pages/readymade-floor-plans';
import CommercialBuildingElevation from './Pages/commercial-building-elevation';
import FrontElevation from './Pages/front-elevation';

import SingleDetails from './Pages/SingleDetails';
import SingleCommercial from './Pages/SingleCommercial';
import Pagination from './Pages/Pagination';
import PaginationComponent from './Pages/Pagination-old';
import SingleStoryElevation from './Pages/front-elevation-pages';
import CommercialPages from './Pages/commercial-pages';
import FloorPages from './Pages/floor-pages';
import RecentProjects from './Pages/RecentProjects';
import Recentbckup from './Pages/recentbckup';
import ReadyMadePages from './Pages/readymade-house-pages';
import SearchFilters from './Pages/searchfilters';
import Faq from './Pages/Faq';
import ContactUs from './Pages/ContactUs';
import MyForm from './Pages/MyForm';
import SingleProject from './Pages/SingleProject';
import FloorPlan from './Pages/FloorPlan';
import Terms from './Pages/terms-and-conditions';
import Policy from './Pages/privacy-policy';
import Clients from './Pages/Clients';
import CommercialDesigns from './Pages/CommercialDesigns';
import SingleCommercialDesign from './Pages/SingleCommercialDesign';
import VideoWalkThrough from './Pages/VideoWalkThrough';
//Demo
import ProjectGridemo from './Pages/ProjectGridemo';
import ImageModal from './Pages/ImageModal';
import DetailsDemo from './Pages/DetailsDemo';
//Auth
import Login from './Pages/Login';
import OtpVerification from './Pages/OtpVerification';
import Register from './Pages/Register';
import Profile from './Pages/Profile';
import FavDesign from './Pages/Favourite-design' 
//search
import SearchList from './Pages/SearchList';*/
//const Home1 = lazy(() => import('./Pages/Home1'));
//const Home1 = loadable(() => import('./Pages/Home1'))
//const renderLoader = () => <p>Loading</p>;

class Components extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <div className="page-wraper">
          <ScrollToTop>
            <Switch>
              <Route path="/" exact component={Home1} />
			  <Route path="/landing" exact component={LandingNew} />
              <Route
                path="/readymade-house-design.php"
                exact
                component={ReadymadeHouseDesign}
              />
              <Route path='/:slug/:id/106' exact component={ReadymadeDetail} />
              <Route
                path="/single-floor-house-design.php"
                exact
                component={ReadymadePages}
              />
              <Route
                path="/two-floor-house-design.php"
                exact
                component={ReadymadePages}
              />
              <Route
                path="/three-floor-house-design.php"
                exact
                component={ReadymadePages}
              />
              <Route
                path="/multi-floor-house-design.php"
                exact
                component={ReadymadePages}
              />

              <Route
                path="/commercial-building-elevation.php"
                exact
                component={CommercialBuildingElevation}
              />
              <Route
                path="/shopping-complex-building-designs.php"
                exact
                component={CommercialPages}
              />
              <Route
                path="/school-and-collage-building-designs.php"
                exact
                component={CommercialPages}
              />
              <Route
                path="/hospital-and-clinic-building-designs.php"
                exact
                component={CommercialPages}
              />
              <Route
                path="/hotel-and-banquet-building-designs.php"
                exact
                component={CommercialPages}
              />
              <Route
                path="/industrial-and-factory-building-designs.php"
                exact
                component={CommercialPages}
              />
              <Route
                path="/corporate-and-office-building-designs.php"
                exact
                component={CommercialPages}
              />

              <Route
                path="/commercial-designs"
                exact
                component={CommercialDesigns}
              />
              <Route
                path="/commercial-designs/:id"
                exact
                component={CommercialDesigns}
              />

              <Route
                path="/front-elevation.php"
                exact
                component={FrontElevation}
              />
              <Route
                path="/single-storey-elevation.php"
                exact
                component={FrontElevationPages}
              />
              <Route
                path="/double-storey-elevation.php"
                exact
                component={FrontElevationPages}
              />
              <Route
                path="/triple-storey-elevation.php"
                exact
                component={FrontElevationPages}
              />
              <Route
                path="/small-house-elevations.php"
                exact
                component={FrontElevationPages}
              />
              <Route
                path="/contemporary-house-elevation.php"
                exact
                component={FrontElevationPages}
              />
              <Route
                path="/hut-shaped-elevation.php"
                exact
                component={FrontElevationPages}
              />
              <Route
                path="/traditional-house-elevations.php"
                exact
                component={FrontElevationPages}
              />
              <Route
                path="/one-side-open-plot-elevations.php"
                exact
                component={FrontElevationPages}
              />
              <Route
                path="/residential-cum-commercial-elevation.php"
                exact
                component={FrontElevationPages}
              />
              <Route
                path="/multi-storey-residential-buildings.php"
                exact
                component={FrontElevationPages}
              />

              <Route
                path="/readymade-floor-plans.php"
                exact
                component={FloorPlan}
              />
              <Route
                path="/simplex-floor-plans.php"
                exact
                component={FloorPlanPages}
              />
              <Route
                path="/duplex-floor-plans.php"
                exact
                component={FloorPlanPages}
              />
              <Route
                path="/small-house-plans.php"
                exact
                component={FloorPlanPages}
              />
              <Route
                path="/vastu-house-plans.php"
                exact
                component={FloorPlanPages}
              />
              <Route
                path="/bungalow-house-plans.php"
                exact
                component={FloorPlanPages}
              />
              <Route
                path="/popular-house-plans.php"
                exact
                component={FloorPlanPages}
              />
              <Route
                path="/affordable-house-plans.php"
                exact
                component={FloorPlanPages}
              />
              <Route
                path="/independent-floor-house-plans.php"
                exact
                component={FloorPlanPages}
              />
              <Route
                path="/multifamily-apartment-house-plans.php"
                exact
                component={FloorPlanPages}
              />
              <Route
                path="/farm-house-plans.php"
                exact
                component={FloorPlanPages}
              />
              <Route
                path="/commercial-floor-plans.php"
                exact
                component={FloorPlanPages}
              />

               <Route
                path="/15x40-house-designs.php"
                exact
                component={ReadymadeCustom}
              />
              <Route
                path="/showroom-plans.php"
                exact
                component={FloorCustom}
              />
               <Route
                path="/institutional-plans.php"
                exact
                component={InstitutionalPlan}
              />
              <Route
                path="/hospital-plans.php"
                exact
                component={HospitalPlans}
              />
              
                <Route path='/:slug/:id/' exact component={ElevationDetail} />
                <Route path='/:slug/126/:id/' exact component={CommercialDesignsDetail} />
                <Route path='/customized-house-designs.php' exact  component={CustomizedHouseDesign} />
                <Route path='/3d-2d-floor-plans.php' exact component={Floor} />
				
				
				
                <Route path='/interior-designing.php' exact component={InteriorDesigning} />	
                <Route path='/bedroom-interior-designs.php'  exact component={InteriorCategory} />	
                <Route path='/kitchen-interior-designs.php' exact  component={InteriorCategory} />	
                <Route path='/living-room-interior-designs.php' exact component={InteriorCategory} />	
                <Route path='/drawing-room-interior-designs.php' exact  component={InteriorCategory} />	
                <Route path='/dining-interior-designs.php' exact component={InteriorCategory} />	
                <Route path='/toilet-interior-designs.php' exact component={InteriorCategory} />	
                <Route path='/entrance-interior-design.php' exact component={InteriorCategory} />	
                <Route path='/kids-room-interior-designs.php' exact  component={InteriorCategory} />	
                <Route path='/puja-room-interior-designs.php' exact  component={InteriorCategory} />	
                <Route path='/office-interior-designs.php' exact  component={InteriorCategory} />	
                <Route path='/restaurant-interior-designs.php' exact  component={InteriorCategory} />	
                <Redirect from="/restaurant-plans.php" to="/restaurant-interior-designs.php" />
                <Redirect from="/plotting-layouts.php" to="/one-side-open-plot-elevations.php" />
                
                <Route path='/jewellery-showroom-interior-designs.php' exact  component={InteriorCategory} />	
                <Route path='/hotel-and-banquet-hall-interior-designs.php' exact component={InteriorCategory} />	
                <Route path='/gym-and-yoga-center.php' exact component={InteriorCategory} />	
                <Route path='/show-room-interior-designs.php' exact component={InteriorCategory} />
                <Route path='/recent-interior-designing-project.php' exact component={RecentProjects} />	
                <Route path='/:slug/:id/recent' exact component={SingleRecentProject} />
                <Route path='/video-walkthrough.php' exact  component={VideoWalkThrough} />
                <Route path='/aboutus.php' exact component={AboutUs} />
                <Route path='/contact-us.php' exact component={ContactUs} />
                <Route  path='/search.php' exact component={SearchList} />
                <Route path='/faq' exact component={Faq} />
                <Route path='/terms-and-conditions.php' exact component={Terms} />
                <Route path='/privacy-policy.php' exact component={Policy} />
                <Route path='/feedback.php' exact  component={Feedback} />
                <Route path='/site-map.php' exact component={Sitemap} />
                <Route path='/testimonial.php' exact  component={Testimonial} />
                <Route path='/our-clients.php' exact component={Clients} />
                <Route path='/blog' exact component={Blog} />
                <Route path='/blog.php' exact component={Blog} />
                <Route path='/completedprojects.php' exact component={CompletedProjects} />
                <Route path="/get-associated-become-a-partner-nakshewala" exact component={Partner} />
                <Route path='/login' exact component={Login} />
                <Route path='/otp-verify' exact component={OtpVerification} />
                <Route path='/register' exact component={Register} />


				
               <Route path='/vaastu-consultancy.php' exact component={VastuConsultancy} />
              <Route path='/vastu-for-east-facing-house.php' exact component={VastuEastFacing} />
              <Route path='/vastu-for-west-facing-house.php' exact component={VastuWestFacing} />  
              <Route path='/vastu-for-north-facing-house.php' exact component={VastuNorthFacing} />
              <Route path='/vastu-for-south-facing-house.php' exact component={VastuSouthFacing} />
              <Route path='/vastu-for-bedrooms.php' exact component={VastuBedroom} />
              <Route path='/vastu-for-interiors.php' exact component={VastuInterior} />
              <Route path='/vastu-for-kitchen.php' exact component={VastuKitchen} />
              <Route path='/vastu-for-new-house-construction.php' exact component={VastuNewHouse} />
              <Route path='/vastu-for-pooja-room.php' exact component={VastuPoojaRoom} />
              <Route path='/vastu-for-toilets.php' exact component={VastuToilet} />


              <Route path='/profile' exact component={Profile} />
              <Route path='/favourite-design' exact component={FavDesign} />
              <Route path='/askexpert.php' exact component={AskExpert} />
              <Route path='/get_a_free_quote.php' exact component={AskExpert} />
              <Route path='/viewdetail.php' exact component={ViewDetails} />
              <Route path='/modifyyourplans.php' exact component={CustomizedHouseDesign} />
              <Route path='/orderplan.php' exact component={ReadymadeHouseDesign} />
              <Route path='/payment.php' exact component={Payment} />
              <Route path='/planinfo.php' exact component={ContactUs} />
              <Route path='/recent-project.php' exact component={RecentProjects} />
              <Route path='/why-nakshewala.php' exact  component={WhyNakshewala} />
              <Route path='/submit_testimonial.php' exact  component={Testimonial} />
              <Route path='/renovation-remodelling.php' exact component={CustomizedHouseDesign} />
              <Route path='/modern-interior.php' exact component={ModernInterior} />
			  <Route path='/minimalist-interior.php' exact component={MinimalistInterior} />
		      <Route path='/retro-interior.php' exact component={RetroInterior} />
			  <Route path='/contemporary-interior.php' exact component={ContemporaryInterior} />
			  <Route path='/luxurious-interior.php' exact component={LuxuriousInterior} />
			  <Route path='/artdeco.php' exact component={ArtInterior} />
         <Route path='/error-404' exact component={Error} />
         <Route path='/our-packages.php' exact component={Package} />
         <Route path='/thanks.php' exact component={Thanks} />
         
              <Route path='/:blog_url' exact component={BlogSingle} />
				

                
                {/*<Route path='/error-404' exact component={Error} />
              <Route path='/home-2' exact component={Home2} />
                            <Route path='/home-3' exact component={Home3} />
                            <Route path='/home-4' exact component={Home4} />
                            <Route path='/home-5' exact component={Home5} />
                            <Route path='/home-6' exact component={Home6} />

                            <Route path='/about-1' exact component={About1} />
                            <Route path='/aboutus.php' exact component={About2} />

                            <Route path='/services-1' exact component={Services1} />
                            <Route path='/services-2' exact component={Services2} />
                            <Route path='/services-detail' exact component={ServiceDetail} />

                            <Route path='/team-1' exact component={Team1} />
                            <Route path='/team-2' exact component={Team2} />
                            <Route path='/team-single' exact component={TeamDetail} />

                            <Route path='/our-history' exact component={OurHistory} />
                            <Route path='/icon-font' exact component={FontIcons} />
                            <Route path='/error-404' exact component={Error} />

                            <Route path='/project-grid-3-columns' exact component={ProjectGrid3} />
                            <Route path='/project-grid-3-columns-no-gap' exact component={ProjectGridNoGap3} />
                            <Route path='/project-grid-4-columns' exact component={ProjectGrid4} />
                            <Route path='/project-grid-4-columns-no-gap' exact component={ProjectGridNoGap4} />
                            <Route path='/project-grid-5-columns' exact component={ProjectGrid5} />
                            <Route path='/project-grid-5-columns-no-gap' exact component={ProjectGridNoGap5} />

                            <Route path='/project-masonry-3-columns' exact component={ProjectMasonary3} />
                            <Route path='/project-masonry-3-columns-no-gap' exact component={ProjectMasonaryNoGap3} />
                            <Route path='/project-masonry-4-columns' exact component={ProjectMasonary4} />
                            <Route path='/project-masonry-4-columns-no-gap' exact component={ProjectMasonaryNoGap4} />
                            <Route path='/project-masonry-5-columns' exact component={ProjectMasonary5} />
                            <Route path='/project-masonry-5-columns-no-gap' exact component={ProjectMasonaryNoGap5} />

                            <Route path='/project-carousel' exact component={ProjectCorousel} />
                            <Route path='/project-detail1' exact component={ProjectDetail1} />
                            <Route path='/project-detail2' exact component={ProjectDetail2} />

                            <Route path='/blog-grid' exact component={BlogGrid} />
                            <Route path='/blog' exact component={BlogListing} />
                            
                          
                            <Route path='/post-right-sidebar' exact component={PostRightSidebar} />

                            <Route path='/shop-grid' exact component={ShopGrid} />
                            <Route path='/shop-list' exact component={ShopList} />
                            <Route path='/shop-detail' exact component={ShopDetail} />
                            <Route path='/login-old' exact component={ShopAccount} />
                            <Route path='/shop-cart' exact component={ShopCart} />
                            <Route path='/shop-checkout' exact component={ShopCheckout} />

                            <Route path='/faq' exact component={Faq} />
                            <Route path='/contact-us.php' exact component={ContactUs} />
                            <Route path='/terms-and-conditions.php' exact component={Terms} />
                            <Route path='/privacy-policy.php' exact component={Policy} />
                           
                             <Route path='/Searchfilters' exact component={SearchFilters} />
                             <Route path='/MyForm' exact component={MyForm} />
                             <Route path='/:slug/:id/recent' exact component={SingleProject} />
                            
                            <Route path='/recent-interior-designing-project.php' exact component={RecentProjects} />	
                            <Route path='/interior-designing.php' exact component={InteriorDesigning} />	

                             <Route path='/readymade-house-design.php' exact  component={ReadymadeHouseDesign} />
                             <Route path='/single-floor-house-design.php'  exact component={ReadyMadePages} />	
                             <Route path='/two-floor-house-design.php'  exact component={ReadyMadePages} />	
                             <Route path='/three-floor-house-design.php'  exact component={ReadyMadePages} />	
                             <Route path='/multi-floor-house-design.php'  exact component={ReadyMadePages} />	
                            
                             <Route path='/readymade-floor-plans.php' exact component={ReadymadeFloorPlans} />
                              <Route path='/readymade-house-design-demo.php' exact component={ReadymadeHouseDesignDemo} />
                             <Route path='/commercial-building-elevation.php' exact component={CommercialBuildingElevation} />
                             <Route path='/front-elevation.php' exact component={FrontElevation} />
                             <Route path='/pagination' exact component={Pagination} />
                             <Route path='/Pagination-old' exact component={PaginationComponent} />
                             <Route path='/commercial-designs/:id' exact component={CommercialDesigns} />
                             <Route path='/:slug/126/:id/' exact component={SingleCommercialDesign} />
                             <Route path='/:slug/:id/' exact component={SingleCommercial} />

                             <Route path='/:slug/:id/:page_id' exact component={SingleDetails} />

                             <Route path='/bedroom-interior-designs.php'  exact component={ProjectGrid3} />	
                             <Route path='/kitchen-interior-designs.php' exact  component={ProjectGrid3} />	
                             <Route path='/living-room-interior-designs.php' exact component={ProjectGrid3} />	
                             <Route path='/drawing-room-interior-designs.php' exact  component={ProjectGrid3} />	
                             <Route path='/dining-interior-designs.php' exact component={ProjectGrid3} />	
                             <Route path='/toilet-interior-designs.php' exact component={ProjectGrid3} />	
                             <Route path='/entrance-interior-design.php' exact component={ProjectGrid3} />	
                             <Route path='/kids-room-interior-designs.php' exact  component={ProjectGrid3} />	
                             <Route path='/puja-room-interior-designs.php' exact  component={ProjectGrid3} />	
                             <Route path='/office-interior-designs.php' exact  component={ProjectGrid3} />	
                             <Route path='/restaurant-interior-designs.php' exact  component={ProjectGrid3} />	
                             <Route path='/jewellery-showroom-interior-designs.php' exact  component={ProjectGrid3} />	
                             <Route path='/hotel-and-banquet-hall-interior-designs.php' exact component={ProjectGrid3} />	
                             <Route path='/gym-and-yoga-center.php' exact component={ProjectGrid3} />	
                             <Route path='/show-room-interior-designs.php' exact component={ProjectGrid3} />


                             
                             <Route path='/3d-2d-floor-plans.php' exact component={FloorPlan} />

                             
                             <Route path='/single-storey-elevation.php' exact component={SingleStoryElevation} />
                             <Route path='/double-storey-elevation.php' exact component={SingleStoryElevation} />
                             <Route path='/triple-storey-elevation.php' exact component={SingleStoryElevation} />
                             <Route path='/small-house-elevations.php' exact component={SingleStoryElevation} />
                             <Route path='/contemporary-house-elevation.php' exact component={SingleStoryElevation} />
                             <Route path='/hut-shaped-elevation.php' exact component={SingleStoryElevation} />
                             <Route path='/traditional-house-elevations.php' exact component={SingleStoryElevation} />
                             <Route path='/one-side-open-plot-elevations.php' exact component={SingleStoryElevation} />
                             <Route path='/residential-cum-commercial-elevation.php' exact component={SingleStoryElevation} />
                             <Route path='/multi-storey-residential-buildings.php' exact component={SingleStoryElevation} />

                             
                              <Route path='/shopping-complex-building-designs.php' exact component={CommercialPages} />
                              <Route path='/school-and-collage-building-designs.php' exact component={CommercialPages} />
                              <Route path='/hospital-and-clinic-building-designs.php' exact component={CommercialPages} />
                              <Route path='/hotel-and-banquet-building-designs.php' exact component={CommercialPages} />
                              <Route path='/industrial-and-factory-building-designs.php' exact component={CommercialPages} />
                              <Route path='/corporate-and-office-building-designs.php' exact component={CommercialPages} />



                               <Route path='/simplex-floor-plans.php' exact component={FloorPages} /> 
                               <Route path='/duplex-floor-plans.php' exact component={FloorPages} /> 
                               <Route path='/small-house-plans.php' exact component={FloorPages} /> 
                               <Route path='/vastu-house-plans.php' exact component={FloorPages} /> 
                               <Route path='/bungalow-house-plans.php' exact component={FloorPages} />  
                               <Route path='/popular-house-plans.php' exact component={FloorPages} /> 
                               <Route path='/affordable-house-plans.php' exact component={FloorPages} /> 
                               <Route path='/independent-floor-house-plans.php' exact component={FloorPages} /> 
                               <Route path='/multifamily-apartment-house-plans.php' exact component={FloorPages} /> 
                               <Route path='/farm-house-plans.php' exact component={FloorPages} />
                               <Route path='/commercial-floor-plans.php' exact component={FloorPages} />

                                <Route path='/recentbckup' exact component={Recentbckup} /> 
                                <Route path='/ProjectGridemo' exact component={ProjectGridemo} />
                                <Route path='/ImageModal' exact component={ImageModal} />

                                <Route path='/site-map.php' exact component={Sitemap} />
                                <Route path='/our-client.php' exact component={Clients} />
                                <Route path='/testimonial.php' exact  component={Testimo} />
                                <Route path='/video-walkthrough.php' exact  component={VideoWalkThrough} />
                                
								
								  <Route path='/customized-house-designs.php' exact  component={Customizedhouse} />
								 
								 <Route path='/vaastu-consultancy.php' exact  component={Vastu} />
								 
								 <Route path='/vastu-for-north-facing-house.php' exact  component={VastuNorth} />
								 
								 <Route path='/vastu-for-south-facing-house.php' exact  component={VastuSouth} />
							
                                  <Route path='/vastu-for-east-facing-house.php' exact  component={VastuEast} />
								  
								   <Route path='/vastu-for-west-facing-house.php' exact  component={VastuWest} />
								  
								    <Route path='/how-it-works' exact  component={Howwork} />
									
									<Route path='/why-nakshewala.php' exact  component={WhyNakshewala} />
									
									<Route path='/modern-interior.php' exact  component={ModernInt} />
									
									<Route path='/minimalist-interior.php' exact  component={MinimalistInt} />
									
									<Route path='/retro-interior.php' exact  component={RetroInt} />

                                     <Route path='/contemporary-interior.php' exact  component={Contemporary} />
									 
									 <Route path='/luxurious-interior.php' exact  component={Luxurious} />
									
									<Route path='/feedback.php' exact  component={Feedback} />

                                <Route path='/completedprojects.php' exact component={Completedprojt} />

                                <Route path='/commercial-designs' exact component={CommercialDesigns} />
								
								 <Route path='/vastu-for-interiors.php' exact component={VastuInt} />
								
								 <Route path='/vastu-for-kitchen.php' exact component={Vastuktch} />
								
								  <Route path='/vastu-for-new-house-construction.php' exact component={VastuNewHouse} />
								 
								   <Route path='/vastu-for-bedrooms.php' exact component={VastuBedroom} />
								  
								    <Route path='/vastu-for-toilets.php' exact component={VastuToilets} />
								
									 <Route path='/vastu-for-pooja-room.php' exact component={VastuPooja} />
									 
									 <Route path='/askexpert.php' exact component={AskExpert} />
									
									 <Route path='/our-packages.php' exact component={Package} />
									 
									 <Route path='/viewdetail.php' exact component={View} />
									 
									 <Route path='/artdeco.php' exact component={ArtInt } />
									 
								
								<Route path="/get-associated-become-a-partner-nakshewala" exact component={Partner} />

                                <Route path="/DetailsDemo" exact component={DetailsDemo} />

                               
                                <Route path='/blogs' exact component={BlogCategory} />

                                <Route path='/login' exact component={Login} />
                                <Route path='/otp-verify' exact component={OtpVerification} />
                                <Route path='/register' exact component={Register} />
								
								<Route path='/profile' exact component={Profile} />
								
								<Route path='/favourite-design' exact component={FavDesign} />
								<Route  path='/Search' exact component={SearchList} />
                            
                                <Route path='/:blog_url' exact component={BlogSingle} />
                                
                               

                  
        <Route path="*" component={Error} />*/}
            </Switch>
          </ScrollToTop>
        </div>
      </BrowserRouter>
    );
  }
}

export default Components;
