import React from 'react';
import { NavLink } from 'react-router-dom';
import EnquireNow from './../Elements/EnquireNow'; 


class WhyNakshewalaBanner extends React.Component {
    render() {
        return (
            <>
                <div className="sx-bnr-inr overlay-wraper bg-center bg-cover" style={{ backgroundImage: 'url(' + this.props.bgimage + ')' }}>
                   
                    <div className="container">
                        <div className="sx-bnr-inr-entry">
                            <div className="banner-title-outer text-center">
                                <div className="banner-title-name ">
                                    <h2 className="m-tb0 mb-3">{this.props.title}</h2>   
                                    <h5 className="red">{this.props.pagename}</h5>                             
                                    <p className="fs-15 lh-1">
                                        {this.props.description}
                                    </p>
                                </div>
                                 <EnquireNow />
                            </div>
                            {/* BREADCRUMB ROW */}
                            <div className="d-none">
                                <ul className="sx-breadcrumb breadcrumb-style-2">
                                <li><NavLink to={"./"}>Home</NavLink></li>
                                    <li>{this.props.pagename}</li>
                                </ul>
                            </div>
                           
                            {/* BREADCRUMB ROW END */}
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default WhyNakshewalaBanner;