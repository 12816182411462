import React from "react";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "react-lazy-load-image-component/src/effects/blur.css";

class HomeServiceSliderMobile extends React.Component {
  render() {
    const options = {
      loop: false,
      autoplay: false,
      nav: false,
      dots: false,
      navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
      responsive: {
          0: {
              items: 3.5,
              margin: 15,
               loop: true,
          },
          480: {
              items: 3.5,
              margin: 15,
               loop: true,
              autoplay: true,
          },
          767: {
              items: 4,
              margin: 15,
               loop: true,
               autoplay: true,
          },
          1000: {
              items: 7,
               margin: 40,
          }
      }
  };
    return (
      <>
        <div className={`${this.props.bgcolor} section-full desktop-hidden for-mobile-slider-service`}>
          <div className="container container-mobile">
            <div className="section-content pt-3 owl-btn-vertical-center">
            <OwlCarousel   className="owl-carousel home-client-carousel-2" {...options}>
              <div className="item mt-50">
                <NavLink
                  to={"/readymade-house-design.php"}
                  className="client-logo-pic"
                >
                  <LazyLoadImage
                    className="w-100"
                    alt="House Designs"
                    effect="blur"
                    src="/assets/images/services/House_Design.webp"
                  />
                  <h2 class="cat_text d-sm-block  d-lg-none">House Designs</h2>
                  
                </NavLink>
              </div>

              <div className="item mt-50">
                <NavLink to={"/commercial-designs"} className="client-logo-pic">
                  <LazyLoadImage
                    className="w-100"
                    alt="Commercial Designs"
                    effect="blur"
                    src="/assets/images/services/Commercial_Designs.webp"
                  />

                  <h2 class="cat_text d-sm-block  d-lg-none">
                    Commercial Designs
                  </h2>
                 
                </NavLink>
              </div>

              <div className="item mt-50">
                <NavLink
                  to={"/front-elevation.php"}
                  className="client-logo-pic"
                >
                  <LazyLoadImage
                    className="w-100"
                    alt="3D Front Elevations"
                    effect="blur"
                    src="/assets/images/services/Front_Elevation.webp"
                  />

                  <h2 class="cat_text d-sm-block  d-lg-none">
                    3D Front Elevations
                  </h2>
                  
                </NavLink>
              </div>

              <div className="item mt-50">
                <NavLink
                  to={"/interior-designing.php"}
                  className="client-logo-pic"
                >
                  <LazyLoadImage
                    className="w-100"
                    alt="Interior Designs"
                    effect="blur"
                    src="/assets/images/services/Interior.webp"
                  />

                  <h2 class="cat_text d-sm-block  d-lg-none">Interior Designs</h2>
                  
                </NavLink>
              </div>

              <div className="item mt-50">
                <NavLink
                  to={"/customized-house-designs.php"}
                  className="client-logo-pic"
                >
                  <LazyLoadImage
                    className="w-100"
                    alt="Customized Designs"
                    effect="blur"
                    src="/assets/images/services/customized_design.ca23fc5d.webp"
                  />

                  <h2 class="cat_text d-sm-block  d-lg-none">
                    Customized Designs
                  </h2>
                 
                </NavLink>
              </div>

              <div className="item mt-50">
                <NavLink
                  to={"/readymade-floor-plans.php"}
                  className="client-logo-pic"
                >
                  <LazyLoadImage
                    className="w-100"
                    src="/assets/images/services/floor_plan.webp"
                    alt="Floor Plans"
                    effect="blur"
                  />

                  <h2 class="cat_text d-sm-block  d-lg-none">Floor Plans</h2>
                 
                </NavLink>
              </div>

              <div className="item mt-50">
                <a href="/3d-2d-floor-plans.php" className="client-logo-pic">
                  <LazyLoadImage
                    className="w-100"
                    src="/assets/images/services/3D.webp"
                    alt="3D Floor Plans"
                    effect="blur"
                  />

                  <h2 class="cat_text d-sm-block  d-lg-none">3D Floor Plans</h2>
                 
                </a>
              </div>
              </OwlCarousel>
            </div>
            
          </div>
          
        </div>
      </>
    );
  }
}

export default HomeServiceSliderMobile;
