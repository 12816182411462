import React from "react";
import HomeServiceSlider from "./../../Elements/HomeServiceSlider";
import HomeServiceSliderMobile from "./../../Elements/HomeServiceSliderMobile";

class LandingService extends React.Component {
  render() {
    return (
      <>
      <HomeServiceSlider /> 
      <HomeServiceSliderMobile />
      </>
    );
  }
}

export default LandingService;