import React, { Suspense } from "react";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import Recommended from "./../../Elements/Recommended";
import VastuDirection from "./VastuDirection";
import VastuAllDirectionBanner from "./VastuAllDirectionBanner";
import About5 from "./../../Elements/About5";
import axios from "axios";
import { useEffect, useState } from "react";
import configData from "./../../../config.js";
var bnrimg = require("./../../../images/vastu-consultancy.webp");

const VastuNorthFacing = () => {
	
  const [page_content, setPage_content] = useState("");

  //SEO API CALL
  useEffect(() => {
    async function fetchData() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=vastu-for-north-facing-house.php`
        );
       
        setPage_content(pageSeo.data[0].short_description);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      <Header2 />
      <div className="page-content">
        <VastuAllDirectionBanner
          title="Vastu for North Facing House"
          pagename=""
          description="Getting designs in an auspicious way"
          bgimage={bnrimg}
        />
        <div className="p-t50 p-b50 north-all-paragraph-content">
          <div className="container">
            <h3 className="north-facing-content-section"> The best way to design</h3>
            <p>
              Vastu is a science of setting things, it is very useful for
              architectural drawing to bring more harmony, peace, abundance, and
              prosperity to the inmates of the house. If someone has given a
              chance to choose the facing of their house, they will surely
              choose north-facing as it is known as the most auspicious facing
              for any type of building. This is a partly true fact that north
              facing house is very auspicious, even a north-facing house can be
              inauspicious if and only if, it is not obeying the several
              important facts of Vastu. Let us discuss here the various points
              which make a north-facing house most auspicious.
            </p>

            <p>
              Going towards the various important aspects of the Vastu for the
              north-facing house, it must be stated that north is the direction
              of lord kuber which indicates money and wealth. In north-facing
              housed you can place entrance anywhere you want in the north
              direction, however, 5th pada [step] is most suitable for the
              entrance, after that, you 1, 2, 3 & 4th pada can be utilized for
              entrance. If somehow these stated positions are not available you
              can place entrance in 6, 7, 8 & 9th pada too, it won’t harm but
              not so auspicious as per Vastu norms. The kitchen is best suitable
              to locate in the southeast corner if it is not available then the
              only northwest can be used for the kitchen. Do not forget to face
              in the east while you are cooking if you are in the southeast
              kitchen and face towards the west while you are cooking in the
              northwest kitchen. Avoid having a kitchen in the north-east corner
              of the house, not so good as per Vastu. Apart from the kitchen,
              you can place a divine pooja room in the north-east corner
              complemented by a living room too. You can make a guest bedroom in
              the northwest of the house also kids’ bedroom can be placed here
              too. The best suitable position for the master bedroom is the
              southwest, if your house is multi-story then it should be placed
              on the top floor. Do not make staircase and heavy structures
              especially in the 1-5 padas. Please keep in mind no direction is
              bad it’s just the scientific fact behind setting things according
              to direction, I am sure the information was useful for you all.
            </p>
          </div>
        </div>

        <Suspense fallback={<div>Loading...</div>}>
        <VastuDirection />
        <Recommended title="Recommended Services" bgcolor="bg-white" />
        <About5 page_content={page_content} />
        </Suspense>
      </div>
      <Footer />
    </>
  );
};
export default VastuNorthFacing;
