import React from "react";
import Header2 from "../Common/Header2";
import Footer from "../Common/Footer";
import Recommended from "./../Elements/Recommended";
import InteriorSideNav from "./../Elements/InteriorSideNav";
import CommomInteriorCollapse from "./../Elements/CommomInteriorCollapse";

const ArtInterior = () => {
  return (
    <>
      <Header2 />
      <div className="page-content">
        <div className="section-full p-t80 p-b50 mobile-page-padding retro-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 sticky_column for-desktop">
                <div className="about4-section m-b20 bg-gray p-a20 for-interior-style-part">
                  <div className="widget widget_services">
                    <InteriorSideNav />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="section-content">
                  <div className="service-single-block m-b30">
                    <div className="sx-media">
                      <img src="assets/images/modeint.webp" alt="" />
                    </div>
                    <h3 className="m-t30 sx-tilte">
                      <b>Art Deco</b>
                    </h3>

                    <p>
                      One of the most trending theme nowadays is Art Deco. So
                      what does Art Deco actually means. How people pull of this
                      bold yet elegant theme. Art Deco stands forArts
                      Décoratifs, and it consist concept of Rich Colors, Bold
                      Geometrical Shapes, and decadent detailed work. This style
                      is best suitable for people, who are fond of glamour,
                      luxury & elegance. This style reflects symmetrical designs
                      in exuberant shapes that makes the space look more
                      aesthetic.
                    </p>

                    <p>
                      Art Deco combined ultramodern tones with fine artificer
                      and rich accouterments. And it's representing the taste of
                      the ultramodern age and feels simple and pleasing to the
                      eye.
                    </p>

                    <p>
                      This style is in contrast to the new and experimental
                      ideas of art of the period. Which challenged everyday
                      viewers to find meaning and beauty in what were often
                      apologetically anti-traditional images and forms.
                    </p>

                    <p>
                      The art deco style, which overall reflected ultramodern
                      technology, was characterized by smooth lines, geometric
                      shapes, streamlined forms, and bright, occasionally gaudy
                      colors. The accouterments like tableware, demitasse,
                      ivory, wanton, and lacquer, after the Depression also used
                      cheaper and mass-produced accouterments like chrome,
                      plastics, and other artificial particulars feeding the
                      growing middle class's taste for a design style that was
                      elegant, glamorous, and functional.
                    </p>
                    <b>And, here are some features of Art Deco</b>

                    <div className="single-service-list">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <ul className="a-rrt-de-cco">
                            <li>
                              <b>Relative</b>
                            </li>

                            <li>
                              <b>Simplicity</b>
                            </li>

                            <li>
                              <b>Symmetry and unvaried</b>
                            </li>

                            <li>
                              <b>Repetition of Elements</b>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <p>
                      Whenever we decide to design our whole house renovation in
                      terms of decoration & interior. Sometimes we get stuck,
                      it’s just we don’t know where to start. Here comes
                      NaksheWala.com Our professional architect and interior
                      designer focuses on the need and requirements of the
                      client to develop a dream home as per your requirements.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-12 sticky_column for-mobile-interior-view">
                    <div className="about4-section m-b20 bg-gray p-a20">
                      <div className="widget widget_services">
                        <InteriorSideNav />
                      </div>
                    </div>
                  </div>
                  <CommomInteriorCollapse />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>
      <Footer />
    </>
  );
};
export default ArtInterior;
