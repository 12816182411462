import React from 'react';
import { NavLink } from 'react-router-dom';
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";


const logos = [

{ image: require('./../../images/bedroom_area.webp'),
title: 'Modern ',
url: 'modern-interior.php'
},
{ image: require('./../../images/drawing.webp'),
title: 'Minimalist ',
url: 'minimalist-interior.php'
},
{ image: require('./../../images/jewellery.webp'),
title: 'Retro ',
url: 'retro-interior.php'
},
{ image: require('./../../images/gym.webp'),
title: 'Contemporary ',
url: 'contemporary-interior.php'
},
{ image: require('./../../images/banquet_hall.webp'),
title: 'Luxurious',
url: 'luxurious-interior.php'
},
{ image: require('./../../images/dining.webp'),
title: 'Art Deco',
url: 'artdeco.php'
},
    
]
var bgimg1 = require('./../../images/background/bg-12.jpg');

function InteriorSection6() {
   
        return (
            <>
                <div className="section-full desktop-hidden mobile-page-padding bg-gray  p-t20 p-b10 bg-repeat" style={{ backgroundImage: 'url(' + bgimg1.default + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-left">
                               
                                   <h3 className="sep-line-one">Find <span className='red'>Your style</span>  <span className="red v-align-m"><i class="fa fa-long-arrow-right"></i></span></h3>
                            
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="client-grid m-b20">
                                <div className="row for-mobile">
                                    {logos.map((item, index) => (
                                        
                                        <div className="col-lg-3 col-md-4 col-sm-3 col-4 m-b10 p-1" key={index}>
                                        <div className="shadow-cs">
                                            <NavLink to={item.url} className="client-logo-pic">
                                            <LazyLoadImage
                                            className="w-100"
                                            effect="blur"
                                            src={item.image}
                                            alt={item.title}
                                            />
                                                
                                                <div>
                                                    <span><i className="fa fa-search"></i></span>
                                                </div>
                                            </NavLink>
                                            <NavLink to={item.url}><p className='fs-18 fsm-14 interior_area_text bg-white p015 text-for-int-dg-only'>{item.title}</p></NavLink>
                                        </div>
                                        </div>
                                    ))}
                                </div>


                                

                            </div>
                        </div>
                          
                        
                       

                    </div>
                   
                  
                </div>

                <div className="section-full mobile-hidden mobile-page-padding bg-gray  p-t20 p-b10 bg-repeat" style={{ backgroundImage: 'url(' + bgimg1.default + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-left">
                               
                                   <h3 className="sep-line-one">Find <span className='red'>Your style</span>  <span className="red v-align-m"><i class="fa fa-long-arrow-right"></i></span></h3>
                            
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="client-grid m-b20">
                                <div className="row">
                                    {logos.map((item, index) => (
                                        
                                        <div className="col-md-2 m-b10 p-1" key={index}>
                                        <div className="shadow-cs">
                                            <NavLink to={item.url} className="client-logo-pic">
                                            <LazyLoadImage
                                            className="w-100"
                                            effect="blur"
                                            src={item.image}
                                            alt={item.title}
                                            />
                                                <div>
                                                    <span><i className="fa fa-search"></i></span>
                                                </div>
                                            </NavLink>
                                            <NavLink to={item.url}><h3 className='fs-18 interior_area_text bg-white p015'>{item.title}</h3></NavLink>
                                        </div>
                                        </div>
                                    ))}
                                </div>


                                

                            </div>
                        </div>
                          
                        
                       

                    </div>
                   
                  
                </div>
            </>
        );   
};

export default InteriorSection6;

