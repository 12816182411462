import React from "react";
import PtnrBanner from "./../../Elements/PartnerBanner";


var bnrimg = require("./../../../images/banner/ptnrbanner1.png");

const LandingBanner = () => {
 
  return (
    <>

    <PtnrBanner
        title="Generate"
        pagename="Abo"
        description="More Work & Profit"
        title1="While Working With Us."
        bgimage={bnrimg}
      />
     
      
    </>
  );
};

export default LandingBanner;
