import React from 'react';
import Header2 from './../Common/Header2';
import Footer from '../Common/Footer';
import Banner from './../Elements/Banner';
import { NavLink } from 'react-router-dom';

var bnrimg = require('./../../images/banner/Mas.png');

class Sitemap extends React.Component {
    render() {
        return (   
            <>
                <Header2 />
                <div className="page-content">
                    <Banner title="Site Map" pagename="" description="We are a professional architectural and interior designing firm, started by young entrepreneurs keeping a goal in the mind that every house, the building should be designed in the most creative and amazing manner, it should reflect the beauty of the architecture. Obsessed with the idea, the founder created a powerhouse for all types of architecture and interior designing, one platform for all that is “NaksheWala.com”." bgimage={bnrimg.default} />
                    {/* ABOUT TEAM MEMBER SECTION START */}
					
					<div style={{background:"black"}}>
					<div className="container">
					<div className="row">
					<p style={{marginBottom:0}}><a className="site-map-sblk" href="/">Home</a></p>
					<span style={{color:"#c91006",paddingRight:5,paddingLeft:5}}><i class="fa fa-angle-right"></i></span>
					<p style={{color:"white",marginBottom:0}}><a className="site-map-sblk-sec" href="/site-map.php">Sitemap</a></p>   
					</div>
					</div>
					</div>
					
                    <div className="section-full p-tb80 bg-white mobile-page-padding">
                        <div className="container">
                            <div className="section-content ">
                                <div className="about-team-member text-black">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-5 col-sm-12">
                                            <div className="about-single-team-info-left p-a30 sx-bg-secondry">
											
											{/*<div className="profile-image">
                                                    <img src={require('./../../images/our-team5/1.jpg').default} alt="" />
                                             	</div>*/}
                                                {/*<div className="text-black bg-white p-a30">
                                                    <h4 className="m-t0">Johnny Smith</h4>
                                                    <h5 className="m-b0">Architect</h5>
	                                             </div>*/}
												 
                                                <div className="team-single-social m-t30 text-white">
                                                    <ul className="m-b0 list-unstyled">
                                                        <li><span><i className="fa fa-envelope" /></span><span>support@nakshewala.com</span></li>
                                                        <li><span><i className="fa fa-phone" /></span><span>(+91) 801-0822-233</span></li>
                                                        <li><span><i className="fa fa-map-marker" /></span><span>E-53, Ground Floor, E Block, Sector 63, Noida Uttar Pradesh 201301</span></li>
                                                        <li><span><i className="fa fa-globe" /></span><span>www.nakshewala.com</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-7 col-sm-12">
                                            <div className="about-single-team-info">
											
											
											
											
											{/* <div className="our-exp p-b30">
                                                    <div className="sx-box">
                                                        <h3 className="m-t0 m-b30">We Have<span className="sx-text-primary"> 1Years</span> Experience in Architecture &amp; Interior Design.</h3>
                                                    </div>
                                                    <span className="progressText "><b>Architecher</b></span>
                                                    <div className="progress m-b30 m-t10">
                                                        <div className="progress-bar sx-bg-primary progress-bar-striped progress-bar-animated text-black" role="progressbar" style={{ width: '95%' }} aria-valuenow={95} aria-valuemin={0} aria-valuemax={100}>95%</div>
                                                    </div>
                                                    <span className="progressText "><b>Construction</b></span>
                                                    <div className="progress m-b30 m-t10">
                                                        <div className="progress-bar sx-bg-primary progress-bar-striped progress-bar-animated text-black" role="progressbar" style={{ width: '85%' }} aria-valuenow={85} aria-valuemin={0} aria-valuemax={100}>85%</div>
                                                    </div>
                                                    <span className="progressText "><b>Interior</b></span>
                                                    <div className="progress m-b30 m-t10">
                                                        <div className="progress-bar sx-bg-primary progress-bar-striped progress-bar-animated text-black" role="progressbar" style={{ width: '65%' }} aria-valuenow={65} aria-valuemin={0} aria-valuemax={100}>65%</div>
                                                    </div>
	                                      </div>  */}
										  
										  
										  
                                                {/* TITLE START */}
												{/*<div className="section-head">
                                                    <div className="sx-separator-outer separator-left">
                                                        <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>
                                                            <h3 className="sep-line-one">Our Experience</h3>
                                                        </div>
                                                    </div>
	                                            </div>*/}
                                                {/* TITLE END */}
                                                <div className="experience">
												
												
                                                    <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														{/*<div className="our-story-time"><span className="date">01</span><span className="month">Jan</span></div>*/}
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><NavLink to="/15x40-house-designs.php">15 x 40 House Design</NavLink></h4>
                                                            {/*<p>A multitask profession which creates any land in beautiful creation</p>*/}
                                                        </div>
                                                    </div>
													
													
													
                                                   <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/interior-designing.php">Interior Design</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/hotel-and-banquet-hall-interior-designs.php">Hotel and Banquet Hall Interior Designs</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													 <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">House Plan</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/multifamily-apartment-house-plans.php">Multifamily / Apartment House Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													
													 <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/our-packages.php">Our Packages</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													 <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/readymade-house-design.php">Readymade House Design</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/three-floor-house-design.php">Three Floor House Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													 <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/3d-2d-floor-plans.php">3D Floor Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/front-elevation.php">3D Front Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/affordable-house-plans.php">Affordable House Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/customized-house-designs.php">Architectural Drawings</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/askexpert.php">Ask The Expert</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/">Basement House Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/bedroom-interior-designs.php">Bedroom Interior Designs</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/bungalow-house-plans.php">Bungalow House Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="https://www.nakshewala.in/crm/recruitment/recruitment_portal">Career</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/commercial-building-elevation.php">Commercial Building Elevation Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/commercial-floor-plans.php">Commercial Floor Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/completedprojects.php">Completed Projects</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/contact-us.php">Contact Us</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/contemporary-house-elevation.php">Contemporary House Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/corporate-and-office-building-designs.php">Corporate And Office Building Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/customized-3D-Front-elevations.php">Customized Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/customized-floor-plans.php">Customized Floor Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/dining-interior-designs.php">Dining Interior Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/double-storey-elevation.php">Double Story Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/drawing-room-interior-designs.php">Drawing Room Interior Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/duplex-floor-plans.php">Duplex House Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/">Energy Efficient House Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/entrance-interior-design.php">Entrance Interior Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/farm-house-plans.php">Farm House Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/feedback.php">Feedback</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/readymade-floor-plans.php">Floors Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/get_a_free_quote.php">Get a free quote</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/gym-and-yoga-center.php">Gym And Yoga Center Interior Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/hospital-and-clinic-building-designs.php">Hospital And Clinic Building Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/hospital-plans.php">Hospital Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/independent-floor-house-plans.php">Independent Floor House Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/industrial-and-factory-building-designs.php">Industrial And Factory Building Designs</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/institutional-plans.php">Institutional Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/jewellery-showroom-interior-designs.php">Jewellery Showroom Interior Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/hut-shaped-elevation.php">Kerala Style Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/kids-room-interior-designs.php">Kids Room Interior Designs</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/kitchen-interior-designs.php">Kitchen Interior Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/links.php">Links</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/living-room-interior-designs.php">Living Room Interior Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><NavLink to="/modifyyourplans.php">Modify Any Plan</NavLink></h4>
                                                           
                                                        </div>
                                                    </div>

                                                    <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><NavLink to="/viewdetail.php">Modify Plan</NavLink></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/multi-floor-house-design.php">Multi Floor House Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/multi-storey-residential-buildings.php">Multi Storey Residential Buildings Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/">Multi Family House Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/office-interior-designs.php">Office Interior Designs</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/one-side-open-plot-elevations.php">One Side Open Plot Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/">One Story Home Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
                                                   
												   <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><NavLink to="/orderplan.php">Order Plan</NavLink></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/our-clients.php">Our Clients</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/">Package 1</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/">Package 2</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/">Package 3</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/">Package 4(Sample Floor Plan)</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/">Package 5(Sample 3D and 2D Elevation)</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><NavLink to="/payment.php">Payment</NavLink></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><NavLink to="/planinfo.php">Plan Info</NavLink></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/plotting-layouts.php">Plotting Layouts</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/privacy-policy.php">Privacy Policy</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/puja-room-interior-designs.php">Puja Room Interior Designs</a></h4>
                                                           
                                                        </div>
                                                    </div>
												   
												   
												   <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><NavLink to="/recent-project.php">Recent Interior Projects</NavLink></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/recent-interior-designing-project.php">Recent Projects</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/renovation-remodelling.php">Renovation & Remodelling</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/residential-cum-commercial-elevation.php">Residential Cum Commercial Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/restaurant-interior-designs.php">Restaurant Interior Designs</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/restaurant-plans.php">Restaurant Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/school-and-collage-building-designs.php">School And College Building Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/shopping-complex-building-designs.php">Shoping Complex Builging Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/show-room-interior-designs.php">Showroom Interior Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><NavLink to="/showroom-plans.php">Showroom Plans</NavLink></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/simplex-floor-plans.php">Simplex House Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/">Site Map</a></h4>
                                                           
                                                        </div>
                                                    </div>
												   
												   
												   <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/small-house-elevations.php">Small House Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/blog.php">Story Board</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><NavLink to="/submit_testimonial.php">Submit Testimonial</NavLink></h4>
                                                           
                                                        </div>
                                                    </div>
												   
												   <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/terms-and-conditions.php">Terms & Conditions</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/testimonial.php">Testimonial</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/tahnks.php">Thanks</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/toilet-interior-designs.php">Toilet Interior Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/traditional-house-elevations.php">Traditional House Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/triple-storey-elevation.php">Tripal Storey Elevation</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/two-floor-house-design.php">Tow Floor House Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/">Two Story Homes Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/vastu-for-bedrooms.php">Vastu For Bedrooms</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/vastu-for-east-facing-house.php">Vastu For East Facing House</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/vastu-for-interiors.php">Vastu For Interior</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/vastu-for-kitchen.php">Vastu For Kitchen</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/vastu-for-new-house-construction.php">Vastu For New House Construction</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/vastu-for-north-facing-house.php">Vastu For North Facing House</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/vastu-for-pooja-room.php">Vastu For Pooja Room</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/vastu-for-south-facing-house.php">Vastu For South Facing House</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/vastu-for-toilets.php">Vastu For Toilets</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/vastu-for-west-facing-house.php">Vastu For West Facing House</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/vastu-house-plans.php">Vastu House Plans</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/vaastu-consultancy.php">Vastu Shastra</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/video-walkthrough.php">Video Walkthrough</a></h4>
                                                           
                                                        </div>
                                                    </div>
													
													<div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/">Home</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><NavLink to="/why-nakshewala.php">Why NaksheWala.com</NavLink></h4>
                                                           
                                                        </div>
                                                    </div>


                                                    <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/readymade-house-design.php">Readymade House Design</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/single-floor-house-design.php">Single Floor House Design</a></h4>
                                                           
                                                        </div>
                                                    </div>

                                                    <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/readymade-house-design.php">Readymade House Design</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/two-floor-house-design.php">Two Floor House Design</a></h4>
                                                           
                                                        </div>
                                                    </div>

                                                    <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/readymade-house-design.php">Readymade House Design</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/three-floor-house-design.php">Three Floor House Design</a></h4>
                                                           
                                                        </div>
                                                    </div>

                                                    <div className="sx-box our-story">
                                                        <div className="mt-media our-story-info d-flex justify-content-start">
														
                                                            <h4 className="text-uppercase our-story-year"><a className="site-map-lft-links" href="/readymade-house-design.php">Readymade House Design</a> <i class="fa fa-angle-right"></i></h4>
                                                        </div>
                                                        <div className="our-story-detail edit">
                                                            <h4 className="m-b20"><a href="/multi-floor-house-design.php">Multi Floor House Design</a></h4>
                                                           
                                                        </div>
                                                    </div>
												   
												   
													
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ABOUT TEAM MEMBER SECTION END */}
                </div>

                <Footer />
            </>
        );
    };
};

export default Sitemap;