import React from "react";
import Truncate from "react-truncate";
import PropTypes from "prop-types";

//import "./styles.css";

export default class HeaderText extends React.Component {
  constructor(...args) {
    super(...args);

    this.state = {
      expanded: false,
      truncated: false,
    };

    this.handleTruncate = this.handleTruncate.bind(this);
    this.toggleLines = this.toggleLines.bind(this);
  }

  handleTruncate(truncated) {
    if (this.state.truncated !== truncated) {
      this.setState({
        truncated,
      });
    }
  }

  toggleLines(event) {
    event.preventDefault();

    this.setState({
      expanded: !this.state.expanded,
    });
  }
  render() {
    const { more, less, lines } = this.props;

    const { expanded, truncated } = this.state;
    return (
      <div className="App">
        <div>
          <Truncate
            lines={!expanded && lines}
            ellipsis={
              <span>
                ... <p onClick={this.toggleLines}>{more}</p>
              </span>
            }
            onTruncate={this.handleTruncate}
          >
            {this.props.short_description}
          </Truncate>
          {!truncated && expanded && (
            <span>
              {" "}
              <p onClick={this.toggleLines}>{less}</p>
            </span>
          )}
        </div>
      </div>
    );
  }
}

HeaderText.defaultProps = {
  lines: 2,
  more: (
    <div>
      <span className="red cursor-pointer">Read more ▼</span>
    </div>
  ),
  less: (
    <div>
      <span className="red cursor-pointer">Read less ▲</span>
    </div>
  ),
};

HeaderText.propTypes = {
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
};
