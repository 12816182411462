/*eslint jsx-a11y/click-events-have-key-events: "off"*/
/*eslint jsx-a11y/no-noninteractive-element-interactions: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint react-hooks/exhaustive-deps: "off"*/
/*eslint no-lone-blocks: "off"*/
import React from "react";
import { useEffect } from "react";
import axios from "axios";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { userWishList } from "../../../features/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import { clearWishList } from "../../../features/user/userSlice";
import configData from "./../../../config.js";

const SimilarWishList = ({id, item}) => { 
    const { error, onBoarding, wishListStatus } = useSelector(
        (state) => state.user
      );
      const dispatch = useDispatch();
      useEffect(() => {
        if (wishListStatus) {
          toast.success("Service added to wishlist", { autoClose: 2000 });
        }
        if (error) {
          toast.error(error, { autoClose: 3000 });
        }
    
        dispatch(clearWishList());
      }, [wishListStatus, error]);
      const addItemToWishlist = (event) => {
        if (!onBoarding) {
          toast.warning("Please login first", { autoClose: 3000 });
        }
        if (onBoarding) {
          document.getElementById(event.currentTarget.id).classList.add("d-none");
          dispatch(userWishList({ type: 1, id: event.currentTarget.id }));
          document
            .getElementById(`${event.currentTarget.id}-removed`)
            .classList.remove("d-none");
        }
      };

      useEffect(() => {
        async function fetchProducts() {
            ///// wishlist //////////
            if (onBoarding) {
              const config = {
                headers: {
                  Accept: "application/json",
                  "x-api-key": configData.X_API_KEY,
                  "x-api-secret": configData.X_API_SECRET,
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              };
              let listing = await axios.get(
                `${configData.ADMIN_URL}/api/users/wishlist`,
                config
              );
    
              const result = item.data.data.filter((cert) =>
                listing.data.data.find((detail) => detail.id === cert.id)
              );
            
              {
                result.map((list) =>
                  document
                    .getElementById(`${list.id}-removed`)
                    .classList.remove("d-none")
                );
              }
    
              {
                result.map((list) =>
                  document.getElementById(list.id).classList.add("d-none")
                );
              }
            }
            ///// wishlist end//////////
        }
    
        fetchProducts();
      });

      async function removeWishList(event) {
        document.getElementById(event.currentTarget.id).classList.add("d-none");
        var id = event.currentTarget.id.replace("-removed", "");
        document.getElementById(id).classList.remove("d-none");
        try {
          const response = await fetch(
            `${configData.ADMIN_URL}/api/services/wishlist/remove`,
            {
              method: "POST",
              body: JSON.stringify({
                id: Number(id),
              }),
              headers: {
                Accept: "application/json",
                "x-api-key": configData.X_API_KEY,
                "x-api-secret": configData.X_API_SECRET,
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );
          if (!response.ok) {
            toast.error("Please try again", { autoClose: 1000 });
          }
          const result = await response.json();
         
          if (result.status === "success") {
            toast.success("Item Removed Successfully", { autoClose: 1000 });
          }
          
        } catch (err) {
          console.log(err);
          toast.error("Please try again", { autoClose: 1000 });
        }
      }
    return (
        <>
        <div
            id={id}
            onClick={addItemToWishlist}
            className="item-cart-btn"
        >
            {" "}
            <i className="fa fa-heart-o" />
        </div>
        <div
            id={`${id}-removed`}
            onClick={removeWishList}
            className="item-cart-btn wishlist-added d-none"
        >
            {" "}
            <i className="fa fa-heart-o" />
        </div>
        
        </>
    )
}

export default SimilarWishList;