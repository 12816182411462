import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
//import Banner from './../Elements/Banner';
import { NavLink } from 'react-router-dom';


//var bnrimg = require('./../../images/banner/hdr_bnr5ea914d89692bTerms_&_Conditions.jpg');
//var bgimg1 = require('./../../images/video-bg.jpg');
//var bgimg3 = require('./../../images/background/bg-12.jpg');
//var bgimg4 = require('./../../images/background/cross-line2.png');



class Terms extends React.Component {

   

    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };
    render() {
        return (
            <>
                <Header2 />
                <div className="page-content">
                   
                    <div className="section-full mobile-page-padding p-t40 p-b50 trms-and-con-dt">
                    <div className="container">
                      <div className="text-center"><img className="w-35" src="assets/images/terms-law-illustration.webp" alt="" style={{width: "35%"}}/></div>
                        <div className="section-content">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="sx-separator-outer separator-center">
                                <div className=" mb-2">
                                    
                                    <h2 class="mb-3 heading-h2">Terms & <span className="red">Conditions</span></h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}

                         <div className="section-content">
                            <p>The following Terms and conditions represent the rules and regulations for the use of NaksheWala.com website. By accessing our website we assume that you accept these terms & conditions fully. </p>
                            <h4>Communication Policy:</h4>
                            <p>Providing your contact details to us by any means, you are agreed to receive emails, phone calls, or sms from Nakshewala.com representatives.</p>
                            <h4>Refund Policy:</h4>
                            <p>NakhseWala.com takes every House Design project very seriously and we ensure that the same is to be handled in a professional manner and the client gets the result as per our promise to him. However, if any client is not happy with the outcome service, have gone through the Change Option, we do not entertain refund request reason being that we are a service provider, but for the convenience of the client we have given them change options as per the services hired by the customers.</p>
                            <h4>Local Codes and building regulations:</h4>
                            <p>Every country, state, city and municipality does have its own building regulations, bylaws, zoning requirements, codes and ordinances. Therefore, your plan may need to be modified to comply with local building requirements. Drawings will be as per Bye-laws provided by client and all the responsibility shall lie with him/her only.</p>
                            <h4>Delivery Policy:</h4>
                            <p>Drawing deliveries are subjected to:</p>

                            <h4>Timely payments: </h4>
                            <p>The drawings will be sent only after successfully receiving the payments as per agreed payment terms.</p>
                            <p>Timely communication from the client: If required information for successful completion of the project is not communicated to us in a timely manner. Any delay or failure of the project arising out of such situations cannot be attributed to NaksheWala.com.</p>



                            <h4>Completeness of information provided by the client: </h4>
                            <p>If the information provided to us is incomplete and/or complete information/scope of the project (service and/or product) is not provided at the beginning of the project, NaksheWala.com will not be bound to maintain its delivery time</p>

                            <p>Compensation for the delay of delivery: NaksheWala.com will not make any compensation for the delay of delivery under any circumstances, unless there is a special agreement signed or Committed with penalty clause for delay in delivery.</p>


                            <h4>Delivery Schedule: </h4>
                            <p>After Successful receiving of payment and enough detail of the project from the client, Plan will be send (email) within 72 hours.</p>

                            <h4>Licence</h4>
                            <p>Unless until stated, Nakshewala.com and/or it’s licensors own the all property rights for all material or products available on Nakshewala.com. All rights are reserved by NakshWala.com. You may view and/or print pages from https://www.nakshewala.com/ for your own personal use subject to restrictions set in these terms and conditions.</p>
                            <p>You must not:</p>

                            <p>Republish any material from <NavLink className="red" to="./">https://www.nakshewala.com</NavLink></p>
                            <p>Sell, rent or sub-license material from <NavLink className="red" to="./">https://www.nakshewala.com</NavLink></p>
                            <p>Reproduce, duplicate or copy material from <NavLink className="red" to="./">https://www.nakshewala.com</NavLink></p>
                            <p>Redistribute content from NaksheWala.com (unless or until content/campaign is specifically made for redistribution)</p>
                            <p>For any query or clarification feel free to contact:</p>
                             <p>Email: <a className="red" href="mailto:Support@NaksheWala.com">Support@NaksheWala.com</a></p>

                             <p>Phone: <a className="red" href="tel:+91-8010822233">+91-8010822233</a></p>

 


                    </div>
                        
                       </div>
                     </div>
                
                   
                  
                    
                </div>
                </div>

                <Footer />
            </>
        );
    };
};

export default Terms;