/*eslint jsx-a11y/click-events-have-key-events: "off"*/
/*eslint jsx-a11y/no-noninteractive-element-interactions: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint react-hooks/exhaustive-deps: "off"*/
/*eslint no-lone-blocks: "off"*/
import React from "react";
import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import { slide as Menu } from "react-burger-menu";
import Tabs, { TabPane } from "rc-tabs";
import ServiceBottom from "./../../Elements/ServiceBottom";
import axios from "axios";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import InteriorSection1 from "./../../Elements/InteriorSection1";
import Services from "./../../Elements/custom-shimmers/Services";
import { userWishList } from "../../../features/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import { clearWishList } from "../../../features/user/userSlice";
import StickyBox from "react-sticky-box";
import configData from "./../../../config.js";

var url = `${configData.ADMIN_URL}//assets/images/banner/`;
var default_url = `${configData.ADMIN_URL}/assets/gallery/`;

const CommercialBuildingElevation = ({ match }) => {
  //alert(configData.ADMIN_URL);
  // const notify = () => toast("Wow so easy!");
  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const openMenu = () => {
    //console.log("clicked");
    setOpen(true);
  };

  let history = useHistory();
  const clearFilters = () => {
    localStorage.removeItem("size");
    localStorage.removeItem("direction");
    localStorage.removeItem("unit");
    localStorage.removeItem("plotSize");
    localStorage.removeItem("plan_no");
    localStorage.removeItem("minWidth");
    localStorage.removeItem("maxWidth");
    localStorage.removeItem("maxDepth");
    localStorage.removeItem("minDepth");
    localStorage.removeItem("filterResult");

    toast.success("Filter Refreshed Successfully!", { autoClose: 3000 });
    //alert('Filter Refreshed Successfully!');
    let path = `/commercial-building-elevation.php`;
    history.push(path);
    history.go(0);
    //window.location.reload(true);
  };

  window.onunload = function () {
    localStorage.removeItem("size");
    localStorage.removeItem("direction");
    localStorage.removeItem("unit");
    localStorage.removeItem("plotSize");
    localStorage.removeItem("plan_no");
    localStorage.removeItem("minWidth");
    localStorage.removeItem("maxWidth");
    localStorage.removeItem("maxDepth");
    localStorage.removeItem("minDepth");
    localStorage.removeItem("filterResult");
  };

  var pageName = window.location.pathname.replace("/", "");
  const [seo, setSeo] = useState([]);
  const [todos, setTodos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loginRedirection, setLoginRedirection] = useState(false);
  const [totalTodos, setTotalTodos] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(12);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const [plan_no, setPlan_no] = useState("");
  const [direction, setDirection] = useState("");
  const [unit, setUnit] = useState("");
  const [size, setSize] = useState("");
  const [plotSize, setPlotSize] = useState("");
  const [minWidth, setMinWidth] = useState("");
  const [maxWidth, setMaxWidth] = useState("");
  const [minDepth, setMinDepth] = useState("");
  const [maxDepth, setMaxDepth] = useState("");
  const [message, setMessage] = useState("");
  // const [checked, setChecked] = useState(true);
  //const [unChecked, setUnChecked] = useState("");
  const [paginateUrl, setPaginateUrl] = useState("");
  // const [current, setCurrent] = useState("");
  //contact form

  //dynamic filters
  const [filters, setFilters] = useState([]);
  //  const [filterResult, setFilterResult] = useState("");
  const [appendFilter, setAppendFilter] = useState([]);
  //Getting Url Params
  const search = useLocation().search;
  const sizeParams = new URLSearchParams(search).get("psize");
  const plan_noParams = new URLSearchParams(search).get("dcode");
  const directionParams = new URLSearchParams(search).get("direction");
  const unitParams = new URLSearchParams(search).get("unit");
  const plotSizeParams = new URLSearchParams(search).get("plotSize");
  const minWidthParams = new URLSearchParams(search).get("pwMin");
  const maxWidthParams = new URLSearchParams(search).get("pwMax");
  const minDepthParams = new URLSearchParams(search).get("pdMin");
  const maxDepthParams = new URLSearchParams(search).get("pdMax");
  const currenPageParms = new URLSearchParams(search).get("currentpage");
  const [page_content, setPage_content] = useState("");
  const dynamicFilterParms = new URLSearchParams(search).get("dynamic_filter");
  const onKeyDown = (e) => {
    document.getElementById("plan_no").placeholder = "";
    document.getElementById("plans_no").placeholder = "";
  };
  //////////// Redux /////////////////////
  const { error, onBoarding, wishListStatus } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (wishListStatus) {
      toast.success("Service added to wishlist", { autoClose: 2000 });
    }
    if (error) {
      toast.error(error, { autoClose: 3000 });
    }

    dispatch(clearWishList());
  }, [wishListStatus, error]);
  const addItemToWishlist = (event) => {
    if (!onBoarding) {
      setLoginRedirection(true);
      toast.warning("Please login first", { autoClose: 3000 });
    }
    if (onBoarding) {
      document.getElementById(event.currentTarget.id).classList.add("d-none");
      // alert(window.location.search)
      // if(location.pathname === "/commercial-building-elevation.php") {
      // history.push('/commercial-building-elevation.php')
      // }
      // else {
      //  history.push(window.location.search)
      // }
      dispatch(userWishList({ type: 2, id: event.currentTarget.id }));
      document
        .getElementById(`${event.currentTarget.id}-removed`)
        .classList.remove("d-none");
    }
  };
  //////////// Redux End /////////////////////
  //DYNMIC FILTERS API CALL
  useEffect(() => {
    async function fetchData() {
      try {
        let item = await axios.get(
          `${configData.ADMIN_URL}/api/filter-categories`
        );
        setFilters(item.data);
        setitemsPerPage(12);
        setpageNumberLimit(5);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const location = useLocation();

  useEffect(() => {
    async function fetchProducts() {
      if (location.pathname === "/commercial-building-elevation.php") {
        document.getElementById("0x1000").checked = false;
        document.getElementById("1000x2000").checked = false;
        document.getElementById("2000x3000").checked = false;
        document.getElementById("3000x4000").checked = false;
        document.getElementById("4000x6000").checked = false;
        document.getElementById("6000x100000").checked = false;
        document.getElementById("East").checked = false;
        document.getElementById("West").checked = false;
        document.getElementById("South").checked = false;
        document.getElementById("North").checked = false;
        document.getElementById("North-East").checked = false;
        document.getElementById("North-West").checked = false;
        document.getElementById("South-East").checked = false;
        document.getElementById("South-West").checked = false;
        document.getElementById("1").checked = false;
        document.getElementById("2").checked = false;
        document.getElementById("3").checked = false;
        document.getElementById("4").checked = false;
        document.getElementById("5").checked = false;
      }

      if (sizeParams) {
        if (sizeParams !== "null") {
          document.getElementById(sizeParams).checked = "checked";
        }
      }

      if (unitParams) {
        if (unitParams !== "null") {
          document.getElementById(unitParams).checked = "checked";
        }
      }

      if (directionParams) {
        if (directionParams !== "null") {
          document.getElementById(directionParams).checked = "checked";
        }
      }

      if (plotSizeParams) {
        if (plotSizeParams !== "null") {
          document.getElementById(plotSizeParams).checked = "checked";
        }
      }

      if (currenPageParms) {
       
        if (currenPageParms !== "null") {
          setcurrentPage(currenPageParms);
        
        }
      }

      if (dynamicFilterParms === null) {
        var dFilter = "";
      } else {
        var dFilter = dynamicFilterParms.toString();
        //const dFilterChecked = document.getElementById("d-filter-"+dFilter).checked = "checked";
      }

      setLoading(true);

      try {
        let item = await axios.get(
          `${configData.ADMIN_URL}/api/commercialBuilding?currentpage=${
            currenPageParms ? currenPageParms : 1
          }&psize=${sizeParams ? sizeParams : ""}&direction=${
            directionParams ? directionParams : ""
          }&sort=&dcode=${plan_noParams ? plan_noParams : ""}&unit=${
            unitParams ? unitParams : ""
          }&plotSize=${plotSizeParams ? plotSizeParams : ""}&pwMin=${
            minWidthParams ? minWidthParams : ""
          }&pwMax=${maxWidthParams ? maxWidthParams : ""}&pdMin=${
            minDepthParams ? minDepthParams : ""
          }&pdMax=${maxDepthParams ? maxDepthParams : ""}&dynamic_filter=${
            dFilter ? dFilter : ""
          }`
        );
        setPaginateUrl(
          `?slct_page=commercial-building-elevation.php&a=1&psize=${
            sizeParams ? sizeParams : ""
          }&direction=${directionParams ? directionParams : ""}&sort=&dcode=${
            plan_noParams ? plan_noParams : ""
          }&unit=${unitParams ? unitParams : ""}&plotSize=${
            plotSizeParams ? plotSizeParams : ""
          }&pwMin=${minWidthParams ? minWidthParams : ""}&pwMax=${
            maxWidthParams ? maxWidthParams : ""
          }&pdMin=${minDepthParams ? minDepthParams : ""}&pdMax=${
            maxDepthParams ? maxDepthParams : ""
          }&dynamic_filter=${dFilter ? dFilter : ""}`
        );
        setTodos(item.data.data);
       
        setTotalTodos(item.data.total);
        //SEO DATA
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=${pageName}`
        );
        setSeo(pageSeo.data);
       
        setPage_content(pageSeo.data[0].page_content);

        setLoading(false);

        ///// wishlist //////////
        if (onBoarding) {
          const config = {
            headers: {
              Accept: "application/json",
              "x-api-key": configData.X_API_KEY,
              "x-api-secret": configData.X_API_SECRET,
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          let listing = await axios.get(
            `${configData.ADMIN_URL}/api/users/wishlist`,
            config
          );
          // setWishList(listing.data.data)
          
          const result = item.data.data.filter((cert) =>
            listing.data.data.find((detail) => detail.id === cert.gid)
          );
          
          {
            result.map((list) =>
              document
                .getElementById(`${list.gid}-removed`)
                .classList.remove("d-none")
            );
          }

          {
            result.map((list) =>
              document.getElementById(list.gid).classList.add("d-none")
            );
          }
        }
        ///// wishlist end//////////
      } catch (error) {
        console.log(error);
      }
    }

    fetchProducts();
  }, [location.key]);

  async function removeWishList(event) {
    document.getElementById(event.currentTarget.id).classList.add("d-none");
    var id = event.currentTarget.id.replace("-removed", "");
    document.getElementById(id).classList.remove("d-none");
    //var id = event.currentTarget.id.replace('-removed','');
    try {
      const response = await fetch(
        `${configData.ADMIN_URL}/api/services/wishlist/remove`,
        {
          method: "POST",
          body: JSON.stringify({
            id: Number(id),
          }),
          headers: {
            Accept: "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) {
        toast.error("Please try again", { autoClose: 1000 });
        
      }
      const result = await response.json();
    
      //alert("item deleted")
      if (result.status === "success") {
        toast.success("Item Removed Successfully", { autoClose: 1000 });
        // if(location.pathname === "/commercial-building-elevation.php") {
        // history.push('/commercial-building-elevation.php')
        // }
        // else {
        // history.push(window.location.search)
        //  }
      }
     
    } catch (err) {
      console.log(err);
      toast.error("Please try again", { autoClose: 1000 });
    }
  }

  async function value1(event) {
    var size = event.target.value;
    localStorage.setItem("size", size);
    history.push(
      `?slct_page=commercial-building-elevation.php&a=1&psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&direction=${
        localStorage.getItem("direction")
          ? localStorage.getItem("direction")
          : ""
      }&sort=&dcode=${plan_no}&unit=${
        localStorage.getItem("unit") ? localStorage.getItem("unit") : ""
      }&plotSize=${
        localStorage.getItem("plotSize") ? localStorage.getItem("plotSize") : ""
      }&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercialBuilding?psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&direction=${
        localStorage.getItem("direction")
          ? localStorage.getItem("direction")
          : ""
      }&sort=&dcode=${plan_no}&unit=${
        localStorage.getItem("unit") ? localStorage.getItem("unit") : ""
      }&plotSize=${
        localStorage.getItem("plotSize") ? localStorage.getItem("plotSize") : ""
      }&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    setTodos(item.data.data);
    setTotalTodos(item.data.total);
    setMessage(item.data.message);
  }

  async function value2(event) {
    var direction = event.target.value;
    localStorage.setItem("direction", direction);
    history.push(
      `?slct_page=commercial-building-elevation.php&a=1&psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&direction=${
        localStorage.getItem("direction")
          ? localStorage.getItem("direction")
          : ""
      }&sort=&dcode=${plan_no}&unit=${
        localStorage.getItem("unit") ? localStorage.getItem("unit") : ""
      }&plotSize=${
        localStorage.getItem("plotSize") ? localStorage.getItem("plotSize") : ""
      }&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercialBuilding?psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&direction=${
        localStorage.getItem("direction")
          ? localStorage.getItem("direction")
          : ""
      }&sort=&dcode=${plan_no}&unit=${
        localStorage.getItem("unit") ? localStorage.getItem("unit") : ""
      }&plotSize=${
        localStorage.getItem("plotSize") ? localStorage.getItem("plotSize") : ""
      }&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    setTodos(item.data.data);
    setTotalTodos(item.data.total);
    setMessage(item.data.message);
  }

  async function value3(event) {
    var plotSize = event.target.value;
    localStorage.setItem("plotSize", plotSize);
    history.push(
      `?slct_page=commercial-building-elevation.php&a=1&psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&direction=${
        localStorage.getItem("direction")
          ? localStorage.getItem("direction")
          : ""
      }&sort=&dcode=${plan_no}&unit=${
        localStorage.getItem("unit") ? localStorage.getItem("unit") : ""
      }&plotSize=${
        localStorage.getItem("plotSize") ? localStorage.getItem("plotSize") : ""
      }&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercialBuilding?psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&direction=${
        localStorage.getItem("direction")
          ? localStorage.getItem("direction")
          : ""
      }&sort=&dcode=${plan_no}&unit=${
        localStorage.getItem("unit") ? localStorage.getItem("unit") : ""
      }&plotSize=${
        localStorage.getItem("plotSize") ? localStorage.getItem("plotSize") : ""
      }&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    setTodos(item.data.data);
    setTotalTodos(item.data.total);
    setMessage(item.data.message);
  }

  async function value4(event) {
    var unit = event.target.value;
    localStorage.setItem("unit", unit);
    history.push(
      `?slct_page=commercial-building-elevation.php&a=1&psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&direction=${
        localStorage.getItem("direction")
          ? localStorage.getItem("direction")
          : ""
      }&sort=&dcode=${plan_no}&unit=${
        localStorage.getItem("unit") ? localStorage.getItem("unit") : ""
      }&plotSize=${
        localStorage.getItem("plotSize") ? localStorage.getItem("plotSize") : ""
      }&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercialBuilding?psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&direction=${
        localStorage.getItem("direction")
          ? localStorage.getItem("direction")
          : ""
      }&sort=&dcode=${plan_no}&unit=${
        localStorage.getItem("unit") ? localStorage.getItem("unit") : ""
      }&plotSize=${
        localStorage.getItem("plotSize") ? localStorage.getItem("plotSize") : ""
      }&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    setTodos(item.data.data);
    setTotalTodos(item.data.total);
    setMessage(item.data.message);
  }

  async function value5(event) {
    //console.log(plan_no);
    localStorage.setItem("plan_no", plan_no);

    localStorage.setItem("maxWidth", maxWidth);
    localStorage.setItem("minWidth", minWidth);

    localStorage.setItem("maxDepth", maxDepth);
    localStorage.setItem("minDepth", minDepth);

    event.preventDefault();
    history.push(
      `?slct_page=commercial-building-elevation.php&a=1&psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&direction=${
        localStorage.getItem("direction")
          ? localStorage.getItem("direction")
          : ""
      }&sort=&dcode=${plan_no}&unit=${
        localStorage.getItem("unit") ? localStorage.getItem("unit") : ""
      }&plotSize=${
        localStorage.getItem("plotSize") ? localStorage.getItem("plotSize") : ""
      }&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercialBuilding?psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&direction=${
        localStorage.getItem("direction")
          ? localStorage.getItem("direction")
          : ""
      }&sort=&dcode=${plan_no}&unit=${
        localStorage.getItem("unit") ? localStorage.getItem("unit") : ""
      }&plotSize=${
        localStorage.getItem("plotSize") ? localStorage.getItem("plotSize") : ""
      }&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    setOpen(false);
    setTodos(item.data.data);
    setTotalTodos(item.data.total);
    setMessage(item.data.message);
   
  }

  //Mobile Apply Filter
  async function HandleSubmit(event) {
    event.preventDefault();
    history.push(
      `?slct_page=commercial-building-elevation.php&a=1&psize=${size}&direction=${direction}&sort=&dcode=${plan_no}&unit=${unit}&plotSize=${plotSize}&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    let item = await axios.get(
      `${
        configData.ADMIN_URL
      }/api/commercialBuilding?plan_no=${plan_no}&direction=${direction}&psize=${size}&unit=${unit}&plotSize=${plotSize}&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    setOpen(false);
    setTodos(item.data.data);
    setTotalTodos(item.data.total);
    setMessage(item.data.message);
  }

  //Setting dynamic filters onclick event
  async function appendNewFilter(index, id, key) {
    appendFilter.push(id);
    setAppendFilter(appendFilter);
    //Remove duplicate value
    const toFindDuplicates = (appendFilter) =>
      appendFilter.filter(
        (item, index) => appendFilter.indexOf(item) !== index
      );
    const duplicateElements = toFindDuplicates(appendFilter);
    
    function removeFromArray(original, remove) {
      return original.filter((value) => !remove.includes(value));
    }
    let uniqueFilters = removeFromArray(appendFilter, duplicateElements);
    setAppendFilter(uniqueFilters);
    var filterResult = appendFilter.toString();
    localStorage.setItem("filterResult", filterResult);
    history.push(
      `?slct_page=commercial-building-elevation.php&a=1&psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&direction=${
        localStorage.getItem("direction")
          ? localStorage.getItem("direction")
          : ""
      }&sort=&dcode=${plan_no}&unit=${
        localStorage.getItem("unit") ? localStorage.getItem("unit") : ""
      }&plotSize=${
        localStorage.getItem("plotSize") ? localStorage.getItem("plotSize") : ""
      }&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${uniqueFilters}`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercialBuilding?psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&direction=${
        localStorage.getItem("direction")
          ? localStorage.getItem("direction")
          : ""
      }&sort=&dcode=${plan_no}&unit=${
        localStorage.getItem("unit") ? localStorage.getItem("unit") : ""
      }&plotSize=${
        localStorage.getItem("plotSize") ? localStorage.getItem("plotSize") : ""
      }&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${uniqueFilters}`
    );
    setTodos(item.data.data);
    setTotalTodos(item.data.total);
    setMessage(item.data.message);
  }

  const pages = [];
  for (let i = 1; i <= Math.ceil(totalTodos / itemsPerPage); i++) {
    pages.push(i);
  }

  //console.log(pages.length);

  //const indexOfLastItem = currentPage * itemsPerPage;
  //const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //const currentItems = todos.slice(indexOfFirstItem, indexOfLastItem);

  // Get current Todo

  // Change page
  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={() => paginate(number)}
          className={currentPage === number ? "active" : null}
        >
          <NavLink
            onClick={() => paginate(number)}
            to={paginateUrl + "&currentpage=" + number}
            className="page-link"
          >
            {number}
          </NavLink>
        </li>
      );
    } else {
      return null;
    }
  });

  async function handleNextbtn(event) {
    localStorage.setItem("plan_no", plan_no);
    localStorage.setItem("maxWidth", maxWidth);
    localStorage.setItem("minWidth", minWidth);
    localStorage.setItem("maxDepth", maxDepth);
    localStorage.setItem("minDepth", minDepth);
    event.preventDefault();
    history.push(
      `?slct_page=commercial-building-elevation.php&a=1&psize=${
        sizeParams ? sizeParams : ""
      }&direction=${directionParams ? directionParams : ""}&sort=&dcode=${
        plan_noParams ? plan_noParams : ""
      }&unit=${unitParams ? unitParams : ""}&plotSize=${
        plotSizeParams ? plotSizeParams : ""
      }&pwMin=${minWidthParams ? minWidthParams : ""}&pwMax=${
        maxWidthParams ? maxWidthParams : ""
      }&pdMin=${minDepthParams ? minDepthParams : ""}&pdMax=${
        maxDepthParams ? maxDepthParams : ""
      }&dynamic_filter=${appendFilter.toString()}&currentpage=${
        Number(currentPage) + 1
      }`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercialBuilding?psize=${
        sizeParams ? sizeParams : ""
      }&direction=${directionParams ? directionParams : ""}&sort=&dcode=${
        plan_noParams ? plan_noParams : ""
      }&unit=${unitParams ? unitParams : ""}&plotSize=${
        plotSizeParams ? plotSizeParams : ""
      }&pwMin=${minWidthParams ? minWidthParams : ""}&pwMax=${
        maxWidthParams ? maxWidthParams : ""
      }&pdMin=${minDepthParams ? minDepthParams : ""}&pdMax=${
        maxDepthParams ? maxDepthParams : ""
      }&dynamic_filter=${appendFilter.toString()}&currentpage=${
        Number(currentPage) + 1
      }`
    );
    setTodos(item.data.data);
    setcurrentPage(Number(currentPage) + 1);
    setTotalTodos(item.data.total);
    setMessage(item.data.message);
    

    if (Number(currentPage) + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  }

  async function handlePrevbtn(event) {
    localStorage.setItem("plan_no", plan_no);
    localStorage.setItem("maxWidth", maxWidth);
    localStorage.setItem("minWidth", minWidth);
    localStorage.setItem("maxDepth", maxDepth);
    localStorage.setItem("minDepth", minDepth);
    event.preventDefault();
    history.push(
      `?slct_page=commercial-building-elevation.php&a=1&psize=${
        sizeParams ? sizeParams : ""
      }&direction=${directionParams ? directionParams : ""}&sort=&dcode=${
        plan_noParams ? plan_noParams : ""
      }&unit=${unitParams ? unitParams : ""}&plotSize=${
        plotSizeParams ? plotSizeParams : ""
      }&pwMin=${minWidthParams ? minWidthParams : ""}&pwMax=${
        maxWidthParams ? maxWidthParams : ""
      }&pdMin=${minDepthParams ? minDepthParams : ""}&pdMax=${
        maxDepthParams ? maxDepthParams : ""
      }&dynamic_filter=${appendFilter.toString()}&currentpage=${
        Number(currentPage) - 1
      }`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercialBuilding?psize=${
        sizeParams ? sizeParams : ""
      }&direction=${directionParams ? directionParams : ""}&sort=&dcode=${
        plan_noParams ? plan_noParams : ""
      }&unit=${unitParams ? unitParams : ""}&plotSize=${
        plotSizeParams ? plotSizeParams : ""
      }&pwMin=${minWidthParams ? minWidthParams : ""}&pwMax=${
        maxWidthParams ? maxWidthParams : ""
      }&pdMin=${minDepthParams ? minDepthParams : ""}&pdMax=${
        maxDepthParams ? maxDepthParams : ""
      }&dynamic_filter=${appendFilter.toString()}&currentpage=${
        Number(currentPage) - 1
      }`
    );
    setTodos(item.data.data);
    setcurrentPage(Number(currentPage) - 1);
    setTotalTodos(item.data.total);
    setMessage(item.data.message);
   

    if ((Number(currentPage) - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  }

  /**const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };**/

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  return (
    <>
      {seo.map((item, index) => (
        <Helmet key={index}>  
        <title>{item.pagetitle}</title>
        <meta name="description" content={item.metadescription} />
        <meta name="keywords" content={item.metakeywords} />
        <meta property="og:type" content="Page" />
        <meta property="og:title" content={item.pagetitle} />
        <meta property="og:description" content={item.metadescription} />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={`${configData.CANONICAL_URL}commercial-building-elevation.php`} />
        <meta property="og:site_name" content={configData.SITE_NAME} />
        <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_banner}`} />
        </Helmet> 
      ))}

      <Header2 />

      <div className="page-content">
        {seo.map((item, index) => (
          <InteriorSection1
            page_name={item.page_name}
            short_des_heading={item.short_des_heading}
            sub_title={item.sub_title}
            short_description={item.short_description}
          />
        ))}

        {/* SHOP SECTION START */}
        <div className="section-full bg-white mobile-page-padding">
          <div className="p-3 desktop-hidden bg-gray3">
            <NavLink to={"./"}> Home </NavLink> {">"}{" "}
            {seo.map((item, index) => (
              <span className="red fw-500">Commercia Building Elevation</span>
            ))}{" "}
            <span onClick={openMenu} className="float-right filterBox">
              <i className="fa fa-filter red"></i> Filters
            </span>
          </div>
          <Menu
            noOverlay
            isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}
          >
            <h4>Filters</h4>
            <Tabs tabPosition="left">
              <TabPane tab="Style Type" key="2">
                <div className="widget widget_tag_cloud widget_services">
                  <div className="p-a20 tagcloud">
                    <ul>
                      <li>
                        <NavLink
                          className="styleType"
                          to={"/shopping-complex-building-designs.php"}
                        >
                          Shopping Complex Building Designs
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="styleType"
                          to={"/school-and-collage-building-designs.php"}
                        >
                          School & College Building Designs
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="styleType"
                          to={"/hospital-and-clinic-building-designs.php"}
                        >
                          Hospital & Clinic Building Design{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="styleType"
                          to={"/hotel-and-banquet-building-designs.php"}
                        >
                          Hotel & Banquet Building Design{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="styleType"
                          to={"/industrial-and-factory-building-designs.php"}
                        >
                          Industrial & Factory Building Design{" "}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="styleType"
                          to={"/corporate-and-office-building-designs.php"}
                        >
                          Corporate & Office Building Designs
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane tab="Design Code" key="9">
                <div className="widget widget_services">
                  <ul className="pl-3">
                    <li>
                      <input
                        type="text"
                        id="plans_no"
                        className="form-control design_code_input "
                        name="plan_no"
                        placeholder={plan_noParams ? plan_noParams : plan_no}
                        onKeyDown={onKeyDown}
                        onChange={(e) => setPlan_no(e.target.value)}
                      />

                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary ais-RangeInput-submit"
                          onClick={value5}
                          type="button"
                        >
                          Go
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </TabPane>

              <TabPane tab="Plot Dimensions" key="5">
                <div className="widget widget_services">
                  <ul className="pl-3">
                    <li>
                      <h4 className="widget-title fs-16 mb-2">Width</h4>
                      <div className="d-flex">
                        <input
                          name="minWidth"
                          value={minWidthParams ? minWidthParams : minWidth}
                          onChange={(e) => setMinWidth(e.target.value)}
                          className="w-50 design_code_input mr-0 rounded-0 "
                          type="text"
                          placeholder="Min"
                        />

                        <input
                          name="maxWidth"
                          value={maxWidthParams ? maxWidthParams : maxWidth}
                          onChange={(e) => setMaxWidth(e.target.value)}
                          className="w-50  design_code_input rounded-0 "
                          type="text"
                          placeholder="Max"
                        />
                      </div>
                      <h4 className="widget-title fs-16 mb-2">Depth</h4>
                      <div className="d-flex">
                        <input
                          name="minWidth"
                          value={minDepth}
                          onChange={(e) => setMinDepth(e.target.value)}
                          className="w-50 design_code_input mr-0 rounded-0 "
                          type="text"
                          placeholder="Min"
                        />

                        <input
                          name="maxWidth"
                          value={maxDepth}
                          onChange={(e) => setMaxDepth(e.target.value)}
                          className="w-50  design_code_input rounded-0 "
                          type="text"
                          placeholder="Max"
                        />
                      </div>

                      <input
                        type="submit"
                        className="ais-RangeInput-submit h-35"
                        value="Go"
                        onClick={value5}
                      />
                    </li>
                  </ul>
                </div>
              </TabPane>

              <TabPane tab="Plot Area" key="1">
                <div className="widget widget_services ">
                  <ul className="pl-3">
                    <li>
                      <input
                        onChange={(e) => setSize(e.target.value)}
                        value="0x1000"
                        type="radio"
                        id="0x1000m"
                        name="size"
                      />
                      <label className="fw-400" for="0x1000m">
                        Upto 1000sqft
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        onChange={(e) => setSize(e.target.value)}
                        value="1000x2000"
                        id="1000x2000m"
                        name="size"
                      />
                      <label className="fw-400" for="1000x2000m">
                        1000-2000sqft
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        onChange={(e) => setSize(e.target.value)}
                        value="2000x3000"
                        id="2000x3000m"
                        name="size"
                      />
                      <label className="fw-400" for="2000x3000m">
                        2000-3000sqft
                      </label>
                    </li>
                    <li>
                      <input
                        onChange={(e) => setSize(e.target.value)}
                        value="3000x4000"
                        type="radio"
                        id="3000x4000m"
                        name="size"
                      />
                      <label className="fw-400" for="3000x4000m">
                        {" "}
                        3000-4000sqft
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        onChange={(e) => setSize(e.target.value)}
                        value="4000x6000"
                        id="4000x6000m"
                        name="size"
                      />
                      <label className="fw-400" for="4000x6000m">
                        4000-6000sqft{" "}
                      </label>
                    </li>
                  </ul>
                </div>
              </TabPane>

              <TabPane tab="Direction" key="3">
                <div className="widget widget_tag_cloud widget_services">
                  <div className="tagcloud p-a20">
                    <ul className="">
                      <li>
                        <input
                          type="radio"
                          onChange={(e) => setDirection(e.target.value)}
                          id="Eastm"
                          value="East"
                          name="direction"
                        />
                        <label className="fw-400" for="Eastm">
                          East
                        </label>
                      </li>

                      <li>
                        <input
                          onChange={(e) => setDirection(e.target.value)}
                          value="West"
                          type="radio"
                          id="Westm"
                          name="direction"
                        />
                        <label className="fw-400" for="Westm">
                          West
                        </label>
                      </li>

                      <li>
                        <input
                          onChange={(e) => setDirection(e.target.value)}
                          value="North"
                          type="radio"
                          id="Northm"
                          name="direction"
                        />
                        <label className="fw-400" for="Northm">
                          North
                        </label>
                      </li>

                      <li>
                        <input
                          onChange={(e) => setDirection(e.target.value)}
                          value="South"
                          type="radio"
                          id="Southm"
                          name="direction"
                        />
                        <label className="fw-400" for="Southm">
                          South
                        </label>
                      </li>

                      <li>
                        <input
                          type="radio"
                          onChange={(e) => setDirection(e.target.value)}
                          id="North-Eastm"
                          value="North-East"
                          name="direction"
                        />
                        <label className="fw-400" for="North-Eastm">
                          North-East
                        </label>
                      </li>

                      <li>
                        <input
                          onChange={(e) => setDirection(e.target.value)}
                          value="North-Westm"
                          type="radio"
                          id="North-West"
                          name="direction"
                        />
                        <label className="fw-400" for="North-Westm">
                          North-West
                        </label>
                      </li>

                      <li>
                        <input
                          onChange={(e) => setDirection(e.target.value)}
                          value="South-West"
                          type="radio"
                          id="South-Westm"
                          name="direction"
                        />
                        <label className="fw-400" for="South-Westm">
                          South-West
                        </label>
                      </li>

                      <li>
                        <input
                          onChange={(e) => setDirection(e.target.value)}
                          value="South-East"
                          type="radio"
                          id="South-Eastm"
                          name="direction"
                        />
                        <label className="fw-400" for="South-Eastm">
                          South-East
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane tab="Standard Plot Size" key="7">
                <div className="widget widget_tag_cloud widget_services">
                  <div className="tagcloud bg-white pl-2">
                    <div class="dropdown">
                      <h4 className=" widget-title fs-18 mb-4">
                        Standard Plot Size
                      </h4>

                      <div>
                        <ul className="">
                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="15x40m"
                              value="15x40"
                              name="plotSize"
                            />
                            <label className="fw-400" for="15x40m">
                              15x40 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="15x50m"
                              value="15x50"
                              name="plotSize"
                            />
                            <label className="fw-400" for="15x50m">
                              15x50 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="20x40m"
                              value="20x40"
                              name="plotSize"
                            />
                            <label className="fw-400" for="20x40m">
                              20x40 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="20x50m"
                              value="20x50"
                              name="plotSize"
                            />
                            <label className="fw-400" for="20x50m">
                              20x50 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="25x40m"
                              value="25x40"
                              name="plotSize"
                            />
                            <label className="fw-400" for="25x40m">
                              25x40 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="25x50m"
                              value="25x50"
                              name="plotSize"
                            />
                            <label className="fw-400" for="25x50m">
                              25x50 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="25x60m"
                              value="25x60"
                              name="plotSize"
                            />
                            <label className="fw-400" for="25x60m">
                              25x60 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="30x40m"
                              value="30x40"
                              name="plotSize"
                            />
                            <label className="fw-400" for="30x40m">
                              30x40 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="30x45m"
                              value="30x45"
                              name="plotSize"
                            />
                            <label className="fw-400" for="30x45m">
                              30x45 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="30x50m"
                              value="30x50"
                              name="plotSize"
                            />
                            <label className="fw-400" for="30x50m">
                              30x50 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="30x60m"
                              value="30x60"
                              name="plotSize"
                            />
                            <label className="fw-400" for="30x60m">
                              30x60 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="30x65m"
                              value="30x65"
                              name="plotSize"
                            />
                            <label className="fw-400" for="30x65m">
                              30x65 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="40x50m"
                              value="40x50"
                              name="plotSize"
                            />
                            <label className="fw-400" for="40x50m">
                              40x50 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="40x60m"
                              value="40x60"
                              name="plotSize"
                            />
                            <label className="fw-400" for="40x60m">
                              40x60 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="40x80m"
                              value="40x80"
                              name="plotSize"
                            />
                            <label className="fw-400" for="40x80m">
                              40x80 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="50x60m"
                              value="50x60"
                              name="plotSize"
                            />
                            <label className="fw-400" for="50x60m">
                              50x60 House Designs
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={(e) => setPlotSize(e.target.value)}
                              id="50x90m"
                              value="50x90"
                              name="plotSize"
                            />
                            <label className="fw-400" for="50x90m">
                              50x90 House Designs
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Unit type" key="8">
                <div className="widget widget_tag_cloud widget_services">
                  <div className="tagcloud p-a20 bg-white">
                    <ul>
                      <li>
                        <input
                          onChange={(e) => setUnit(e.target.value)}
                          value="1"
                          type="radio"
                          id="1BHKm"
                          name="unit"
                        />
                        <label className="fw-400" for="1BHKm">
                          1BHK
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          onChange={(e) => setUnit(e.target.value)}
                          value="2"
                          id="2BHKm"
                          name="unit"
                        />
                        <label className="fw-400" for="2BHKm">
                          2BHK
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          onChange={(e) => setUnit(e.target.value)}
                          value="3"
                          id="3BHKm"
                          name="unit"
                        />
                        <label className="fw-400" for="3BHKm">
                          3BHK
                        </label>
                      </li>
                      <li>
                        <input
                          onChange={(e) => setUnit(e.target.value)}
                          value="4"
                          type="radio"
                          id="4BHKm"
                          name="unit"
                        />
                        <label className="fw-400" for="4BHKm">
                          4BHK
                        </label>
                      </li>
                      <li>
                        <input
                          type="radio"
                          onChange={(e) => setUnit(e.target.value)}
                          value="5"
                          id="5BHKm"
                          name="unit"
                        />
                        <label className="fw-400" for="5BHKm">
                          4BHK+{" "}
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane tab="Vastu" key="10">
                {Object.entries(filters).map(([key, item], i) => (
                  <div className="widget widget_tag_cloud widget_services mt--60">
                    <div className="tagcloud p-a20 bg-white ptm-100">
                      <ul className="">
                        <h5 className="widget-title fs-14">{key}</h5>
                        {item.filters.map((data, index) => (
                          <li>
                            <input
                              className="form-control design_code_input"
                              onChange={() => {
                                appendNewFilter(index, data.id, item.name);
                              }}
                              type={item.type}
                              value={data.name}
                              id={data.id}
                              name={item.name}
                            />
                            <label className="fw-400" for={data.id}>
                              {data.name}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))}
              </TabPane>
            </Tabs>
            <div className="bottom-btn">
              <div className="mt-4">
                <a onClick={clearFilters} className="fs-19 v-sub">
                  Reset
                </a>
                <a onClick={HandleSubmit} className="float-right filter-btn">
                  Apply Filters
                </a>
              </div>
            </div>
          </Menu>
          <div className="container">
            <div className="product-filter-wrap d-flex   m-b30"></div>
            <div className="row d-flex justify-content-center">
              {/* SIDE BAR START */}
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 sticky_column mb-10 mobile-hidden">
                <div className="side-bar p-a20 bg-gray3">
                  {/* SEARCH */}

                  {/*PRICE RANGE SLIDER*/}
                  <div className="widget product-range-slider1">
                    <div className="m-b10">
                      <h4 className="widget-title fw-500">
                        Filters{" "}
                        <a
                          onClick={clearFilters}
                          className="clearFilters ais-ClearRefinements-button"
                        >
                          Clear Filters
                        </a>
                      </h4>
                    </div>
                  </div>
                  {/* Categories  */}
                  <form>
                    <div className="widget widget_services ">
                      <ul className="p-a20 bg-white">
                        <h4 className="widget-title fs-18">Plot Area</h4>

                        <li>
                          <input
                            onChange={value1}
                            value="0x1000"
                            type="radio"
                            id="0x1000"
                            name="size"
                          />
                          <label className="fw-400" for="0x1000">
                            Upto 1000sqft
                          </label>
                        </li>
                        <li>
                          <input
                            type="radio"
                            onChange={value1}
                            value="1000x2000"
                            id="1000x2000"
                            name="size"
                          />
                          <label className="fw-400" for="1000x2000">
                            1000-2000sqft
                          </label>
                        </li>
                        <li>
                          <input
                            type="radio"
                            onChange={value1}
                            value="2000x3000"
                            id="2000x3000"
                            name="size"
                          />
                          <label className="fw-400" for="2000x3000">
                            2000-3000sqft
                          </label>
                        </li>
                        <li>
                          <input
                            onChange={value1}
                            value="3000x4000"
                            type="radio"
                            id="3000x4000"
                            name="size"
                          />
                          <label className="fw-400" for="3000x4000">
                            {" "}
                            3000-4000sqft
                          </label>
                        </li>
                        <li>
                          <input
                            type="radio"
                            onChange={value1}
                            value="4000x6000"
                            id="4000x6000"
                            name="size"
                          />
                          <label className="fw-400" for="4000x6000">
                            4000-6000sqft{" "}
                          </label>
                        </li>
                        <li>
                          <input
                            type="radio"
                            onChange={value1}
                            value="6000x100000"
                            id="6000x100000"
                            name="size"
                          />
                          <label className="fw-400" for="6000x100000">
                            6000 & above{" "}
                          </label>
                        </li>
                      </ul>
                    </div>
                    {/* NEWSLETTER */}

                    <div className="widget widget_tag_cloud widget_services">
                      <div className="tagcloud p-a20 bg-white">
                        <h4 className="widget-title fs-18 mb-3">Style Type</h4>
                        <ul>
                          <NavLink
                            className="styleType"
                            to={"/shopping-complex-building-designs.php"}
                          >
                            Shopping Complex Building Designs
                          </NavLink>
                          <NavLink
                            className="styleType"
                            to={"/school-and-collage-building-designs.php"}
                          >
                            School & College Building Designs
                          </NavLink>
                          <NavLink
                            className="styleType"
                            to={"/hospital-and-clinic-building-designs.php"}
                          >
                            Hospital & Clinic Building Design{" "}
                          </NavLink>
                          <NavLink
                            className="styleType"
                            to={"/hotel-and-banquet-building-designs.php"}
                          >
                            Hotel & Banquet Building Design{" "}
                          </NavLink>
                          <NavLink
                            className="styleType"
                            to={"/industrial-and-factory-building-designs.php"}
                          >
                            Industrial & Factory Building Design{" "}
                          </NavLink>
                          <NavLink
                            className="styleType"
                            to={"/corporate-and-office-building-designs.php"}
                          >
                            Corporate & Office Building Designs
                          </NavLink>
                        </ul>
                      </div>
                    </div>

                    <div className="widget widget_tag_cloud widget_services">
                      <div className="tagcloud p-a20 bg-white">
                        <h4 className="widget-title fs-18">Direction</h4>
                        <ul className="">
                          <li>
                            <input
                              type="radio"
                              onChange={value2}
                              id="East"
                              value="East"
                              name="direction"
                            />
                            <label className="fw-400" for="East">
                              East
                            </label>
                          </li>

                          <li>
                            <input
                              onChange={value2}
                              value="West"
                              type="radio"
                              id="West"
                              name="direction"
                            />
                            <label className="fw-400" for="West">
                              West
                            </label>
                          </li>

                          <li>
                            <input
                              onChange={value2}
                              value="North"
                              type="radio"
                              id="North"
                              name="direction"
                            />
                            <label className="fw-400" for="North">
                              North
                            </label>
                          </li>

                          <li>
                            <input
                              onChange={value2}
                              value="South"
                              type="radio"
                              id="South"
                              name="direction"
                            />
                            <label className="fw-400" for="South">
                              South
                            </label>
                          </li>

                          <li>
                            <input
                              type="radio"
                              onChange={value2}
                              id="North-East"
                              value="North-East"
                              name="direction"
                            />
                            <label className="fw-400" for="North-East">
                              North-East
                            </label>
                          </li>

                          <li>
                            <input
                              onChange={value2}
                              value="North-West"
                              type="radio"
                              id="North-West"
                              name="direction"
                            />
                            <label className="fw-400" for="North-West">
                              North-West
                            </label>
                          </li>

                          <li>
                            <input
                              onChange={value2}
                              value="South-West"
                              type="radio"
                              id="South-West"
                              name="direction"
                            />
                            <label className="fw-400" for="South-West">
                              South-West
                            </label>
                          </li>

                          <li>
                            <input
                              onChange={value2}
                              value="South-East"
                              type="radio"
                              id="South-East"
                              name="direction"
                            />
                            <label className="fw-400" for="South-East">
                              South-East
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="widget widget_tag_cloud widget_services">
                      <div className="tagcloud p-a20 bg-white">
                        <h4 className="widget-title fs-18 mb-4">
                          Plot Dimensions
                        </h4>
                        <h4 className="widget-title fs-16 mb-2">Width</h4>
                        <div className="d-flex">
                          <input
                            name="minWidth"
                            value={minWidthParams ? minWidthParams : minWidth}
                            onChange={(e) => setMinWidth(e.target.value)}
                            className="w-50 design_code_input mr-0 rounded-0 "
                            type="text"
                            placeholder="Min"
                          />

                          <input
                            name="maxWidth"
                            value={maxWidthParams ? maxWidthParams : maxWidth}
                            onChange={(e) => setMaxWidth(e.target.value)}
                            className="w-50  design_code_input rounded-0 "
                            type="text"
                            placeholder="Max"
                          />
                        </div>

                        <h4 className="widget-title fs-16 mb-2">Depth</h4>
                        <div className="d-flex">
                          <input
                            name="minWidth"
                            value={minDepth}
                            onChange={(e) => setMinDepth(e.target.value)}
                            className="w-50 design_code_input mr-0 rounded-0 "
                            type="text"
                            placeholder="Min"
                          />

                          <input
                            name="maxWidth"
                            value={maxDepth}
                            onChange={(e) => setMaxDepth(e.target.value)}
                            className="w-50  design_code_input rounded-0 "
                            type="text"
                            placeholder="Max"
                          />
                        </div>

                        <input
                          type="submit"
                          className="ais-RangeInput-submit h-35"
                          value="Go"
                          onClick={value5}
                        />
                      </div>
                    </div>

                    <div className="widget widget_tag_cloud widget_services">
                      <div className="tagcloud p-a10 bg-white">
                        <h4 className="dropdown-toggle widget-title fs-18 mb-4">
                          Standard Plot Size
                        </h4>

                        <div class="dropdown">
                          <ul className="">
                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="15x40"
                                value="15x40"
                                name="plotSize"
                              />
                              <label className="fw-400" for="15x40">
                                15x40 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="15x50"
                                value="15x50"
                                name="plotSize"
                              />
                              <label className="fw-400" for="15x50">
                                15x50 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="20x40"
                                value="20x40"
                                name="plotSize"
                              />
                              <label className="fw-400" for="20x40">
                                20x40 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="20x50"
                                value="20x50"
                                name="plotSize"
                              />
                              <label className="fw-400" for="20x50">
                                20x50 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="25x40"
                                value="25x40"
                                name="plotSize"
                              />
                              <label className="fw-400" for="25x40">
                                25x40 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="25x50"
                                value="25x50"
                                name="plotSize"
                              />
                              <label className="fw-400" for="25x50">
                                25x50 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="25x60"
                                value="25x60"
                                name="plotSize"
                              />
                              <label className="fw-400" for="25x60">
                                25x60 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="30x40"
                                value="30x40"
                                name="plotSize"
                              />
                              <label className="fw-400" for="30x40">
                                30x40 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="30x45"
                                value="30x45"
                                name="plotSize"
                              />
                              <label className="fw-400" for="30x45">
                                30x45 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="30x50"
                                value="30x50"
                                name="plotSize"
                              />
                              <label className="fw-400" for="30x50">
                                30x50 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="30x60"
                                value="30x60"
                                name="plotSize"
                              />
                              <label className="fw-400" for="30x60">
                                30x60 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="30x65"
                                value="30x65"
                                name="plotSize"
                              />
                              <label className="fw-400" for="30x65">
                                30x65 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="40x50"
                                value="40x50"
                                name="plotSize"
                              />
                              <label className="fw-400" for="40x50">
                                40x50 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="40x60"
                                value="40x60"
                                name="plotSize"
                              />
                              <label className="fw-400" for="40x60">
                                40x60 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="40x80"
                                value="40x80"
                                name="plotSize"
                              />
                              <label className="fw-400" for="40x80">
                                40x80 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="50x60"
                                value="50x60"
                                name="plotSize"
                              />
                              <label className="fw-400" for="50x60">
                                50x60 House Designs
                              </label>
                            </li>

                            <li>
                              <input
                                type="radio"
                                onChange={value3}
                                id="50x90"
                                value="50x90"
                                name="plotSize"
                              />
                              <label className="fw-400" for="50x90">
                                50x90 House Designs
                              </label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="widget widget_tag_cloud widget_services">
                      <div className="tagcloud p-a20 bg-white">
                        <ul className=" bg-white">
                          <h4 className="widget-title fs-18">Unit Type</h4>

                          <li>
                            <input
                              onChange={value4}
                              value="1"
                              type="radio"
                              id="1"
                              name="unit"
                            />
                            <label className="fw-400" for="1">
                              1BHK
                            </label>
                          </li>
                          <li>
                            <input
                              type="radio"
                              onChange={value4}
                              value="2"
                              id="2"
                              name="unit"
                            />
                            <label className="fw-400" for="2">
                              2BHK
                            </label>
                          </li>
                          <li>
                            <input
                              type="radio"
                              onChange={value4}
                              value="3"
                              id="3"
                              name="unit"
                            />
                            <label className="fw-400" for="3">
                              3BHK
                            </label>
                          </li>
                          <li>
                            <input
                              onChange={value4}
                              value="4"
                              type="radio"
                              id="4"
                              name="unit"
                            />
                            <label className="fw-400" for="4">
                              4BHK
                            </label>
                          </li>
                          <li>
                            <input
                              type="radio"
                              onChange={value4}
                              value="5"
                              id="5"
                              name="unit"
                            />
                            <label className="fw-400" for="5">
                              4BHK+{" "}
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="widget widget_tag_cloud widget_services">
                      <div className="tagcloud p-a20 bg-white">
                        <h4 className="widget-title fs-18 mb-3">Design Code</h4>

                        <div class="input-group mb-3">
                          <input
                            type="text"
                            id="plan_no"
                            className="form-control design_code_input w-50"
                            name="plan_no"
                            placeholder={
                              plan_noParams ? plan_noParams : "Enter Code"
                            }
                            onKeyDown={onKeyDown}
                            onChange={(e) => setPlan_no(e.target.value)}
                            aria-describedby="basic-addon2"
                          />
                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary ais-RangeInput-submit"
                              onClick={value5}
                              type="button"
                            >
                              Go
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {Object.entries(filters).map(([key, item], i) => (
                      <div className="widget widget_services ">
                        <ul className="p-a20 bg-white">
                          <h4 className="widget-title fs-18">{key}</h4>

                          {item.filters.map((data, index) => (
                            <li>
                              <input
                                className="form-control design_code_input"
                                onClick={() => {
                                  appendNewFilter(index, data.id, item.name);
                                }}
                                type={item.type}
                                value={data.name}
                                id={"d-filter-" + data.id}
                                name={item.name}
                              />
                              <label
                                className="fw-400"
                                for={"d-filter-" + data.id}
                              >
                                {data.name}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </form>
                </div>
              </div>
              {/* SIDE BAR END */}

              <div className=" col-xl-9 col-lg-9 col-md-12 col-sm-12 m-b30 mtm--30">
                <StickyBox offsetTop={80} offsetBottom={20}>
                  {loading ? (
                    <Services />
                  ) : (
                    <>
                      <div className="row">
                        {message ? (
                          <>
                            <div className="text-center">
                              <img
                                alt="no data found"
                                className="w-50"
                                src="assets/images/no-data-empty-data.jpg"
                              />
                              <h3>No Data Found!</h3>
                            </div>
                          </>
                        ) : null}
                        <>
                          {todos.map((item, index) => (
                            <div
                              key={index}
                              className="col-lg-4 col-md-4 col-sm-6 col-6 m-b10 p-2 mm-b30 mobile-grip"
                            >
                              <div className="sx-box sx-product-box overflow-hide shadow-cs">
                                {`${item.sale}` === "yes" ? (
                                  <div className="shop-pro-sale-bnr">Sale!</div>
                                ) : (
                                  ""
                                )}
                                <div className="sx-thum-bx">
                                  <NavLink to={item.slug}>
                                    {item.header_image ? (
                                      <div
                                        className="bg-contain bg-center h-200 bg-no-repeat"
                                        style={{
                                          backgroundImage: `url('${
                                            url + item.header_image
                                          }')`,
                                        }}
                                      />
                                    ) : (
                                      <div
                                        className="bg-contain bg-center h-200 bg-no-repeat"
                                        style={{
                                          backgroundImage: `url('${
                                            default_url + item.image
                                          }')`,
                                        }}
                                      />
                                    )}
                                  </NavLink>
                                  <div className="item-cart-view">
                                    <div className="d-none item-cart-btn">
                                      <NavLink to={"/"}>
                                        <i className="fa fa-cart-plus" />
                                      </NavLink>
                                    </div>
                                    <div
                                      id={item.gid}
                                      onClick={addItemToWishlist}
                                      className="item-cart-btn"
                                    >
                                      {" "}
                                      <i className="fa fa-heart-o" />
                                    </div>
                                    <div
                                      id={`${item.gid}-removed`}
                                      onClick={removeWishList}
                                      className="item-cart-btn wishlist-added d-none"
                                    >
                                      {" "}
                                      <i className="fa fa-heart-o" />
                                    </div>
                                  </div>
                                </div>
                                <div className="p-t10 pl-2 pr-2 text-left">
                                  <h3 className="sx-title fsm-15 fs-16">
                                    <NavLink to={item.slug}>
                                      {item.title}
                                    </NavLink>
                                  </h3>
                                  <ul className=" m-b10 category_info dm-flex">
                                    <li>
                                      <i class="fa fa-expand"></i> {item.width}{" "}
                                      sqft
                                    </li>
                                    <li className="pl-20">
                                      <i className="fa fa-compass" />{" "}
                                      {item.direction} Facing
                                    </li>
                                  </ul>
                                  <p className="category_code_text">
                                    Code: {item.plan_no}{" "}
                                    <NavLink to={item.slug}>
                                      <span className="float-right view_details mobile-hidden">
                                        View details
                                      </span>
                                      <span className="float-right desktop-hidden">
                                        <i className="fa fa-eye"></i>
                                      </span>
                                    </NavLink>
                                  </p>
                                  <span className="price d-none">
                                    {`${item.sale}` === "yes" ? (
                                      <del>
                                        <span>
                                          <span className="Price-currencySymbol">
                                            ${" "}
                                          </span>
                                          {item.cutprice}
                                        </span>
                                      </del>
                                    ) : (
                                      ""
                                    )}
                                    <ins>
                                      <span>
                                        <span className="Price-currencySymbol">
                                          Rs.{" "}
                                        </span>
                                      </span>
                                    </ins>
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      </div>
                    </>
                  )}
                  <div className="text-center">
                    {message ? (
                      ""
                    ) : (
                      <ul className="pageNumbers">
                        <li>
                          <button
                            onClick={handlePrevbtn}
                            disabled={currentPage === pages[0] ? true : false}
                          >
                            <i
                              class="fa fa-long-arrow-left"
                              aria-hidden="true"
                            ></i>{" "}
                            Previous
                          </button>
                        </li>
                        {pageDecrementBtn}
                        {renderPageNumbers}
                        {pageIncrementBtn}

                        <li>
                          <button
                            onClick={handleNextbtn}
                            disabled={
                              currentPage === pages[pages.length - 1]
                                ? true
                                : false
                            }
                          >
                            Next{" "}
                            <i
                              class="fa fa-long-arrow-right"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </li>
                      </ul>
                    )}
                  </div>
                </StickyBox>
              </div>
            </div>
          </div>
        </div>
        {/* SHOP SECTION END */}
      </div>

      <ServiceBottom page_content={page_content} />
      
      
      <Footer loginRedirection={loginRedirection} />
    </>
  );
};

export default CommercialBuildingElevation;
