import React, { Suspense } from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import configData from "./../../../config.js";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import VastuLiving from "./vastuLiving";
import VastuDirection from "./VastuDirection";
import VastuCategories from "./VastuCategories";
import Vastutips from "./Vastutips";
import Recommended from "./../../Elements/Recommended";
import About5 from "./../../Elements/About5";
import HomeCta from "./../../Elements/HomeCta";
import InteriorSection1 from "./../../Elements/InteriorSection1";
var bnrimg = require("./../../../images/Vastu_const.webp");

const VastuConsultancy = () => {
  const [seo, setSeo] = useState([]);
  const [page_content, setPage_content] = useState("");

  //SEO API CALL
  useEffect(() => {
    async function fetchData() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=vaastu-consultancy.php`
        );
        setSeo(pageSeo.data);
        setPage_content(pageSeo.data[0].page_content);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {seo.map((item, index) => (
        <Helmet>
          <title>{item.pagetitle}</title>
          <meta name="description" content={item.metadescription} />
          <meta name="keywords" content={item.metakeywords} />
        </Helmet>
      ))}
      <Header2 />
      <Suspense fallback={<div>Loading...</div>}>
      <>
      <div className="page-content">
        {seo.map((item, index) => (
          <div
            className="sx-bnr-inr overlay-wraper bg-parallax bg-center bg-cover vastu-consultancy-banner"
            style={{ backgroundImage: "url(" + bnrimg + ")" }}
          >
            <InteriorSection1
              className="hello"
              short_description={item.short_description}
              page_name={item.page_name}
              sub_title={item.sub_title}
            />
          </div>
        ))}
        <div className="breadcrum-vastu-main">
          <div className="container">
            <div className="row">
              <p className="breadcrum-paragraph-style">
                <a className="site-map-sblk" href="/">
                  Home
                </a>
              </p>
              <span className="arrow-breadcrum-symbol">
                <i class="fa fa-angle-right"></i>
              </span>
              <p className="main-vastu-breadcrum-heading">
                <a
                  className="site-map-sblk-sec"
                  href="/vastu-consultancy.php"
                >
                  Vastu Shastra
                </a>
              </p>
            </div>
          </div>
        </div>
        
        <VastuLiving />
        <VastuDirection />
        <VastuCategories />
        <HomeCta />
        <Vastutips />
        <Recommended title="Recommended Services" bgcolor="bg-white" />
        <About5 page_content={page_content} />
       
      </div></>
      </Suspense>
      <Footer />
    </>
  );
};

export default VastuConsultancy;
