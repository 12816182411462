import {React, useEffect} from 'react';
import { useHistory } from "react-router-dom";

const Payment = () => {
    const history = useHistory();
    useEffect(() => {
       history.push('/contact-us.php')
      })

    return(
        <></>
    )
}
export default Payment;