/*eslint no-redeclare: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
import React from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios'
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import Profile11 from './../Elements/refermodel'; 
import Sales from '../Elements/ConsultSale'; 
import { logout } from '../../features/user/userSlice'
import configData from "./../../config.js";

 const ProfileSideBar = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    useEffect(() => { 
        async function fetchData() {
         try {
         const config = {
            "headers": {
                "Accept": "application/json",
                "x-api-key": configData.X_API_KEY,
                "x-api-secret": configData.X_API_SECRET,
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('token')}` 
            },
            }
          let item = await axios.get(`${configData.ADMIN_URL}/api/users/me`,config)
          setFirstName(item.data[0].first_name)
          setLastName(item.data[0].last_name)
          if(!item.data[0].email){
            history.push("/register"); 
            }
         } catch (error) {
          console.log(error);
         }
        }
        fetchData();
    }, [history]);
    
    return (
        <div className="">
        <div className="shop-account">
          <div className="account-detail text-center">
            <div className="my-image">
              <a href={'#'}>
                <img
                  alt="profile"
                  src="./assets/images/protstp.webp"
                />
              </a>
            </div>
            <div className="account-title">
              <div className="">
                <h4 className="m-b5">
                  <a href={'#'}>
                    {firstName ? firstName : ""}{" "}
                    {lastName ? lastName : ""}
                  </a>
                </h4>
              </div>
            </div>
          </div>
          <ul className="account-list">
          <li>
              <NavLink to="/profile">
                <i className="fa fa-user"></i>
                <span>Profile</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/favourite-design">
                <i className="fa fa-heart"></i>
                <span>Favorite Designs</span>
              </NavLink>
            </li>
          <li><Profile11 /></li>
            

            <li>
              <NavLink to="/contact-us.php">
                <i className="fa fa-briefcase"></i>
                <span>Help Center</span>
              </NavLink>
            </li>

            <Sales />

            <li  onClick={() => dispatch(logout())}>
            <a href="/login">
                <i className="fa fa-sign-out"></i>
                <span>LogOut</span>
                </a>
            </li>
          </ul>
        </div>
      </div>
    )
 }

 export default ProfileSideBar;
