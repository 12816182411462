import React from 'react';
import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation  } from 'react-router-dom';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import axios from "axios";
import { Helmet } from "react-helmet"; 
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Loader from "./../Elements/Loader";
//config url
import configData from "./../../config.js";

var bannerUrl = `${configData.ADMIN_URL}/assets/images/banner/`;
var bgimg1 = require('./../../images/background/cross-line2.png');

const Blog = ({ match })  => {
     
     let history = useHistory();
     const search = useLocation().search;
     const [seo, setSeo] = useState([]);
     const [latest, setLatest] = useState([]);
     const [todos, setTodos] = useState([]);
     const [loading, setLoading] = useState(false);
     const [totalTodos, setTotalTodos] = useState("");
     const [currentPage, setcurrentPage] = useState(1);
     const [itemsPerPage, setitemsPerPage] = useState(12);
     const [pageNumberLimit, setpageNumberLimit] = useState(5);
     const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
     const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
     const currenPageParms = new URLSearchParams(search).get('currentpage');
    

     const location = useLocation();
    
    //Latest blogs
     useEffect(() => {
        async function fetchProducts() {
         try {
          let item = await axios.get(`${configData.ADMIN_URL}/api/blogs?page=1`)
          setLatest(item.data.data);
          setpageNumberLimit(5)
          setitemsPerPage(12)
         } catch (error) {
          console.log(error);
         }
        }
         fetchProducts();
    }, [location.key]);


     useEffect(() => {
        async function fetchProducts() {
          setLoading(true)
        if(currenPageParms) {
           // console.log(currenPageParms);
          if(currenPageParms !== 'null') {
            setcurrentPage(currenPageParms);
            //console.log(currentPage);
          }

        }

         try {
          let item = await axios.get(`${configData.ADMIN_URL}/api/blogs?page=${currenPageParms ? currenPageParms : 1}`)
          setSeo(item.data.seo);
          setTodos(item.data.data);
          setLoading(false)
         // setBannerImg(item.data.seo.header_banner);
          setTotalTodos(item.data.total);
          setcurrentPage(item.data.current_page);
         } catch (error) {
          console.log(error);
         }
        }
        
        fetchProducts();
    }, [location.key, currenPageParms]);


      const pages = [];
  for (let i = 1; i <= Math.ceil(totalTodos / itemsPerPage); i++) {
    pages.push(i);
  }
  const paginate = pageNumber => setcurrentPage(pageNumber);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={() => paginate(number)}
          className={currentPage === number ? "active" : null}
        >
         <NavLink onClick={() => paginate(number)} to={"blog?currentpage=" + number} className='page-link'>
              {number}
            </ NavLink>
       
        </li>
      );
    } else {
      return null;
    }
  });

  async function handleNextbtn() {
      
      history.push(`blog?currentpage=${Number(currentPage) + 1}`);
      const item = await axios.get(`${configData.ADMIN_URL}/api/blogs?page=${Number(currentPage) + 1}`)
     
     setTodos(item.data.data);
     setTotalTodos(item.data.total);

    setcurrentPage(Number(currentPage) + 1);
    //console.log(Number(currentPage) + 1);

    if (Number(currentPage) + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };


    /**const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };**/

  async function handlePrevbtn()  {

     history.push(`blog?currentpage=${Number(currentPage) - 1}`);
      const item = await axios.get(`${configData.ADMIN_URL}/api/blog_listing/blog?page=${Number(currentPage) - 1}`)
     
     setTodos(item.data.data);
     setTotalTodos(item.data.total);


    setcurrentPage(currentPage - 1);

    if ((Number(currentPage) - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  }; 

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  

   const options = {
            loop: true,
            dotsEach: 3,
            autoplay: false,
            center: false,
              //stagePadding: 100,
            //items: 1,
            margin: 40,
            nav: false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1.2,
                    margin: 20,
                    //dots: true,
                },
                640: {
                    items: 2,
                    margin: 20,
                    //dots: true,
                },
                768: {
                    items: 2,
                    margin: 20,
                    //dots: true,
                },
                991: {
                    items: 3,
                    margin: 20,
                    //dots: true,
                },
                1200: {
                    items: 3,
                     margin: 20,
                     //dots: true,
                }

            }
        };
   

    
        return (
            <>

            {loading ? <Loader /> :

            <>

            {seo.map((item, index) => (

            <Helmet key={index}>  
            <title>{item.pagetitle}</title>
            <meta name="description" content={item.metadescription} />
            <meta name="keywords" content={item.metakeywords} />
            <meta property="og:type" content="Page" />
            <meta property="og:title" content={item.pagetitle} />
            <meta property="og:description" content={item.metadescription} />
            <meta property="og:url" content={window.location.href} />
            <link rel="canonical" href={`${configData.CANONICAL_URL}blog`} />
            <meta property="og:site_name" content={configData.SITE_NAME} />
            <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_banner}`} />
            </Helmet> 

             ))}

                <Header2 />
                <div className="page-content">
                {seo.map((item, index) => (
                    <div className="mobile-hidden sx-bnr-inr overlay-wraper h-400 bg-center bg-cover" style={{ backgroundImage: 'url('+ bannerUrl + item.header_banner + ')' }}>
                    <div class="overlay-main bg-black opacity-05"></div>
                    <div className="container">
                        <div className="sx-bnr-inr-entry">
                            <div className="banner-title-outer text-left">
                                <div className="banner-title-name ">
                                    <h2 className="m-tb0 mb-3">{item.page_name}</h2>   
                                                              
                                    <p className="fs-15 lh-1">
                                        
                                    </p>
                                </div>
                                
                            </div>
                           
                        </div>
                    </div>
                </div>

                   
                     ))}
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb50 inner-page-padding">
                        {/* GALLERY CONTENT START */}
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 mt--20">
                                    <div className="masonry-outer mfp-gallery news-grid clearfix row ">
                                {todos.map((item, index) => (
                                    <div className="masonry-item  col-lg-4 col-md-4 col-sm-12" key={index}>
                                    <div className="blog-post blog-grid date-style-2">
                                        <div className="sx-post-media sx-img-effect img-reflection">
                                        <NavLink to={`${item.blog_url}`}><img className="blog-image" src={`${configData.ADMIN_URL}/assets/blog/` + item.blog_image} alt="" /></NavLink>
                                        </div>
                                        <div className="sx-post-info p-t10">
                                            <div className="sx-post-meta ">
                                            
                
                                                <ul>
                                                    <li className="post-date post-date-lising"><strong>{item.created['day']}</strong> <span>{item.created['month']}</span> </li>
                                                    <li className="post-author"><NavLink to={"/#"}>By <span className="red">Admin</span></NavLink> </li>
                                                     {item.category.map((cat, index) => (
                                                      <li key={index} className="post-comment"> <NavLink to={"blogs?category=" + cat.id}>{cat.category_name}</NavLink> </li>
                                                     ))}
                                                    
                                                </ul>
                                            </div>
                                            <div className="sx-post-title ">
                                                <h4 className="post-title fw600 fs-20"><NavLink to={`${item.blog_url}`}>{item.blog_title}</NavLink></h4>
                                            </div>

                                            <p className='mb-4 mt-2 line-h-2'>{item.blogdescription.slice(0, 100)}...<NavLink to={item.blog_url} title="READ MORE" rel="bookmark" className="site-button-link red"><u>Read more</u></NavLink></p>
                                            
                                        </div>
                                    </div>
                                    </div>
                                ))}
                            </div> 
                                   <ul className="pageNumbers">
                                        <li>
                                          <button
                                            onClick={handlePrevbtn}
                                            disabled={currentPage === pages[0] ? true : false}
                                          >
                                            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Previous
                                          </button>
                                        </li>
                                        {pageDecrementBtn}
                                        {renderPageNumbers}
                                        {pageIncrementBtn}

                                        <li>
                                          <button
                                            onClick={handleNextbtn}
                                            disabled={currentPage === pages[pages.length - 1] ? true : false}
                                          >
                                            Next <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                                          </button>
                                        </li>
                                       

                                      </ul>
                                </div>
                                {/* SIDE BAR START 
                                <div className="col-lg-3 col-md-12 col-sm-12 sticky_column">
                                    <BlogSidebar />
                                </div>
                                {/* SIDE BAR END */}
                            </div>

                            {/* TITLE START */}
                                <div className="section-head">
                                    <div className="sx-separator-outer separator-left mt-4">
                                        <div className="sx-separator bg-white bg-moving bg-repeat-x" style={{ backgroundImage: 'url(' + bgimg1.default + ')' }}>
                                            <h3 className="sep-line-one p-3">Latest Blogs</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* TITLE END */}
                                {/* IMAGE CAROUSEL START */}
                                <div className="section-content container">
                                    <div className="">
                                {/* TITLE END */}
                                {/* IMAGE CAROUSEL START */}
                                <div className="section-content">
                                    <div className="">
                                    <OwlCarousel className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center " {...options}>
                                    {latest.map((item, index) => (
                                        <div className="">
                                            <div className="blog-post blog-grid date-style-2">
                                                <div className="sx-post-media sx-img-effect img-reflection">
                                                    <NavLink to={`${item.blog_url}`}><img className="h-218" src={`${configData.ADMIN_URL}/assets/blog/` + item.blog_image} alt=""/></NavLink>
                                                </div>
                                                <div className="sx-post-info p-t10">
                                                    <div className="sx-post-meta ">
                                                        <ul>
                                                            <li className="post-date"><strong>{item.created['day']}</strong> <span>{item.created['month']}</span> </li>
                                                            <li className="post-author"><NavLink to={"#"}>By <span className="red">Admin</span></NavLink> </li>
                                                            {item.category.map((cat, index) => (
                                                            <li className="post-comment"> <NavLink to={"blogs?category=" + cat.id}>{cat.category_name}</NavLink> </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                    <div className="sx-post-title ">
                                                        <h4 className="post-title fs-20"><NavLink to={`${item.blog_url}`}>{item.blog_title}</NavLink></h4>
                                                    </div>
                                                     <p className='mb-4 mt-2 line-h-2'>{item.blogdescription.slice(0, 100)}...<NavLink to={`${item.blog_url}`} title="READ MORE" rel="bookmark" className="site-button-link red"><u>Read more</u></NavLink></p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        ))}
                                    </OwlCarousel>
                                    </div>
                                </div>
                                    </div>
                                </div>
                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>

                

                <Footer />
                </>
            }
            </>
        );

};

export default Blog;