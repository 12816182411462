import React from "react";
import Accordion from "react-bootstrap/Accordion";

const CommomInteriorCollapse = () => {
  return (
    <>
      <div className="sx-accordion acc-bg-gray m-b30" id="accordion5">
        <h3>
          <b>Achieve Modern Designs For Your House?</b>
        </h3>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="interior-active-plans">
              <b>Plan What You Want</b>
            </Accordion.Header>
            <Accordion.Body className="interior">
              Try not to immediately jump into picking the furniture you need in
              your home. To begin with, map out and make representations to
              envision what you need for your interior design. Begin by
              estimating your rooms and each item in them. Along these lines,
              you can sort out the particulars of your thoughts will be and
              their accessibility.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header className="interior-active-plans">
              <b>Keep It Simple & Clean</b>
              <span className="indicator for1">
                <i className="fa plus-collapse" />
              </span>
            </Accordion.Header>
            <Accordion.Body className="interior">
              As is commonly said, simplicity reflects elegance. What's more,
              while embellishing, one thing to remember is to keep the space
              simple and expressive. Natural light is here and there all that
              you require, as it emanates its own magnificence. Besides,
              moderate plans have the advantage of causing spots to seem bigger
              and making the most of everything about them. Your plan will stand
              apart in view of its effortlessness, and having clean areas is
              dependably wonderful.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header className="interior-active-plans">
              <b>Make A Balanced Space</b>
              <span className="indicator for1">
                <i className="fa plus-collapse" />
              </span>
            </Accordion.Header>
            <Accordion.Body className="interior">
              It is essential to guarantee that the air is adjusted for things
              to stream pleasantly in a room. Attempt to accomplish an even
              space by isolating the room into equal parts and guaranteeing that
              each side has a decent detail.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header className="interior-active-plans">
              <b>Put resources into Iconic Furniture</b>
              <span className="indicator for1">
                <i className="fa plus-collapse" />
              </span>
            </Accordion.Header>
            <Accordion.Body className="interior">
              If you have any desire to embrace this style, search for rare plan
              components that fit the current times. However true plans from the
              past periods are troublesome and costly to drop by, you might go
              for the following best thing that copies their energy.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header className="interior-active-plans">
              <b>Choose The Right Colors</b>
              <span className="indicator for1">
                <i className="fa plus-collapse" />
              </span>
            </Accordion.Header>
            <Accordion.Body className="interior">
              Innovation considers the utilization of variety, adding the right
              color to your home to enhance the look and helps in achieving
              modern design house interior but remember one thing the end look
              should not be overworked.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="row">
        <div className="col-md-12 m-b30">
          <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
            <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
              <span className="icon-cell sx-text-primary">
                <i className="flaticon-set-square" />
              </span>
            </div>
            <div className="icon-content">
              <p>
                If you want more information regarding interior design and any
                queries visit our website <i>www.interiordesignwala.com</i> our
                team helps you in achieving your dream project.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CommomInteriorCollapse;
