/*eslint jsx-a11y/anchor-is-valid: "off"*/
import React from "react";
import { useHistory } from "react-router-dom";
import Header2 from "./../Common/Header2";
import Footer from "./../Common/Footer";
import About5 from "./../Elements/About5";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import axios from "axios";
import configData from "./../../config.js";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useContext } from "react";
import { IPInfoContext } from "ip-info-react";

var bgimg1 = require("./../../images/background/bg5.jpg");

const ReadMoreNew = ({ children }) => {
  const text = children;
  const [isReadMoreNew, setIsReadMoreNew] = useState(true);
  const toggleReadMoreNew = () => {
    setIsReadMoreNew(!isReadMoreNew);
  };
  return (
    <p className="text">
      {isReadMoreNew ? text.slice(0, 100) : text}
      <span onClick={toggleReadMoreNew} className="read-or-hide">
        {isReadMoreNew ? (
          <span className="red">...read more</span>
        ) : (
          <span className="red">show less</span>
        )}
      </span>
    </p>
  );
};

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [requirement, setRequirement] = useState("");
  const [countryInfo, setCountryInfo] = useState(null);
  const [ip, setIp] = useState(null);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [number, setNumber] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [requirementError, setRequirementError] = useState("");
  const userInfo = useContext(IPInfoContext);
  const [seo, setSeo] = useState([]);
  let history = useHistory();
  //   const enabled =
  //     requirement.length > 0 &&
  //     email.length > 0 &&
  //     name.length > 0 &&
  //     mobile.length > 0 &&
  //     email.length > 0;

  useEffect(() => {
    const fetchCountryData = async () => {
      setIp(userInfo.ip);
      if (ip) {
        try {
          const response = await fetch(`https://api.country.is/${ip}`);
          const data = await response.json();
          setCountryInfo(data.country);
        } catch (error) {
          console.error("Error fetching country data:", error);
        }
      }
    };
    fetchCountryData();
  }, [userInfo.ip, ip]);
  const handleChangeNumber = (number) => {
    setNumber(number);
    if (number) {
      try {
        const phoneNumber = parsePhoneNumberFromString(number);

        const countryCode = `${
          phoneNumber ? phoneNumber.countryCallingCode : ""
        }`;
        const nationalNumber = phoneNumber ? phoneNumber.nationalNumber : "";
        setCode(countryCode);
        // setValue(nationalNumber);
        setMobile(code + nationalNumber);

        if (isValidPhoneNumber(number)) {
          console.log(number);
          setError(false);
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      }
    } else {
      setCode("");
      // setValue("");
      setError(true);
    }
  };
  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    setNameError(value.trim() === "" ? "Name is required" : "");
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(value) ? "Please enter a valid email" : "");
  };

  const handleRequirementChange = (e) => {
    const value = e.target.value;
    setRequirement(value);
    setRequirementError(value.trim() === "" ? "Requirement is required" : "");
  };
  async function saveQuery(event) {
    event.preventDefault();
    if (name && email && mobile && requirement) {
      let data = { name, email, mobile, requirement };
      if (isValidPhoneNumber("+" + mobile)) {
        let result = await fetch(`${configData.ADMIN_URL}/api/contact`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            Accept: "applicatiion/json",
          },

          body: JSON.stringify(data),
        });

        const item = await result.json();

        if (item.status === "success") {
          history.push("/thanks.php");
        } else {
          var msg = JSON.stringify(item.errors.email[0]);
          toast.error(msg, { autoClose: 3000 });
        }
      } else {
        toast.error("Invalid Phone Number!", { autoClose: 3000 });
      }
    } else {
      if (!mobile) {
        setError(true);
      }
      if (!name) {
        setNameError("Name is required");
      }
      if (!email) {
        setEmailError("Email is required");
      }
      if (!requirement) {
        setRequirementError("Requirement is required");
      }
    }
  }

  useEffect(() => {
    async function fetchProducts() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=contact-us.php`
        );
        setSeo(pageSeo.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchProducts();
  }, []);

  return (
    <>
      {seo.map((item, index) => (
        <Helmet key={index}>
          <title>{item.pagetitle}</title>
          <meta name="description" content={item.metadescription} />
          <meta name="keywords" content={item.metakeywords} />
          <meta property="og:type" content="Page" />
          <meta property="og:title" content={item.pagetitle} />
          <meta property="og:description" content={item.metadescription} />
          <meta property="og:url" content={window.location.href} />
          <link
            rel="canonical"
            href={`${configData.CANONICAL_URL}contact-us.php`}
          />
          <meta property="og:site_name" content={configData.SITE_NAME} />
          <meta
            property="og:image"
            content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_banner}`}
          />
        </Helmet>
      ))}
      <Header2 />
      <div className="page-content">
        <div className="section-full p-t80 inner-page-padding">
          <div className="container">
            <div className=" section-full  p-t30 p-b30 bg-no-repeat">
              <div className="">
                <div class="row justify-content-center">
                  <div class="col-md-12">
                    <div class="wrapper">
                      <div class="row mb-5">
                        <div class="col-md-4">
                          <div class="dbox w-100 text-center">
                            <div class="icon d-flex align-items-center justify-content-center">
                              <span class="fa fa-map-marker"></span>
                            </div>
                            <div class="text">
                              <p>
                                <span className="fw-500">Address:</span> E-53,
                                Ground Floor, E Block, Sector 63, Noida Uttar
                                Pradesh 201301
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="dbox w-100 text-center">
                            <div class="icon d-flex align-items-center justify-content-center">
                              <span class="fa fa-phone"></span>
                            </div>
                            <div class="text">
                              <p>
                                <span className="fw-500">Phone:</span>{" "}
                                <a className="red" href="tel:+918010822233">
                                  +91 - 8010822233
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="dbox w-100 text-center">
                            <div class="icon d-flex align-items-center justify-content-center">
                              <span class="fa fa-paper-plane"></span>
                            </div>
                            <div class="text">
                              <p>
                                <span className="fw-500">Email:</span>{" "}
                                <a className="red" href="">
                                  <span className="red">
                                    {" "}
                                    support@nakshewala.com
                                  </span>
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="content">
                  <div class="">
                    <div class="row justify-content-center">
                      <div class="col-md-10">
                        <div class="row align-items-center">
                          <div class="col-lg-7 mb-5 mb-lg-0">
                            <h2 class="mb-5 heading-h2">
                              Post Your <span className="red">Query</span>
                            </h2>
                            <form
                              class="border-right pr-5 mb-5"
                              method="post"
                              id="contactForm"
                              name="contactForm"
                            >
                              <div class="row">
                                <div class="col-md-12 form-group">
                                  <input
                                    type="text"
                                    onChange={handleNameChange}
                                    class="form-control form-control2"
                                    name="fname"
                                    id="fname"
                                    placeholder="First name"
                                  />
                                  {nameError && (
                                    <p className="error-msg">{nameError}</p>
                                  )}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 form-group">
                                  <input
                                    onChange={handleEmailChange}
                                    type="email"
                                    class="form-control form-control2"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                  />
                                  {emailError && (
                                    <p className="error-msg">{emailError}</p>
                                  )}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 form-group">
                                  {/* <input
                                    type="text"
                                    onChange={(e) => {
                                      setMobile(e.target.value);
                                    }}
                                    class="form-control form-control2"
                                    name="number"
                                    id="number"
                                    placeholder="Number"
                                  /> */}
                                  <PhoneInput
                                    international
                                    className="contact-phone-control2 form-control2"
                                    defaultCountry={countryInfo}
                                    countryCallingCodeEditable={false}
                                    value={number || ""} // Ensuring value is a string
                                    placeholder="Enter phone number"
                                    onChange={handleChangeNumber}
                                  />
                                  {error && (
                                    <p className="errorMsg">
                                      Please enter a valid number and country
                                      code
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 form-group">
                                  <textarea
                                    onChange={handleRequirementChange}
                                    class="form-control form-control2"
                                    name="message"
                                    id="message"
                                    cols="30"
                                    rows="5"
                                    placeholder="Write your message"
                                  ></textarea>
                                  {requirementError && (
                                    <p className="error-msg">
                                      {requirementError}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <input
                                    onClick={saveQuery}
                                    // disabled={!enabled}
                                    type="button"
                                    value="submit"
                                    class="btn btn-primary rounded-0 py-2 px-4"
                                  />
                                  <span class="submitting"></span>
                                </div>
                              </div>
                            </form>
                            <div id="form-message-warning mt-4"></div>
                          </div>
                          <div class="col-lg-4 ml-auto">
                            <h3 class="mb-4 heading-h3">
                              Let's talk about everything.
                            </h3>
                            <p>
                              <ReadMoreNew>
                                Reach us if you any query regarding our services
                                or about house plans and architecture services
                                you need. We are here to help you out!! We will
                                be obliged to listen to your question and answer
                                them so that you can get to know more about
                                architectural designing that will help you out
                                to make a better decision.
                              </ReadMoreNew>
                            </p>

                            <img
                              alt="contact us"
                              src="assets/images/contactImg.webp"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" d-none section-content">
                  <div className="row">
                    <div className="col-xl-5 col-lg-5 col-md-12 d-none">
                      <div
                        className="home-2-about bg-bottom-left bg-no-repeat bg-cover"
                        style={{
                          backgroundImage: "url(" + bgimg1.default + ")",
                        }}
                      ></div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className=" text-center">
                        <h4 className="contact-page m-t0 sx-tilte fsm-14  mt-contact mb-4">
                          REACH US IF YOU ANY QUERY REGARDING OUR SERVICES OR
                          ABOUT HOUSE PLANS AND ARCHITECTURE SERVICES YOU NEED.
                          WE ARE HERE TO HELP YOU OUT!! WE WILL BE OBLIGED TO
                          LISTEN TO YOUR QUESTION AND ANSWER THEM SO THAT YOU
                          CAN GET TO KNOW MORE ABOUT ARCHITECTURAL DESIGNING
                          THAT WILL HELP YOU OUT TO MAKE A BETTER DECISION.
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content d-none">
              {/* CONTACT FORM*/}
              <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <form
                    className="contact-form cons-contact-form bg-gray p-a30"
                    method="post"
                    action="#"
                  >
                    <div className="contact-one">
                      {/* TITLE START */}
                      <div className="section-head">
                        <div className="sx-separator-outer separator-left">
                          <div
                            className="sx-separator bg-white bg-moving bg-repeat-x"
                            style={{
                              backgroundImage:
                                "url(images/background/cross-line2.png)",
                            }}
                          >
                            <h3 className="sep-line-one sep-line-one2">
                              Post Your Query
                            </h3>
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <div className="form-group">
                        <input
                          name="username"
                          type="text"
                          required
                          className="form-control form-control2"
                          placeholder="Name"
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="email"
                          type="text"
                          className="form-control form-control2"
                          required
                          placeholder="Email"
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          rows={4}
                          className="form-control form-control2"
                          required
                          placeholder="Message"
                          defaultValue={""}
                        />
                      </div>
                      <div className="text-right">
                        <button
                          name="submit"
                          type="button"
                          value="Submit"
                          className="site-button btn-half"
                        >
                          <span> submit</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div
                    className="contact-info block-shadow bg-white bg-center p-a40"
                    style={{
                      backgroundImage: "url(images/background/bg-map.png)",
                    }}
                  >
                    <div>
                      {/* TITLE START */}
                      <div className="section-head">
                        <div className="sx-separator-outer separator-left">
                          <div
                            className="sx-separator bg-white bg-moving bg-repeat-x"
                            style={{
                              backgroundImage:
                                "url(images/background/cross-line2.png)",
                            }}
                          >
                            <h3 className="sep-line-one sep-line-one2">Info</h3>
                          </div>
                        </div>
                      </div>
                      {/* TITLE END */}
                      <div className="sx-icon-box-wraper left p-b30">
                        <div className="icon-xs">
                          <i className="fa fa-phone" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Phone number</h5>
                          <p>+91 - 8010822233</p>
                        </div>
                      </div>
                      <div className="sx-icon-box-wraper left p-b30">
                        <div className="icon-xs">
                          <i className="fa fa-envelope" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Email address</h5>
                          <p> support@nakshewala.com</p>
                        </div>
                      </div>
                      <div className="sx-icon-box-wraper left">
                        <div className="icon-xs">
                          <i className="fa fa-map-marker" />
                        </div>
                        <div className="icon-content">
                          <h5 className="m-t0">Address info</h5>
                          <p>H-44, First Floor, Sector-63, Noida</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SECTION CONTENT END */}
      </div>
      {seo.map((item, index) => (
        <About5 page_content={item.page_content} />
      ))}

      <ToastContainer />
      <Footer />
    </>
  );
};

export default ContactUs;
