import React from "react";
import Navigation from "./Navigation";
import { NavLink, withRouter } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
//config url
import configData from "./../../config.js";

class Header2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: require("./../../images/NAKSHEWALA_LOGO-min.png"),
      keywords: [],
      value: "",
    };
  }
  state = { isSearchActive: false };

  handleSearchToggle = () => {
    this.setState({ isSearchActive: !this.state.isSearchActive });
  };

  selectKeyword = (event) => {
    this.props.history.push(`/search.php?keywords=${event.currentTarget.id}`);
  };

  handleFocus = () => {
    document.getElementById("searchImg").click();
  }
  
  _handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.props.history.push(`/search.php?keywords=${this.state.value}`);
    }
  }

  handleClick = (event) => {
    this.setState({
      value: event.target.value,
    });
    fetch(`${configData.ADMIN_URL}/api/search?keywords=${event.target.value}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "x-api-key": configData.X_API_KEY,
        "x-api-secret": configData.X_API_SECRET,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            keywords: result.data.keywords,
          });
          
        },
        (error) => {
          console.log(error);
        }
      );
    if (event.key === "Enter") {
      event.preventDefault();
      this.props.history.push(`/search.php?keywords=${event.target.value}`);
    }
    
  };

  componentDidMount() {
    const handleScroll = () => {
      const offset = window.scrollY;

            const stickyheader = document.querySelector('.sticky-header ');

             if (offset >= 70) {
                stickyheader.classList.add('is-fixed');
                stickyheader.classList.add('color-fill');

            } else {
                stickyheader.classList.remove('is-fixed');
                stickyheader.classList.remove('color-fill');
            }
    };

    window.addEventListener("scroll", handleScroll);

    window.updateTopMostParent = (logopath) => {
      this.setState({ logo: logopath });
    };
  }

  render() {
    const { isSearchActive, keywords } = this.state;

    return (
      <>
        <header
          className="site-header nav-wide nav-transparent mobile-sider-drawer-menu"
          id="box1"
        >
          <div className="top-bar sx-bg-secondry">
            <div className="container">
              <div className="d-flex justify-content-between ">
                <ul className="list-unstyled e-p-bx text-white">
                  <li className="supant-phone-no">
                    <a href="mailto:support@nakshewala.com">
                      support@nakshewala.com</a>  <a href="tel:+918010822233">| +91 - 8010822233</a> 
                    
                  </li>
                </ul>
                <ul className="list-unstyled social-bx text-white d-flex flex-wrap align-content-center">
                  <li>
                    <a
                      href="https://bit.ly/36DYECE "
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-facebook social-icon-top" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://bit.ly/2Cizuvo "
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-instagram social-icon-top" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://bit.ly/2oSI2WP"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-pinterest social-icon-top" />
                    </a>
                  </li>
                  <li>
                    <a
                      href=" https://bit.ly/34ElmJ7 "
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-twitter social-icon-top" />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.youtube.com/@nakshewala_"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-youtube social-icon-top" />
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://in.linkedin.com/company/nakshewala"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-linkedin social-icon-top" />
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className="sticky-header main-bar-wraper navbar-expand-lg">
            <div className="main-bar mobile">
              <div className="container clearfix">
                <div className="logo-header">
                  {/* NAV Toggle Button */}
                  <button
                    data-target=".header-nav"
                    data-toggle="collapse"
                    id="mobile-side-drawer"
                    type="button"
                    className="mobile-side-drawer navbar-toggler collapsed"
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar icon-bar-first" />
                    <span className="icon-bar icon-bar-two" />
                    <span className="icon-bar icon-bar-three" />{" "}
                  </button>
                  <div className="logo-header-inner logo-header-one">
                    <NavLink to={"/"}>
                      <LazyLoadImage
                        effect="blur"
                        src={this.state.logo}
                        alt="NAKSHEWALA_LOGO"
                      />
                    </NavLink>
                  </div>
                </div>

                {/* EXTRA NAV */}
                <div className="extra-nav">
                  <div className="extra-cell">
                    <NavLink to={"#"} onClick={this.handleSearchToggle}>
                      {isSearchActive ? (
                        <img
                          alt="cross"
                          
                          src="/assets/images/images.png"
                          style={{ width: 16 }}
                        />
                      ) : (
                        <img  onClick={this.handleFocus} alt="search" src="/assets/images/search.svg" />
                      )}
                    </NavLink>
                  </div>
                  <div className="extra-cell">
                    <NavLink to={"/login"}>
                      <img alt="login" src="/assets/images/user.svg" />
                    </NavLink>
                  </div>
                  <div className="extra-cell">
                    {localStorage.getItem("token") ? (
                      <NavLink to={"/favourite-design"}>
                        <img alt="wishlist" src="/assets/images/heart.svg" />
                      </NavLink>
                    ) : (
                      <NavLink to={"/login"}>
                        <img alt="wishlist" src="/assets/images/heart.svg" />
                      </NavLink>
                    )}
                  </div>
                </div>
                {/* EXTRA Nav */}
                {/* MAIN NAVIGATION */}
                <div className="header-nav nav-dark navbar-collapse collapse justify-content-center collapse">
                  <Navigation />
                </div>
                {/* SITE SEARCH */}
                <div id="search" className={isSearchActive ? "open" : null}>
                  <span
                    className="close"
                    onClick={this.handleSearchToggle}
                  ></span>
                  <form
                    className="radius-xl"
                  >
                    <div className="input-group">
                      <input
                        onChange={this.handleClick}
                        name="keywords"
                        id="searchImg"
                        type="search"
                        onKeyDown={this._handleKeyDown}
                        autoComplete="off"
                        placeholder="Type to search"
                      />
                      <span className="input-group-btn">
                        <button type="button" className="search-btn">
                          <i className="fa fa-search arrow-animation" />
                        </button>
                      </span>
                    </div>
                    <div className="search-suggestions">
                      <ul>
                        {keywords.map((item) => (
                          <li id={item} onClick={this.selectKeyword}>
                            {item}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

/*window.addEventListener('mouseup', function(event){
    var box = document.getElementById('box1');
    box.classList.remove("active"); 

    const btn = document.getElementById("side_close_mobile");
      btn.onclick = function () {
      if (box.style.display !== "none") {
      box.classList.remove("active"); 
      } else {
       box.style.display = "block";
     }
   };

});*/

export default withRouter(Header2);
