import React from 'react';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import WhyNakshewalaBanner from './../Elements/whynakshewalabanner';
import Recommended from './../Elements/Recommended';
import Choose from './../Elements/Choosewhy';
import Whybuy from './../Elements/whybuynakshewala';

var bnrimg = require('./../../images/banner/why_nakshewala.webp');  

const WhyNakshewala = () => {
 
  return (
  <>
  <Header2 />
  <div className="page-content">
  <WhyNakshewalaBanner title="Why NaksheWala.com" pagename="" description="Why would you go with Nakshewala.com" bgimage={bnrimg} />              
  <Whybuy />
  <Choose />
  <Recommended  title="Recommended Services" bgcolor="bg-white" /> 
  </div>
  <Footer />
</>
);
   
};
export default WhyNakshewala;	