import Shimmer from "react-shimmer-effect";

const Services = () => {
  return (
    <>
      <div className="container mt-2 mb-2">
        <div className="row">
          <div className="col-lg-4 col-md-12">
            <div className="container-2 ">
              <div className="w-100">
                <Shimmer>
                  <div className="circle" />
                </Shimmer>
              </div>
              <div className="w-100">
                <Shimmer>
                  <div className="line-10" />
                  <div className="line" />
                  <div className="line-80" />
                  <div className="line-80" />
                  <div className="line-50" />
                </Shimmer>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <div className="container-2 ">
              <div className="w-100">
                <Shimmer>
                  <div className="circle" />
                </Shimmer>
              </div>
              <div className="w-100">
                <Shimmer>
                  <div className="line-10" />
                  <div className="line" />
                  <div className="line-80" />
                  <div className="line-80" />
                  <div className="line-50" />
                </Shimmer>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mt-3">
            <div className="container-2 ">
              <div className="w-100">
                <Shimmer>
                  <div className="circle" />
                </Shimmer>
              </div>
              <div className="w-100">
                <Shimmer>
                  <div className="line-10" />
                  <div className="line" />
                  <div className="line-80" />
                  <div className="line-80" />
                  <div className="line-50" />
                </Shimmer>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mt-3">
            <div className="container-2 ">
              <div className="w-100">
                <Shimmer>
                  <div className="circle" />
                </Shimmer>
              </div>
              <div className="w-100">
                <Shimmer>
                  <div className="line-10" />
                  <div className="line" />
                  <div className="line-80" />
                  <div className="line-80" />
                  <div className="line-50" />
                </Shimmer>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mt-3">
            <div className="container-2 ">
              <div className="w-100">
                <Shimmer>
                  <div className="circle" />
                </Shimmer>
              </div>
              <div className="w-100">
                <Shimmer>
                  <div className="line-10" />
                  <div className="line" />
                  <div className="line-80" />
                  <div className="line-80" />
                  <div className="line-50" />
                </Shimmer>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mt-3">
            <div className="container-2 ">
              <div className="w-100">
                <Shimmer>
                  <div className="circle" />
                </Shimmer>
              </div>
              <div className="w-100">
                <Shimmer>
                  <div className="line-10" />
                  <div className="line" />
                  <div className="line-80" />
                  <div className="line-80" />
                  <div className="line-50" />
                </Shimmer>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mt-3">
            <div className="container-2 ">
              <div className="w-100">
                <Shimmer>
                  <div className="circle" />
                </Shimmer>
              </div>
              <div className="w-100">
                <Shimmer>
                  <div className="line-10" />
                  <div className="line" />
                  <div className="line-80" />
                  <div className="line-80" />
                  <div className="line-50" />
                </Shimmer>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mt-3">
            <div className="container-2 ">
              <div className="w-100">
                <Shimmer>
                  <div className="circle" />
                </Shimmer>
              </div>
              <div className="w-100">
                <Shimmer>
                  <div className="line-10" />
                  <div className="line" />
                  <div className="line-80" />
                  <div className="line-80" />
                  <div className="line-50" />
                </Shimmer>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 mt-3">
            <div className="container-2 ">
              <div className="w-100">
                <Shimmer>
                  <div className="circle" />
                </Shimmer>
              </div>
              <div className="w-100">
                <Shimmer>
                  <div className="line-10" />
                  <div className="line" />
                  <div className="line-80" />
                  <div className="line-80" />
                  <div className="line-50" />
                </Shimmer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
