import React, { Suspense } from "react";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import LandingBanner from "./LandingBanner";
import LandingHouseDesign from "./LandingHouseDesign"; 
import LandingService from "./LandingService"; 
import LandingOffer from "./LandingOffer"; 
import LandingCommercial from "./LandingCommercial";
import Work from "./Work";

import Testimonials from "./Testimonials";

//var bnrimg = require("./../../../images/vastu-consultancy.webp");


const LandingNew = () => {
	
return (
	<>
	  <Header2 /> 
	  <Suspense fallback={<div>Loading...</div>}>
	   <LandingBanner />
		<LandingHouseDesign
		alignment="separator-left"
		title="Trending House Design"
		bgcolor="bg-white"
        />
		<LandingService />
		<LandingOffer />
		<LandingCommercial /> 
		
		<Testimonials />
		<Work/>
		</Suspense>
	  <Footer />
	</>
  );	
};

export default LandingNew;