import React from "react";
import { NavLink } from "react-router-dom";

const services = [
  {
    count: "E",
    title: "East Facing House",
    image: require("./../../../images/east.webp"),
    url: "../vastu-for-east-facing-house.php",
  },
  {
    count: "W",
    title: "West Facing House",
    image: require("./../../../images/west.webp"),
    url: "../vastu-for-west-facing-house.php",
  },
  {
    count: "N",
    title: "North Facing House",
    image: require("./../../../images/north.webp"),
    url: "./vastu-for-north-facing-house.php",
  },
  {
    count: "S",
    title: "South Facing House",
    image: require("./../../../images/south.webp"),
    url: "./vastu-for-south-facing-house.php",
  },
];

var bgimg1 = require("./../../../images/cross-line2.png");

class VastuDirection extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding bg-white  p-t80 p-b50 bg-repeat overflow-hide"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="sx-separator-outer separator-center">
                <div className="sx-separator bg-white bg-moving bg-repeat-x living-ani-mation">
                  <h3 className="sep-line-one dir-vatu-dir">
                    Vastu <span className="by-direction">By Direction</span>
                  </h3>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content all-fo-ur-sli-ce">
              <div className="row number-block-three-outer justify-content-center all-four-dir-ct">
                {services.map((item, index) => (
                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30"
                    key={index}
                  >
                    <div className="number-block-three slide-ani-top">
                      <NavLink to={item.url}>
                        <div className="sx-media">
                          <img src={item.image} alt="" />
                        </div>
                        <div className="figcaption bg-gray  p-a30">
                          <h4 className="m-tb0 dir-title">{item.title}</h4>
                          <div className="figcaption-number animate-top-content">
                            <span>{item.count}</span>
                          </div>
                        </div>
                      </NavLink>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default VastuDirection;
