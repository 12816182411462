import React from "react";
import { NavLink } from "react-router-dom";
import FreeQuote from "./../Elements/FreeQuote";
import Profile11 from "./../Elements/refermodel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

class Navigation extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("/assets/js/mobilenav.js");
  }

  render() {
    return (
      <>
        <div className="toggle_logo">
          <NavLink to={"./"}>
            <LazyLoadImage
              effect="blur"
              className="wm-40"
              src="/assets/images/NAKSHEWALA_LOGO.png"
              alt="NAKSHEWALA_LOGO"
            />
          </NavLink>
        </div>

        <ul className="nav navbar-nav">
          <li className=" d-sm-none d-md-block">
            <NavLink to={"/readymade-house-design.php"}>
              <h4 className="fs-14" >House Design</h4></NavLink>
          </li>

          <li className="d-sm-none d-md-block">
            <NavLink to={"/commercial-designs"}><h4 className="fs-14">Commercial Design</h4></NavLink>
          </li>
          <li className="d-sm-none d-md-block">
            <NavLink to={"/get-associated-become-a-partner-nakshewala"}>
            <h4 className="fs-14" >Get Associated</h4>
            </NavLink>
          </li>

          {/*<li class="d-sm-none d-md-block"><NavLink to={"/"}>Interiors</NavLink></li>*/}
          <li className="d-sm-none d-md-block">
            <NavLink to={"/customized-house-designs.php"}>
            <h4 className="fs-14">How It works?</h4>
            </NavLink>
          </li>

          <li className="d-sm-none d-md-block">
            <NavLink to={"/blog"}>
            <h4 className="fs-14">Blogs</h4>
            </NavLink>
          </li>

          <li className="d-sm-none d-md-block">
            <NavLink to={"/"}>
            <h4 className="fs-14">Design Gallery</h4>
            </NavLink>
            <ul className="sub-menu">
              <li><NavLink to={"/readymade-floor-plans.php"}>Floor Plan</NavLink></li>
              <li><NavLink to={"/front-elevation.php"}>3D Front Elevation</NavLink></li>
              <li><NavLink to={"/3d-2d-floor-plans.php"}>3D Floor Plan</NavLink></li>
              <li><NavLink to={"/interior-designing.php"}>Interior Design</NavLink></li>
            </ul>
          </li>

          <li className="d-md-none">
            {localStorage.getItem("token") ? (
              <NavLink to={"/profile"}>
                <i className="fa fa-user"></i> My Account
              </NavLink>
            ) : (
              <NavLink to={"/login"}>
                <i className="fa fa-user"></i> My Account
              </NavLink>
            )}
          </li>
          <li className="d-md-none">
            {localStorage.getItem("token") ? (
              <NavLink to={"/favourite-design"}>
                <i className="fa fa-heart"></i> My Wishlist{" "}
              </NavLink>
            ) : (
              <NavLink to={"/login"}>
                <i className="fa fa-heart"></i> My Wishlist{" "}
              </NavLink>
            )}
          </li>
          <li className="d-md-none">
            {localStorage.getItem("token") ? (
              <Profile11 />
            ) : (
              <NavLink to={"/login"}>
                <i className="fa fa-id-card"></i> Refer & Earn
              </NavLink>
            )}
          </li>

          <li className="d-md-none">
            <NavLink to={"/faq"}>
              <i className="fa fa-question"></i> Faq
            </NavLink>
          </li>

          <li className="d-md-none">
            <NavLink to={"/customized-house-designs.php"}>
              <i className="fa fa-tasks"></i> How It Works
            </NavLink>
          </li>
          <FreeQuote />
          <li className="d-md-none mb-3">
            <NavLink to={""}>
              <i className="fa fa-calculator"></i> Cost Calculation{" "}
              <span className="badge badge-pill badge-success">
                coming Soon{" "}
              </span>
            </NavLink>
          </li>
          <li className="d-md-none">
            <NavLink to={""}>
              <i className="fa fa-file"></i> Find Contractor/Supplier{" "}
              <span className="badge badge-pill badge-success">
                coming Soon{" "}
              </span>
            </NavLink>
          </li>
        </ul>
        <div className="bottom_link">
          <span className="verticle--webkit-baseline-middle d-none">
            {" "}
            <NavLink to={"/"}>
              <i className="fa fa-arrow-left "></i> Logout
            </NavLink>
          </span>
          <button
            id="side_close_mobile"
            data-target=".header-nav"
            data-toggle="collapse"
            type="button"
            className="side_close_mobile mobile-side-drawer navbar-toggler collapsed"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar icon-bar-first" />
            <span className="icon-bar icon-bar-two" />
            <span className="icon-bar icon-bar-three" />
          </button>
        </div>
      </>
    );
  }
}

export default Navigation;
