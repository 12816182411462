import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

var bgimg1 = require("./../../images/background/bg-5.png");

const services = [
  {
    count: "01",
    title: "Single Storey Elevations",
    url: "single-storey-elevation.php",
    flaticon: "flaticon-sketch",
    image: require("./../../images/single_story.webp"),
  },
  {
    count: "02",
    title: "Double Storey Elevations",
    url: "double-storey-elevation.php",
    flaticon: "flaticon-stairs",
    image: require("./../../images/double_store.webp"),
  },
  {
    count: "03",
    title: "Triple Storey Elevations",
    url: "triple-storey-elevation.php",
    flaticon: "flaticon-window",
    image: require("./../../images/triple_storey.webp"),
  },
  {
    count: "04",
    title: "Small House Elevations",
    url: "small-house-elevations.php",
    flaticon: "flaticon-skyline",
    image: require("./../../images/small_house.webp"),
  },
  {
    count: "05",
    title: "Contemporary House Elevations",
    url: "contemporary-house-elevation.php",
    flaticon: "flaticon-bed",
    image: require("./../../images/contempery.webp"),
  },
  {
    count: "06",
    title: "Kerala / Hut Shaped Elevations",
    url: "hut-shaped-elevation.php",
    flaticon: "flaticon-door",
    image: require("./../../images/hut.webp"),
  },
];

const services2 = [
  {
    count: "01",
    title: "Traditional House Elevations",
    url: "traditional-house-elevations.php",
    flaticon: "flaticon-sketch",
    image: require("./../../images/traditional.webp"),
  },
  {
    count: "02",
    title: "One Side Open Plot Elevations",
    url: "one-side-open-plot-elevations.php",
    flaticon: "flaticon-stairs",
    image: require("./../../images/one-side-plot.webp"),
  },
  {
    count: "03",
    title: "Residential Cum Commercial",
    url: "residential-cum-commercial-elevation.php",
    flaticon: "flaticon-window",
    image: require("./../../images/residentail_commercial.webp"),
  },
  {
    count: "04",
    title: "Multi Storey Residential Buildings",
    url: "multi-storey-residential-buildings.php",
    flaticon: "flaticon-skyline",
    image: require("./../../images/multistorey.webp"),
  },
  {
    count: "05",
    title: "Commercial Building elevations",
    url: "commercial-building-elevation.php",
    flaticon: "flaticon-bed",
    image: require("./../../images/625cd506-9461-4d2e-8145-402dd2766e4f.e16bd6260adeff05a6f5.webp"),
  },
];

class HomeElevation extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: false,
      center: false,
      margin: 10,
      //nav: false,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 2.3,
          margin: 15,
        },
        640: {
          items: 2.2,
        },
        768: {
          items: 2.2,
        },
        991: {
          items: 3.5,
          margin: 30,
        },
        1200: {
          items: 3.5,
          margin: 30,
          nav: true,
        },
      },
    };
    return (
      <>
        <div
          className="section-full   p-t20 p-b40"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container container-mobile">
            {/* TITLE START */}
            <div className="section-head ">
              <div className="sx-separator-outer separator-left plm-15">
                <NavLink to={"/front-elevation.php"}>
                  <h3 className="sep-line-one">
                    <span className="red">Most</span> Viewed Elevations{" "}
                    <img
                      className="w-3"
                      src="assets/images/building.svg"
                      alt="Elevation"
                    />
                  </h3>
                </NavLink>
              </div>
            </div>
            {/* TITLE END */}
            <div className="">
              <OwlCarousel
                className="owl-carousel service-slider-two owl-btn-vertical-center hello"
                {...options}
              >
                {services.map((item, index) => (
                  <NavLink key={index} to={"/" + item.url}>
                    <div className="item">
                      <div
                        className="bgcall-block d-flex flex-wrap  align-content-end  overlay-wraper"
                        style={{ backgroundImage: "url(" + item.image + ")" }}
                      >
                        <div className="overlay-main bg-black opacity-01" />
                        <div className="bg-content-mid-outer">
                          <div className="bg-content-mid">
                            <div className="sx-icon-box-wraper text-left text-white">
                              <div className="icon-content">
                                <h4 className="sx-tilte sx-tilte-mobile float-left">
                                  {" "}
                                  <NavLink
                                    to={"/" + item.url}
                                    className="sx-text-white"
                                  >
                                    {" "}
                                    {item.title}
                                  </NavLink>
                                </h4>
                              </div>
                            </div>

                            <div className="bg-overlay" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ))}
              </OwlCarousel>

              <OwlCarousel
                className="mt-4 owl-carousel service-slider-two owl-btn-vertical-center hello1"
                {...options}
              >
                {services2.map((item, index) => (
                  <NavLink key={index} to={"/" + item.url}>
                    <div className="item hello">
                      <div
                        className="bgcall-block d-flex flex-wrap  align-content-end  overlay-wraper"
                        style={{ backgroundImage: "url(" + item.image + ")" }}
                      >
                        <div className="overlay-main bg-black opacity-01" />
                        <div className="bg-content-mid-outer">
                          <div className="bg-content-mid">
                            <div className="sx-icon-box-wraper text-left text-white">
                              <div className="icon-content">
                                <h4 className="sx-tilte sx-tilte-mobile float-left">
                                  {" "}
                                  <NavLink
                                    to={"/" + item.url}
                                    className="sx-text-white"
                                  >
                                    {" "}
                                    {item.title}
                                  </NavLink>
                                </h4>
                              </div>
                            </div>

                            <div className="bg-overlay" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </NavLink>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomeElevation;
