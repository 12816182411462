import React from "react";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import Recommended from "./../../Elements/Recommended";
import About5 from "./../../Elements/About5";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import HomeCta from "./../../Elements/HomeCta";
import InteriorSection1 from "./../../Elements/InteriorSection1";
import configData from "./../../../config.js";

const VastuToilet = () => {
  const [seo, setSeo] = useState([]);
  const [page_content, setPage_content] = useState("");

  useEffect(() => {
    async function fetchProducts() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=vastu-for-toilets.php`
        );
        setSeo(pageSeo.data);
        setPage_content(pageSeo.data[0].page_content);
      } catch (error) {
        console.log(error);
      }
    }

    fetchProducts();
  }, []);

  return (
    <>
      {seo.map((item, index) => (
        <Helmet>
          <title>{item.pagetitle}</title>
          <meta name="description" content={item.metadescription} />
          <meta name="keywords" content={item.metakeywords} />
        </Helmet>
      ))}

      <Header2 />

      <div className="page-content">
        {seo.map((item, index) => (
          <InteriorSection1
            page_name={item.page_name}
            short_des_heading={item.short_des_heading}
            sub_title={item.sub_title}
            short_description={item.short_description}
          />
        ))}

        <div className="p-t30 p-b30 ">
          <div className="container">
            <div className="section-content">
              <h2>Vastu For Toilets</h2>

              <p>
                Each and every section of the house is designed according to the
                Vastu norms, toilets, and bathrooms too. here are some Vastu
                Shastra guidelines for toilet and washroom design, to ensure
                that it helps produce an overall positive climate at home for
                all family members.
              </p>

              <p>
                Bathrooms and toilets are often the most neglected spaces
                homeowners must give equal importance to each room because every
                space will be molded to emit positive energy. toilets and
                bathrooms can become sources of negative energy as per Vastu
                guidelines if it's not positioned in the right direction. it's
                unwise to go away from a much-frequented space uncared for. So,
                the proper bathroom and toilet direction as per Vastu should be
                considered at the time of construction of a house. One should
                avoid constructing a rest room within the center of the house.
                it's essential to understand the toilet Vastu guidelines to push
                positive energies.
              </p>

              <p>
                A bathroom or toilet space that is not as per Vastu guidelines
                can lead to financial problems or loss of wealth or even health
                issues for family members, stress, or even minor accidents. Some
                people design a bedroom and an attached bathroom can become a
                source of negative energy.
              </p>

              <h2>
                The Direction Of the bathroom And Toilet As Per Vastu Guidelines
              </h2>
              <p>
                As per Vastu. don't build the tub area in the south direction or
                maybe within the southeast or south-west direction, because it
                is claimed to possess a negative impact on the health of the
                people within the house. the bathroom and toilet area must be
                within the north direction or north-west portion of your house,
                the rest room should be constructed one to 2 feet on top of the
                bottom level as per Vastu Guidelines
              </p>

              <div className="single-service-list">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <h2>
                      Points To Remember While Designing A Bathroom/Toilet
                    </h2>

                    <ul className="remember-badroom-points">
                      <li>
                        The northwest part of the house is the best location for
                        toilets and bathrooms.
                      </li>
                      <li>
                        Avoid constructing toilets in the southwest direction as
                        this tends to give birth to ill effects.
                      </li>
                      <li>
                        Ensure that the doorway door to the bathroom is along
                        the eastern or northern wall.
                      </li>
                      <li>
                        In line with Vastu the most effective direction of the
                        toilet seat, is on the southeast or northwest side. this
                        could be in such some way that someone using it's not
                        facing either the east or the west.
                      </li>
                      <li>
                        Constructing the bathroom a pair of feet above the
                        bottom may be a good idea.
                      </li>
                      <li>
                        Make sure that the toilet is positioned along the west
                        or north-west, for a bathroom and toilet attached space.
                        this is often because the toilet is related to the
                        discarding of waste.
                      </li>
                      <li>
                        The shower or wash basin must be within the east, north,
                        or northeast of the bathroom.
                      </li>
                      <li>
                        Make sure that the bathroom doesn't share a wall with
                        the pooja room or the kitchen, because it can have
                        negative repercussions.
                      </li>
                      <li>
                        Outlets for bathroom pipes must be towards the north or
                        the east.
                      </li>
                    </ul>

                    <p>
                      Designing a Bathroom and Toilet as per the Vastu
                      guidelines is up to you, we share some related information
                      regarding “Vastu For Toilet” and help you in achieving
                      your dream project.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <HomeCta />
        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>

      <About5 page_content={page_content} />
      <Footer />
    </>
  );
};
export default VastuToilet;
