import React from "react";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

class HomeServiceSlider extends React.Component {
  render() {
    return (
      <>
        <div className={`${this.props.bgcolor} section-full mobile-hidden`}>
          <div className="container">
            <div className="section-content pt-3 owl-btn-vertical-center d-flex">
              <div className="item">
                <NavLink
                  to={"/readymade-house-design.php"}
                  className="client-logo-pic"
                >
                  <LazyLoadImage
                    className="w-100"
                    alt="House Designs"
                    effect="blur"
                    src="/assets/images/services/House_Design.webp"
                  />
                  
                  <h2 class="cat_text2 d-sm-none d-md-block">House Designs</h2>
                </NavLink>
              </div>

              <div className="item">
                <NavLink to={"/commercial-designs"} className="client-logo-pic">
                  <LazyLoadImage
                    className="w-100"
                    alt="Commercial Designs"
                    effect="blur"
                    src="/assets/images/services/Commercial_Designs.webp"
                  />

                  
                  <h2 class="cat_text2 d-sm-none d-md-block">
                    Commercial Designs
                  </h2>
                </NavLink>
              </div>

              <div className="item">
                <NavLink
                  to={"/front-elevation.php"}
                  className="client-logo-pic"
                >
                  <LazyLoadImage
                    className="w-100"
                    alt="3D Front Elevations"
                    effect="blur"
                    src="/assets/images/services/Front_Elevation.webp"
                  />

                 
                  <h2 class="cat_text2 d-sm-none d-md-block">
                    3D Front Elevations
                  </h2>
                </NavLink>
              </div>

              <div className="item">
                <NavLink
                  to={"/interior-designing.php"}
                  className="client-logo-pic"
                >
                  <LazyLoadImage
                    className="w-100"
                    alt="Interior Designs"
                    effect="blur"
                    src="/assets/images/services/Interior.webp"
                  />

                  
                  <h2 class="cat_text2 d-sm-none d-md-block">Interior Designs</h2>
                </NavLink>
              </div>

              <div className="item">
                <NavLink
                  to={"/customized-house-designs.php"}
                  className="client-logo-pic"
                >
                  <LazyLoadImage
                    className="w-100"
                    alt="Customized Designs"
                    effect="blur"
                    src="/assets/images/services/customized_design.ca23fc5d.webp"
                  />

                  
                  <h2 class="cat_text2 d-sm-none d-md-block">
                    Customized Designs
                  </h2>
                </NavLink>
              </div>

              <div className="item">
                <NavLink
                  to={"/readymade-floor-plans.php"}
                  className="client-logo-pic"
                >
                  <LazyLoadImage
                    className="w-100"
                    src="/assets/images/services/floor_plan.webp"
                    alt="Floor Plans"
                    effect="blur"
                  />

                 
                  <h2 class="cat_text2 d-sm-none d-md-block">Floor Plans</h2>
                </NavLink>
              </div>

              <div className="item">
                <a href="/3d-2d-floor-plans.php" className="client-logo-pic">
                  <LazyLoadImage
                    className="w-100"
                    src="/assets/images/services/3D.webp"
                    alt="3D Floor Plans"
                    effect="blur"
                  />

                  
                  <h2 class="cat_text2 d-sm-none d-md-block">3D Floor Plans</h2>
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomeServiceSlider;
