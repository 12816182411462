import React from "react";
import configData from "./../../config.js";

var bgimg1 = require("./../../images/background/cross-line2.png");

class PriceTable extends React.Component {
  render() {
    return (
      <>
        <div className="section-full mobile-page-padding p-t80  p-b50 bg-gray">
          <div className="section-content">
            <div className="container">
              <div className="section-head">
                <div className="sx-separator-outer separator-center for-only-vas-tuu">
                  <div
                    className="sx-separator bg-white bg-moving bg-repeat-x"
                    style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                  >
                    <h3 className="sep-line-one vastuu-txt-lvng">
                      Our <span style={{ color: "#c9100f" }}>Packages</span>
                    </h3>
                  </div>
                </div>
              </div>

              <div className="row ourcstmclctnrow">
                <div className="col-md-4 ourcstmtrgrd ourcstmtrgrdfirst">
                  <div className="ourtreedv">
                    <div className="box-border-top"></div>

                    <span className="trtopspn"></span>

                    <div className="ourcstmbacktre ourcstmbacktrefrst">
                      <h3>Package-1</h3>
                    </div>

                    <div className="ourcstmmaincnt">
                      <h4 className="phaseheading">Phase-1</h4>

                      <h5 className="ourcstmsub">
                        Floor Plans with Furniture Layout:
                      </h5>

                      <p className="para-table-all">
                        (Customized, with any modifications): Customized floor
                        plan will be design as per client’s plot, requirements,
                        preference and up to satisfaction in 2D. In this we
                        cover dimensions, Vastu compliant, basic furniture
                        layout, and door &amp; window locations etc.
                      </p>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="all-two-bttn">
                          <div className="know-more-smple-bttn">
                            <a
                              className="Button Button--primary"
                              href={`${configData.ADMIN_URL}/assets/package-pdf/FLOOR PLAN.pdf`}
                              download=""
                            >
                              SAMPLE
                            </a>
                          </div>

                          <div className="know-more-smple-bttn-1">
                            <a
                              className="Button Button--primary"
                              href="/viewdetail.php"
                            >
                              KNOW MORE
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 ourcstmtrgrd ourcstmtrgrdthord">
                  <div className="ourtreedv">
                    <div className="box-border-top"></div>

                    <span className="trtopspn"></span>

                    <div className="ourcstmbacktre ourcstmbacktrethrd">
                      <h3>Package-2</h3>
                    </div>

                    <div className="ourcstmmaincnt">
                      <h4 className="phaseheading">Phase-1</h4>

                      <h5 className="ourcstmsub">
                        Floor Plans with Furniture Layout:
                      </h5>

                      <p className="para-table-all">
                        (Customized, with any modifications).. Customized floor
                        plan will be design as per client's plot, requirements,
                        preference and up to satisfaction in 2D. In this we
                        cover dimensions, Vastu compliant, basic furniture
                        layout, and door &amp; window locations etc.
                      </p>
                    </div>

                    <div className="ourcstmmaincnt">
                      <h4 className="phaseheading">Phase-2</h4>

                      <h5 className="ourcstmsub"> 3D Front Elevation:</h5>

                      <p className="para-table-all">
                        (Customized, with any modifications)... Customized front
                        elevation (exterior) will be design as per client
                        preference and up to satisfaction in 3D. This will give
                        imaginative look of Constructed building from two sides
                        (Front and one side). It gives you an idea that how's
                        your house exterior will look like after complete finish
                        with colour combination, balcony and door &amp; window
                        design, Boundary wall etc.
                      </p>
                    </div>
                    <div className="row">
                      <div className="ourbtns col-md-12">
                        <div className="all-two-bttn">
                          <div className="know-more-smple-bttn">
                            <a
                              className="Button Button--primary"
                              href={`${configData.ADMIN_URL}/assets/package-pdf/PLAN AND ELEVATION.pdf`}
                              download=""
                            >
                              SAMPLE
                            </a>
                          </div>

                          <div className="know-more-smple-bttn-1">
                            <a
                              className="Button Button--primary"
                              href="/viewdetail.php"
                            >
                              KNOW MORE
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 ourcstmtrgrd ourcstmtrgrdsecond">
                  <div className="ourtreedv">
                    <div className="box-border-top"></div>

                    <span className="trtopspn"></span>

                    <div className="ourcstmbacktre ourcstmbacktrescnd">
                      <h3>Package-3</h3>
                    </div>

                    <div className="ourcstmmaincnt">
                      <h4 className="phaseheading">Phase-1</h4>

                      <h5 className="ourcstmsub">
                        Floor Plans with Furniture Layout:
                      </h5>

                      <p>
                        (Customized, with any modifications).. Customized floor
                        plan will be design as per client's plot, requirements,
                        preference and up to satisfaction in 2D. In this we
                        cover dimensions, Vastu compliant, basic furniture
                        layout, and door &amp; window locations etc.
                      </p>
                    </div>

                    <div className="ourcstmmaincnt">
                      <h4 className="phaseheading">Phase-2</h4>

                      <h5 className="ourcstmsub"> 3D Front Elevation:</h5>

                      <p>
                        (Customized, with any modifications)... Customized front
                        elevation (exterior) will be design as per client
                        preference and up to satisfaction in 3D. This will give
                        imaginative look of Constructed building from two sides
                        (Front and one side). It gives you an idea that how's
                        your house exterior will look like after complete finish
                        with colour combination, balcony and door &amp; window
                        design, Boundary wall etc.
                      </p>
                    </div>

                    <div className="ourcstmmaincnt">
                      <h4 className="phaseheading">Phase-3</h4>

                      <h5 className="ourcstmsub">2D Front Elevation:</h5>

                      <p>
                        Technical working details of 3D front elevation for
                        construction in 2D with dimensions and levels.
                      </p>

                      <h5 className="ourcstmsub">Working Drawings:</h5>

                      <p>
                        will include, detail working plans, wall details, door
                        &amp; window details, section drawings, stair case
                        details etc.
                      </p>

                      <h5 className="ourcstmsub">Structural Drawings:</h5>

                      <p>
                        Column marking plan, foundation details, Footing
                        Details, columns detail, plinth &amp; slab level beams
                        detail, lintel details, roof details etc.
                      </p>

                      <h5 className="ourcstmsub">Plumbing Drawings:</h5>

                      <p>
                        Water inflow, out flow, drainage, sewerage, pipe lines,
                        septic tank etc.
                      </p>

                      <h5 className="ourcstmsub">Electrical Drawings:</h5>

                      <p>
                        wiring, Switch location, electrical component location
                        etc
                      </p>
                    </div>

                    <div className="ourbtns">
                      <div className="all-two-bttn">
                        <div className="know-more-smple-bttn">
                          <a
                            className="Button Button--primary"
                            href={`${configData.ADMIN_URL}/assets/package-pdf/Architectural Service.pdf`}
                            download=""
                          >
                            SAMPLE
                          </a>
                        </div>

                        <div className="know-more-smple-bttn-1">
                          <a
                            className="Button Button--primary"
                            href="/viewdetail.php"
                          >
                            KNOW MORE
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PriceTable;
