import React from 'react';
//import ReactDOM from 'react-dom';
import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation  } from 'react-router-dom';
import Header2 from '../Common/Header2';
import Footer from '../Common/Footer';
import axios from "axios";
import Loader from "../Elements/Loader";
import { Helmet } from "react-helmet"; 
//config url
import configData from "../../config.js";
var imageUrl = `${configData.ADMIN_URL}/assets/images/testimonial/` 


const Testimonial = () => {

	let history = useHistory();
          const [seo, setSeo] = useState([]);
          const [todos, setTodos] = useState([]);
          const [loading, setLoading] = useState(false);
          const [totalTodos, setTotalTodos] = useState("");
          const [currentPage, setcurrentPage] = useState(1);
          const [itemsPerPage, setitemsPerPage] = useState(10);
          const [pageNumberLimit, setpageNumberLimit] = useState(5);
          const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
          const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
          
         //Getting Url Params
          const location = useLocation();
          const search = useLocation().search;
          const currenPageParms = new URLSearchParams(search).get('currentpage');

          useEffect(() => {
            async function fetchProducts() {
                 if(currenPageParms) {
                   if(currenPageParms !== 'null') {
                   setcurrentPage(currenPageParms);
                 }
              }
                setLoading(true);
             try {
              const item = await axios.get(`${configData.ADMIN_URL}/api/testimonials?page=${currenPageParms ? currenPageParms : 1}`)
              setTodos(item.data.data);
              setSeo(item.data.seo);
              setitemsPerPage(10)
              setpageNumberLimit(5)
              setTotalTodos(item.data.total);
              
              
               setLoading(false);
             } catch (error) {
              console.log(error);
             }
            }
            
            fetchProducts();
        }, [location.key, currenPageParms]);

            // Get current Todo
 const pages = [];
  for (let i = 1; i <= Math.ceil(totalTodos / itemsPerPage); i++) {
    pages.push(i);
  }

  
 
  // Change page
  const paginate = pageNumber => setcurrentPage(pageNumber);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={() => paginate(number)}
          className={currentPage === number ? "active" : null}
        >
         <NavLink onClick={() => paginate(number)} to={"?a=1&currentpage=" + number} className='page-link'>
              {number}
            </ NavLink>
       
        </li>
      );
    } else {
      return null;
    }
  });


    async function handleNextbtn() {
      
      history.push(`?a=1&currentpage=${Number(currentPage) + 1}`);
      const item = await axios.get(`${configData.ADMIN_URL}/api/testimonials?page=${Number(currentPage) + 1}`)
      setTodos(item.data.data);
      setSeo(item.data.seo);
      setTotalTodos(item.data.total);

    setcurrentPage(Number(currentPage) + 1);
    console.log(Number(currentPage) + 1);

    if (Number(currentPage) + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  async function handlePrevbtn()  {

      history.push(`?a=1&currentpage=${Number(currentPage) - 1}`);
      const item = await axios.get(`${configData.ADMIN_URL}/api/testimonials?page=${Number(currentPage) - 1}`)
      setTodos(item.data.data);
      setSeo(item.data.seo);
      setTotalTodos(item.data.total);


    setcurrentPage(currentPage - 1);

    if ((Number(currentPage) - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  
 
  return (


  <>

{loading ? <Loader /> :

<>
{seo.map((item, index) => (
           <Helmet key={index}>  
           <title>{item.pagetitle}</title>
           <meta name="description" content={item.metadescription} />
           <meta name="keywords" content={item.metakeywords} />
           <meta property="og:type" content="Page" />
           <meta property="og:title" content={item.pagetitle} />
           <meta property="og:description" content={item.metadescription} />
           <meta property="og:url" content={window.location.href} />
           <link rel="canonical" href={`${configData.CANONICAL_URL}testimonial.php`} />
           <meta property="og:site_name" content={configData.SITE_NAME} />
           <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_banner}`} />
           </Helmet>  
             ))}
   <Header2 />
   
   <div className="page-content">
   
    <div className="p-t130 p-b50">
                    <div className="container">
                        <div className="section-content">
                                <div className="section-head">
                                    <div className="sx-separator-outer separator-center">
									{/* <div className=" mb-5" style={{ backgroundImage: 'url(images/background/cross-line2.png)' }}>*/}
                                            
                                            <h2 className="heading-h2 te-st sep-line-one sep-line-one-two">Testimonial</h2>
									{/*</div>*/}  
										
                                    </div>
                                </div>
            
                        </div>
                    </div>
                </div>  
   
   
   {todos.map((item, index) => (
			<div key={index} className="p-t0 p-b30 ">
			<div className="container">
			<div className="section-content">
			<div className="row">

			<div className="col-xl-12 col-lg-6 col-md-12"> 

			<div className="tetl-card-con-tent bg-white shadow-css">

			<div className="col-xl-2 col-lg-6 col-md-12 p-0">
      <div
      className="bg-cover bg-center h-120 bg-no-repeat"
      style={{
        backgroundImage: `url('${
          imageUrl + item.author_images
        }')`,
      }}
    />
			{/*<div className="testml-on-ly-img">
         {item.author_images !== "" ? <img className="img-pro-tml" src={imageUrl + item.author_images} alt={item.test_author} /> : <img className="img-pro-tml" src="/assets/images/author6043252855cf0images.png" alt={item.test_author} />}
			

   </div>*/}

			</div>


			<div className="col-xl-10 col-lg-6 col-md-12">

			<p className="tsmoil-para-all">{item.test_txt}</p>

			<div className="dtail-test-ml">  
			<span>{item.test_author}, {item.location}</span>  

			<div className="stars">  
			<ul className="str-ul-list">
			<li>
			<i aria-hidden="true" className="fa fa-star yellow"></i>
			<i aria-hidden="true" className="fa fa-star yellow"></i>
			<i aria-hidden="true" className="fa fa-star yellow"></i>
			<i aria-hidden="true" className="fa fa-star yellow"></i>
			<i aria-hidden="true" className="fa fa-star  yellow"></i>
			</li>
			</ul>
			</div>

			</div>

			</div>


			</div>

			</div>
			</div>
			</div>
			</div>
			</div>

			))}
                    <div className="paginate">
                        <ul className="pageNumbers">
                            <li>
                              <button
                                onClick={handlePrevbtn}
                                disabled={currentPage === pages[0] ? true : false}
                              >
                                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Previous
                              </button>
                            </li>
                            {pageDecrementBtn}
                            {renderPageNumbers}
                            {pageIncrementBtn}

                            <li>
                              <button
                                onClick={handleNextbtn}
                                disabled={currentPage === pages[pages.length - 1] ? true : false}
                              >
                                Next <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                              </button>
                            </li>
                           

                          </ul>
                    </div>
 
   </div>
   
   <Footer />

   </>

}

</>

);
   
};
export default Testimonial;			  