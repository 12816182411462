/*eslint jsx-a11y/click-events-have-key-events: "off"*/
/*eslint jsx-a11y/no-noninteractive-element-interactions: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint react-hooks/exhaustive-deps: "off"*/
/*eslint no-lone-blocks: "off"*/
/*eslint no-unused-vars: "off"*/
import React from "react";
import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation, useParams } from "react-router-dom";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import { slide as Menu } from "react-burger-menu";
import Tabs, { TabPane } from "rc-tabs";
import ServiceBottom from "./../../Elements/ServiceBottom";
import axios from "axios";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import InteriorSection1 from "./../../Elements/InteriorSection1";
import Services from "./../../Elements/custom-shimmers/Services";
import { userWishList } from "../../../features/user/userActions";
import { useDispatch, useSelector } from "react-redux";
import { clearWishList } from "../../../features/user/userSlice";
import StickyBox from "react-sticky-box";
import ReactHtmlParser from "html-react-parser";
import HeaderText from "./../../Elements/HeaderText";
import EnquireNow from "./../../Elements/EnquireNow";
import configData from "./../../../config.js";

var url = `${configData.ADMIN_URL}/assets/images/banner/`;

const CommercialDesigns = ({ match }) => {
  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const openMenu = () => {
    //console.log("clicked");
    setOpen(true);
  };
  let history = useHistory();
  const clearFilters = () => {
    localStorage.removeItem("size");
    localStorage.removeItem("direction");
    localStorage.removeItem("unit");
    localStorage.removeItem("plotSize");
    localStorage.removeItem("plan_no");
    localStorage.removeItem("minWidth");
    localStorage.removeItem("maxWidth");
    localStorage.removeItem("maxDepth");
    localStorage.removeItem("minDepth");

    toast.success("Filter Refreshed Successfully!", { autoClose: 3000 });
    //alert('Filter Refreshed Successfully!');
    let path = `/commercial-designs`;
    history.push(path);
    history.go(0);
    //window.location.reload(true);
  };

  window.onunload = function () {
    localStorage.removeItem("size");
    localStorage.removeItem("direction");
    localStorage.removeItem("unit");
    localStorage.removeItem("plotSize");
    localStorage.removeItem("plan_no");
    localStorage.removeItem("minWidth");
    localStorage.removeItem("maxWidth");
  };

  const [seo, setSeo] = useState([]);
  const [todos, setTodos] = useState([]);
  const [loading, setLoading] = useState(false);
  //Pagination
  const [loginRedirection, setLoginRedirection] = useState(false);
  const [totalTodos, setTotalTodos] = useState("");
  const [category, setCategory] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(12);
  const [pageNumberLimit, setpageNumberLimit] = useState(8);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(8);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  // Static filters
  const [plan_no, setPlan_no] = useState("");
  //const [direction, setDirection] = useState("");
  //const [unit, setUnit] = useState("");
  const [size, setSize] = useState("");
  // const [plotSize, setPlotSize] = useState("");
  const [minWidth, setMinWidth] = useState("");
  const [maxWidth, setMaxWidth] = useState("");
  const [minDepth, setMinDepth] = useState("");
  const [maxDepth, setMaxDepth] = useState("");
  const [message, setMessage] = useState(false);

  //const [unChecked, setUnChecked] = useState("");
  const [paginateUrl, setPaginateUrl] = useState("");

  const [page_content, setPage_content] = useState("");
  //dynamic filters
  const [filters, setFilters] = useState([]);

  const [appendFilter, setAppendFilter] = useState([]);
  //Category SEO
  const [catSeo, setCatSeo] = useState([]);
  //Meta tags

  const [page_name, setPage_name] = useState("");
  const [sub_title, setSub_title] = useState("");
  const [short_description, setShort_description] = useState("");
  const [short_des_heading, setShort_des_heading] = useState("");
  //page_name={item.page_name} sub_title={item.sub_title} short_description
  //Getting Url Params
  const search = useLocation().search;
  const sizeParams = new URLSearchParams(search).get("psize");
  const plan_noParams = new URLSearchParams(search).get("dcode");

  const minWidthParams = new URLSearchParams(search).get("pwMin");
  const maxWidthParams = new URLSearchParams(search).get("pwMax");
  const minDepthParams = new URLSearchParams(search).get("pdMin");
  const maxDepthParams = new URLSearchParams(search).get("pdMax");
  const currenPageParms = new URLSearchParams(search).get("currentpage");
  const dynamicFilterParms = new URLSearchParams(search).get("dynamic_filter");
  const params = useParams();
  const onKeyDown = (e) => {
    document.getElementById("plan_no").placeholder = "";
    document.getElementById("plans_no").placeholder = "";
  };
  //alert(params.id);
  //////////// Redux /////////////////////
  const { error, onBoarding, wishListStatus } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (wishListStatus) {
      toast.success("Service added to wishlist", { autoClose: 3000 });
    }
    if (error) {
      toast.error(error, { autoClose: 3000 });
    }

    dispatch(clearWishList());
  }, [wishListStatus, error]);
  const addItemToWishlist = (event) => {
    if (!onBoarding) {
      setLoginRedirection(true);
      toast.warning("Please login first", { autoClose: 3000 });
    }
    if (onBoarding) {
      document.getElementById(event.currentTarget.id).classList.add("d-none");
      dispatch(userWishList({ type: 3, id: event.currentTarget.id }));
      document
        .getElementById(`${event.currentTarget.id}-removed`)
        .classList.remove("d-none");
    }
  };
  //////////// Redux End /////////////////////
  //DYNMIC FILTERS API CALL
  useEffect(() => {
    async function fetchData() {
      try {
        let item = await axios.get(
          `${configData.ADMIN_URL}/api/filter-categories`
        );
        setFilters(item.data);
        setitemsPerPage(12);
        setpageNumberLimit(5);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const location = useLocation();
  useEffect(() => {
    async function fetchProducts() {
      if (location.pathname === "/commercial-designs") {
        document.getElementById("0x1000").checked = false;
        document.getElementById("1000x2000").checked = false;
        document.getElementById("2000x3000").checked = false;
        document.getElementById("3000x4000").checked = false;
        document.getElementById("4000x6000").checked = false;
      }

      if (sizeParams) {
        if (sizeParams !== "null") {
          document.getElementById(sizeParams).checked = "checked";
        }
      }

      if (currenPageParms) {

        if (currenPageParms !== "null") {
          setcurrentPage(currenPageParms);
          //console.log(currentPage);
        }
      }
      if (dynamicFilterParms === null) {
        var dFilter = "";
      } else {
        var dFilter = dynamicFilterParms.toString();
        //const dFilterChecked = document.getElementById("d-filter-"+dFilter).checked = "checked";
      }

      setLoading(true);

      try {
        let item = await axios.get(
          `${configData.ADMIN_URL}/api/commercial-designs?category=${
            params.id ? params.id : ""
          }&page=${currenPageParms ? currenPageParms : 1}&psize=${
            sizeParams ? sizeParams : ""
          }&sort=&dcode=${plan_noParams ? plan_noParams : ""}&pwMin=${
            minWidthParams ? minWidthParams : ""
          }&pwMax=${maxWidthParams ? maxWidthParams : ""}&pdMin=${
            minDepthParams ? minDepthParams : ""
          }&pdMax=${maxDepthParams ? maxDepthParams : ""}&dynamic_filter=${
            dFilter ? dFilter : ""
          }`
        );
        setPaginateUrl(
          `?slct_page=commercial-designs&a=1&psize=${
            sizeParams ? sizeParams : ""
          }&sort=&dcode=${plan_noParams ? plan_noParams : ""}&pwMin=${
            minWidthParams ? minWidthParams : ""
          }&pwMax=${maxWidthParams ? maxWidthParams : ""}&pdMin=${
            minDepthParams ? minDepthParams : ""
          }&pdMax=${maxDepthParams ? maxDepthParams : ""}&dynamic_filter=${
            dFilter ? dFilter : ""
          }`
        );
        setTodos(item.data.data);
        setCatSeo([]);
        setTotalTodos(item.data.total);
        setcurrentPage(currenPageParms ? currenPageParms : 1);
        setLoading(false);
        //SEO DATA
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=commercial-designs`
        );
        setSeo(pageSeo.data);
        console.log(pageSeo.data);
        setPage_content(pageSeo.data[0].page_content);
        setPage_name("");
        setSub_title("");
        setShort_des_heading("");
        setShort_description("");

        let category = await axios.get(
          `${configData.ADMIN_URL}/api/commercial-designs/categories`
        );
        setCategory(category.data);

        if (params.id) {
          let categorySeo = category.data.filter((categorySeo) =>
            categorySeo.id === Number(params.id) ? Number(params.id) : ""
          );
          setCatSeo(categorySeo);
          setSeo([]);
        }
        setLoading(false);
        ///// wishlist //////////
        if (onBoarding) {
          const config = {
            headers: {
              Accept: "application/json",
              "x-api-key": configData.X_API_KEY,
              "x-api-secret": configData.X_API_SECRET,
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          let listing = await axios.get(
            `${configData.ADMIN_URL}/api/users/wishlist`,
            config
          );
          const result = item.data.data.filter((cert) =>
            listing.data.data.find((detail) => detail.id === cert.id)
          );
          
          {
            result.map((list) =>
              document
                .getElementById(`${list.id}-removed`)
                .classList.remove("d-none")
            );
          }

          {
            result.map((list) =>
              document.getElementById(list.id).classList.add("d-none")
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchProducts();
  }, [location.key]);

  async function removeWishList(event) {
    document.getElementById(event.currentTarget.id).classList.add("d-none");
    var id = event.currentTarget.id.replace("-removed", "");
    document.getElementById(id).classList.remove("d-none");
    try {
      const response = await fetch(
        `${configData.ADMIN_URL}/api/services/wishlist/remove`,
        {
          method: "POST",
          body: JSON.stringify({
            id: Number(id),
          }),
          headers: {
            Accept: "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) {
        toast.error("Please try again", { autoClose: 1000 });
      }
      const result = await response.json();
      if (result.status === "success") {
        toast.success("Item Removed Successfully", { autoClose: 1000 });
      }
      
    } catch (err) {
      console.log(err);
      toast.error("Please try again", { autoClose: 1000 });
    }
  }

  async function value1(event) {
    var size = event.target.value;
    localStorage.setItem("size", size);
    history.push(
      `?slct_page=commercial-designs&a=1&psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&sort=&dcode=${plan_no}&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercial-designs?category=${
        params.id ? params.id : ""
      }&psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&sort=&dcode=${plan_no}&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    setTodos(item.data.data);
    //console.log(item.data);
    setTotalTodos(item.data.total);
    if(item.data.total === 0) {
      setMessage(true);
    }
    else {
      setMessage(false);
    }
   
  }

  const closeSideBar = () => {
    setOpen(false);
  }

  async function value5(event) {
    //console.log(plan_no);
    setOpen(false);
    localStorage.setItem("plan_no", plan_no);

    localStorage.setItem("maxWidth", maxWidth);
    localStorage.setItem("minWidth", minWidth);

    localStorage.setItem("maxDepth", maxDepth);
    localStorage.setItem("minDepth", minDepth);

    event.preventDefault();
    history.push(
      `?slct_page=commercial-designs&a=1&psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&sort=&dcode=${plan_no}&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercial-designs?category=${
        params.id ? params.id : ""
      }&psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&sort=&dcode=${plan_no}&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${appendFilter.toString()}`
    );
    setOpen(false);
    setTodos(item.data.data);
    setTotalTodos(item.data.total);
    if(item.data.total === 0) {
      setMessage(true);
    }
    else {
      setMessage(false);
    }
  }

  //Mobile Apply Filter
  async function HandleSubmit(event) {
    event.preventDefault();
    setOpen(false);
    history.push(
      `?slct_page=const&a=1&psize=${size}&sort=&dcode=${plan_no}&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercial-designs?category=${
        params.id ? params.id : ""
      }&plan_no=${plan_no}&psize=${size}&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}`
    );
    setTodos(item.data.data);
    setTotalTodos(item.data.total);
    if(item.data.total === 0) {
      setMessage(true);
    }
    else {
      setMessage(false);
    }
  }
  async function appendNewFilter(index, id, key) {
    appendFilter.push(id);
    setAppendFilter(appendFilter);
    const toFindDuplicates = (appendFilter) =>
      appendFilter.filter(
        (item, index) => appendFilter.indexOf(item) !== index
      );
    const duplicateElements = toFindDuplicates(appendFilter);
   
    function removeFromArray(original, remove) {
      return original.filter((value) => !remove.includes(value));
    }
    let uniqueFilters = removeFromArray(appendFilter, duplicateElements);
    setAppendFilter(uniqueFilters);
    var filterResult = appendFilter.toString();
    localStorage.setItem("filterResult", filterResult);
    history.push(
      `?slct_page=commercial-designs&a=1&psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&sort=&dcode=${plan_no}&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${uniqueFilters}`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercial-designs?category=${
        params.id ? params.id : ""
      }&psize=${
        localStorage.getItem("size") ? localStorage.getItem("size") : ""
      }&sort=&dcode=${plan_no}&pwMin=${minWidth}&pwMax=${maxWidth}&pdMin=${minDepth}&pdMax=${maxDepth}&dynamic_filter=${uniqueFilters}`
    );

   setTodos(item.data.data);
    setTotalTodos(item.data.total);
    if(item.data.total === 0) {
      setMessage(true);
    }
    else {
      setMessage(false);
    }
  }
  const pages = [];
  for (let i = 1; i <= Math.ceil(totalTodos / itemsPerPage); i++) {
    pages.push(i);
  }
  const paginate = (pageNumber) => setcurrentPage(pageNumber);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={() => paginate(number)}
          className={currentPage === number ? "active" : null}
        >
          <NavLink
            onClick={() => paginate(number)}
            to={paginateUrl + "&currentpage=" + number}
            className="page-link"
          >
            {number}
          </NavLink>
        </li>
      );
    } else {
      return null;
    }
  });

  async function handleNextbtn(event) {
    setOpen(false);
    localStorage.setItem("plan_no", plan_no);
    localStorage.setItem("maxWidth", maxWidth);
    localStorage.setItem("minWidth", minWidth);
    localStorage.setItem("maxDepth", maxDepth);
    localStorage.setItem("minDepth", minDepth);
    event.preventDefault();
    history.push(
      `?slct_page=commercial-designs&a=1&psize=${
        sizeParams ? sizeParams : ""
      }&sort=&dcode=${plan_noParams ? plan_noParams : ""}&pwMin=${
        minWidthParams ? minWidthParams : ""
      }&pwMax=${maxWidthParams ? maxWidthParams : ""}&pdMin=${
        minDepthParams ? minDepthParams : ""
      }&pdMax=${
        maxDepthParams ? maxDepthParams : ""
      }&dynamic_filter=${appendFilter.toString()}&currentpage=${
        Number(currentPage) + 1
      }`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercial-designs?psize=${
        sizeParams ? sizeParams : ""
      }&sort=&dcode=${plan_noParams ? plan_noParams : ""}&pwMin=${
        minWidthParams ? minWidthParams : ""
      }&pwMax=${maxWidthParams ? maxWidthParams : ""}&pdMin=${
        minDepthParams ? minDepthParams : ""
      }&pdMax=${
        maxDepthParams ? maxDepthParams : ""
      }&dynamic_filter=${appendFilter.toString()}&currentpage=${
        Number(currentPage) + 1
      }`
    );
    setTodos(item.data.data);
    setTotalTodos(item.data.total);
    setcurrentPage(Number(currentPage) + 1);
    if(item.data.total === 0) {
      setMessage(true);
    }
    else {
      setMessage(false);
    }
    if (Number(currentPage) + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  }

  async function handlePrevbtn(event) {
    setcurrentPage(currentPage - 1);
    setOpen(false);
    localStorage.setItem("plan_no", plan_no);
    localStorage.setItem("maxWidth", maxWidth);
    localStorage.setItem("minWidth", minWidth);
    localStorage.setItem("maxDepth", maxDepth);
    localStorage.setItem("minDepth", minDepth);
    event.preventDefault();
    history.push(
      `?slct_page=commercial-designs&a=1&psize=${
        sizeParams ? sizeParams : ""
      }&sort=&dcode=${plan_noParams ? plan_noParams : ""}&pwMin=${
        minWidthParams ? minWidthParams : ""
      }&pwMax=${maxWidthParams ? maxWidthParams : ""}&pdMin=${
        minDepthParams ? minDepthParams : ""
      }&pdMax=${
        maxDepthParams ? maxDepthParams : ""
      }&dynamic_filter=${appendFilter.toString()}&currentpage=${
        Number(currentPage) - 1
      }`
    );
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/commercial-designs?psize=${
        sizeParams ? sizeParams : ""
      }&sort=&dcode=${plan_noParams ? plan_noParams : ""}&pwMin=${
        minWidthParams ? minWidthParams : ""
      }&pwMax=${maxWidthParams ? maxWidthParams : ""}&pdMin=${
        minDepthParams ? minDepthParams : ""
      }&pdMax=${
        maxDepthParams ? maxDepthParams : ""
      }&dynamic_filter=${appendFilter.toString()}&currentpage=${
        Number(currentPage) - 1
      }`
    );
    setTodos(item.data.data);
    setTotalTodos(item.data.total);
    setcurrentPage(Number(currentPage) - 1);
    if(item.data.total === 0) {
      setMessage(true);
    }
    else {
      setMessage(false);
    }

    if ((Number(currentPage) - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  }

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  return (
    <>
      {seo.map((item, index) => (
        <Helmet key={index}>  
        <title>{item.pagetitle}</title>
        <meta name="description" content={item.metadescription} />
        <meta name="keywords" content={item.metakeywords} />
        <meta property="og:type" content="Page" />
        <meta property="og:title" content={item.pagetitle} />
        <meta property="og:description" content={item.metadescription} />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={`${configData.CANONICAL_URL}commercial-designs`} /> 
        <meta property="og:site_name" content={configData.SITE_NAME} />
        <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.banner}`} />
        </Helmet> 
      ))}

      {catSeo.map((item, index) => (
        <Helmet key={index}>
          <title>{item.meta_title}</title>
          <meta name="description" content={item.meta_description} />
          <meta name="keywords" content={item.meta_keywords} />
          <meta property="og:type" content="Page" />
          <meta property="og:title" content={item.meta_title} />
          <meta property="og:description" content={item.meta_description} />
          <meta property="og:url" content={window.location.href} />
          <link rel="canonical" href={`${configData.CANONICAL_URL}commercial-designs`} />
          <meta property="og:site_name" content={configData.SITE_NAME} />
        </Helmet>
      ))}

      <Header2 />

      <div className="page-content">
        {seo.map((item, index) => (
          <InteriorSection1
            key={index}
            page_name={item.page_name}
            short_des_heading={item.short_des_heading}
            sub_title={item.sub_title}
            short_description={item.short_description}
          />
        ))}

        {catSeo.length > 0 ? 
        <>
        {catSeo.map((data, index) => (
         <div key={index}
         className="section-full  p-t30 p-b30 bg-no-repeat bg-pink pt-10"
       >
         <div className="container">
           <div className="section-content">
             <div className="row">
               
               <div className="col-xl-12 col-lg-12 col-md-12">
                 <div>
                   <h2 className="header-h2 fw-600">
                     {data.sub_title ? data.sub_title : ""}
                   </h2>

                   <h1 className="header-h1 fw-600">
                     {data.title ? data.title : ""}
                   </h1>

                   <h3 className="header-h3">
                     {data.short_des_heading ? data.short_des_heading : ""}
                   </h3>
                   <p className="fs-16 m-t0 sx-tilte fsm-14  mt-3 mb-3 text-right-mobile">
                     <HeaderText
                       short_description={ReactHtmlParser(
                        data.short_description ? data.short_description : ""
                       )}
                     />
                   </p>
                   <div className="float-left mb-2">
                    <EnquireNow />
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
        ))}
        
          </>
          
       : ''}

        {/* SHOP SECTION START */}
        <div className="section-full bg-white mobile-page-padding">
          <div className="p-3 desktop-hidden bg-gray3">
            <NavLink to={"./"}> Home </NavLink> {">"}{" "}
            <span className="red fw-500">Commercial Designs</span>{" "}
            <span onClick={openMenu} className="float-right filterBox">
              <i className="fa fa-filter red"></i> Filters
            </span>
          </div>
          <Menu
            noOverlay
            isOpen={isOpen}
            onOpen={handleIsOpen}
            onClose={handleIsOpen}
          >
            <h4>Filters</h4>
            <Tabs tabPosition="left">
              <TabPane tab="Categories" key="2">
                <div className="widget widget_tag_cloud widget_services mt--50 for-commercial-filter-mobile-view">
                  <div className="p-a20 tagcloud">
                  <ul>
                    {category.map((item, index) => (
                      <NavLink
                       onClick={closeSideBar}   
                        key={index}
                        className="styleType_cat"
                        to={"/commercial-designs/" + item.id}
                      >
                        {item.title}
                      </NavLink>
                    ))}
                    </ul>
                  </div>
                </div>
              </TabPane>

              <TabPane tab="Design Code" key="9">
                <div className="widget widget_services mt--100">
                  <ul className="pl-3">
                    <li>
                      <input
                        id="plans_no"
                        className="form-control design_code_input "
                        type="text"
                        name="plan_no"
                        placeholder={
                          plan_noParams ? plan_noParams : "Enter Code"
                        }
                        onKeyDown={onKeyDown}
                        onChange={(e) => setPlan_no(e.target.value)}
                      />

                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary ais-RangeInput-submit"
                          onClick={value5}
                          type="button"
                        >
                          Go
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </TabPane>

              <TabPane tab="Plot Dimensions" key="5">
                <div className="widget widget_services mt--100">
                  <ul className="pl-3">
                    <li>
                      <h4 className="widget-title fs-16 mb-2">Width</h4>
                      <div className="d-flex">
                        <input
                          name="minWidth"
                          value={minWidth}
                          onChange={(e) => setMinWidth(e.target.value)}
                          className="w-50 design_code_input mr-0 rounded-0 "
                          type="text"
                          placeholder="Min"
                        />

                        <input
                          name="maxWidth"
                          value={maxWidth}
                          onChange={(e) => setMaxWidth(e.target.value)}
                          className="w-50  design_code_input rounded-0 "
                          type="text"
                          placeholder="Max"
                        />
                      </div>
                      <h4 className="widget-title fs-16 mb-2">Depth</h4>
                      <div className="d-flex">
                        <input
                          name="minWidth"
                          value={minDepth}
                          onChange={(e) => setMinDepth(e.target.value)}
                          className="w-50 design_code_input mr-0 rounded-0 "
                          type="text"
                          placeholder="Min"
                        />

                        <input
                          name="maxWidth"
                          value={maxDepth}
                          onChange={(e) => setMaxDepth(e.target.value)}
                          className="w-50  design_code_input rounded-0 "
                          type="text"
                          placeholder="Max"
                        />
                      </div>

                      <input
                        type="submit"
                        className="ais-RangeInput-submit h-35"
                        value="Go"
                        onClick={value5}
                      />
                    </li>
                  </ul>
                </div>
              </TabPane>

              <TabPane tab="Plot Area" key="1">
                <div className="widget widget_services mt--100">
                  <ul className="pl-3">
                    <li>
                      <input
                        onChange={(e) => setSize(e.target.value)}
                        value="0x1000"
                        type="radio"
                        id="0x1000m"
                        name="size"
                      />
                      <label className="fw-400" for="0x1000m">
                        Upto 1000sqft
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        onChange={(e) => setSize(e.target.value)}
                        value="1000x2000"
                        id="1000x2000m"
                        name="size"
                      />
                      <label className="fw-400" for="1000x2000m">
                        1000-2000sqft
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        onChange={(e) => setSize(e.target.value)}
                        value="2000x3000"
                        id="2000x3000m"
                        name="size"
                      />
                      <label className="fw-400" for="2000x3000m">
                        2000-3000sqft
                      </label>
                    </li>
                    <li>
                      <input
                        onChange={(e) => setSize(e.target.value)}
                        value="3000x4000"
                        type="radio"
                        id="3000x4000m"
                        name="size"
                      />
                      <label className="fw-400" for="3000x4000m">
                        {" "}
                        3000-4000sqft
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        onChange={(e) => setSize(e.target.value)}
                        value="4000x6000"
                        id="4000x6000m"
                        name="size"
                      />
                      <label className="fw-400" for="4000x6000m">
                        4000-6000sqft{" "}
                      </label>
                    </li>
                  </ul>
                </div>
              </TabPane>

              {/*<TabPane tab="Plot Dimensions" key="5">
                            <div className="tagcloud p-a20 bg-white">
                             
                                <h4 className="widget-title fs-16 mb-2">Width</h4>
                                <div className="d-flex">
                            <input  
                              name="minWidth"
                              value={minWidthParams ? minWidthParams : minWidth}
                              onChange={(e) => setMinWidth(e.target.value)} 
                              className="w-50 design_code_input mr-0 rounded-0 " type="text" placeholder="Min" />

                            <input 
                            name="maxWidth"
                            value={maxWidthParams ? maxWidthParams : maxWidth}
                            onChange={(e) => setMaxWidth(e.target.value)} 
                            className="w-50  design_code_input rounded-0 " type="text" placeholder="Max" />

                            

                            </div>

                            <h4 className="widget-title fs-16 mb-2">Depth</h4>
                            <div className="d-flex">
                            <input  
                              name="minWidth"
                              value={minDepth}
                              onChange={(e) => setMinDepth(e.target.value)} 
                              className="w-50 design_code_input mr-0 rounded-0 " type="text" placeholder="Min" />

                            <input 
                            name="maxWidth"
                            value={maxDepth}
                            onChange={(e) => setMaxDepth(e.target.value)} 
                            className="w-50  design_code_input rounded-0 " type="text" placeholder="Max" />

                            

                            </div>

                             
                            <input type="submit"  className="ais-RangeInput-submit h-35" value="Go" onClick={value5} />
                               
                            </div>
                            </TabPane>*/}

              {/*Dynamic filter tabs start
                            
                            <TabPane tab="other" key="10">
                            {Object.entries(filters).map(([key, item], i) => (
                              <div className="widget widget_tag_cloud widget_services mt--60">
                                <div className="tagcloud p-a20 bg-white">
                                     <ul className="">
                                     <h5 className="widget-title fs-14">{key}</h5>
                                         {item.filters.map((data, index) => (
                                          <li>
                                              <input className="form-control design_code_input" onChange={() => {appendNewFilter(index, data.id, item.name);}} 
                                               type={item.type} value={data.name} id={data.id} name={item.name} />
                                              <label className="fw-400" for={data.id}>{data.name}</label>
                                            </li>
                                          ))}
                                    </ul>
                                    </div>
                                 </div>
                                    ))}
                            </TabPane>
                          
                          {/*Dynamic filter tabs End*/}

              {/*<TabPane tab="Design Code" key="9">
                             <div className="p-a20">

                              <input type="text"  className="form-control design_code_input "
                                  type="text" 
                                  name="plan_no"
                                  placeholder="Enter Code"
                                  value={plan_noParams ? plan_noParams : plan_no}
                                  onChange={(e) => setPlan_no(e.target.value)} aria-describedby="basic-addon2" />
                              </div>
                            </TabPane>*/}
            </Tabs>
            <div className="bottom-btn">
              <div className="mt-4">
                <a onClick={clearFilters} className="fs-19 v-sub">
                  Reset
                </a>
                <a onClick={HandleSubmit} className="float-right filter-btn">
                  Apply Filters
                </a>
              </div>
            </div>
          </Menu>
          <div className="container">
            <div className="product-filter-wrap d-flex   m-b30"></div>
            <div className="row d-flex justify-content-center">
              {/* SIDE BAR START */}
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 sticky_column mb-10 mobile-hidden">
                <StickyBox offsetTop={80} offsetBottom={20}>
                  <div className="side-bar p-a20 bg-gray3">
                    {/* SEARCH */}

                    {/*PRICE RANGE SLIDER*/}
                    <div className="widget product-range-slider1">
                      <div className="m-b10">
                        <h4 className="widget-title fw-500">
                          Filters{" "}
                          <a
                            onClick={clearFilters}
                            className="clearFilters ais-ClearRefinements-button"
                          >
                            Clear Filters
                          </a>
                        </h4>
                      </div>
                    </div>
                    {/* Categories  */}
                    <form>
                      <div className="widget widget_services ">
                        <ul className="p-a20 bg-white">
                          <h4 className="widget-title fs-18">Plot Area</h4>

                          <li>
                            <input
                              onChange={value1}
                              value="0x1000"
                              type="radio"
                              id="0x1000"
                              name="size"
                            />
                            <label className="fw-400" for="0x1000">
                              Upto 1000sqft
                            </label>
                          </li>
                          <li>
                            <input
                              type="radio"
                              onChange={value1}
                              value="1000x2000"
                              id="1000x2000"
                              name="size"
                            />
                            <label className="fw-400" for="1000x2000">
                              1000-2000sqft
                            </label>
                          </li>
                          <li>
                            <input
                              type="radio"
                              onChange={value1}
                              value="2000x3000"
                              id="2000x3000"
                              name="size"
                            />
                            <label className="fw-400" for="2000x3000">
                              2000-3000sqft
                            </label>
                          </li>
                          <li>
                            <input
                              onChange={value1}
                              value="3000x4000"
                              type="radio"
                              id="3000x4000"
                              name="size"
                            />
                            <label className="fw-400" for="3000x4000">
                              {" "}
                              3000-4000sqft
                            </label>
                          </li>
                          <li>
                            <input
                              type="radio"
                              onChange={value1}
                              value="4000x6000"
                              id="4000x6000"
                              name="size"
                            />
                            <label className="fw-400" for="4000x6000">
                              4000-6000sqft{" "}
                            </label>
                          </li>
                        </ul>
                      </div>
                      {/* NEWSLETTER */}

                      <div className="widget widget_tag_cloud widget_services">
                        <div className="tagcloud p-a20 bg-white">
                          <h4 className="widget-title fs-18 mb-3">
                            Categories
                          </h4>
                          {category.map((item, index) => (
                            <NavLink
                              key={index}
                              to={"/commercial-designs/" + item.id}
                            >
                              {item.title}
                            </NavLink>
                          ))}
                        </div>
                      </div>

                      <div className="widget widget_tag_cloud widget_services">
                        <div className="tagcloud p-a20 bg-white">
                          <h4 className="widget-title fs-18 mb-4">
                            Plot Dimensions
                          </h4>
                          <h4 className="widget-title fs-16 mb-2">Width</h4>
                          <div className="d-flex">
                            <input
                              name="minWidth"
                              value={minWidth}
                              onChange={(e) => setMinWidth(e.target.value)}
                              className="w-50 design_code_input mr-0 rounded-0 "
                              type="text"
                              placeholder="Min"
                            />

                            <input
                              name="maxWidth"
                              value={maxWidth}
                              onChange={(e) => setMaxWidth(e.target.value)}
                              className="w-50  design_code_input rounded-0 "
                              type="text"
                              placeholder="Max"
                            />
                          </div>

                          <h4 className="widget-title fs-16 mb-2">Depth</h4>
                          <div className="d-flex">
                            <input
                              name="minWidth"
                              value={minDepth}
                              onChange={(e) => setMinDepth(e.target.value)}
                              className="w-50 design_code_input mr-0 rounded-0 "
                              type="text"
                              placeholder="Min"
                            />

                            <input
                              name="maxWidth"
                              value={maxDepth}
                              onChange={(e) => setMaxDepth(e.target.value)}
                              className="w-50  design_code_input rounded-0 "
                              type="text"
                              placeholder="Max"
                            />
                          </div>

                          <input
                            type="submit"
                            className="ais-RangeInput-submit h-35"
                            value="Go"
                            onClick={value5}
                          />
                        </div>
                      </div>

                      <div className="widget widget_tag_cloud widget_services">
                        <div className="tagcloud p-a20 bg-white">
                          <h4 className="widget-title fs-18 mb-3">
                            Design Code
                          </h4>

                          <div class="input-group mb-3">
                            <input
                              type="text"
                              id="plan_no"
                              className="form-control design_code_input w-50"
                              name="plan_no"
                              placeholder={
                                plan_noParams ? plan_noParams : "Enter Code"
                              }
                              onKeyDown={onKeyDown}
                              onChange={(e) => setPlan_no(e.target.value)}
                              aria-describedby="basic-addon2"
                            />
                            <div class="input-group-append">
                              <button
                                class="btn btn-outline-secondary ais-RangeInput-submit"
                                onClick={value5}
                                type="button"
                              >
                                Go
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </StickyBox>
              </div>
              {/* SIDE BAR END */}

              <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 m-b30 mtm--30">
                {loading ? (
                  <Services />
                ) : (
                  <>
                    <div className="row">
                      {message ? (
                        <>
                          <div className="text-center">
                            <img
                              alt="No Data Found"
                              className="w-50"
                              src="assets/images/no-data-empty-data.jpg"
                            />
                            <h3>No Data Found!</h3>
                          </div>
                        </>
                      ) : null}
                      <>
                        {todos.map((item, index) => (
                          <div
                            key={index}
                            className="col-lg-4 col-md-4 col-sm-6 col-6 m-b10 p-2 mm-b30 mobile-grip"
                          >
                            <div className="sx-box sx-product-box overflow-hide shadow-cs">
                             
                              <div className="sx-thum-bx">
                                <NavLink
                                  to={"/" + item.slug + "/126/" + item.id}
                                >
                                  <div
                                    className="bg-contain bg-center h-200 bg-no-repeat"
                                    style={{
                                      backgroundImage: `url('${
                                        url + item.primary_image
                                      }')`,
                                    }}
                                  />
                                </NavLink>
                                <div className="item-cart-view">
                                  
                                  <div
                                    id={item.id}
                                    onClick={addItemToWishlist}
                                    className="item-cart-btn"
                                  >
                                    {" "}
                                    <i className="fa fa-heart-o" />
                                  </div>
                                  <div
                                    id={`${item.id}-removed`}
                                    onClick={removeWishList}
                                    className="item-cart-btn wishlist-added d-none"
                                  >
                                    {" "}
                                    <i className="fa fa-heart-o" />
                                  </div>
                                </div>
                              </div>
                              <div className="p-t10 pl-2 pr-2 text-left">
                                <h3 className="sx-title fsm-15 fs-16">
                                  <NavLink
                                    to={"/" + item.slug + "/126/" + item.id}
                                  >
                                    {item.title}
                                  </NavLink>
                                </h3>
                                <ul className=" m-b10 category_info dm-flex">
                                  <li>
                                    <i class="fa fa-expand"></i> {item.width}{" "}
                                    sqft
                                  </li>
                                  <li className="pl-20">
                                    <i class="fa fa-building-o"></i>{" "}
                                    {item.floor_count}
                                  </li>
                                </ul>
                                <p className="category_code_text">
                                  Code: {item.design_code}{" "}
                                  <NavLink
                                    to={"/" + item.slug + "/126/" + item.id}
                                  >
                                    <span className="float-right view_details mobile-hidden">
                                      View details
                                    </span>
                                    <span className="float-right desktop-hidden">
                                      <i className="fa fa-eye"></i>
                                    </span>
                                  </NavLink>
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    </div>
                  </>
                )}
                <div className="text-center">
                  {message ? (
                    ""
                  ) : (
                    <ul className="pageNumbers">
                      <li>
                        <button
                          onClick={handlePrevbtn}
                          disabled={currentPage === pages[0] ? true : false}
                        >
                          <i
                            class="fa fa-long-arrow-left"
                            aria-hidden="true"
                          ></i>{" "}
                          Previous
                        </button>
                      </li>
                      {pageDecrementBtn}
                      {renderPageNumbers}
                      {pageIncrementBtn}

                      <li>
                        <button
                          onClick={handleNextbtn}
                          disabled={
                            currentPage === pages[pages.length - 1]
                              ? true
                              : false
                          }
                        >
                          Next{" "}
                          <i
                            class="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SHOP SECTION END */}
      </div>
      {catSeo.length > 0 ? 
        <>
        {catSeo.map((data) => (
        <ServiceBottom page_content={data.footer_description} />
        ))}
        </>
       : <ServiceBottom page_content={page_content} />}
      
      
      <Footer loginRedirection={loginRedirection} />
    </>
  );
};

export default CommercialDesigns;
