import React from "react";
import Header2 from "../Common/Header2";
import Footer from "../Common/Footer";
import Recommended from "./../Elements/Recommended";
import HomeCta from "./../Elements/HomeCta";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import About5 from "./../Elements/About5";
import InteriorSection1 from "./../Elements/InteriorSection1";
import configData from "./../../config.js";

const AskExpert = () => {
  const [seo, setSeo] = useState([]);

  useEffect(() => {
    async function fetchProducts() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=viewdetail.php`
        );
        setSeo(pageSeo.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchProducts();
  }, []);

  return (
    <>
      {seo.map((item, index) => (
        <Helmet key={index}>
          <title>{item.pagetitle}</title>
          <meta name="description" content={item.metadescription} />
          <meta name="keywords" content={item.metakeywords} />
          <link rel="canonical" href={`${configData.CANONICAL_URL}viewdetail.php`} /> 
        </Helmet>
      ))}
      <Header2 />
      <div className="page-content">
        {seo.map((item) => (
          <InteriorSection1
            page_name={item.page_name}
            short_des_heading={item.short_des_heading}
            sub_title={item.sub_title}
            short_description={item.short_description}
          />
        ))}
        <HomeCta />
        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>
      <About5 page_content="Thinking about constructing a house, office or anything else? You have come to the right platform. Nakshewala.com is an online consultancy emerging in the field of architecture. Nakshewala is providing customized designing services as per your need and requirements. We take care of Vastu the traditional element of Indian architecture. Your satisfaction is our priority, hence we are providing customized services to meet your each and every expectations.
" />
      <Footer />
    </>
  );
};
export default AskExpert;
