import React from 'react';
import { useState } from "react";

const services = [
    {
        count: '01',
        title: 'Online Service Provider',
        description: 'When you go with a local vendor or etc, I can bet you need to kill your half of the time in travelling, so the solution is here NaksheWala.com. Being an online architectural consultancy we discuss thing over phone call, chat or mail, hence its saves 70% of your time, so why go anywhere when you get your designs done sitting comfortably at your home.'
    },
    {
        count: '02',
        title: 'Expert Guidance of Profession Team',
        description: 'We have innovative & expert team of professional architects so we provide expert guidance to make your dream home and Completed solution of Architecture Designing with detailed drawings. Our Expert team support you till late night and weekend as well.'
    },
    {
        count: '03',
        title: 'Thousands of Exclusive Designs',
        description: 'NaksheWala.com successfully completed more than thousands of projects in various cities of India and across the world, no one can match the experience, quality, creativity of our architecture plans, 3D elevation and interiors collection. We have made thousands of designs exclusively for our customers and we are obliged to show you them in our collection.'
    },
    {
        count: '04',
        title: 'Customer Satisfaction Oriented Service',
        description: 'As we have stated above NaksheWala.com is highly devoted towards customer’s satisfaction and we understand the anxiety and uncertainly most of the customers face when they are going to build their dream project whether it is a dream house, restaurant or any other architecture design. It sometimes seems confusing whether to go with this or that, we at Nakshewala.com give you cent percent assurance to satisfy you by our creative, customized and exclusive designs. We are here whenever you need us, with a customer service team that is unparalleled in the industry. We are at NaksheWala.com offering customer satisfaction oriented service so that they can make their dream project easily with our professional guidance and coordination in an effective manner.'
    },
    {
        count: '05',
        title: 'Cost Effective Plans with Competitive Service Charges',
        description: 'NaksheWala.com understands that each every individual has its own taste, need, preference and budget, hence, here we are designing ”Customized Floor Plans” ”Customized 3D Elevations” “Customized Interiors” for your dream project with most cost effective way. Since we love to satisfy our customer here we provide free modifications in the designs, hence you’ll also save big Bingo!!! We are here to give services to as many people as we can so the cost shouldn’t be a barrier in this therefore we have set a very standard cost for the designing services.'
    },
    {
        count: '06',
        title: 'On Time and Faster Delivery of Drawings',
        description: 'Why would you wait for days or weeks for a home plan when you can get in hours? So many things can lead to delays during the construction process, why let the design phase be one of them?  By being an online architecture services provider we share all your designs through online mode, our dedicated team focuses on faster delivery of drawings as per your convenience.'
    },
	 {
        count: '07',
        title: 'Builders and Architect Trust Us',
        description: 'No one sells more plans to the professional builder community than us. That’s because builders know they can trust our drawings to be comprehensive, accurate, competitive and complete.For any queries you can whenever reach us through call, mail or whatsapp, you can also fill our query forms we assure you to respond you within 24 hours.  '
    },
]

var bgimg1 = require('./../../images/background/bg-5.png');
var bgimg2 = require('./../../images/background/cross-line2.png');




const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 200) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? <span className="red cursor-pointer">...read more</span> : <span className="red cursor-pointer">show less</span>}
      </span>
    </p>
  );
};


class Choose extends React.Component {
    render() {

        return (
            <>
                <div className="section-full  mobile-page-padding bg-gray  p-t80 p-b50 bg-repeat overflow-hide" style={{ backgroundImage: 'url(' + bgimg1.default + ')' }}>
                    <div className="container">
                        <div className="large-title-block text-center">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="sx-separator-outer separator-left">
                                    <div className="sx-separator bg-white bg-moving bg-repeat-x for-on-ly-re-son" style={{ backgroundImage: 'url(' + bgimg2.default + ')' }}>
                                        <h3 className="sep-line-one reason" style={{background:"#eef1f2"}}><span style={{color:"#c91006"}}>Reason</span> to Choose Us?</h3>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                        </div>
                        <div className="section-content">
                            <div className="row number-block-two-outer">
                                {services.map((item, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                        <div className="number-block-two animate-in-to-top bdr-gray-light bdr-solid bdr-1">
                                            <div className="figcaption bg-white  p-a30 cont-ennt-crd">
                                                <h4 className="m-t0">{item.title}</h4>
                                                <p className="choose-crrd-txxt"><ReadMore>{item.description}</ReadMore></p>
													{/*<NavLink to={"/"} className="site-button-link">Read More</NavLink>*/}
                                                <div className="figcaption-number animate-in-to-top-content">
                                                    <span>{item.count}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Choose;