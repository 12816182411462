import React from "react";
import ContactForm from "./ContactForm";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import configData from "./../../config.js";
var default_url = `${configData.ADMIN_URL}/assets/homepage_setting/`;

const Banner2 = (props) => {
  var image = "img63bd490cc64cfarchitecture%20design.png";
  var link = "readymade-house-design.php";

  return (
    <>
      <div className="p-t30 p-b30 ">
        <div className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12">
                <ContactForm hTag={props.hTag} />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="">
                  <div className=" bg-no-repeat bg-cover bg-center overlay-wraper">
                    <div className="">
                      <div className="video-section-inner">
                        <div className="video-section-content video-section-content-2 mobile-hidden">
                          <a rel="noreferrer" href={link} target="_blank">
                            <LazyLoadImage
                              alt="Contact form"
                              effect="blur"
                              src={default_url + image}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner2;
