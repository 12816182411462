/*eslint react-hooks/exhaustive-deps: "off"*/
/*eslint no-lone-blocks: "off"*/
/*eslint no-unused-vars: "off"*/
import { userWishList } from '../../../features/user/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { clearWishList } from '../../../features/user/userSlice'
import { useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
import configData from "./../../../config.js";

const WishList = (props) => {
 
 const {  error, onBoarding, wishListStatus } = useSelector(
    (state) => state.user
  )
    const dispatch = useDispatch();
    useEffect(() => {
      if(onBoarding){
      async function fetchProducts() {
        const config = {
            "headers": {
              "Accept": "application/json",
              "x-api-key": configData.X_API_KEY,
              "x-api-secret": configData.X_API_SECRET,
              "Content-Type": "application/json",
              'Authorization': `Bearer ${localStorage.getItem('token')}` 
            },
            }
            let listing = await axios.get(`${configData.ADMIN_URL}/api/users/wishlist`,config)
           
            const result = props.item.filter(cert => listing.data.data.find(detail => detail.id === cert.id));
           
            {result.map((list) => (
              document.getElementById(`${list.id}-removed`).classList.remove("d-none")  
            ))}
           {result.map((list) => (
            document.getElementById(list.id).classList.add("d-none")
            ))}
        }
        fetchProducts();
      }
      if(wishListStatus) {
        toast.success("Service added to wishlist", {autoClose:3000})
      }
      if(error) {
        toast.error(error, {autoClose:3000}) 
      }
      dispatch(clearWishList())
     },[wishListStatus, error])
      const addItemToWishlist = event => {
        if(!onBoarding){
          toast.warning("Please login first", {autoClose:3000})
        }
        if(onBoarding){
          document.getElementById(event.currentTarget.id).classList.add("red")
          dispatch(userWishList({ type: 1, id: event.currentTarget.id }))
        }
       
        async function fetchProducts() {
          if(onBoarding){
        const config = {
            "headers": {
              "Accept": "application/json",
              "x-api-key": configData.X_API_KEY,
              "x-api-secret": configData.X_API_SECRET,
              "Content-Type": "application/json",
              'Authorization': `Bearer ${localStorage.getItem('token')}` 
            },
            }
            let listing = await axios.get(`${configData.ADMIN_URL}/api/users/wishlist`,config)
           
            const result = props.item.filter(cert => listing.data.data.find(detail => detail.id === cert.id));
         
            {result.map((list) => (
              document.getElementById(`${list.id}-removed`).classList.remove("d-none")  
            ))}
           {result.map((list) => (
            document.getElementById(list.id).classList.add("d-none")
            ))}
        }
        fetchProducts();
      }
      };

      async function removeWishList(event) {
        var id = event.currentTarget.id.replace('-removed',''); 
        try {
          const response = await fetch(`${configData.ADMIN_URL}/api/services/wishlist/remove`, {
            method: 'POST',
            body: JSON.stringify({
            "id": Number(id)
            }),
            "headers": {
            "Accept": "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            "Content-Type": "application/json",
            'Authorization': `Bearer ${localStorage.getItem('token')}` 
            },
          });
          if (!response.ok) {
            toast.error("Please try again", {autoClose:1000})
           
          }
          const result = await response.json();
         
          if(result.status === 'success') {
            toast.success("Item Removed Successfully", {autoClose:1000})
            document.getElementById(`removed`).classList.remove("red") 
          }
         
          } catch (err) {
          console.log(err)
          toast.error("Please try again", {autoClose:1000})
          } 
      }
return (
    <>
    <div id={props.id} onClick={addItemToWishlist} className="wishlist-btn"> <i id={props.id} className="fa fa-heart-o" /></div>
    <div id={`${props.id}-removed`} onClick={removeWishList} className="wishlist-btn d-none"> <i id="removed" className="fa fa-heart-o red" /></div>
    <NavLink to={"/login"}><ToastContainer /></NavLink>
    </>
)
}
export default WishList;