import React from "react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
//config url
import configData from "./../../config.js";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useContext } from "react";
import { IPInfoContext } from "ip-info-react";

const ContactForm = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [requirement, setRequirement] = useState("");
  const [countryInfo, setCountryInfo] = useState(null);
  const [ip, setIp] = useState(null);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [number, setNumber] = useState("");
  // const [value, setValue] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  // const [mobileError, setMobileError] = useState("");
  const [requirementError, setRequirementError] = useState("");

  const userInfo = useContext(IPInfoContext);

  // const enabled =
  //   requirement.length > 0 &&
  //   name.length > 0 &&
  //   email.length > 0 &&
  //   mobile.length > 0;

  useEffect(() => {
    const fetchCountryData = async () => {
      setIp(userInfo.ip);
      if (ip) {
        try {
          const response = await fetch(`https://api.country.is/${ip}`);
          const data = await response.json();
          setCountryInfo(data.country);
        } catch (error) {
          console.error("Error fetching country data:", error);
        }
      }
    };
    fetchCountryData();
  }, [userInfo.ip, ip]);

  const handleChangeNumber = (number) => {
    setNumber(number);
    if (number) {
      try {
        const phoneNumber = parsePhoneNumberFromString(number);

        const countryCode = `${
          phoneNumber ? phoneNumber.countryCallingCode : ""
        }`;
        const nationalNumber = phoneNumber ? phoneNumber.nationalNumber : "";
        setCode(countryCode);
        // setValue(nationalNumber);
        setMobile(code + nationalNumber);

        if (isValidPhoneNumber(number)) {
          console.log(number);
          setError(false);
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      }
    } else {
      setCode("");
      // setValue("");
      setError(true);
    }
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    setNameError(value.trim() === "" ? "Name is required" : "");
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailRegex.test(value) ? "Please enter a valid email" : "");
  };

  const handleRequirementChange = (e) => {
    const value = e.target.value;
    setRequirement(value);
    setRequirementError(value.trim() === "" ? "Requirement is required" : "");
  };

  async function saveQuery(event) {
    event.preventDefault();
  if (name && email && mobile && requirement) {
    let data = { name, email, mobile, requirement };
    if (isValidPhoneNumber("+" + mobile)) {
      let result = await fetch(`${configData.ADMIN_URL}/api/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": configData.X_API_KEY,
          "x-api-secret": configData.X_API_SECRET,
          Accept: "applicatiion/json",
        },

        body: JSON.stringify(data),
      });

      const item = await result.json();

      if (item.status === "success") {
        window.location.href = `/thanks.php`;
      } else {
        var msg = JSON.stringify(item.errors.email[0]);
        toast.error(msg, { autoClose: 3000 });
      }
    } else {
      toast.error("Invalid mobile number", { autoClose: 3000 });
    }
  } else {
    if (!mobile) {
      setError(true);
    }
    if (!name) {
      setNameError("Name is required");
    }
    if (!email) {
      setEmailError("Email is required");
    }
    if (!requirement) {
      setRequirementError("Requirement is required");
    }
  }
  }
  return (
    <>
      <div className="display-form">
        <div className="bg-white">
          <form className="bg-white shadow-css p-a20" action="#" method="post">
            {props.hTag ? (
              <h5 className="post-query text-center fw-600">
                Get Expert <span className="red">Advise Now</span>{" "}
                <span className="red v-align-m">
                  <i class="fa fa-long-arrow-right"></i>
                </span>
              </h5>
            ) : (
              <h3 className="post-query text-center fw-600">
                Get Expert <span className="red">Advise Now</span>{" "}
                <span className="red v-align-m">
                  <i class="fa fa-long-arrow-right"></i>
                </span>
              </h3>
            )}

            <div className="form-group">
              <label className="mt-0"> Name</label>
              <input
                type="text"
                value={name}
                onChange={handleNameChange}
                className="bg-gray-light bdr-gray-light form-control"
                placeholder="Enter Your Name"
              />
              {nameError && <p className="error-msg">{nameError}</p>}
            </div>

            <div className="form-group">
              <label className="mt-0">Email Address</label>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                className="bg-gray-light bdr-gray-light form-control"
                placeholder="Enter Email Address"
              />
              {emailError && <p className="error-msg">{emailError}</p>}
            </div>
            <div className="form-group">
              <label className="mt-0">Contact Number</label>
              {/* <input
                type="text"
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                className="bg-gray-light bdr-gray-light  form-control"
                placeholder="Enter Contact Number"
              /> */}
              <PhoneInput
                international
                className="contact-phone-control bg-gray-light"
                defaultCountry={countryInfo}
                countryCallingCodeEditable={false}
                value={number || ""} // Ensuring value is a string
                placeholder="Enter phone number"
                onChange={handleChangeNumber}
              />
              {error && (
                <p className="errorMsg">
                  Please enter a valid number and country code
                </p>
              )}
            </div>
            <div className="form-group">
              <label class="mt-0">Plot size & Requirements</label>
              <textarea
                value={requirement}
                onChange={handleRequirementChange}
                className="bg-gray-light bdr-gray-light form-control"
                placeholder="Enter Plot size & Requirements"
              ></textarea>
              {requirementError && (
                <p className="error-msg">{requirementError}</p>
              )}
            </div>

            <button
              onClick={saveQuery}
              // disabled={!enabled}
              type="button"
              value="submit"
              className="counter-btn w-100 site-cart-btn site-button-secondry mt-3"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default ContactForm;
