import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import configData from "./../../config.js";

const options = {
  loop: true,
  autoplay: false,
  center: false,
  items: 3,
  margin: 30,
  

  navText: [
    '<i class="fa fa-angle-left"></i>',
    '<i class="fa fa-angle-right"></i>',
  ],
  responsive: {
    0: {
      items: 1,
      margin: 10,
      dots: true,
      nav: true,
    },
    640: {
      items: 2,
      margin: 15,
    },
    768: {
      items: 2,
      margin: 15,
    },
    991: {
      items: 3,
      mouseDrag: false,
      dots: false,
      margin: 15,
    },
    1200: {
      items: 3,
      mouseDrag: false,
      dots: false,
    },
  },
};

class HomeBlog extends React.Component {
  // Constructor
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      DataisLoaded: false,
    };
  }

  //API CALL
  componentDidMount() {
    fetch(`${configData.ADMIN_URL}/api/blogs?currentpage=1`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json.data,
          DataisLoaded: true,
        });
      });
  }

  render() {
    const { DataisLoaded, items } = this.state;
    if (!DataisLoaded)
      return (
        <div>
          <p> Loading.... </p>{" "}
        </div>
      );

    return (
      <>
        <div className="">
          <div className="section-full   p-t30 p-b30  bg-gray">
            <div className="container">
              <div className="section-content">
                <div className="section-head">
                  <div className="row mb--20">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <div className="sx-separator-outer separator-left">
                        <div className="sx-separator bg-white bg-moving bg-repeat-x">
                          <h3 className="sep-line-one sep-line-one-two">
                            {" "}
                            &nbsp; Blogs &nbsp;
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <NavLink
                        to="/blog"
                        className="btn view_all float-right top-20"
                      >
                        View All
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" ">
                <OwlCarousel
                  className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center "
                  {...options}
                >
                  {items.slice(0, 3).map((item, index) => (
                    <div key={index} className="masonry-item ">
                      <div className="blog-post blog-grid date-style-2">
                        <div className="sx-post-media sx-img-effect img-reflection">
                          <NavLink to={`${item.blog_url}`}>
                            <LazyLoadImage
                              height="218px"
                              className="h-218 w-100"
                              alt={item.blog_title}
                              effect="blur"
                              src={
                                `${configData.ADMIN_URL}/assets/blog/` +
                                item.blog_image
                              }
                            />
                          </NavLink>
                        </div>
                        <div className="sx-post-info p-t10">
                          <div className="sx-post-meta ">
                            <ul>
                              <li className="post-date post-date-lising">
                                <strong>{item.created["day"]}</strong>{" "}
                                <span>{item.created["month"]}</span>{" "}
                              </li>
                              <li className="post-author">
                                <NavLink to={"/#"}>
                                  By <span className="red">Admin</span>
                                </NavLink>{" "}
                              </li>
                              {/*{item.category.map((cat, index) => (
                                                      <li className="post-comment"> <NavLink to={"blogs?category=" + cat.id}>{cat.category_name}</NavLink> </li>
                                                     ))}*/}
                            </ul>
                          </div>
                          <div className="sx-post-title ">
                            <h4 className="post-title mb-1 fs-20">
                              <NavLink to={`${item.blog_url}`}>
                                {item.blog_title}
                              </NavLink>{" "}
                            </h4>
                          </div>
                          <p className="mb-1 mt-2 line-h-2">
                            {item.blogdescription.slice(0, 100)}...
                            <NavLink
                              to={item.blog_url}
                              title="READ MORE"
                              rel="bookmark"
                              className="site-button-link red"
                            >
                              <u>Read more</u>
                            </NavLink>
                          </p>
                          <div className="sx-post-readmore"></div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomeBlog;
