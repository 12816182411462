/*eslint react-hooks/exhaustive-deps: "off"*/
import React from 'react';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import { useEffect, useState } from "react";
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import axios from "axios";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
//config url
import configData from "./../../config.js";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 250) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? <span className="red">...read more</span> : <span className="red">show less</span>}
      </span>
    </p>
  );
};


var bnrimg = require('./../../images/banner/hdr_bnr5ea91895128d6Our_clients.jpg');

const Clients = () => {
        
        let history = useHistory();
         async function refreshFilters(event) {
          event.preventDefault()
          setCity("");
          setState("");
          setName("");
          history.push(`/our-clients.php`);
          let item = await axios.get(`${configData.ADMIN_URL}/api/clients?city=${city}&state=${state}&name=${name}`)
          setTodos(item.data.data);
          setTotalTodos(item.data.total);
          toast.success('Filter Refreshed Successfully!', {autoClose:3000})  
          }

          const [seo, setSeo] = useState([]);
          const [todos, setTodos] = useState([]);
        //  const [loading, setLoading] = useState(false);
          const [totalTodos, setTotalTodos] = useState("");
          //const [totalRecords, setTotalRecords] = useState("");
          const [currentPage, setcurrentPage] = useState(1);
          const [itemsPerPage, setitemsPerPage] = useState(12);
          const [pageNumberLimit, setpageNumberLimit] = useState(5);
          const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
          const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
          //const [page_content, setPage_content] = useState("");
          const [name, setName] = useState("");
          const [city, setCity] = useState("");
          const [state, setState] = useState("");

         //Getting Url Params
          const location = useLocation();
          const search = useLocation().search;
          const currenPageParms = new URLSearchParams(search).get('currentpage');

          useEffect(() => {
            async function fetchProducts() {
                 if(currenPageParms) {
                   if(currenPageParms !== 'null') {
                   setcurrentPage(currenPageParms);
                 }
              }
                //setLoading(true);
             try {
              const item = await axios.get(`${configData.ADMIN_URL}/api/clients?city=${city}&state=${state}&name=${name}&page=${currenPageParms ? currenPageParms : 1}`)
              setTodos(item.data.data);
              setpageNumberLimit(5);
              setitemsPerPage(12);
              setTotalTodos(item.data.total);
              let pageSeo = await axios.get(`${configData.ADMIN_URL}/api/metapageurl?page_url=our-clients.php`)
               setSeo(pageSeo.data);
             } catch (error) {
              console.log(error);
             }
            }
            
            fetchProducts();
        }, [location.key]);


          //Mobile Apply Filter
          async function Search(event){
          event.preventDefault();
         
          history.push(`?city=${city}&state=${state}&name=${name}`);
          let item = await axios.get(`${configData.ADMIN_URL}/api/clients?city=${city}&state=${state}&name=${name}`)
          setTodos(item.data.data);
          setTotalTodos(item.data.total);
          
          
     }


          const pages = [];
  for (let i = 1; i <= Math.ceil(totalTodos / itemsPerPage); i++) {
    pages.push(i);
  }

  // Change page
  const paginate = pageNumber => setcurrentPage(pageNumber);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={() => paginate(number)}
          className={currentPage === number ? "active" : null}
        >
         <NavLink onClick={() => paginate(number)} to={`?city=${city}&state=${state}&name=${name}&currentpage=` + number} className='page-link'>
              {number}
            </ NavLink>
       
        </li>
      );
    } else {
      return null;
    }
  });


    async function handleNextbtn() {
      
      history.push(`?city=${city}&state=${state}&name=${name}&currentpage=${Number(currentPage) + 1}`);
      const item = await axios.get(`${configData.ADMIN_URL}/api/clients?city=${city}&state=${state}&name=${name}&page=${Number(currentPage) + 1}`)
      setTodos(item.data.data);
     
      setTotalTodos(item.data.total);

    setcurrentPage(Number(currentPage) + 1);
    console.log(Number(currentPage) + 1);

    if (Number(currentPage) + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  async function handlePrevbtn()  {

      history.push(`?city=${city}&state=${state}&name=${name}&currentpage=${Number(currentPage) - 1}`);
      const item = await axios.get(`${configData.ADMIN_URL}/api/clients?city=${city}&state=${state}&name=${name}&page=${Number(currentPage) - 1}`)
      setTodos(item.data.data);
      
      setTotalTodos(item.data.total);


    setcurrentPage(Number(currentPage) - 1);

    if ((Number(currentPage) - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }
        return (
            <>
                {seo.map((item, index) => (
                 <Helmet key={index}>  
                 <title>{item.pagetitle}</title>
                 <meta name="description" content={item.metadescription} />
                 <meta name="keywords" content={item.metakeywords} />
                 <meta property="og:type" content="Page" />
                 <meta property="og:title" content={item.pagetitle} />
                 <meta property="og:description" content={item.metadescription} />
                 <meta property="og:url" content={window.location.href} />
                 <link rel="canonical" href={`${configData.CANONICAL_URL}our-clients.php`} />
                 <meta property="og:site_name" content={configData.SITE_NAME} />
                 <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_banner}`} />
                 </Helmet> 
                ))}
                <Header2 />
                <div className="page-content">
                  <div className="sx-bnr-inr overlay-wraper bg-center bg-cover" style={{ backgroundImage: 'url(' + bnrimg + ')' }}>
                    <div class="overlay-main bg-black opacity-05"></div>
                    <div className="container">
                        <div className="sx-bnr-inr-entry">
                            <div className="banner-title-outer text-center p-0-100">
                                <div className="banner-title-name ">
                                    <h2 className="m-tb0 mb-3">Our Clients</h2>   
                                                                
                                    <p className="fs-15 lh-1">
                                     <ReadMore> By focusing our efforts to maintain a customer relationship with interactive approach and best customer service, we are able to bring our clients life-long customers with high levels of customer satisfaction. We believed that client’s trust and satisfaction is more important and we build it every day by delivering creative designs and outstanding service.

These are several of the thousands of clients across all over India even abroad that have praised our customer service satisfaction, expertise in delivery of quality drawings and have seen their dream come true of making a “Dream Home” of  their choice...</ReadMore>
                                    </p>
                                </div>

                                <div class="input-group mb-3">
                                  <input onChange={(e) => setName(e.target.value)} type="text" class="form-control br-10" placeholder="Name" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                  <input onChange={(e) => setCity(e.target.value)} type="text" class="form-control" placeholder="City" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                  <input onChange={(e) => setState(e.target.value)} type="text" class="form-control " placeholder="State" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                  <div class="input-group-append">
                                    <span onClick={Search} class="input-group-text br-r-10" id="basic-addon2">Search</span>
                                  </div>
                                  <span onClick={refreshFilters} className="refresh-filter" ><i className="fa fa-refresh"></i></span>
                                </div>
                                
                            </div>
                            {/* BREADCRUMB ROW */}
                            
                            
                            {/* BREADCRUMB ROW END */}
                        </div>
                    </div>
                </div>
                    {/* OUR Team START */}
                    <div className="section-full p-t80 p-b50 mobile-page-padding">
                        <div className="container">
                            {/* IMAGE CAROUSEL START */}
                            <div className="section-content">
                            <p className="d-none">Showing {currentPage} to {itemsPerPage*currentPage} of <span className="red">{totalTodos}</span> entries</p>
                                <div className="row our-team-2-outer">
                                    {todos.map((item, index) => (
                                        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                            <div className="our-team-2">
                                                <div className="figcaption text-center">
                                                    <h4 className="m-t0 red">{item.name}</h4>
                                                    <span>{item.city}, {item.state}</span>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="paginate">
                                    <ul className="pageNumbers">
                                        <li>
                                          <button
                                            onClick={handlePrevbtn}
                                            disabled={currentPage === pages[0] ? true : false}
                                          >
                                            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Previous
                                          </button>
                                        </li>
                                        {pageDecrementBtn}
                                        {renderPageNumbers}
                                        {pageIncrementBtn}

                                        <li>
                                          <button
                                            onClick={handleNextbtn}
                                            disabled={currentPage === pages[pages.length - 1] ? true : false}
                                          >
                                            Next <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                                          </button>
                                        </li>
                                       

                                      </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* OUR Team END */}
                </div>
                <ToastContainer />
                <Footer />
            </>
        );
    
};

export default Clients;