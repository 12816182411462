import React from "react";
import HomeChrome from "./HomeChrome";
import HomeFirefox from "./HomeFirefox";


const Home1 = () => {
return (
<> {(() => {
    if (navigator.userAgent.indexOf("Firefox") !== -1) {
      return <HomeFirefox /> ;
    } else {
      return <HomeChrome /> ;
    }
  })()}
  </>
)
}

export default Home1;
