import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
//config url
import configData from "./../../config.js";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useContext } from "react";
import { IPInfoContext } from "ip-info-react";

const PtnrBanner = (props) => {
  const userInfo = useContext(IPInfoContext);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm(); // Destructure setError and errors from useForm
  let history = useHistory();
  const [countryInfo, setCountryInfo] = useState(null);
  const [ip, setIp] = useState(null);
  const [code, setCode] = useState("");
  const [number, setNumber] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    const fetchCountryData = async () => {
      setIp(userInfo.ip);
      if (ip) {
        try {
          const response = await fetch(`https://api.country.is/${ip}`);
          const data = await response.json();
          setCountryInfo(data.country);
        } catch (error) {
          console.error("Error fetching country data:", error);
        }
      }
    };
    fetchCountryData();
  }, [userInfo.ip, ip]);

  const handleChangeNumber = (number) => {
    setNumber(number);
    if (number) {
      try {
        const phoneNumber = parsePhoneNumberFromString(number);
        const countryCode = phoneNumber ? phoneNumber.countryCallingCode : "";
        const nationalNumber = phoneNumber ? phoneNumber.nationalNumber : "";
        setCode(countryCode);
        setValue(nationalNumber);

        if (isValidPhoneNumber(number)) {
          setError("mobile", { type: "manual", message: "" }); // Clear error if valid
        } else {
          setError("mobile", {
            type: "manual",
            message: "Invalid phone number",
          });
        }
      } catch (error) {
        setError("mobile", { type: "manual", message: "Invalid phone number" });
      }
    } else {
      setCode("");
      setValue("");
      setError("mobile", {
        type: "manual",
        message: "Phone number is required",
      });
    }
  };

  const submitForm = async (data) => {
    if (isValidPhoneNumber("+" + code + value)) {
      try {
        const response = await fetch(`${configData.ADMIN_URL}/api/contact`, {
          method: "POST",
          body: JSON.stringify({
            name: data.name,
            email: data.email,
            mobile: code + value,
            requirement: data.requirement,
          }),
          headers: {
            Accept: "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();

        if (result.data.success) {
          history.push("/thanks.php");
        }
      } catch (err) {
        toast.error("Try again", { autoClose: 3000 });
      }
    } else {
      toast.error("Invalid Phone Number", { autoClose: 3000 });
    }
  };

  const handleError = (errors) => {
    toast.error("All fields must be filled", { autoClose: 3000 });
  };

  const registerOptions = {
    name: { required: "Name is required" },
    email: { required: "Email is required" },
    requirement: { required: "Requirement is required" },
  };

  return (
    <>
      <div
        className="sx-bnr-inr overlay-wraper bg-top-center for-mbl-op"
        data-stellar-background-ratio="0.5"
        style={{
          backgroundImage: "url(" + props.bgimage + ")",
          backgroundSize: "contain",
          paddingTop: 100,
          paddingBottom: 100,
        }}
      >
        <div className="container">
          <div className="sx-bnr-inr-entry">
            <div className="baner-txt-and-form">
              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="banner-title-outer">
                  <div className="banner-title-name ptr-all-bnr-txxt"></div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12">
                <div className="col-lg-8 col-md-12 col-sm-12 form-ext-col">
                  <div className="contact-home1-left contact-home1-left-v2 shadow-lg bg-white p-a30 cont-form-int-ero">
                    <form
                      onSubmit={handleSubmit(submitForm, handleError)}
                      className=""
                    >
                      <div className="input input-animate ptnr-form">
                        <label htmlFor="name">Name</label>
                        <input
                          {...register("name", registerOptions.name)}
                          type="text"
                          className="input-field"
                        />
                        {errors.name && (
                          <p className="errorMsg">{errors.name.message}</p>
                        )}{" "}
                        {/* Display inline error */}
                        <span className="spin" />
                      </div>
                      <div className="input input-animate ptnr-form">
                        <label htmlFor="email">Email</label>
                        <input
                          {...register("email", registerOptions.email)}
                          type="email"
                          className="input-field"
                        />
                        {errors.email && (
                          <p className="errorMsg">{errors.email.message}</p>
                        )}{" "}
                        {/* Display inline error */}
                        <span className="spin" />
                      </div>
                      <div className="input input-animate ptnr-form-2">
                        <label htmlFor="Phone">Phone</label>
                        <div className="phone-input">
                          <PhoneInput
                            {...register("mobile", {
                              required: "Phone number is required",
                            })}
                            international
                            fieldId="number"
                            className="p-1 register-input"
                            defaultCountry={countryInfo || ""}
                            countryCallingCodeEditable={false}
                            value={number || ""}
                            placeholder="Enter phone number"
                            onChange={handleChangeNumber}
                          />
                        </div>
                        {errors.mobile && (
                          <p className="errorMsg">{errors.mobile.message}</p>
                        )}{" "}
                        {/* Display inline error */}
                        <span className="spin" />
                      </div>

                      <div className="form-group ptnr-form-textarea">
                        <label htmlFor="requirement">
                          Plot Size & requirements
                        </label>
                        <textarea
                          {...register(
                            "requirement",
                            registerOptions.requirement
                          )}
                          className="form-textarea"
                          placeholder="Enter Plot Size & requirement"
                        ></textarea>
                        {errors.requirement && (
                          <p className="errorMsg">
                            {errors.requirement.message}
                          </p>
                        )}{" "}
                        {/* Display inline error */}
                        <span className="spin" />
                      </div>

                      <div className="text-left">
                        <button
                          type="submit"
                          className="site-button-secondry btn-half"
                          style={{ width: "100%", padding: 10 }}
                        >
                          <span style={{ letterSpacing: "normal" }}>
                            Submit
                          </span>
                        </button>
                      </div>

                      <p className="ptr-form-accept-plcy">
                        By Submitting this form, you agree to the privacy policy
                        and terms of use.
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default PtnrBanner;
