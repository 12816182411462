import React from 'react';
import { NavLink } from 'react-router-dom';
//config url
import configData from "./../../config.js";

class BlogSidebar extends React.Component {
   
        // Constructor 
    constructor(props) {
        super(props);
   
        this.state = {
           
            categories: [],
            //DataisLoaded: false
        };
    }

     //API CALL
    componentDidMount() {
       
        fetch(
        `${configData.ADMIN_URL}/api/blogcategories`)
        .then((res) => res.json())
        .then((json) => {
            this.setState({
                categories: json.data
            });
        })
    }

    render() {

         const {  categories } = this.state;

        return (
            <>
                <div className="side-bar p-a10 ">
                    {/* SEARCH */}
                    <div className="widget d-none">
                        <h4 className="widget-title mb-3">Search</h4>
                        <div className="search-bx p-a10 bg-white ">
                            <form role="search" method="post" action="#">
                                <div className="input-group">
                                    <input name="news-letter" type="text" className="form-control bg-gray" placeholder="Write your text" />
                                    <span className="input-group-btn bg-gray">
                                        <button type="button" className="btn"><i className="fa fa-search" /></button>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* RECENT POSTS */}
                    
                    {/* Categories  */}
                    <div className="widget widget_services ">
                        <h4 className="widget-title mb-3">Categories</h4>
                        <ul className="p-a10 bg-white blog-list">
                        {categories.map((item, index) => (
                            <li key={index}><NavLink to={"blogs?category=" + item.id}>{item.category_name}<span> </span></NavLink></li> 
                         ))}   
                        </ul>
                    </div>
                    {/* NEWSLETTER */}
                    <div className="widget widget_newsletter-2 d-none">
                        <h4 className="widget-title  mb-3">Newsletter</h4>
                        <div className="newsletter-bx p-a10 bg-white">
                            <div className="newsletter-content">
                                <p>Subscribe to our mailing list to get the update to your email.</p>
                            </div>
                            <div className="m-t20">
                                <form role="search" method="post" action="#">
                                    <div className="input-group">
                                        <input name="news-letter" className="form-control" placeholder="ENTER YOUR EMAIL" type="text" />
                                        <span className="input-group-btn">
                                            <button type="button" className="site-button"><i className="fa fa-paper-plane-o" /></button>
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* OUR GALLERY  */}
                    <div className="widget widget_gallery mfp-gallery d-none">
                        <h4 className="widget-title  mb-3">Our Gallery</h4>
                        <ul className="p-a10 bg-white clearfix">
                            <li>
                                <div className="sx-post-thum">
                                    <a href={require('./../../images/gallery/pic1.jpg').default} className="mfp-link"><img src={require('./../../images/gallery/thumb/pic1.jpg').default} alt=""/></a>
                                </div>
                            </li>
                            <li>
                                <div className="sx-post-thum ">
                                    <a href={require('./../../images/gallery/pic2.jpg').default} className="mfp-link"><img src={require('./../../images/gallery/thumb/pic2.jpg').default} alt=""/></a>
                                </div>
                            </li>
                            <li>
                                <div className="sx-post-thum  ">
                                    <a href={require('./../../images/gallery/pic3.jpg').default} className="mfp-link"><img src={require('./../../images/gallery/thumb/pic3.jpg').default} alt="" /></a>
                                </div>
                            </li>
                            <li>
                                <div className="sx-post-thum ">
                                    <a href={require('./../../images/gallery/pic4.jpg').default}  className="mfp-link"><img src={require('./../../images/gallery/thumb/pic4.jpg').default} alt="" /></a>
                                </div>
                            </li>
                            <li>
                                <div className="sx-post-thum ">
                                    <a href={require('./../../images/gallery/pic5.jpg').default} className="mfp-link"><img src={require('./../../images/gallery/thumb/pic5.jpg').default} alt=""/></a>
                                </div>
                            </li>
                            <li>
                                <div className="sx-post-thum ">
                                    <a href={require('./../../images/gallery/pic6.jpg').default} className="mfp-link"><img src={require('./../../images/gallery/thumb/pic6.jpg').default} alt="" /></a>
                                </div>
                            </li>
                            <li>
                                <div className="sx-post-thum">
                                    <a href={require('./../../images/gallery/pic7.jpg').default} className="mfp-link"><img src={require('./../../images/gallery/thumb/pic7.jpg').default} alt="" /></a>
                                </div>
                            </li>
                            <li>
                                <div className="sx-post-thum ">
                                    <a href={require('./../../images/gallery/pic8.jpg').default} className="mfp-link"><img src={require('./../../images/gallery/thumb/pic8.jpg').default} alt=""/></a>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/* TAGS */}
                    <div className="widget widget_tag_cloud d-none">
                        <h4 className="widget-title mb-3">Tag Cloud</h4>
                        <div className="tagcloud p-a10 bg-white">
                            <NavLink to={"/blog-masonry"}>Kitchen</NavLink>
                            <NavLink to={"/blog-masonry"}>Food</NavLink>
                            <NavLink to={"/blog-masonry"}>Planining</NavLink>
                            <NavLink to={"/blog-masonry"}>Between </NavLink>
                            <NavLink to={"/blog-masonry"}>Chairs</NavLink>
                            <NavLink to={"/blog-masonry"}>Lawn</NavLink>
                            <NavLink to={"/blog-masonry"}>Table</NavLink>
                            <NavLink to={"/blog-masonry"}>Mantinance</NavLink>
                            <NavLink to={"/blog-masonry"}>Room</NavLink>
                            <NavLink to={"/blog-masonry"}>Landscape </NavLink>
                            <NavLink to={"/blog-masonry"}>Bedroom </NavLink>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default BlogSidebar;