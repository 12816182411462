import React from 'react';

var bgimg1 = require('./../../images/background/bg5.jpg');

const InteriorSection3 = ()  => {
        return (
            <>
                <div className="section-full mobile-page-padding p-t30 p-b30 bg-no-repeat bg-cover bg-center" style={{ backgroundImage: 'url(assets/images/whatsapp-image-2021-10-22-at-15254_2.webp)' }}>
                        <div className="container">
                            <div className="section-content">
                                <div className="row">
                                    <div className="col-xl-5 col-lg-5 col-md-12 d-none">
                                        <div className="home-2-about bg-bottom-left bg-no-repeat bg-cover" style={{ backgroundImage: 'url(' + bgimg1.default + ')' }}>
                                        </div>
                                    </div>
                                    <div className="col-xl-12 col-lg-12 col-md-12">
                                        <div className=" text-center">
                                            <h4 className="m-t0 sx-tilte fsm-25  mt-3 mb-1 fs-40 h1-main2">Can't find yours?</h4>
                                            
                                            <div className="text-center mb-2">
                                            <a href="https://wa.me/+918010822233" className="site-button2 fw-400"><i className="fa fa-whatsapp"></i> Get it On WhatsApp</a>
                                            </div>

                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        );
    
};

export default InteriorSection3;