import React from 'react';
import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation  } from 'react-router-dom';
import Header2 from './../../Common/Header2';
import Footer from './../../Common/Footer';
import Recommended from './../../Elements/Recommended';
import About5 from './../../Elements/About5';
import axios from "axios";
import { Helmet } from "react-helmet"; 
import InteriorSection1 from './../../Elements/InteriorSection1';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
import configData from "./../../../config.js";

const commercial = [
    { image: require('./../../../images/banquet_hall.webp'),
      title: 'Banquet Hall',
      url:   '/hotel-and-banquet-hall-interior-designs.php'
       },
    { image: require('./../../../images/gym.webp'),
      title: 'GYM Interior',
      url:   '/gym-and-yoga-center.php' },
    { image: require('./../../../images/jewellery.webp'),
      title: 'Jewellery',
      url:   '/jewellery-showroom-interior-designs.php' },
      { image: require('./../../../images/office.webp'),
      title: 'Office ',
      url:   '/office-interior-designs.php' },
    { image: require('./../../../images/showroom.webp'),
      title: 'Showrooms',
      url:   '/show-room-interior-designs.php' },
    { image: require('./../../../images/restaurant.webp'),
      title: 'Restaurants',
      url:   '/restaurant-interior-designs.php' },   
]

const residential = [
    { image: require('./../../../images/bedroom_area.webp'),
      title: 'Bedroom',
      url:   '/bedroom-interior-designs.php'
       },
    { image: require('./../../../images/kitchen.webp'),
      title: 'Kitchen',
      url:   '/kitchen-interior-designs.php' },
       { image: require('./../../../images/Living-room.webp'),
      title: ' living Area',
      url:   '/living-room-interior-designs.php' },
    { image: require('./../../../images/dining.webp'),
      title: ' Dinning Area',
      url:   '/dining-interior-designs.php' },
    { image: require('./../../../images/kids.webp'),
      title: 'Kids room',
      url:   '/kids-room-interior-designs.php' },
    { image: require('./../../../images/toilet.webp'),
      title: 'Toilet',
      url:   '/toilet-interior-designs.php' },

    { image: require('./../../../images/drawing.webp'),
      title: 'Drawing Room',
      url:   '/drawing-room-interior-designs.php' },

       { image: require('./../../../images/entarnce.webp'),
      title: 'Entrance',
      url:   '/entrance-interior-design.php'  },
       { image: require('./../../../images/pooja.webp'),
      title: 'Pooja',
      url:   '/puja-room-interior-designs.php' }
]
var bgimg1 = require('./../../../images/background/bg-12.jpg');
    const InteriorCategory = () => {
          let history = useHistory();
          const [seo, setSeo] = useState([]);
          const [page_content, setPage_content] = useState("");
          const [todos, setTodos] = useState([]);
          const [type, setType] = useState([]);
          const [totalTodos, setTotalTodos] = useState("");
          const [currentPage, setcurrentPage] = useState(1);
          const [itemsPerPage, setitemsPerPage] = useState(9);
          const [pageNumberLimit, setpageNumberLimit] = useState(5);
          const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
          const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
          const location = useLocation();
           //Getting Url Params
           const search = useLocation().search;
          const currenPageParms = new URLSearchParams(search).get('currentpage');
         //contact form
           function handleClick(url) {
            async function fetchProducts() {
               
             try {
              const item = await axios.get(`${configData.ADMIN_URL}/api/interior-designing${url}`)
              setTodos(item.data.data);
              //setSeo(item.data.seo);
              let pageSeo = await axios.get(`${configData.ADMIN_URL}/api/metapageurl?page_url=${pageName}`)
              setSeo(pageSeo.data);
              setPage_content(pageSeo.data[0].page_content);
              setitemsPerPage(12);
              setpageNumberLimit(5);
             } catch (error) {
              console.log(error);
             }
            }
            
            fetchProducts();
        }
         var slug = window.location.pathname; 
         var cat = slug.replace(".php", ""); 
         //var catName = slug.replace(".php", ""); 
         console.log(slug)
         var pageName = window.location.pathname.replace("/", "");
          useEffect(() => {
            if(slug === '/hotel-and-banquet-hall-interior-designs.php') {
                setType(commercial);
                //setBanner('Banquet_Hall_Interior_Online.webp');
            }
            else if(slug === '/gym-and-yoga-center.php'){
                setType(commercial);
               // setBanner('GYM_Interior_Design_online.webp');
            }
            else if(slug === '/jewellery-showroom-interior-designs.php'){
                setType(commercial);
               // setBanner('Jewellery_Showroom_interior_online.webp');
            }
            else if(slug === '/office-interior-designs.php'){
                setType(commercial);
                ///setBanner('Office_Interior_Design_Online.webp');
            }
            else if(slug === '/show-room-interior-designs.php'){
                setType(commercial);
               // setBanner('Showroom_Interior_Design_Online.webp');
            }
            else if(slug === '/restaurant-interior-designs.php'){
                setType(commercial);
               // setBanner('Restaurant_Interior_Design_Online.webp');
            }
            else if(slug === '/bedroom-interior-designs.php'){
                setType(residential);
                //setBanner('Bedroom_Interior_Design_Online.webp');
            }
            else if(slug === '/kitchen-interior-designs.php'){
                setType(residential);
                //setBanner('Kitchen_Interior_Design_Online.webp');
            }
             else if(slug === '/living-room-interior-designs.php'){
                setType(residential);
                //setBanner('Living_Room_Interior_Design.webp');
            }
            else if(slug === '/dining-interior-designs.php'){
                setType(residential);
                //setBanner('Dining_Area_Interior_Design_Online.webp');
            }
            else if(slug === '/kids-room-interior-designs.php'){
                setType(residential);
                //setBanner('Kids_Room_Interior_Design_Online.webp');
            }
            else if(slug === '/toilet-interior-designs.php'){
                setType(residential);
               // setBanner('Toilet_Interior_Design_Online.webp');
            }
            else if(slug === '/drawing-room-interior-designs.php'){
                setType(residential);
               // setBanner('Drawing_Room_Interior_Design.webp');
            }
            else if(slug === '/entrance-interior-design.php'){
                setType(residential);
                //setBanner('Entrance_Foyer_Interior_Design_Online.webp');
            }
            else if(slug === '/puja-room-interior-designs.php'){
                setType(residential);
                //setBanner('Puja_Space_Interior_Design_Online.webp');
            }
            else {
                setType(residential);
            }
            async function fetchProducts() {

                if(currenPageParms) {
               //console.log(currenPageParms);
              if(currenPageParms !== 'null') {
                setcurrentPage(currenPageParms);
                //console.log(currentPage);
              }
              }
              
             try {
              const item = await axios.get(`${configData.ADMIN_URL}/api/interior-designing${cat}?currentpage=${currenPageParms ? currenPageParms : 1}`)
              setTodos(item.data.data);
              let pageSeo = await axios.get(`${configData.ADMIN_URL}/api/metapageurl?page_url=${pageName}`)
              setSeo(pageSeo.data);
              setPage_content(pageSeo.data[0].page_content);
              setTotalTodos(item.data.total);
              //setType(item.data.type);
              setcurrentPage(item.data.currentpage);
              //console.log(item.data.type);
             // console.log(item.data.total);
              //const similar = item.data.type;
             } catch (error) {
              console.log(error);
             }
            }
            fetchProducts();
        },  [location.key, cat,currenPageParms,  currentPage,pageName, slug ]);

            // Get current Todo
  const pages = [];
  for (let i = 1; i <= Math.ceil(totalTodos / itemsPerPage); i++) {
    pages.push(i);
  }

  const paginate = pageNumber => setcurrentPage(pageNumber);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={() => paginate(number)}
          className={currentPage === number ? "active" : null}
        >
         <NavLink onClick={() => paginate(number)} to={"?currentpage=" + number} className='page-link'>
              {number}
            </ NavLink>
       
        </li>
      );
    } else {
      return null;
    }
  });


    async function handleNextbtn() {
      
      history.push(`?currentpage=${Number(currentPage) + 1}`);
      const item = await axios.get(`${configData.ADMIN_URL}/api/interior-designing${cat}?currentpage=${Number(currentPage) + 1}`)
      setTodos(item.data.data);
      let pageSeo = await axios.get(`${configData.ADMIN_URL}/api/metapageurl?page_url=${pageName}`)
      setSeo(pageSeo.data);
      setPage_content(pageSeo.data[0].page_content);
      setTotalTodos(item.data.total);

    setcurrentPage(Number(currentPage) + 1);
    console.log(Number(currentPage) + 1);

    if (Number(currentPage) + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  async function handlePrevbtn()  {

      history.push(`?currentpage=${Number(currentPage) - 1}`);
      const item = await axios.get(`${configData.ADMIN_URL}/api/interior-designing${cat}?currentpage=${Number(currentPage) - 1}`)
      setTodos(item.data.data);
      let pageSeo = await axios.get(`${configData.ADMIN_URL}/api/metapageurl?page_url=${pageName}`)
      setSeo(pageSeo.data);
      setPage_content(pageSeo.data[0].page_content);
      setTotalTodos(item.data.total);


    setcurrentPage(currentPage - 1);

    if ((Number(currentPage) - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }
  
        return (
            <>
            {seo.map((item, index) => (
            <Helmet key={index}>  
            <title>{item.pagetitle}</title>
            <meta name="description" content={item.metadescription} />
            <meta name="keywords" content={item.metakeywords} />
            <meta property="og:type" content="Page" />
            <meta property="og:title" content={item.pagetitle} />
            <meta property="og:description" content={item.metadescription} />
            <meta property="og:url" content={`${configData.BASE_URL}${location.pathname}`} />
            <link rel="canonical" href={`${configData.BASE_URL}${location.pathname}`}/> 
            <meta property="og:site_name" content={configData.SITE_NAME} />
            <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_banner}`} />
            </Helmet> 
             ))} 
                <Header2 />
                <div className="page-content">
                {seo.map((item, index) => (
                 <InteriorSection1 key={index} page_name={item.page_name} short_des_heading={item.short_des_heading} sub_title={item.sub_title} short_description={item.short_description}   />
                ))}
                  {seo.map((item, index) => ( 
                <div key={index}>
                    <ul className="sx-breadcrumb breadcrumb-style-2 bg-black pl-2 pb-1">
                    <div className="container">
                    <li className="red"><NavLink className="red" to={"./"}>Home </NavLink></li>
                        <li className="red"><NavLink className="red" to={"/interior-designing.php"}>Interior Designing </NavLink></li>
                        <li>{item.page_name}</li>
                        </div>
                    </ul>
                </div>
                 ))}
                    {/* SECTION CONTENT START */}
                    <div className="section-full pt-5 inner-page-padding">
                        <div className="container">
                                

                              <ul className="masonry-outer mfp-gallery work-grid row clearfix list-unstyled">
                                  {todos.map((item, index) => (
                                      <div key={index} className= "cat-1 masonry-item  col-lg-4 col-md-6 col-sm-12 m-b30">
                                       <a className="mfp-link" href={`${configData.ADMIN_URL}/assets/page_images/large/` + item.l_image }>
                                          <div className="sx-box image-hover-block">
                                          <div className="popup-gallery">
                                              <a className="mfp_image" href={`${configData.ADMIN_URL}/assets/page_images/large/` + item.l_image }>   
                                               <div className="sx-thum-bx">
                                                  <img alt={item.i_name} className="wh-350" src={`${configData.ADMIN_URL}/assets/page_images/large/` + item.l_image } />
                                              </div>

                                                 
                                                </a>
                                          </div> 
                                          </div>
                                           </a>
                                      </div>
                                  ))}
                              </ul>

                                <div className="paginate">
                                    <ul className="pageNumbers">
                                        <li>
                                          <button
                                            onClick={handlePrevbtn}
                                            disabled={currentPage === pages[0] ? true : false}
                                          >
                                            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Previous
                                          </button>
                                        </li>
                                        {pageDecrementBtn}
                                        {renderPageNumbers}
                                        {pageIncrementBtn}

                                        <li>
                                          <button
                                            onClick={handleNextbtn}
                                            disabled={currentPage === pages[pages.length - 1] ? true : false}
                                          >
                                            Next <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                                          </button>
                                        </li>
                                       

                                      </ul>
                                </div>
                           
                        </div>
                    </div>
                   
                       <div className="section-full  mobile-page-padding bg-gray  p-t20 p-b10 bg-repeat " style={{ backgroundImage: 'url(' + bgimg1.default + ')' }}>
                    <div className="container">
                      
                        <div className="section-head">
                            <div className="sx-separator-outer separator-left">
                               
                                   <h3 className="sep-line-one">Similar <span className='red'>Categories</span> <span className="red v-align-m"><i class="fa fa-long-arrow-right"></i></span></h3>
                            
                            </div>
                        </div>
                        <div className="section-content">
                            <div className="client-grid m-b20">
                                <div className="row">
                                    {type.map((item, index) => (
                                        <div className="col-lg-2 col-md-2 col-sm-3 col-4  m-b10 p-1" key={index}>
                                        <div className="shadow-cs">
                                            <NavLink onClick={() => handleClick(item.url)}  to={item.url} className="client-logo-pic">
                                                <img className='w-100' src={item.image} alt={item.title} />
                                                <div>
                                                    <span><i className="fa fa-search"></i></span>
                                                </div>
                                            </NavLink>
                                             <NavLink onClick={() => handleClick(item.url)} to={item.url} ><p className='interior_area_text bg-white'>{item.title}</p>  </NavLink>
                                        </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    <Recommended  title="Recommended Services" bgcolor="bg-white" />
                    <About5 page_content={page_content} />
                </div>
                <Footer />
            </>
        );
};

export default InteriorCategory;