/*eslint react-hooks/exhaustive-deps: "off"*/
import React, { Suspense } from "react";
import Header2 from "./../Common/Header2";
import Footer from "./../Common/Footer";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import axios from "axios";
//config url
import configData from "./../../config.js";

var bnrimg = require("./../../images/banner/hdr_bnr5ea91895128d6Our_clients.jpg");

const SearchList = () => {
  let history = useHistory();
  const search = useLocation().search;
  async function refreshFilters(event) {
    setKeyword("");
    event.preventDefault();
    history.push(`/search.php`);
  }

  //const [seo, setSeo] = useState([]);
  const [todos, setTodos] = useState([]);
  const [suggestions, setSuggestions] = useState([])
  const [hidden, setHidden] = useState(true)
  const [totalTodos, setTotalTodos] = useState("");
  const [message, setMessage] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(20);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [keyword, setKeyword] = useState("");

  //Getting Url Params
  const location = useLocation();

  const currenPageParms = new URLSearchParams(search).get("currentpage");

  useEffect(() => {
    setKeyword(new URLSearchParams(search).get("keywords"));
    async function fetchProducts() {
      if (currenPageParms) {
        if (currenPageParms !== "null") {
          setcurrentPage(currenPageParms);
        }
      }

      try {
        const config = {
          headers: {
            Accept: "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            "Content-Type": "application/json",
          },
        };
        var data = new URLSearchParams(search).get("keywords");
        const item = await axios.get(
          `${configData.ADMIN_URL}/api/search?keywords=${
            keyword ? keyword : data
          }&page=${currenPageParms ? currenPageParms : 1}`,
          config
        );
        setTodos(item.data.data.designs);
        setitemsPerPage(20);
        setpageNumberLimit(5);
        setTotalTodos(item.data.data.total);
        if (item.data.data.total === 0) {
          setMessage(true);
        } else {
          setMessage(false);
        }
        setcurrentPage(item.data.data.current_page);
      } catch (error) {
        console.log(error);
      }
    }

    fetchProducts();
  }, [location.key, currenPageParms, search]);

  async function Search(event) {
    event.preventDefault();
    // alert(keyword)
    history.push(`?keywords=${keyword}`);
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "x-api-key": configData.X_API_KEY,
          "x-api-secret": configData.X_API_SECRET,
          "Content-Type": "application/json",
        },
      };
      const item = await axios.get(
        `${configData.ADMIN_URL}/api/search?keywords=${keyword}&page=${
          currenPageParms ? currenPageParms : 1
        }`,
        config
      );
      setTodos(item.data.data.designs);
      console.log(item.data.data.designs);
      setTotalTodos(item.data.data.total);
      if (item.data.data.total === 0) {
        setMessage(true);
      } else {
        setMessage(false);
      }
      setcurrentPage(item.data.data.current_page);
    } catch (error) {
      console.log(error);
    }
  }

  const pages = [];
  for (let i = 1; i <= Math.ceil(totalTodos / itemsPerPage); i++) {
    pages.push(i);
  }
  const paginate = (pageNumber) => setcurrentPage(pageNumber);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={() => paginate(number)}
          className={currentPage === number ? "active" : null}
        >
          <NavLink
            onClick={() => paginate(number)}
            to={`?keywords=${keyword}&currentpage=` + number}
            className="page-link"
          >
            {number}
          </NavLink>
        </li>
      );
    } else {
      return null;
    }
  });

  async function handleNextbtn() {
    history.push(`?keywords=${keyword}&currentpage=${Number(currentPage) + 1}`);
    const item = await axios.get(
      `${configData.ADMIN_URL}/api/search?keywords=${keyword}&page=${
        Number(currentPage) + 1
      }`
    );
    setTodos(item.data.data);
    //setSeo(item.data.seo);
    setcurrentPage(item.data.data.current_page);
    setTotalTodos(item.data.data.total);

    setcurrentPage(Number(currentPage) + 1);
    console.log(Number(currentPage) + 1);

    if (Number(currentPage) + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  }

  async function handlePrevbtn() {
    history.push(`?keywords=${keyword}&currentpage=${Number(currentPage) - 1}`);
    const item = await axios.get(
      `${configData.ADMIN_URL}/api/search?keywords=${keyword}&page=${
        Number(currentPage) - 1
      }`
    );
    setTodos(item.data.data);
    // setSeo(item.data.seo);
    setTotalTodos(item.data.data.total);
    setcurrentPage(item.data.data.current_page);

    setcurrentPage(currentPage - 1);

    if ((Number(currentPage) - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  }

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  const selectKeyword = (event) => {
    history.push(`/search.php?keywords=${event.currentTarget.id}`);
  };

  async function handleKeyDown(event) {
    setKeyword(event.target.value);
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "x-api-key": configData.X_API_KEY,
          "x-api-secret": configData.X_API_SECRET,
          "Content-Type": "application/json",
        },
      };
      const item = await axios.get(
        `${configData.ADMIN_URL}/api/search?keywords=${event.target.value}&page=${
          currenPageParms ? currenPageParms : 1
        }`,
        config
      );
     
      setSuggestions(item.data.data.keywords)
      if(item.data.data.keywords.length > 0){
        setHidden(false)
      }
      else {
        setHidden(true)
      }
    
    } catch (error) {
      console.log(error);
      setHidden(true)
    }
    if (event.key === "Enter") {
      history.push(`?keywords=${event.target.value}`);
      try {
        const config = {
          headers: {
            Accept: "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            "Content-Type": "application/json",
          },
        };
        const item = await axios.get(
          `${configData.ADMIN_URL}/api/search?keywords=${keyword}&page=${
            currenPageParms ? currenPageParms : 1
          }`,
          config
        );
        setTodos(item.data.data.designs);
        setSuggestions(item.data.data.keywords)
        if(item.data.data.keywords.length > 0){
          setHidden(false)
        }
        else {
          setHidden(true)
        }
        setTotalTodos(item.data.data.total);
        if (item.data.data.total === 0) {
          setMessage(true);
         
        } else {
          setMessage(false);
        }
        setcurrentPage(item.data.data.current_page);
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <>
      <Header2 />
      <Suspense fallback={<div></div>}>
      <div className="page-content">
        <div
          className="sx-bnr-inr overlay-wraper bg-center bg-cover"
          style={{ backgroundImage: "url(" + bnrimg + ")" }}
        >
          <div class="overlay-main bg-black opacity-05"></div>
          <div className="container">
            <div className="sx-bnr-inr-entry">
              <div className="banner-title-outer text-center p-0-100">
                <div className="banner-title-name ">
                  <h2 className="m-tb0 mb-3 text-capitalize">
                    {keyword ? keyword : ""}
                  </h2>
                </div>
                <div class="input-group">
                  <input
                    onKeyDown={handleKeyDown}
                    
                    type="text"
                    class="form-control br-10"
                    placeholder={keyword ? keyword : "Enter keywords to search"}
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                  />

                  <div class="input-group-append">
                    <span
                      onClick={Search}
                      class="input-group-text br-r-10"
                      id="basic-addon2"
                    >
                      Search
                    </span>
                  </div>
                  <span onClick={refreshFilters} className="refresh-filter">
                    <i className="fa fa-refresh"></i>
                  </span>
                </div>
                {hidden ? '' :  
                <div className="search-page-suggestions">
                  <ul>
                    {suggestions.map((item) => (
                      <li id={item} onClick={selectKeyword}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </div> }
               
                
              </div>
            </div>
          </div>
        </div>
        {/* OUR Team START */}
        <div className="section-full p-t80 p-b50 mobile-page-padding">
          <div className="container">
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row our-team-2-outer">
                {message ? (
                  <>
                    <div className="text-center">
                      <img
                        alt="no data found"
                        className="w-50"
                        src="assets/images/no-data-empty-data.jpg"
                      />
                      <h3>No Data Found!</h3>
                    </div>
                  </>
                ) : null}

                {todos.map((item) => (
                  <>
                   {item.plan_no !== null ? <div
                 
                    className="col-lg-3 col-md-3 col-sm-6 col-6 m-b10 p-2 mm-b30 mobile-grip"
                  >
                   
                    <div className="sx-box sx-product-box overflow-hide shadow-cs">
                      <div className="sx-thum-bx">
                        <>
                        {(() => { 
                        if(item.plan_no.indexOf('HD') > -1) {
                           return (
                            <NavLink to={item.slug.includes("106") ? item.slug.replace("//", "/") :
                          `${item.slug}/106`}>
                              {(() => {
                             if(item.primary_images.length > 0 ){
                              if (
                                item.primary_images[0].match(
                                  /\.(jpg|jpeg|png|svg|webp)$/i
                                )
                              ) {
                                return (
                                  <div
                                    className="bg-contain bg-center h-200 bg-no-repeat"
                                    style={{
                                      backgroundImage: `url('${item.primary_images[0]}')`,
                                    }}
                                  />
                                );
                              } else {
                                return (
                                  <div
                                    className="bg-contain bg-center h-200 bg-no-repeat"
                                    style={{
                                      backgroundImage: `url('${item.primary_images[1]}')`,
                                    }}
                                  />
                                );
                              }
                            }
                          })()}
                        </NavLink>
                           )      
                        }
                        else if(item.plan_no.indexOf('CD') > -1) {
                          return (
                            <NavLink to={`${item.slug.replace(/\/\d+$/, '')}/126/${item.id}`}>
                             {(() => {
                              if(item.other_images.length > 0 ){
                                if (
                                  item.other_images[0].match(
                                    /\.(jpg|jpeg|png|svg|webp)$/i
                                  )
                                ) {
                                  return (
                                    <div
                                      className="bg-contain bg-center h-200 bg-no-repeat"
                                      style={{
                                        backgroundImage: `url('${item.other_images[0]}')`,
                                      }}
                                    />
                                  );
                                } else {
                                  return (
                                    <div
                                      className="bg-contain bg-center h-200 bg-no-repeat"
                                      style={{
                                        backgroundImage: `url('${item.other_images[1]}')`,
                                      }}
                                    />
                                  );
                                }
                              }
                           
                         })()}
                       </NavLink>
                          )      
                       }
                       else {
                        return (
                          <NavLink to={item.slug.replace("/106", '')}>
                           {(() => {
                          if(item.primary_images.length > 0 ){
                           if (
                             item.primary_images[0].match(
                               /\.(jpg|jpeg|png|svg|webp)$/i
                             )
                           ) {
                             return (
                               <div
                                 className="bg-contain bg-center h-200 bg-no-repeat"
                                 style={{
                                   backgroundImage: `url('${item.primary_images[0]}')`,
                                 }}
                               />
                             );
                           } else {
                             return (
                               <div
                                 className="bg-contain bg-center h-200 bg-no-repeat"
                                 style={{
                                   backgroundImage: `url('${item.primary_images[1]}')`,
                                 }}
                               />
                             );
                           }
                         }
                       })()}
                     </NavLink>
                        )      
                     }
                        
                      })()}
                        </>
                      </div>
                      <div className="p-t10 pl-2 pr-2 text-left">
                        <h5 className="sx-title fsm-15 fs-16">
                        {(() => {
                          if(item.plan_no.indexOf('HD') > -1) {
                            return (
                          <NavLink to={item.slug.includes("106") ? item.slug.replace("//", "/") :
                          `${item.slug}/106`}>
                          
                            {item.title} 
                          </NavLink>  ) }
                          else if(item.plan_no.indexOf('CD') > -1) {
                            return (
                              <NavLink to={`${item.slug.replace(/\/\d+$/, '')}/126/${item.id}`}> {item.title} 
                              </NavLink>
                            )
                          }
                          else {
                            return (
                              <NavLink to={item.slug.replace("/106", ``)}> {item.title} 
                              </NavLink>
                            )
                          }
                         })()}
                        </h5>
                        <ul className=" m-b10 category_info dm-flex">
                          <li>
                            <i class="fa fa-expand"></i> {item.width} sqft
                          </li>
                          <li className="pl-20">
                            <i className="fa fa-compass" /> {item.direction}{" "}
                            Facing
                          </li>
                        </ul>
                        <p className="category_code_text">
                          Code: {item.plan_no}{" "}
                          {(() => {
                             if(item.plan_no.indexOf('HD') > -1) {
                              return (
                                <NavLink to={item.slug.includes("106") ? item.slug.replace("//", "/") :
                          `${item.slug}/106`}>
                                <span className="float-right view_details mobile-hidden">
                                  View details
                                </span>
                                <span className="float-right desktop-hidden">
                                  <i className="fa fa-eye"></i>
                                </span>
                              </NavLink>  ) }
                                else if(item.plan_no.indexOf('CD') > -1) {
                                  return (
                                <NavLink to={`${item.slug.replace(/\/\d+$/, '')}/126/${item.id}`}>
                                <span className="float-right view_details mobile-hidden">
                                  View details
                                </span>
                                <span className="float-right desktop-hidden">
                                  <i className="fa fa-eye"></i>
                                </span>
                              </NavLink>
                                  )
                                }
                                else {
                                  return (
                                    <NavLink to={item.slug.replace("/106", '')}>
                                <span className="float-right view_details mobile-hidden">
                                  View details
                                </span>
                                <span className="float-right desktop-hidden">
                                  <i className="fa fa-eye"></i>
                                </span>
                              </NavLink>
                                  )
                                }           
                         
                           })()}
                        </p>
                      </div>
                    </div> 

                    
                  </div> : ''}
                  </>
                ))}
              </div>
              {message ? (
                ""
              ) : (
                <div className="paginate">
                  <ul className="pageNumbers">
                    <li>
                      <button
                        onClick={handlePrevbtn}
                        disabled={currentPage === pages[0] ? true : false}
                      >
                        <i class="fa fa-long-arrow-left" aria-hidden="true"></i>{" "}
                        Previous
                      </button>
                    </li>
                    {pageDecrementBtn}
                    {renderPageNumbers}
                    {pageIncrementBtn}

                    <li>
                      <button
                        onClick={handleNextbtn}
                        disabled={
                          currentPage === pages[pages.length - 1] ? true : false
                        }
                      >
                        Next{" "}
                        <i
                          class="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* OUR Team END */}
      </div>
      </Suspense>
      <Footer />
    </>
  );
};

export default SearchList;
