import React from "react";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";
//config url
import configData from "./../../config.js";

const Profile11 = () => {
  const { register, handleSubmit } = useForm();

  const submitForm = async (data) => {
    console.log(data);
    try {
      const response = await fetch(
        `${configData.ADMIN_URL}/api/referral/store`,
        {
          method: "POST",
          body: JSON.stringify({
            send_to: data.send_to,
            send_mobile: data.send_mobile,
            from_name: data.from_name,
            from_mobile: data.from_mobile,
          }),
          headers: {
            Accept: "application/json",
            "x-api-key": configData.X_API_KEY,
            "x-api-secret": configData.X_API_SECRET,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        toast.error("Please try Again!", { autoClose: 3000 });
      }
      const result = await response.json();

      if (result.data.success) {
        setIsOpen(false);
        toast.success("Thank you for referring, message sent", {
          autoClose: 3000,
        });
      }
      console.log(result);
    } catch (err) {
      console.log(err);
      toast.error("Try again", { autoClose: 3000 });
    }
  };
  const handleError = (errors) => {
    toast.error("All fields must be filled", { autoClose: 1000 });
  };
  const registerOptions = {
    send_to: { required: "required" },
    from_name: { required: "required" },
    from_mobile: { required: "required" },
    send_mobile: { required: "required" },
  };
  const [isOpen, setIsOpen] = React.useState(false);

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <NavLink to={"#"} onClick={showModal}>
        <i className="fa fa-id-card"></i> Refer a Friend
      </NavLink>

      <div>
        <Modal show={isOpen} onHide={hideModal}>
          <Modal.Header>
            <div className="modal-header-img for-refer"></div>
            <p className="formText text-center for-text-refer">
              Refer A Friend
            </p>
            <button
              onClick={hideModal}
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </Modal.Header>

          <Modal.Body>
            <form
              method="post"
              className="text-center"
              onSubmit={handleSubmit(submitForm, handleError)}
            >
              <div class="result"></div>
              <div class="form-group">
                <input
                  {...register("send_to", registerOptions.send_to)}
                  type="email"
                  className="form-input"
                  placeholder="Enter Friend's Email"
                />
              </div>

              <div class="form-group">
                <input
                  {...register("send_mobile", registerOptions.send_mobile)}
                  required=""
                  type="number"
                  className="form-input"
                  placeholder="Enter Friend's Mobile Number"
                />
              </div>

              <div class="form-group">
                <input
                  {...register("from_name", registerOptions.from_name)}
                  required=""
                  type="text"
                  className="form-input"
                  placeholder="Enter Your Name"
                />
              </div>

              <div class="form-group">
                <input
                  {...register("from_mobile", registerOptions.from_mobile)}
                  required=""
                  type="number"
                  className="form-input"
                  placeholder="Enter Your Mobile Number"
                />
              </div>

              <button className="submitBtn" type="submit">
                Submit
              </button>
            </form>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        
      </div>
    </>
  );
};
export default Profile11;
