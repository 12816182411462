import React from "react";
import Header2 from "../Common/Header2";
import Footer from "../Common/Footer";
import Recommended from "./../Elements/Recommended";
import InteriorSideNav from "./../Elements/InteriorSideNav";
import CommomInteriorCollapse from "./../Elements/CommomInteriorCollapse";

const ContemporaryInterior = () => {
  return (
    <>
      <Header2 />
      <div className="page-content">
        <div className="section-full p-t80 p-b50 mobile-page-padding retro-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 sticky_column for-desktop">
                <div className="about4-section m-b20 bg-gray p-a20 for-interior-style-part">
                  <div className="widget widget_services">
                    <InteriorSideNav />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="section-content">
                  <div className="service-single-block m-b30">
                    <div className="sx-media">
                      <img src="assets/images/modeint.webp" alt="" />
                    </div>
                    <h3 className="m-t30 sx-tilte">
                      <b>Contemporary Interior Design</b>
                    </h3>

                    <p>
                      Everyone is searching for an simple and modern-looking
                      interior, or we are able to say, at some point, there’s
                      most likely been a time when we’ve all confused modern
                      interior style with contemporary design.
                    </p>

                    <p>
                      As the contemporary decorating design is dateless and
                      outlined by a classic approach to delivering a space
                      through luxurious simplicity, it’s a style movement that’s
                      well worth the effort and investment because it can never
                      go out of fashion and is easy to update
                    </p>

                    <p>
                      In other words, clean lines play on texture, and quiet
                      drama is key in achieving a wonderfully balanced
                      contemporary style.
                    </p>

                    <h4 className="m-t30 sx-tilte">
                      <b>Why We Love Contemporary Designs:</b>
                    </h4>

                    <p>
                      As the contemporary decorating style is unchanged and
                      outlined by a classic approach to conveyance a space
                      through luxurious simplicity, it’s a style movement that’s
                      well worth the effort and investment because it can never
                      go out of favor and is easy to update.
                    </p>

                    <p>
                      Contemporary design is nice for minimalists looking for an
                      exciting mixture of eras and for those looking to form a
                      simple, yet completely accomplished harmony between
                      variable components while specializing in the disciplined
                      parts of a space.
                    </p>

                    <p>
                      In this Contemporary Design, We mix the future design with
                      present texture and the designs become futuristic and easy
                      to update.{" "}
                    </p>

                    <p>
                      <b>
                        We work on your demands exclusively and design an
                        interior and give your home with a fresh and elegant
                        look. We believe in creativity and furnishing decoration
                        services.Nakshewala.com is largely concentrated on
                        meeting the prospects, need, and budget of the customer
                        to give the best client service and support
                      </b>
                    </p>
                  </div>

                  <div className="col-lg-4 col-md-12 sticky_column for-mobile-interior-view">
                    <div className="about4-section m-b20 bg-gray p-a20">
                      <div className="widget widget_services">
                        <InteriorSideNav />
                      </div>
                    </div>
                  </div>
                  <CommomInteriorCollapse />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>
      <Footer />
    </>
  );
};
export default ContemporaryInterior;
