import React from "react";
import { NavLink } from "react-router-dom";
import Header2 from "./../Common/Header2";
import Footer from "./../Common/Footer";
import Recommended from "./../Elements/Recommended";
import InteriorSideNav from "./../Elements/InteriorSideNav";
import CommomInteriorCollapse from "./../Elements/CommomInteriorCollapse";

const MinimalistInterior = () => {
  return (
    <>
	 
      <Header2 />
      <div className="page-content">
        <div className="section-full p-t80 p-b50 mobile-page-padding retro-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 sticky_column for-desktop">
                <div className="about4-section m-b20 bg-gray p-a20 for-interior-style-part">
                  <div className="widget widget_services">
                    <InteriorSideNav />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="section-content">
                  <div className="service-single-block m-b30">
                    <div className="sx-media">
                      <img src="assets/images/modeint.webp" alt="" />
                    </div>
                    <h3 className="m-t30 sx-tilte">
                      <b>Minimalist Interior</b>
                    </h3>

                    <p>
                      Minimalism design is tied in with keeping a space simple,
                      cleaned up, and complementing the appealing engineering
                      highlights of a space. The range is for the most part
                      monochromatic and variety is utilized as a complement. In
                      simple words, Minimalist architecture includes the
                      utilization of reductive plan components, without
                      ornamentation or enhancement.
                    </p>

                    <p>
                      While minimalism ejected from the advanced development,
                      its definition has extended with its work all through
                      different interior design developments. "In spite of the
                      fact that minimalism is normally connected with a modern
                      and contemporary look,
                    </p>

                    <h4 className="m-t30 sx-tilte">
                      <b>Components Of Minimalism Interior Design</b>
                    </h4>

                    <p>
                      Many people are today acquainted with the idea of
                      minimalism, which includes stripping things down to their
                      most fundamental structure.
                    </p>

                    <div className="single-service-list">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <ul className="list-angle-right anchor-line first">
                            <li>
                              <NavLink to={"/services-1"}>
                                <b>Essential Fact:</b>{" "}
                              </NavLink>
                              <p>
                                The minimalist methodology utilizes just the
                                fundamental components: light, structure, and
                                wonderful materials, ordinarily in an open
                                arrangement format, to make a feeling of
                                opportunity and unwinding. There is no
                                exorbitant decoration and ornamentation.
                              </p>
                            </li>

                            <li>
                              <NavLink to={"/services-1"}>
                                <b>Straight lines:</b>{" "}
                              </NavLink>
                              <p>
                                Minimalism design are built with clean, straight
                                lines and minimal ornamentation. flat, smooth
                                surfaces are major areas of strength and, lines
                                make strong articulations that accentuate the
                                fundamental idea of everything.
                              </p>
                            </li>

                            <li>
                              <NavLink to={"/services-1"}>
                                <b>
                                  Making a minimalist space look warm and
                                  inviting:
                                </b>{" "}
                              </NavLink>
                              <p>
                                Minimalist spaces are unmistakable for their
                                fresh, clean, mess-free, and monochromatic look
                                and to guarantee your minimalist planned space
                                likewise also has an inviting vibe, there are a
                                couple of little touches you can make.
                              </p>
                            </li>

                            <li>
                              <NavLink to={"/services-1"}>
                                <b>Merge various shades and surfaces:</b>{" "}
                              </NavLink>
                              <p>
                                An extraordinary method for carrying warmth to
                                the space is by the utilization of soft wool
                                fabrics, linen wallpaper and floor coverings in
                                the room adds calming warmth.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 sticky_column for-mobile-interior-view">
                    <div className="about4-section m-b20 bg-gray p-a20">
                      <div className="widget widget_services">
                        <InteriorSideNav />
                      </div>
                    </div>
                  </div>

                  <CommomInteriorCollapse />
				
                </div>
              </div>
            </div>
          </div>
        </div>

        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>
      <Footer />
    </>
  );
};
export default MinimalistInterior;
