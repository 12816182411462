import React from "react";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(0, 200) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? (
          <span className="red">...read more</span>
        ) : (
          <span className="red">show less</span>
        )}
      </span>
    </p>
  );
};

class HomePartner extends React.Component {
  render() {
    return (
      <>
        <div className={`${this.props.bgcolor} p-t30 p-b30 bg-pink`}>
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="">
                    <div className=" bg-no-repeat bg-cover bg-center overlay-wraper">
                      <div className="">
                        <div className="video-section-inner">
                          <div className="video-section-content mobile-hidden">
                            <LazyLoadImage
                              className="w-100"
                              effect="blur"
                              src="assets/images/partnerbg.svg"
                              alt="Partner"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className=" m-b30">
                    <h3 className="m-t0 mb-1 sx-tilte fs027">
                      <span className="red">Partner</span> With{" "}
                      <span className="red">Naksh</span>eWala.com
                    </h3>
                    <p className="fs-16">For Contractors & Suppliers</p>

                    <h3 className="m-t0 m-b10 sx-tilte fs025">
                      Always welcome to our team
                    </h3>
                    <p className="partner-text fs-16 fsm-14">
                      <ReadMore>
                        Being an architectural company we always need some
                        trusted contractors, suppliers and other vendors
                        regarding house works like (woodwork, false ceiling
                        work, pop work, plumbing, electrical work, and many much
                        more. Hence only an Architect Company is not sufficient
                        to complete the execution and completion of designs,
                        vendors are always required Nakshewala.com believe in
                        the giving and taking process you give the best you have
                        and we will provide you the best you need. Now Generate
                        more work and profit while working with us. Working
                        together we help each other to grow our businesses to
                        the next level in relevant sectors. To be a part of us,
                        you can fill out the form below, we definitely consider
                        your valuable work and get back to you if required.
                      </ReadMore>
                    </p>
                  </div>
                  <div className="pb-4 desktop-hidden">
                    <LazyLoadImage
                      className="w-100"
                      effect="blur"
                      src="assets/images/partnerbg.svg"
                      alt="Partner"
                    />
                  </div>
                  <div className="row">
                    <div className="col-sm-6 w-50">
                      <h4 className="sx-title fsw205">For Contractors</h4>
                      <ul className="list-angle-right anchor-line anchor-line2">
                        <li>
                          <i class="fa-circle-check"></i> Civil Construction
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Interior
                          Execution{" "}
                        </li>
                      </ul>
                      <div className="">
                        <NavLink
                          to="/get-associated-become-a-partner-nakshewala"
                          className="desktop-btn btn-half mb-3"
                        >
                          <span>Get Associated</span>
                        </NavLink>
                      </div>
                    </div>
                    <div className="col-sm-6 w-50">
                      <h4 className="sx-title fsw205">For Suppliers</h4>
                      <ul className="list-angle-right anchor-line anchor-line2">
                        <li>
                          <i class="fa-circle-check"></i> Raw Material Supplier
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Labor
                          Supplier
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HomePartner;
