/*eslint no-unused-vars: "off"*/
import React from "react";
import Header2 from "../Common/Header2";
import Footer from "../Common/Footer";
import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import About5 from "./../Elements/About5";
import InteriorSection1 from "./../Elements/InteriorSection1";
//config url
import configData from "./../../config.js";

var bannerUrl = `${configData.ADMIN_URL}/assets/completed-project/`;

const CompletedProjects = ({ match }) => {
  const [show, setShow] = useState(false);

  
  const handleShow = () => setShow(true);

  let history = useHistory();
  const location = useLocation();
  const search = useLocation().search;
  const [seo, setSeo] = useState([]);
  const [todos, setTodos] = useState([]);
  const [message, setMessage] = useState(false);
  const [totalTodos, setTotalTodos] = useState("");
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [open, setOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [type, setType] = useState("");
  const currenPageParms = new URLSearchParams(search).get("currentpage");
  const typeParams = new URLSearchParams(search).get("category");
  const [page_content, setPage_content] = useState("");

  const container = React.createRef();
  //setOpen(false);

  const handleButtonClick = () => {
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  window.onunload = function () {
    localStorage.removeItem("selectCat");
  };

  

  //console.log(cat);

  useEffect(() => {
    if (currenPageParms) {
      //console.log(currenPageParms);
      if (currenPageParms !== "null") {
        setcurrentPage(currenPageParms);
        //console.log(currentPage);
      }
    }

    async function fetchProducts() {
     
      try {
        const item = await axios.get(
          `${configData.ADMIN_URL}/api/projects?page=${
            currenPageParms ? currenPageParms : 1
          }&category=${typeParams ? typeParams : type}`
        );
        setTodos(item.data.data);
        setTotalTodos(item.data.total);
        if(item.data.total === 0) {
          setMessage(true);
        }
        else {
          setMessage(false);
        }
        //setPage_content(item.data.seo[0].page_content);
        setcurrentPage(item.data.page);
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=completedprojects.php`
        );
        setSeo(pageSeo.data);
        setPage_content(pageSeo.data[0].page_content);
        const category = await axios.get(
          `${configData.ADMIN_URL}/api/project/categories`
        );
        setCategory(category.data);
        
       
      } catch (error) {
        console.log(error);
      }
    }

    fetchProducts();
  },[currenPageParms, typeParams, type, location.key]);

  async function typeValue(event) {
    var selectCat = event.target.value;
    setType(selectCat);
    console.log(selectCat);
    localStorage.setItem("selectCat", selectCat);
    history.push(`?category=${localStorage.getItem("selectCat")}`);
    let item = await axios.get(
      `${configData.ADMIN_URL}/api/projects?category=${localStorage.getItem(
        "selectCat"
      )}`
    );
    setTodos(item.data.data);
    console.log(item.data.data);
    setcurrentPage(item.data.page);
    setTotalTodos(item.data.total);
    if(item.data.total === 0) {
      setMessage(true);
    }
    else {
      setMessage(false);
    }
  }
  //Reset filter
  async function typeValueAll(event) {
    history.push();
    localStorage.setItem("selectCat", null);
    let allItems = await axios.get(`${configData.ADMIN_URL}/api/projects`);
    setTodos(allItems.data.data);
    console.log(allItems.data.data);
    setcurrentPage(allItems.data.page);
    setTotalTodos(allItems.data.total);
  }
  // Get current Todo
  const pages = [];
  for (let i = 1; i <= Math.ceil(totalTodos / itemsPerPage); i++) {
    pages.push(i);
  }

  //console.log(pages.length);

  

  // Get current Todo

  // Change page
  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={() => paginate(number)}
          className={currentPage === number ? "active" : null}
        >
          <NavLink
            onClick={() => paginate(number)}
            to={"?a=1&currentpage=" + number}
            className="page-link"
          >
            {number}
          </NavLink>
        </li>
      );
    } else {
      return null;
    }
  });

  async function handleNextbtn() {
    history.push(`?a=1&currentpage=${Number(currentPage) + 1}`);
    const item = await axios.get(
      `${configData.ADMIN_URL}/api/projects?page=${Number(currentPage) + 1}`
    );
    setTodos(item.data.data);
    setTotalTodos(item.data.total);
    setcurrentPage(Number(currentPage) + 1);
    if (Number(currentPage) + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  }

  async function handlePrevbtn() {
    history.push(`?a=1&currentpage=${Number(currentPage) - 1}`);
    const item = await axios.get(
      `${configData.ADMIN_URL}/api/projects?page=${Number(currentPage) - 1}`
    );
    setTodos(item.data.data);
    setTotalTodos(item.data.total);

    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  }

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  
  return (
    <>
      {seo.map((item, index) => (
        <Helmet key={index}>  
        <title>{item.pagetitle}</title>
        <meta name="description" content={item.metadescription} />
        <meta name="keywords" content={item.metakeywords} />
        <meta property="og:type" content="Page" />
        <meta property="og:title" content={item.pagetitle} />
        <meta property="og:description" content={item.metadescription} />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={`${configData.CANONICAL_URL}completedprojects.php`} />
        <meta property="og:site_name" content={configData.SITE_NAME} />
        <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_banner}`} />
        </Helmet> 
      ))}

      <Header2 />

      <div className="page-content">
        {seo.map((item, index) => (
          <InteriorSection1
            key={index}
            page_name={item.page_name}
            short_des_heading={item.short_des_heading}
            sub_title={item.sub_title}
            short_description={item.short_description}
          />
        ))}

        <div>
         
        </div>

        <div className="p-t30 p-b50">
          <div className="row">
            <div className="container md-flex" ref={container}>
              <div className="wm-50 col-xl-6 col-lg-6 col-md-6 col-sm-6 m-t20 m-b20">
                <h4 className="fw-600 red text-transform-c">
                  Completed Projects
                </h4>
              </div>
              <div className="wm-50 col-xl-12 col-lg-12 col-md-6 col-sm-6 m-b40 m-t20 text-right mtm--50">
                <button onClick={handleButtonClick} className="filters">
                  <span className="red">
                    <i className="fa fa-filter"></i>
                  </span>{" "}
                  Filters
                </button>
                {open && (
                  <div class="dropdown shadow-css dropdown-top">
                    <div onClick={close} className="clear-all pointer">
                      <i className="fa fa-times"></i> Filters
                    </div>
                    <ul className="filters-list p-a10">
                      <li>
                        <input
                          onChange={typeValueAll}
                          type="radio"
                          id="all"
                          value="all"
                          name="filter"
                        />
                        <label className="fw-600" for="all">
                          All
                        </label>
                      </li>

                      {category.map((item, index) => (
                        <li key={index}>
                          <input
                            onChange={typeValue}
                            type="radio"
                            id={item.title}
                            value={item.id}
                            name="filter"
                          />
                          <label className="fw-600" for={item.title}>
                            {item.title}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="container">
          {message ? (
            <>
              <div className="text-center">
                <img
                  alt="no data found"
                  className="w-50"
                  src="assets/images/no-data-empty-data.jpg"
                />
                <h3>No Data Found!</h3>
              </div>
            </>
          ) : null}
            <div className="d-flex align-items-center justify-content-center">
              <div style={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                {todos.map((item, index) => (
                  <div key={index} className="col-xl-12 col-lg-6 col-md-12 cmpt-pjt-all-img-div">
                    <div className="hlo" variant="primary" onClick={handleShow}>
                      <div className="whole-fst">
                        <div className="whole-dv-snd">
                          <div class="img__wrap for deskk-topps">
                            {/*<div class="img__description_layer">*/}
                            <p class="img__description"></p>
                            {/*</div>*/}
                          </div>

                          {/**********************************/}

                          <div style={{ display: "flex" }}>
                            <div className="col-xl-6 col-lg-6 col-md-12 fist-img-coplte">
                              <div
                                className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b0"
                                style={{
                                  backgroundImage: `url('${
                                    bannerUrl + item.image1
                                  }')`,
                                  margin: 0,
                                  bottom: 0,
                                }}
                              >
                                {/*<div className="overlay-main bg-black opacity-04">
	</div>*/}

                                <div className="video-section-content">
                                  <span className="ripple"></span>

                                  <div className="video-section-bottom for-cmpt-pjt-to-ptex-t">
                                    {item.image1_name ? (
                                      <p className="both-img-right-title-modl">
                                        {item.image1_name}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/*********************/}

                            <div className="col-xl-6 col-lg-6 col-md-12 fist-img-coplte">
                              <div
                                className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b0"
                                style={{
                                  backgroundImage: `url('${
                                    bannerUrl + item.image2
                                  }')`,
                                  bottom: 0,
                                }}
                              >
                                {/*<div className="overlay-main bg-black opacity-04">
	</div>*/}

                                <div className="video-section-content">
                                  <span className="ripple"></span>

                                  <div className="video-section-bottom for-cmpt-pjt-to-ptex-t">
                                    {item.image2_name ? (
                                      <p className="both-img-right-title-modl">
                                        {item.image2_name}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/****************************/}
                        </div>
                      </div>
                    </div>

                    <div className="name-txt-ad-state">
                      <p style={{ marginBottom: 10 }}>
                        <span
                          style={{
                            background: "#c9100f",
                            padding: 10,
                            borderRadius: 20,
                            color: "white",
                            textTransform: "uppercase",
                          }}
                        >
                          {item.client_name}
                        </span>
                      </p>
                      <p
                        style={{
                          margin: 0,
                          fontWeight: 600,
                          textTransform: "capitalize",
                        }}
                      >
                        {item.city}, {item.state}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {message ? '' : 

                                <div className="paginate">
                                    <ul className="pageNumbers">
                                        <li>
                                          <button
                                            onClick={handlePrevbtn}
                                            disabled={currentPage === pages[0] ? true : false}
                                          >
                                            <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Previous
                                          </button>
                                        </li>
                                        {pageDecrementBtn}
                                        {renderPageNumbers}
                                        {pageIncrementBtn}

                                        <li>
                                          <button
                                            onClick={handleNextbtn}
                                            disabled={currentPage === pages[pages.length - 1] ? true : false}
                                          >
                                            Next <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                                          </button>
                                        </li>
                                       

                                      </ul>
                                </div> }
          </div>
        </div>
      </div>

      <About5 page_content={page_content} />

      <Footer />
    </>
  );
};

export default CompletedProjects;
