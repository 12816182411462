import React from "react";
import { NavLink } from "react-router-dom";
import Header2 from "../Common/Header2";
import Footer from "../Common/Footer";
import Recommended from "./../Elements/Recommended";
import InteriorSideNav from "./../Elements/InteriorSideNav";
import CommomInteriorCollapse from "./../Elements/CommomInteriorCollapse";

const RetroInterior = () => {
  return (
    <>
      <Header2 />
      <div className="page-content">
        <div className="section-full p-t80 p-b50 mobile-page-padding retro-page">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 sticky_column for-desktop">
                <div className="about4-section m-b20 bg-gray p-a20 for-interior-style-part">
                  <div className="widget widget_services">
                    <InteriorSideNav />
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="section-content">
                  <div className="service-single-block m-b30">
                    <div className="sx-media">
                      <img src="assets/images/modeint.webp" alt="" />
                    </div>
                    <h3 className="m-t30 sx-tilte">
                      <b>Retro Interior</b>
                    </h3>

                    <p>
                      Retro interior designing is a diverse blend of old styles
                      and new structures or new structures with old materials
                      and completions. Any era can be taken advantage of here,
                      with the 50s, 60s, and 70s the most widely recognized.
                    </p>

                    <p>
                      The proportion of old to new will bring a more
                      extraordinary and characterized comprehension of this look
                      this retro style enhance the look of entire house and
                      space.
                    </p>

                    <p>
                      The surprising style production of Retro is supposed to be
                      increasing day by day. Subsequently, this style will in
                      general be fun with an exuberant vibe, uproarious,
                      occupied, and undoubtedly "crazy". Did you get nostalgic
                      as of now? In this way, Let's have knowledge of the retro
                      inside plan and the most ideal ways to{" "}
                      <b>integrate it into your own plan.</b>
                    </p>

                    <div className="single-service-list">
                      <div className="row">
                        <div className="col-lg-12 col-md-12">
                          <ul className="list-angle-right anchor-line first">
                            <li>
                              <NavLink to={"/"}>
                                <b>Familiar lines:</b>{" "}
                              </NavLink>

                              <p>
                                Retro rooms are continuously brimming with
                                furniture, which might appear to be
                                contradictory to the primary look, all pieces
                                are fairly practical, agreeable, and amicable.
                                To add a retro touch to your plan, consistently
                                pick furniture with clear lines that have a
                                theoretical feel to it.
                              </p>
                            </li>

                            <li>
                              <NavLink to={"/"}>
                                <b>Brilliant Colors:</b>{" "}
                              </NavLink>
                              <p>
                                Most importantly, retro inside plan is a wide
                                plan, so it requires a strong way to deal with
                                colors. Avocado green is one of the most famous
                                retro variety decisions along with marshaled
                                yellow which adds an energetic state of mind.
                                Likewise, mixes of brown with high contrast, and
                                blue with purple will likewise convey a merry
                                retro climate.
                              </p>
                            </li>

                            <li>
                              <NavLink to={"/"}>
                                <b>Layered surfaces:</b>{" "}
                              </NavLink>
                              <p>
                                Retro designs are fun loving and marvelous, yet
                                likewise warm and comfortable. Along these
                                lines, to spread this soul, you should layer
                                your surfaces and combine them as one. For
                                example, you can coordinate metal feet furniture
                                with a wooden garnish. continuously attempt to
                                give you the glow and impressiveness that we're
                                simply referencing. At last, recollect that
                                everything is striking while you're designing in
                                the retro style so don't fear surfaces.
                              </p>
                            </li>

                            <li>
                              <NavLink to={"/"}>
                                <b>Vintage Accents:</b>{" "}
                              </NavLink>
                              <p>
                                Accents of the retro design give a Vintage
                                climate to your space You should simply add a
                                few crazy home accessories add some vintage
                                Texture and Noise to your plan and watch your
                                room ready to shine.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 sticky_column for-mobile-interior-view">
                    <div className="about4-section m-b20 bg-gray p-a20">
                      <div className="widget widget_services">
                        <InteriorSideNav />
                      </div>
                    </div>
                  </div>
                  <CommomInteriorCollapse />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>
      <Footer />
    </>
  );
};
export default RetroInterior;
