import {React, useEffect} from 'react';
import {NavLink, useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux'
import { checkNumber } from '../../features/user/userActions'


var logo = '/assets/images/NAKSHEWALA_LOGO.png';

const Login = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const {error, success, onBoarding } = useSelector(
    (state) => state.user
  )
  useEffect(() => {
    if(error) {
      toast.error(error, {autoClose:3000})
    }
    if(success === 200) {
      history.push("/otp-verify");
    }
    if(onBoarding === false) {
      history.push('/register')
    }
    if(onBoarding === true) {
      history.push('/profile')
    }
   
    
  }, [error,success, onBoarding, history])
  
  const HandleRegistration = async (data) => {
    dispatch(checkNumber({ number: data.number }))
   /* try { 
      const response = await fetch(`${configData.ADMIN_URL}/api/auth/check-mobile`, {
        method: 'POST',
        body: JSON.stringify({
          "mobile": data.number,
        }),
        "headers": {
          "Accept": "application/json",
          "x-api-key": configData.X_API_KEY,
          "x-api-secret": configData.X_API_SECRET,
          "Content-Type": "application/json"
        },
      });
      if (!response.ok) {
        //throw new Error(`Error! status: ${response.message}`);
        const result = await response.json();
        const msg = JSON.stringify(result.errors.mobile);
        toast.error(msg.replace(/[^a-zA-Z 0-9]/g, ''), {autoClose:3000})
        
      }
      const result = await response.json();
      localStorage.setItem('otpNumber', data.number);
      history.push("/otp-verify");
      setStatus(result.status);
    } catch (err) {
      setErr(err.message);
      console.log(err)
    } */
  };
  const handleError = (errors) => {
    toast.error(errors.number.message, {autoClose:1000})
    
  };        
  const registerOptions = {
    number: { required: "Number is required",
   }
  };

return (
<>
<div id="main-wrapper" className="oxyy-login-register">
  <div className="container-fluid px-0">
    <div className="row g-0 min-vh-100 for-mobile-view"> 
      <div className="col-md-5">
        <div className="hero-wrap h-100 bg-primary-2 ">
          <div className="hero-mask opacity-3 bg-primary"></div>
          <div className="hero-content w-100">
            <div className="container d-flex flex-column min-vh-100 for-mobile-lo">
              <div className="row g-0">
                <div className="col-11 col-md-10 col-lg-9 mx-auto">
                  <div className="logo mt-5 mb-5 mb-md-0"> <a className="d-flex for-logo-mobile" href="/" title="Nakshewala"><img src={logo} alt="Nakshewala" /></a> </div>
                </div>
              </div>
              <div className="row g-0 my-auto forlogin-txt-mbl">
                <div className="col-11 col-md-10 col-lg-9 mx-auto">
                  <p className="text-4 lh-base">We're glad to see you again!</p>
                  <h1 className="text-9 fw-600 mb-5 for-txt-one-log">Looking for the best architectural services? Sign Up/Login to get started</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-7 d-flex flex-column">
        <div className="container my-auto py-5 login-input-mobile">
          <div className="row g-0">
            <div className="col-11 col-md-10 col-lg-9 col-xl-8 mx-auto">
              <h3 className="fw-600 mb-3 login-h3">Login or Register </h3>
              <form id="forgotForm" onSubmit={handleSubmit(HandleRegistration, handleError)}>
                <div className="mb-3 d-flex">
                  <img  alt="Nakshewala logo" className='w-8' src="/assets/images/india.png"  /> <span className='custom-padding'>+91</span> 
                  <input  name="number"  {...register('number', registerOptions.number) } type="text" className="form-control bg-ec border-light" id="number" maxLength={10} placeholder="Enter the 10 digit mobile number" />
                 
                </div>
				<p>By continuing, I agree to the <NavLink to={"/terms-and-conditions.php"}>Terms of Use</NavLink> & <NavLink to={"/privacy-policy.php"}>Privacy Policy</NavLink></p>
                <button  type='submit' className='btn btn-primary shadow-none mt-2 text-white first-btn-form'>
                Get OTP
              </button>
			  <p class="d-none have-txt">Have trouble logging in? <span class="text-sp-colr">Get Help</span></p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ToastContainer />
</>
)
}
export default Login;