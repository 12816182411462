import React from "react";
import { NavLink } from 'react-router-dom';
import Header2 from '../../Common/Header2';
import Footer from '../../Common/Footer';
import About5 from './../../Elements/About5';
import Banner2 from './../../Elements/Banner2';
//import AlsoViewed from './../../Elements/AlsoViewed';
import ImageGallery from 'react-image-gallery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Helmet } from "react-helmet"; 
import GetDesign from './../../Elements/GetDesign';
import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import Loader from "./../../Elements/Loader";
import WishList from './Wishlist'
import SimilarWishList from './SimilarWishList';
//config url
import configData from "./../../../config.js";

var url = `${configData.ADMIN_URL}/assets/images/banner/`;
var default_url = `${configData.ADMIN_URL}/assets/gallery/`;

class ElevationDetail extends React.Component {

    constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      isSearchActive: false,
      items: [],
      id: '', 
      style: '',
      images: [],
      similar: [],
      dynamic_fields: [],
      page_id: [],
      alsoViewed: [],
      minWidth: null,
      no_bathroom: true,
      no_bedroom : true,
      no_of_floor: true,
      toilet: true,
      maxWidth: null
    };
    }

     handleSearchToggle = () => {
        this.setState({ isSearchActive: !this.state.isSearchActive });
    };


    handleClick(gid, e){

        fetch(`${configData.ADMIN_URL}/api/commercialBuildingElevation/` + gid)
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                isLoaded: true,
                items: result.data,
                id: result.data.id,
                style: result.data[0].style,
                images: result.images,
                similar: result.similar,
                dynamic_fields: result.dynamicdata,
                page_id: result.prev_page,
                minWidth: result.min_width,
                maxWidth: result.max_width
               
              });
               if(result.data[0].no_bathroom === 0 || result.data[0].no_bathroom === null) {
                 this.setState({
                  no_bathroom: false,       
                });
                
              }
              if(result.data[0].no_bedroom === 0 || result.data[0].no_bedroom === null) {
                this.setState({
                  no_bedroom: false,       
                });
                
              }
              if(result.data[0].no_of_floor === 0 || result.data[0].no_of_floor === null ) {
                this.setState({
                  no_of_floor: false,       
                });
                
              }
              if(result.data[0].toilet === 0 || result.data[0].toilet === null ) {
                this.setState({
                  toilet: false,       
                });
               
              }
              
              
            },
            (error) => {
             this.props.history.push('/error-404');
             
            }
          )

        //alert(gid);
    }


        componentDidMount() {

          window.addEventListener("scroll", this.handleScroll);
          const {match} = this.props;
          //console.log(match.params.id);

          fetch(`${configData.ADMIN_URL}/api/commercialBuildingElevation/` + match.params.id)
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                isLoaded: true,
                items: result.data,
                id: result.data.id,
                style: result.data[0].style,
                images: result.images,
                similar: result.similar,
                dynamic_fields: result.dynamicdata,
                page_id: result.prev_page,
                minWidth: result.min_width,
                maxWidth: result.max_width
               
              });

              if(result.data[0].no_bathroom === 0 || result.data[0].no_bathroom === null) {
                 this.setState({
                  no_bathroom: false,       
                });
                
              }
              if(result.data[0].no_bedroom === 0 || result.data[0].no_bedroom === null) {
                this.setState({
                  no_bedroom: false,       
                });
                
              }
              if(result.data[0].no_of_floor === 0 || result.data[0].no_of_floor === null ) {
                this.setState({
                  no_of_floor: false,       
                });
                
              }
              if(result.data[0].toilet === 0 || result.data[0].toilet === null ) {
                this.setState({
                  toilet: false,       
                });
               
              }
             
              
            },
            (error) => {
           this.props.history.push('/error-404');
            console.log(error);
              
            }
          )
    };

    handleScroll = (event) => {
      this.setState({
        scrollY: window.scrollY,
      });
    };

    render() {
       
        const { error, isLoaded, items, images, similar, page_id, isSearchActive, minWidth, maxWidth, dynamic_fields } = this.state;
        let additionalComponents = "";

        if (this.state.scrollY > 10) {
        additionalComponents = (
          <>
             <Banner2 hTag="h3" />
                 {items.map(item => (
                  <About5 page_content={item.footer_banner} />
                  ))}
                <Footer />
           
          </>
        );
      }
        if (error) {
      return <div><Loader/></div>;
    } else if (!isLoaded) {
      return <Loader />
    } else {

       const options = {
        loop: true,
        autoplay: false,
        center: false,
        items: 4,
        margin: 10,
        nav: false,
        dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 1.5,
                margin: 15,
            },
            640: {
                items: 1.8,
                margin: 15
            },
            768: {
                items: 1.8,
                margin: 10
            },
            991: {
                items: 4.5,
                margin: 10
            },
            1200: {
                items: 4
            }

        }
    };

        return (
            <>
             {items.map(item => (

            <Helmet>  
            <title>{item.pagetitle}</title>
              <meta name="description" content={item.metadescription} />
              <meta name="keywords" content={item.metakeywords} />
              <meta property="og:type" content="Page" />
              <meta property="og:title" content={item.pagetitle} />
              <meta property="og:description" content={item.metadescription} />
              <meta property="og:url" content={window.location.href} />
              <link rel="canonical" href={configData.CANONICAL_URL+window.location.pathname.slice(1)} />
              <meta property="og:site_name" content={configData.SITE_NAME} />
              <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_image}`} />
            </Helmet> 

             ))}

                <Header2 />
                <div className="page-content">
                   
                    {/* SHOP SECTION START */}
                    <div className="section-full p-t80  bg-white mobile-page-padding">
                       {items.map(item => (
                        <div className="container woo-entry">
                            <div className="row mt-4">
                                <div className="col-md-6 col-sm-12 mtm-60">
                                <div className="single-product-title desktop-hidden">
                                    <div className="details_breadcrum pb-3 pt-2">
                                         Home `{'>'}` Readymade House `{'>'}` <span className="red fw-500"></span>
                                    </div>
                                    <h4 className="post-title"><NavLink to={"#"}>{item.title}</NavLink></h4>
                                </div>
                                <div className="text-right shareIcon d-none"><img alt="share" onClick={this.handleSearchToggle} src="/assets/images/share_black_24dp%20(1).svg" /></div>
                                    <div className="sx-box sx-product-gallery on-show-slider">
                                    <WishList id={item.id} item={items}  type={2} />
                                    <ImageGallery renderLeftNav={this.renderLeftNav} renderRightNav={this.renderRightNav} items={images} showPlayButton={false} />
                                     <i class="fa fa-expand image-expand" aria-hidden="true"></i>
                                    </div>
                                     <div className="wt-product-text mobile-hidden">
                                            <p className="text-justify mb-1 mt-3 border-style">
                                             <div className="single-product-title ">
                                             <h2 className="post-title mb-1 fs-custom"><NavLink to={"#"}>{item.title2 ? item.title2 : '' }</NavLink></h2>
                                        </div>
                                       
                                                 <div dangerouslySetInnerHTML={ { __html: item.short_description ? item.short_description : '' } }></div>
                                               
                                           </p>
                                            
                                        </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="product-detail-info">
                                       
                                        <div className="single-product-title mobile-hidden">
                                        <h1 className="post-title pb-3 fs-1rem"><NavLink to={"#"}>{item.title}</NavLink></h1>
                                        </div>
                                        <div className="product-single-price">
                                             <div class="grid-2">
                                             <div class="cell-2 bl-0" id="1"><img alt="share" className="detailIcon" src="/assets/images/focus.svg" /> Plot Area <br />{item.width}</div>

                                            <div class="cell-2 " id="2"><img alt="share" className="detailIcon" src="/assets/images/repeat.svg" /> Plot Dimensions <br />{item.plot_size}</div>
                                            <div class="cell-2 bl-0 bb-0" id="3"><img alt="share" className="detailIcon" src="/assets/images/compass.svg" /> Facing <br /> {item.direction}</div>
                                            <div class="cell-2 bb-0 pt-4m" id="4 "><img alt="share" className="detailIcon" src="/assets/images/comment-edit.svg" /> Style <br /> {item.style}</div>
                                          
                                          </div>
                                        </div>
                                        
                                          <GetDesign plan_no={item.plan_no} />

                                        <div className=" mt-4">
                                             <div className="">
                                        <div class="">
                                        <h4 className="plan_details">Plan Details</h4>

                                        

                                          <div class="grid">
                                            
                                           {this.state.no_bedroom ? <div class="cell" id="1">Bedroom <br /> {item.no_bedroom}</div> : ''} 
                                           {this.state.no_bathroom ? <div class="cell" id="2">Bathroom <br /> {item.no_bathroom}</div> : ''} 
                                           {this.state.no_of_floor ? <div class="cell" id="3">Floor <br /> {item.no_of_floor}</div> : ''} 
                                           {/*{this.state.toilet ? <div class="cell" id="4">Toilet <br /> {item.toilet}</div> : ''} */}
                                           {dynamic_fields.map((item, index) => ( 
                                            <div class="cell" id="4">{item.name} <br /> {item.value}</div> 
                                           ))} 
                                           
                                          </div>
                                        </div>
                                           
                                        </div>
                                        </div>
                                        
                                    </div>
                                </div>

                                 <div className="wt-product-text desktop-hidden container">
                                            <p className="text-justify mb-1 mt-3 border-style">
                                             <div className="single-product-title ">
                                             <h2 className="post-title mb-1 fs-custom"><NavLink to={"#"}>{item.title2 ? item.title2 : '' }</NavLink></h2>
                                        </div>
                                       
                                                 <div dangerouslySetInnerHTML={ { __html: item.short_description ? item.short_description : '' } }></div>
                                               
                                           </p>
                                            
                                        </div>

                               
                            </div>
                            {/* TABS CONTENT START */}
                           
                            
                        </div>
                      
                     ))}
                    </div>
                       
                        <div className="section-full  p-b20  inner-page-padding">
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START */}
                             <div className="section-head">
                                 <div className="sx-separator-outer separator-left">
                               
                                    <h3 className="sep-line-one pb-0">Similar <span className='red'>Projects</span>  <img className="w-3"  alt="share" src="/assets/images/Commercial-icon.svg"  /><NavLink to={`/${page_id}?${page_id}&a=1&psize=${minWidth}x${maxWidth}&direction=&sort=&dcode=&unit=&plotSize=&pwMin=&pwMax=&pdMin=&pdMax=&dynamic_filter=`} className="bg-3e btn view_all float-right">View All</NavLink></h3>
                            
                            </div>
                            </div>
                            {/* TITLE END */}
                            <div className="work-carousel-outer">
                                <OwlCarousel className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center" {...options}>
                                    {similar.map((item, index) => (
                                         <div key={index} className=" m-b10  mm-b30">
                                                <div className="sx-box sx-product-box overflow-hide shadow-cs">
                                                    {(`${item.tagline_color}` === 'yes') ? <div className="shop-pro-sale-bnr">Sale!</div> : ''}
                                                    <div className="sx-thum-bx">
                                                        <NavLink  to={"/" + item.slug.toLowerCase()}>
                                                        {item.header_image ?
                                           <div onClick={this.handleClick.bind(this, item.gid)} className="bg-contain bg-center h-200 bg-no-repeat" style={{ backgroundImage: `url('${url + item.header_image}')` }} />
                                         : <div onClick={this.handleClick.bind(this, item.gid)} className="bg-contain bg-center h-200 bg-no-repeat" style={{ backgroundImage: `url('${default_url + item.image}')` }} />}

                                                        </NavLink>
                                                        <div className="item-cart-view">
                                                            <div className="d-none item-cart-btn">
                                                                <NavLink to={"/" + item.slug.toLowerCase()}>
                                                                    <i className="fa fa-cart-plus" />
                                                                </NavLink>
                                                            </div>
                                                            <SimilarWishList id={item.gid} item={similar} />
                                                        </div>
                                                    </div>
                                                    <div className="p-t10 pl-2 pr-2 text-left">
                                                        <h3 className="sx-title fsm-15 fs-16">
                                                        <NavLink onClick={this.handleClick.bind(this, item.gid)} to={"/" + item.slug.toLowerCase()}>{item.title}</NavLink>
                                                        </h3>
                                                        <ul className=" m-b10 category_info dm-flex">
                                                            <li>
                                                                <i class="fa fa-expand"></i> {item.width} sqft
                                                            </li>
                                                            <li className="pl-20">
                                                                <i className="fa fa-compass" />   {item.direction} Facing
                                                            </li>
                                                        </ul>
                                                        <p className="category_code_text">Code: {item.plan_no} <NavLink to={"/" + item.slug.toLowerCase()} onClick={this.handleClick.bind(this, item.gid)}><span className="float-right view_details mobile-hidden">View details</span><span className="float-right desktop-hidden"><i className="fa fa-eye"></i></span></NavLink></p>
                                                        <span className="price d-none">
                                                            {(`${item.tagline_color}` === 'yes') ? <del><span><span className="Price-currencySymbol">$ </span>{item.cutprice}</span></del> : ''}
                                                            <ins>
                                                                <span><span className="Price-currencySymbol">Rs. </span></span>
                                                            </ins>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                    ))}
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
                {/*<AlsoViewed type="commercial_elevation" title="People Also View" style={this.state.style} id={this.state.id} />*/}
                      
                    </div>
                    {/* SHOP SECTION END */}
                
               <div id="search" className={isSearchActive ? "open" : null}>
                <span className="close" onClick={this.handleSearchToggle}></span>
                <div class="mn-social-bottom-c" align="center">
                   <p>Share this:</p>
                   <a href="/#" class="mn-social-bottom"><i class="fa fa-facebook"></i></a>
                   <a  href="/#" class="mn-social-bottom"><i class="fa fa-twitter"></i></a>
                   
                  <a href="/#" class="mn-social-bottom"><i class="fa fa-whatsapp"></i></a>
                   <a  href="/#" class="mn-social-bottom"><i class="fa fa-pinterest-p"></i></a>
                  
                   
                </div>
            </div>
                
                {additionalComponents}
                
            </>
        );
     }
    };
};

export default ElevationDetail;