import React from 'react';
import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation  } from 'react-router-dom';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import About5 from './../Elements/About5';
import axios from "axios";
import { Helmet } from "react-helmet"; 
import VideoPopup from './../Elements/VideoPopup'; 
import InteriorSection1 from './../Elements/InteriorSection1';  
import configData from "./../../config.js";

var bannerUrl = `${configData.ADMIN_URL}/assets/videos/cover/`;

const VideoWalkThrough = ({ match }) => {

          let history = useHistory();
          const location = useLocation();
          const search = useLocation().search;
          const [seo, setSeo] = useState([]);
          const [todos, setTodos] = useState([]);
         // const [loading, setLoading] = useState(false);
         const [message, setMessage] = useState(false);
          const [totalTodos, setTotalTodos] = useState("");
          const [currentPage, setcurrentPage] = useState(1);
          const [itemsPerPage, setitemsPerPage] = useState(8);
          const [pageNumberLimit, setpageNumberLimit] = useState(5);
          const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
          const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
          const [open, setOpen] = useState(false);
          const [category, setCategory] = useState([]);
          const [type, setType] = useState("");
          const currenPageParms = new URLSearchParams(search).get('currentpage');
          const typeParams = new URLSearchParams(search).get('category');
           const [page_content, setPage_content] = useState("");

           const container = React.createRef();
                //setOpen(false);

            const  handleButtonClick = () => {
                 setOpen(true);
              };


            const  close = () => {
            setOpen(false);
            
            };

             window.onunload = function () {
               localStorage.removeItem('selectCat');
               
             }
       
          useEffect(() => {
            if(currenPageParms) {
            //console.log(currenPageParms);
          if(currenPageParms !== 'null') {
            setcurrentPage(currenPageParms);
            //console.log(currentPage);
          }

        }
            async function fetchProducts() {
               // setLoading(true);
             try {
              const item = await axios.get(`${configData.ADMIN_URL}/api/videos?page=${currenPageParms ? currenPageParms : 1}&category=${typeParams ? typeParams : type}`)
              setTodos(item.data.data);
              setTotalTodos(item.data.total);
               if(item.data.total === 0) {
                setMessage(true);
              }
              else {
                setMessage(false);
              }
              setitemsPerPage(8);
              setpageNumberLimit(5);
              //setPage_content(item.data.seo[0].page_content);
              setcurrentPage(item.data.currentpage);
              let pageSeo = await axios.get(`${configData.ADMIN_URL}/api/metapageurl?page_url=video-walkthrough.php`)
              setSeo(pageSeo.data);
              setPage_content(pageSeo.data[0].page_content);
              const category = await axios.get(`${configData.ADMIN_URL}/api/video/categories`)
              setCategory(category.data);
              //console.log(category.data)
              // setLoading(false);
             } catch (error) {
              console.log(error);
             }
            }
            
            fetchProducts();
        }, [location.key, currenPageParms, type,typeParams ]);


          async function typeValue(event){
          var selectCat = event.target.value;
          setType(selectCat);
          console.log(selectCat);
          localStorage.setItem("selectCat", selectCat);
          history.push(`?category=${localStorage.getItem('selectCat')}`);
          let item = await axios.get(`${configData.ADMIN_URL}/api/videos?category=${localStorage.getItem('selectCat')}`)
          setTodos(item.data.data);
        
          setcurrentPage(item.data.page);
          setTotalTodos(item.data.total);
           if(item.data.total === 0) {
                setMessage(true);
              }
              else {
                setMessage(false);
              }
    };

    //Reset filter
    async function typeValueAll(event){
          history.push();
          let item = await axios.get(`${configData.ADMIN_URL}/api/videos?page=${currenPageParms ? currenPageParms : 1}`)
          setTodos(item.data.data);
          setcurrentPage(item.data.page);
          setTotalTodos(item.data.total);
          if(item.data.total === 0) {
            setMessage(true);
          }
          else {
            setMessage(false);
          }
    };
         const pages = [];
          for (let i = 1; i <= Math.ceil(totalTodos / itemsPerPage); i++) {
            pages.push(i);
          }
          const paginate = pageNumber => setcurrentPage(pageNumber);
          const renderPageNumbers = pages.map((number) => {
            if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
              return (
                <li
                  key={number}
                  id={number}
                  onClick={() => paginate(number)}
                  className={currentPage === number ? "active" : null}
                >
                 <NavLink onClick={() => paginate(number)} to={"?a=1&currentpage=" + number} className='page-link'>
                      {number}
                    </ NavLink>
               
                </li>
              );
            } else {
              return null;
            }
          });


            const handleNextbtn = () => {
            setcurrentPage(currentPage + 1);

            if (currentPage + 1 > maxPageNumberLimit) {
              setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
              setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
            }
          };

          const handlePrevbtn = () => {
            setcurrentPage(currentPage - 1);

            if ((currentPage - 1) % pageNumberLimit === 0) {
              setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
              setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
            }
          };

          let pageIncrementBtn = null;
          if (pages.length > maxPageNumberLimit) {
            pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
          }

          let pageDecrementBtn = null;
          if (minPageNumberLimit >= 1) {
            pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
          }
        return (
            <>
                {seo.map((item, index) => (

                <Helmet key={index}>  
                <title>{item.pagetitle}</title>
                <meta name="description" content={item.metadescription} />
                <meta name="keywords" content={item.metakeywords} />
                <meta property="og:type" content="Page" />
                <meta property="og:title" content={item.pagetitle} />
                <meta property="og:description" content={item.metadescription} />
                <meta property="og:url" content={window.location.href} />
                <link rel="canonical" href={`${configData.CANONICAL_URL}video-walkthrough.php`} />
                <meta property="og:site_name" content={configData.SITE_NAME} />
                <meta property="og:image" content={`${configData.ADMIN_URL}/assets/images/banner/${item.header_banner}`} />
                </Helmet> 

                   ))}


                <Header2 />
                <div className="page-content">

                {seo.map((item, index) => (
                   <InteriorSection1 key={index} page_name={item.page_name} short_des_heading={item.short_des_heading} sub_title={item.sub_title} short_description={item.short_description}  />
                ))}
                
                    <div>
                    <ul className="sx-breadcrumb breadcrumb-style-2 bg-black pl-2 pb-1">
                     <div className="container">
                    <li className="red"><NavLink className="red" to={"./"}>Home </NavLink></li>
                        
                        <li>Video Walkthrough</li>
                        </div>
                    </ul>
                </div>
                    {/* SECTION CONTENT START */}
                    {/* OUR Team START */}
                    <div className="section-full p-t30 p-b50 mobile-page-padding">
                    <div  className="row">
                    <div className="container md-flex"  ref={container}>
                       <div className="wm-50 col-xl-6 col-lg-6 col-md-6 col-sm-6 m-t20 m-b20"><h4 className="fw-600 red text-transform-c">Our Videos</h4></div> 
                        <div className="wm-50 col-xl-12 col-lg-12 col-md-6 col-sm-6 m-b20 m-t20 text-right mtm--50">
                        <button  onClick={handleButtonClick} className="filters">
                        <span className="red"><i className="fa fa-filter"></i></span> Filters</button>
                        {open && (
                        <div class="dropdown shadow-css dropdown-top">
                        <div onClick={close} className="clear-all pointer"><i className="fa fa-times"></i> Filters</div>
                          <ul className="filters-list p-a10">
                          <li>
                            <input onChange={typeValueAll} type="radio"  id="all" value="all" name="filter"  />
                            <label className="fw-600" for="all">All</label> 
                            </li>

                          {category.map((item, index) => (
                            <li>
                            <input onChange={typeValue} type="radio"  id={item.title} value={item.id} name="filter"  />
                            <label className="fw-600" for={item.title}>{item.title}</label> 
                            </li>

                            
                           ))}
                          </ul>
                        </div>
                      )}</div>
                        </div>
                    </div>
                        <div className="container">
                        {message ? (
                          <>
                            <div className="text-center">
                              <img
                                alt="no data found"
                                className="w-50"
                                src="assets/images/no-data-empty-data.jpg"
                              />
                              <h3>No Data Found!</h3>
                            </div>
                          </>
                        ) : null}
                            {/* IMAGE CAROUSEL START */}
                            <div className="section-content">
                                <div className="row our-team-2-outer">
                                    {todos.map((item, index) => (

                                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                         
                                            <div className="our-team-2">
                                                <div className="profile-image">
                                                <VideoPopup url={item.link} />
                                                    <NavLink to={"/#"}><img alt={item.title} src={bannerUrl + item.cover_image}  /></NavLink>
                                                  
                                                </div>
                                                <div className="figcaption text-black text-center">
                                                    <h4 className="m-t0 mb-0"><NavLink className="red fw-600 fs-19" to={"/#"}>{item.title}</NavLink></h4>
                                                    <span>{item.client_name}, {item.country}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {message ? '' : 

                                    <div className="paginate">
                                        <ul className="pageNumbers">
                                            <li>
                                              <button
                                                onClick={handlePrevbtn}
                                                disabled={currentPage === pages[0] ? true : false}
                                              >
                                                <i class="fa fa-long-arrow-left" aria-hidden="true"></i> Previous
                                              </button>
                                            </li>
                                            {pageDecrementBtn}
                                            {renderPageNumbers}
                                            {pageIncrementBtn}

                                            <li>
                                              <button
                                                onClick={handleNextbtn}
                                                disabled={currentPage === pages[pages.length - 1] ? true : false}
                                              >
                                                Next <i class="fa fa-long-arrow-right" aria-hidden="true"></i>
                                              </button>
                                            </li>
                                          

                                          </ul>
                                    </div> }
                            </div>
                        </div>
                    </div>
                      <About5 page_content={page_content} />
                
                </div>

                <Footer />
            </>
        );
   
};

export default VideoWalkThrough;