import React from 'react';
import { NavLink } from 'react-router-dom';
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/css/modal-video.min.css';

var bgimg1 = require('./../../images/video-bg.jpg');

class VideoPopup extends React.Component {
    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    

    render() {

        var video_id =  this.props.url.split("v=")[1].substring(0, 11);

        return (
            <>
                               
                <div className="">
                    <div className="" style={{ backgroundImage: 'url(' + bgimg1.default + ')' }}>
                        <div className="overlay-main bg-black opacity-04" />
                        <div className="video-section-inner">
                            <div className="video-section-content">
                                <NavLink to={"#"} className="play-now" onClick={this.openModal}>
                                    <i className="icon fa fa-play" />
                                    <span className="ripple" />
                                </NavLink>

                            </div>
                        </div>
                    </div>
                </div>
                                
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={video_id} onClose={() => this.setState({ isOpen: false })} />
            </>
        );
    }
};



export default VideoPopup;