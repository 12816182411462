import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class VastuCategories extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: false,
      center: false,
      //stagePadding: 100,

      margin: 40,

      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1.2,
          margin: 20,
          dots: false,
        },
        640: {
          items: 1.2,
          margin: 20,
          dots: false,
        },
        768: {
          items: 1.2,
          margin: 20,
          dots: false,
        },
        991: {
          items: 4.3,
          margin: 15,
          dots: false,
        },
        1200: {
          items: 4.3,
          margin: 15,
        },
      },
    };
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full  mobile-page-padding p-t20 p-b30 `}
        >
          <div className="">
            <div className="container">
              {/* TITLE START */}
              <div className="section-head vastu-constt">
                <div className="sx-separator-outer separator-center">
                  <div className="sx-separator bg-white bg-moving bg-repeat-x living-ani-mation">
                    <h3 className="sep-line-one dir-vatu-dir">
                      Vastu <span className="categories-text-vastu">Categories</span>
                    </h3>
                  </div>
                </div>
              </div>

              {/* TITLE END */}
              <div className="container container-mobile work-carousel-outer for-vastu-cont">
                <OwlCarousel
                  className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center "
                  {...options}
                >
                  <div className="item">
                    <div className="project-mas hover-shadow m-a30 ">
                      <NavLink to={"/vastu-for-bedrooms.php"}>
                        <img
                          class="img-for-ctg-vatu"
                          src={require("./../../../images/vastu-bed-rm.webp")}
                          alt=""
                        />
                      </NavLink>
                      <div className="project-info shadow-cs">
                        <h4 className="m-tb0 dir-title vatu-cat">
                          <NavLink to={"/vastu-for-bedrooms.php"}>
                            Vastu For Bedrooms
                          </NavLink>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="project-mas hover-shadow m-a30 ">
                      <NavLink to={"/vastu-for-interiors.php"}>
                        <img
                          class="img-for-ctg-vatu"
                          src={require("./../../../images/Vastu-Ti-home.webp")}
                          alt=""
                        />
                      </NavLink>
                      <div className="project-info shadow-cs">
                        <h4 className="m-tb0 dir-title vatu-cat">
                          <NavLink to={"/vastu-for-interiors.php"}>
                            Vastu For Interior
                          </NavLink>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="project-mas hover-shadow m-a30 ">
                      <NavLink to={"/vastu-for-kitchen.php"}>
                        <img
                          class="img-for-ctg-vatu"
                          src={require("./../../../images/vist-tu-ktn.webp")}
                          alt=""
                        />
                      </NavLink>
                      <div className="project-info shadow-cs">
                        <h4 className="m-tb0 dir-title vatu-cat">
                          <NavLink to={"/vastu-for-kitchen.php"}>
                            Vastu For Kitchen
                          </NavLink>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="project-mas hover-shadow m-a30 ">
                      <NavLink to={"/vastu-for-new-house-construction.php"}>
                        <img
                          class="img-for-ctg-vatu"
                          src={require("./../../../images/House-Vastu-Plannew.webp")}
                          alt=""
                        />
                      </NavLink>
                      <div className="project-info shadow-cs">
                        <h4 className="m-tb0 dir-title vatu-cat">
                          <NavLink to={"/vastu-for-new-house-construction.php"}>
                            Vastu For New House Construction
                          </NavLink>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="project-mas hover-shadow m-a30 ">
                      <NavLink to={"/vastu-for-pooja-room.php"}>
                        <img
                          class="img-for-ctg-vatu"
                          src={require("./../../../images/poojs-vs-tu.webp")}
                          alt=""
                        />
                      </NavLink>
                      <div className="project-info shadow-cs">
                        <h4 className="m-tb0 dir-title vatu-cat">
                          <NavLink to={"/vastu-for-pooja-room.php"}>
                            Vastu For Pooja Room
                          </NavLink>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="project-mas hover-shadow m-a30 ">
                      <NavLink to={"/vastu-for-toilets.php"}>
                        <img
                          class="img-for-ctg-vatu"
                          src={require("./../../../images/washroom-vatu.webp")}
                          alt=""
                        />
                      </NavLink>
                      <div className="project-info shadow-cs">
                        <h4 className="m-tb0 dir-title vatu-cat">
                          <NavLink to={"/vastu-for-toilets.php"}>
                            Vastu For Toilets
                          </NavLink>
                        </h4>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="project-mas hover-shadow m-a30 ">
                      <NavLink to={"/vastu-house-plans.php"}>
                        <img
                          class="img-for-ctg-vatu"
                          src={require("./../../../images/vastu-home-plans-li.webp")}
                          alt=""
                        />
                      </NavLink>
                      <div className="project-info shadow-cs">
                        <h4 className="m-tb0 dir-title vatu-cat">
                          <NavLink to={"/vastu-house-plans.php"}>
                            Vastu For House Plans
                          </NavLink>
                        </h4>
                      </div>
                    </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default VastuCategories;
