import React from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import configData from "./../../../config.js";
import ExploreButton from "./ExploreButton";
 
const items = [
  {
    "id":474,
    "page_id":null,
    "style":"Twin House Elevation Design",
    "plan_no":"HD386",
    "plot_size":"60X30",
    "no_of_floor":"2",
    "width":1800,
   
    "direction":"South",
    
   
    
    "title":"60X30 Twin House Elevation Design",
   
    "header_image":"60X30_twin_house_elevation_design_1676013248.webp",
    "pagetitle":"60X30 Twin House Elevation Design | 60 by 30 Two Storey House Ideas | 1800 Duplex Design Floor Plan",
    "metakeywords":"60X30 Twin House Elevation Design, 60 by 30 Two Storey House Ideas, 1800 Duplex Design Floor Plan, 30X60 Double Story Front Elevation, 1800 Small Duplex House Plans, 60*30 Modern House Design Plan, Indian House Plans, Ghar ka Naksha, 30 by 60 Best House Plan, South Facing House Designs, 1800 sqft House Plan India",
    "page_content":null,
    "footer_banner":null,
    "banner_text_color":"grey",
    "menu_color":null,
    "tagline_color":null,
    "package1_price":"6900.00",
    "package2_price":"20700.00",
    "addon1_price":"10350.00",
    "addon2_price":"10350.00",
    "addon3_price":"10350.00",
    "addon4_price":"10350.00",
    "slug":"60X30-Twin-House-Elevation-Design-South-facing/474/106",
    "filters":"[\"25\",\"28\"]",
    "status":"Active",
    "created_at":"2022-01-21 10:54:13",
    "updated_at":"2023-02-10 07:14:08",
    "deleted_at":null,
    "page_name":"Two Floor House Design , Popular House Plans , Affordable House Plans , Duplex House Plans , Floor Plans"
    },
    {
    "id":475,
    "page_id":null,
    "style":"Duplex Home With Balcony",
    "plan_no":"HD387",
    "plot_size":"20X37",
    "no_of_floor":"2",
    "width":740,
    "depth":"",
    "no_bedroom":"3",
    "toilet":"",
    "direction":"North",
    "no_bathroom":"3",
    "plan_meta":"{\"Kitchen\":\"1\",\"Dinning\":\"1\",\"Lobby\":\"1\",\"Living room\":\"1\",\"Parking\":\"1\",\"Lawn\":\"1\"}",
    "image":"",
    "gdate":"2022-01-21",
    "large_image":"",
    "second_image":"",
    "second_large":"",
    "third_image":null,
    "third_large":null,
    "forth_image":null,
    "forth_large":null,
    "elevation_image":null,
    "elevation_image1":null,
    "elevation_image2":null,
    "cost":"4900.00",
    "title":"20X37 sqft Duplex Home With Balcony",
    "description":"740 sqft Duplex House Exterior Design",
    "title2":"37*20 sqft 3D Front Elevation",
    "short_description":"<p>We have designed this <strong>20X37 sqft Duplex Home With Balcony </strong>Online. In ground & first floor, we have design a house floor plan, in this plan you can see Parking; Bedroom; Toilet; Kitchen/Dining where, you can enjoy cooking food with aroma; Living Area, you enjoy family get-together/meeting And Staircase. All these rooms design according to vastu. All these floor is beautifully designed and gave a perfect outcome.</p>",
    "title_color":"grey",
    "description_color":"grey",
    "header_image":"20X37_sqft_duplex_home_with_balcony_1676013064.webp",
    "pagetitle":"20X37 sqft Duplex Home With Balcony | 740 Duplex House Exterior Design | 37*20 sqft 3D Front Elevation",
    "metakeywords":"20X37 sqft Duplex Home With Balcony, 740 Duplex House Exterior Design, 37*20 sqft 3D Front Elevation, 37X20 Double Story Front Elevation, 740 Small Duplex House Plans, 20*37 Modern House Design Plan, Indian House Plans, Ghar ka Naksha, 20 by 37 Best House Plan, North Facing House Designs, 740 sqft House Plan India",
    "metadescription":"If you are searching ready-made house about 20X37 sqft Duplex Home With Balcony, 740 Duplex House Exterior Design, 37*20 sqft 3D Front Elevation, 3D Elevation Design and Ghar ka Naksha at affordable cost. So, Buy/Call Now.",
    "page_content":null,
    "footer_banner":null,
    "banner_text_color":"grey",
    "menu_color":null,
    "tagline_color":null,
    "package1_price":"4900.00",
    "package2_price":"14700.00",
    "addon1_price":"7350.00",
    "addon2_price":"7350.00",
    "addon3_price":"7350.00",
    "addon4_price":"7349.00",
    "slug":"20X37-Duplex-Home-With-Balcony-North-facing/475/106",
    "filters":"[\"25\",\"28\"]",
    "status":"Active",
    "created_at":"2022-01-21 10:59:31",
    "updated_at":"2023-02-10 07:11:04",
    "deleted_at":null,
    "page_name":"Two Floor House Design , Vastu House Plans , Popular House Plans , Small House Plans , Affordable House Plans , Duplex House Plans , Floor Plans , Readymade House Design"
    },
    {
    "id":470,
    "page_id":null,
    "style":"Modern Duplex Front Elevation Design",
    "plan_no":"HD382",
    "plot_size":"47X40",
    "no_of_floor":"2",
    "width":1880,
    "depth":"",
    "no_bedroom":"5",
    "toilet":"",
    "direction":"East",
    "no_bathroom":"4",
    "plan_meta":"{\"Lobby\":\"1\",\"Kitchen\":\"1\",\"Living room\":\"1\",\"Parking\":\"1\",\"Open Terrce\":\"1\",\"Store room\":\"1\"}",
    "image":"",
    "gdate":"2022-01-18",
    "large_image":"",
    "second_image":"",
    "second_large":"",
    "third_image":null,
    "third_large":null,
    "forth_image":null,
    "forth_large":null,
    "elevation_image":null,
    "elevation_image1":null,
    "elevation_image2":null,
    "cost":"6900.00",
    "title":"47X40 Modern Duplex Front Elevation Design",
    "description":"1880 Double Floor House Plan",
    "title2":"47 by 40 Modern House Design",
    "short_description":"<p>We have designed this <strong>47X40 Modern Duplex Front Elevation Design </strong>Online. In ground & first floor, we have design a house floor plan, in this plan you can see Parking; Bedroom; Toilet; Kitchen/Dining where, you can enjoy cooking food with aroma; Store Area; Hall Area, you enjoy family get-together/meeting And Staircase. All these design according to vastu. All these floor is beautifully designed and gave a perfect outcome.</p>",
    "title_color":"grey",
    "description_color":"grey",
    "header_image":"47X40_modern_house_design_1676014022.webp",
    "pagetitle":"47X40 Modern Duplex Front Elevation Design | 1880 Double Floor House Plan | 47 by 40 Modern House Design",
    "metakeywords":"47X40 Modern Duplex Front Elevation Design, 1880 Double Floor House Plan, 47 by 40 Modern House Design, 47 by 40 Double Floor House Design, 47X40 Double Story Front Elevation, 1880 Small Duplex House Plans, Indian House Plans , Ghar ka Naksha, 47 by 40 Best House Plan, East Facing House Designs, 1880 House Plan India",
    "metadescription":"If you are searching ready-made house about 47X40 Modern Duplex Front Elevation Design, 1880 Double Floor House Plan, 47 by 40 Modern House Design, 3D Elevation Design and Ghar ka Naksha at affordable cost. So, Buy/Call Now.",
    "page_content":null,
    "footer_banner":null,
    "banner_text_color":"grey",
    "menu_color":null,
    "tagline_color":null,
    "package1_price":"6900.00",
    "package2_price":"20700.00",
    "addon1_price":"10350.00",
    "addon2_price":"10350.00",
    "addon3_price":"10350.00",
    "addon4_price":"10350.00",
    "slug":"47X40-Modern-Duplex-Front-Elevation-Design-East-facing/470/106",
    "filters":"[\"25\",\"28\"]",
    "status":"Active",
    "created_at":"2022-01-18 12:22:24",
    "updated_at":"2023-02-10 07:27:02",
    "deleted_at":null,
    "page_name":"Two Floor House Design , Vastu House Plans , Small House Plans , Affordable House Plans , Simplex House Plan , Floor Plans"
    },
    {
    "id":471,
    "page_id":null,
    "style":"Small Duplex House Design",
    "plan_no":"HD383",
    "plot_size":"53X31",
    "no_of_floor":"2",
    "width":1643,
    "depth":"",
    "no_bedroom":"4",
    "toilet":"",
    "direction":"South",
    "no_bathroom":"4",
    "plan_meta":"{\"Kitchen\":\"1\",\"Living room\":\"2\",\"Dinning room\":\"1\"}",
    "image":"",
    "gdate":"2022-01-18",
    "large_image":"",
    "second_image":"",
    "second_large":"",
    "third_image":null,
    "third_large":null,
    "forth_image":null,
    "forth_large":null,
    "elevation_image":null,
    "elevation_image1":null,
    "elevation_image2":null,
    "cost":"6900.00",
    "title":"53X31 sqft Small Duplex House Design",
    "description":"1643 Duplex Design Floor Plan",
    "title2":"53 by 31 Double Floor House Design",
    "short_description":"<p>We have designed this <strong>53X31 sqft Small Duplex House Design </strong>Online. In ground & first floor, we have design a house floor plan, in this plan you can see Bedroom; Toilet; Kitchen/Dining where, you can enjoy cooking food with aroma; Puja Area; Drawing Area, you enjoy family get-together/meeting And Staircase, all these rooms design according to vastu. All these floor is beautifully designed and gave a perfect outcome.</p>",
    "title_color":"grey",
    "description_color":"grey",
    "header_image":"53X31_small_duplex_house_design_1676013763.webp",
    "pagetitle":"53X31 sqft Small Duplex House Design | 1643 Duplex Design Floor Plan- Nakshewala.Com",
    "metakeywords":"53X31 sqft Small Duplex House Design, 1643 Duplex Design Floor Plan, 53 by 31 Double Floor House Design, 53X31 Double Story Front Elevation, 1643 Small Duplex House Plans, 53*31 Modern House Design Plan, Indian House Plans, Ghar ka Naksha, 53 by 31 Best House Plan, South Facing House Designs, 1643 sqft House Plan India",
    "metadescription":"If you are searching ready-made house about 53X31 sqft Small Duplex House Design, 1643 Duplex Design Floor Plan, 53 by 31 Double Floor House Design, 3D Elevation Design and Ghar ka Naksha at affordable cost. So, Buy/Call Now.",
    "page_content":null,
    "footer_banner":null,
    "banner_text_color":"grey",
    "menu_color":null,
    "tagline_color":null,
    "package1_price":"6900.00",
    "package2_price":"20700.00",
    "addon1_price":"10350.00",
    "addon2_price":"10350.00",
    "addon3_price":"10350.00",
    "addon4_price":"10350.00",
    "slug":"53X31-Small-Duplex-House-Design-South-facing/471/106",
    "filters":"[\"25\",\"28\"]",
    "status":"Active",
    "created_at":"2022-01-18 12:28:05",
    "updated_at":"2023-02-10 07:22:43",
    "deleted_at":null,
    "page_name":"Two Floor House Design , Vastu House Plans , Farm House Plans , Simplex House Plan , Floor Plans"
    },
    {
    "id":472,
    "page_id":null,
    "style":"Duplex Exterior Design",
    "plan_no":"HD384",
    "plot_size":"40X26",
    "no_of_floor":"2",
    "width":1040,
    "depth":"",
    "no_bedroom":"3",
    "toilet":"",
    "direction":"East",
    "no_bathroom":"3",
    "plan_meta":"{\"Parking\":\"1\",\"Dinning \":\"1\",\"Living room\":\"2\",\"Dinning room\":\"2\",\"Puja room\":\"1\",\"Balcony\":\"1\"}",
    "image":"",
    "gdate":"2022-01-18",
    "large_image":"",
    "second_image":"",
    "second_large":"",
    "third_image":null,
    "third_large":null,
    "forth_image":null,
    "forth_large":null,
    "elevation_image":null,
    "elevation_image1":null,
    "elevation_image2":null,
    "cost":"6900.00",
    "title":"40X26 sqft Duplex Exterior Design",
    "description":"1040 Double Story Front Elevation",
    "title2":"40 by 26 Two Floor House Design",
    "short_description":"<p>We have designed this <strong>40X26 sqft Duplex Exterior Design </strong>Online. In ground & first floor, we have design a house floor plan, in this plan you can see Parking; Bedroom; Toilet; Kitchen/Dining where, you can enjoy cooking food with aroma; Pooja Area; Living Area, you enjoy family get-together/meeting And staircase. All these room design according to vastu. All these floor is beautifully designed and gave a perfect outcome.</p>",
    "title_color":"grey",
    "description_color":"grey",
    "header_image":"40X26_sqft_duplex_exterior_design_1676013376.webp",
    "pagetitle":"40X26 sqft Duplex Exterior Design | 1040 Double Story Front Elevation | 40 by 26 Two Floor House Design",
    "metakeywords":"40X26 sqft Duplex Exterior Design, 1040 Double Story Front Elevation, 40 by 26 Two Floor House Design, 1040 Small Duplex House Plans, 40*26 Modern House Design Plan, Indian House Plans, Ghar ka Naksha, 40 by 26 Best House Plan, East Facing House Designs, 1040 sqft House Plan India",
    "metadescription":"If you are searching ready-made house about 40X26 sqft Duplex Exterior Design, 1040 Double Story Front Elevation, 40 by 26 Two Floor House Design, 3D Elevation Design and Ghar ka Naksha at affordable cost. So, Buy/Call Now.",
    "page_content":null,
    "footer_banner":null,
    "banner_text_color":"grey",
    "menu_color":null,
    "tagline_color":null,
    "package1_price":"6900.00",
    "package2_price":"20700.00",
    "addon1_price":"10350.00",
    "addon2_price":"10350.00",
    "addon3_price":"10350.00",
    "addon4_price":"10350.00",
    "slug":"40X26-Duplex-Exterior-Design-East-facing/472/106",
    "filters":"[\"25\",\"28\"]",
    "status":"Active",
    "created_at":"2022-01-18 12:32:35",
    "updated_at":"2023-02-10 07:16:16",
    "deleted_at":null,
    "page_name":"Two Floor House Design , Popular House Plans , Affordable House Plans , Duplex House Plans , Floor Plans"
    },
    
]



class LandingCommercial extends React.Component {
  
  render() {
    const options = {
      loop: true,
      dotsEach: 3,
      autoplay: false,
      center: false,
      //stagePadding: 100,
      //items: 1,
      margin: 40,

      dots: true,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1.2,
          margin: 20,
          //dots: true,
        },
        640: {
          items: 2,
          margin: 20,
          //dots: true,
        },
        768: {
          items: 2,
          margin: 20,
          //dots: true,
        },
        991: {
          items: 4,
          margin: 15,
          nav: true,
          //dots: true,
        },
        1200: {
          items: 4,
          margin: 15,
          nav: true,
       
        },
      },
    };

    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-bg  mobile-page-padding p-t40 p-b30 `}
        >
          <div className="">
            <div className="">
              {/* TITLE START */}
              <div className="section-head container">
                <div className="sx-separator-outer separator-left">
                  
                    <h3 className="sep-line-one">
                      {" "}
                      <span className="red">Commercial Building</span> Elevation{" "}
                      <img
                        alt="trending-img"
                        className="mobile-hidden"
                        src="assets/images/Trending.svg"
                      />
                     <ExploreButton />
                    </h3>
                 
                </div>
              </div>

              {/* TITLE END */}
              <div className="container work-carousel-outer container-mobile">
                <OwlCarousel
                  className="owl-carousel mfp-gallery project-carousel project-carousel3 owl-btn-vertical-center "
                  {...options}
                >
                  {items.map((item, index) => (
                    <div key={index} className="item">
                      <div className="project-mas hover-shadow m-a30 bg-white">
                        <div className="">
                          <div className="image-efct-one">
                            <span className="design_code">
                              Design Code : {item.plan_no}{" "}
                            </span>

                            <NavLink to={item.slug}>
                              <LazyLoadImage
                                className="w-100 h-213"
                                effect="blur"
                                src={
                                  `${configData.ADMIN_URL}/assets/images/banner/` +
                                  item.header_image
                                }
                                alt={item.title}
                              />
                            </NavLink>
                          </div>
                          <div className="project-info ">
                            <h4 className="sx-tilte mt-3 ml-2 fs-17 min-height-40">
                              <NavLink to={item.slug}>{item.title}</NavLink>
                            </h4>
                            <p className="bg-gray p-10 fs-14 shadow-cs text-center">
                              <i class="fa fa-expand"></i> {item.width}
                              sqft&nbsp; &nbsp;
                              <i class="fa fa-retweet"></i> {item.plot_size}
                              &nbsp;&nbsp;
                              <i class="fa fa-compass"></i> {item.direction}
                            </p>
                            <NavLink to={item.slug}>
                              <i className="link-plus bg-primary" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default LandingCommercial;
