import React, { Suspense } from "react";
import { NavLink } from 'react-router-dom';
import Header2 from './../Common/Header2';
import Footer from './../Common/Footer';
import { Helmet } from "react-helmet";
//import Loader from "./../Elements/Loader"; 

class Thanks extends React.Component {
    render() {
        
        return (
            <>
            
            <Suspense fallback={<div></div>}>
            
            <Helmet>
                <title>
                Thanks for your query. Our consultant will get in touch with you soon.
                </title>
               
                </Helmet>
                <Header2 />
                <div className="page-content">
                    
                    {/* SECTION CONTENTG START */}
                    <div className="section-full mobile-page-padding p-tb150 bg-bottom-left bg-no-repeat" style={{ backgroundImage: 'url(images/background/bg-4.png)' }}>
                        <div className="container">
                            <div className="section-content">
                                <div className="page-notfound row">
                                    <div className="col-md-7">
                                        <img src={require('./../../images/thanks.jpg')} alt="404" />
                                    </div>
                                    <div className="col-md-5">
                                        
                                        <p>Thanks for your query. Our consultant will get in touch with you soon.
                                            if your requirement is urgent and you want to discuss now call us at +91-8010822233.</p>
                                            <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                            <div class="btn-group mr-2" role="group" aria-label="First group">
                                                <NavLink to="/"><button type="button" class="btn site-button2 mr-2">Home</button></NavLink>
                                                <NavLink to="/contact-us.php"><button type="button" class="btn btn-secondary thanks-btn">Contact us</button></NavLink>
                                            </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>

                <Footer />
            </Suspense>
            
            </>
        );
    };
};

export default Thanks;