import React from 'react';
import ExploreButton from "./ExploreButton";


const services = [
    {
        count: '1',
        title: 'Get in Touch With Us',
        image: require('./../../../images/services/service-projects/Gtouch.svg'),

        description: 'Reach us through call, mail, whatsapp, or fill our query form along with your plot details and basic requirements. One of our consultants will brief you our services and procedure.',
    },
    {
        count: '2',
        title: 'Getting to Know Your Reuirements',
        image: require('./../../../images/services/service-projects/rett.svg'),
        description: 'Once you are on board after approval on offered services a project manager will be assigned to you. We will be having few rounds of chats with you to understand your requirements in detail.',
    },
    {
        count: '3',
        title: 'Concept Designing',
        image: require('./../../../images/services/service-projects/coptdesign.svg'),
        description: 'The designer will come up with proposed concept design considering your requirements, plot and Vastu, for your review and feedback, be ready to spend some time away from your busy schedules for discussion.',
    },
    {
        count: '4',
        title: 'Correction & Modification',
        image: require('./../../../images/services/service-projects/modifi.svg'),
        description: 'After reviewing the concept design within your family, now its time to discuss with your project manager about the changes and modifications required. Considering the feedback given by you, designer will come up with the revised design. This will be repeated as per your satisfaction.',
    },
    {
        count: '5',
        title: 'Design Handover',
        image: require('./../../../images/services/service-projects/handesign.svg'),
        description: 'On the completion of your project we will mail all the drawings in PDF format or soft copies on special request over the mail.',
    },
    {
        count: '6',
        title: 'Online Support',
        image: require('./../../../images/services/service-projects/support.svg'),
        description: 'Once we handover the final design, our relationship doesn’t end there, we do provide online / telephonic consultancy during Execution.',
    }
]



class Work extends React.Component {
    render() {

        return (
            <>
                <div className="section-full mobile-page-padding p-t10   exat-pd">
                <div className="section-head container">
                <div className="sx-separator-outer separator-left">
                  
                    <h3 className="sep-line-one">
                      {" "}
                      <span className="red">Our</span> Process{" "}
                      <img
                        alt="trending-img"
                        className="mobile-hidden"
                        src="assets/images/Trending.svg"
                      />
                     <ExploreButton />
                    </h3>
                  
                </div>
              </div>
                    <div className="section-content">
                        <div className="container">
                            {/* TITLE START 
                            <div className="section-head mt-4 mb-5" style={{textAlign:"center"}}>
                                <h2 style={{fontWeight: 600}}><span style={{color:"#c9100f"}}>How</span> It Works</h2>
								<p>A perfect platform which provides customized designing services.</p>
                            </div>*/}
                            {/* TITLE END */}
                            <div className="row">
                                {services.map((item, index) => (
                                    <div className="col-lg-4 col-md-6 col-sm-12 m-b30" key={index}>
                                        <div className="sx-icon-box-wraper  icon-count-2-outer cust-all-crd">
                                            <div className="icon-count-2 bg-white">
                                                <span className="icon-count-number for-cuto-page count-numb">{item.count}</span>
												
                                                <div className="icon-xl inline-icon m-b5 scale-in-center">
                                                    <span className="icon-cell"> <div className="sx-media">
                                                <img className="work-card-indi-imgs" src={item.image.default} alt="" />
                                            </div></span>
                                                </div>
												
                                                <div className="icon-content">
                                                    <h2 className="sx-tilte" style={{color:"#c9100f"}}>{item.title}</h2>
                                                    <p>{item.description}</p>
												   {/*<div className="text-left">
                                                        <NavLink to={"/services-detail"} className="site-button-link">Read More</NavLink>
								                    </div>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {/*<div className="hilite-title text-left p-l50 text-uppercase">
                        <strong>Services</strong>
	                </div>*/}
                </div>
            </>
        );
    }
};

export default Work;