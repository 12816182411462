import {React, useEffect} from 'react';
import { useHistory } from "react-router-dom";

const FloorCustom = () => {
    const history = useHistory();
    useEffect(() => {
       history.push('/readymade-floor-plans.php?slct_page=readymade-floor-plans.php&a=1&psize=&direction=&sort=&dcode=RM207&unit=&plotSize=&pwMin=&pwMax=&pdMin=&pdMax=&dynamic_filter=')
      })

    return(
        <></>
    )
}
export default FloorCustom;