import React from "react";
import Header2 from "../../Common/Header2";
import Footer from "../../Common/Footer";
import Recommended from "./../../Elements/Recommended";
import About5 from "./../../Elements/About5";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import HomeCta from "./../../Elements/HomeCta";
import InteriorSection1 from "./../../Elements/InteriorSection1";
import configData from "./../../../config.js";

const VastuKitchen = () => {
  const [seo, setSeo] = useState([]);
  const [page_content, setPage_content] = useState("");

  useEffect(() => {
    async function fetchProducts() {
      try {
        let pageSeo = await axios.get(
          `${configData.ADMIN_URL}/api/metapageurl?page_url=vastu-for-kitchen.php`
        );
        setSeo(pageSeo.data);
        setPage_content(pageSeo.data[0].page_content);
      } catch (error) {
        console.log(error);
      }
    }

    fetchProducts();
  }, []);

  return (
    <>
      {seo.map((item, index) => (
        <Helmet>
          <title>{item.pagetitle}</title>
          <meta name="description" content={item.metadescription} />
          <meta name="keywords" content={item.metakeywords} />
        </Helmet>
      ))}

      <Header2 />

      <div className="page-content">
        {seo.map((item, index) => (
          <InteriorSection1
            page_name={item.page_name}
            short_des_heading={item.short_des_heading}
            sub_title={item.sub_title}
            short_description={item.short_description}
          />
        ))}

        <div className="p-t30 p-b30 ">
          <div className="container">
            <div className="section-content">
              <h2>Vastu For Kitchen</h2>

              <p>
                The kitchen is the heart of every house and everyone loves that
                place. In kitchen interior designing Vastu matters a lot, the
                perfect kitchen position in the home as per Vastu shastra
                guidelines is the southeast corner of the house, which is owned
                by the Fire god or Agni devta.
              </p>
              <p>
                Kitchen Vaastu gives insights into many aspects; the part of the
                house the kitchen is found in, the direction that it faces,
                doors and window placements, and areas where gadgets and
                appliances will be placed, including where the gas cylinder,
                refrigerator, and sink should be located.
              </p>

              <p>
                Our decor experts at nakshewala.com give insights into different
                Vaastu Guidelines and also explain the scientific reasoning
                behind each of them. These will be interpreted and used at your
                discretion within the kitchen.
              </p>

              <h2>As per Vastu choose the best location for your Kitchen</h2>
              <p>
                Southeast or Northwest: Southeast or Northwest are considered
                the most effective locations to line up your home kitchen. When
                cooking was done on woodfired stoves earlier, these corners were
                most preferred since hot winds, blowing from S-W to N-E
                direction, couldn't cause damage to the house by fire. The
                South-East corner is additionally considered the most effective
                since the element of the fireside governs this direction.
              </p>

              <p>
                Avoid the Northeast location for your kitchen. With the
                first-morning sun entering the house from the North and East
                direction, a front room that opens out into other rooms is a
                perfect option. Placing a kitchen there's considered a waste of
                space.
              </p>

              <h2>
                Location of the Doors and Windows as per the Vaastu Shastra{" "}
              </h2>
              <p>
                Doors to the kitchen should be placed within the NORTH, EAST, or
                NORTH-EAST DIRECTION. Since the South-East is taken into account
                as a perfect location for the kitchen, in an L-shaped layout, if
                the countertops are positioned along these two walls, the
                doorway can only be made up of the North or West direction.
              </p>

              <h2>
                Kitchen doors should open clockwise. This helps the bulk of the
                population, which is right-handed.
              </h2>
              <p>
                The main kitchen windows should face the East. With early
                morning sunlight considered beneficial to health and UV rays
                sanitizing the cooking space, it's thought that East facing
                windows allows the flow of sunshine and helps rid the kitchen of
                odors, and germs, and dispel darkness. This sunlight is
                additionally good for homeowners who might not go outside to
                soak it in.
              </p>
            </div>
          </div>
        </div>

        <HomeCta />
        <Recommended title="Recommended Services" bgcolor="bg-white" />
      </div>

      <About5 page_content={page_content} />
      <Footer />
    </>
  );
};
export default VastuKitchen;
