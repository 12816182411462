import {React, useEffect} from 'react';
import { useHistory } from "react-router-dom";

const ReadymadeCustom = () => {
    const history = useHistory();
    useEffect(() => {
       history.push('/readymade-house-design.php?slct_page=readymade-house-design.php&a=1&no_of_floor=&psize=&direction=&sort=&dcode=HD216&unit=&plotSize=&pwMin=&pwMax=&pdMin=&pdMax=&dynamic_filter=')
      })

    return(
        <></>
    )
}
export default ReadymadeCustom;